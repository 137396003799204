<div class="header-container d-flex flex-wrap justify-content-end align-items-center ">
  <div class="date-format me-auto">
   <p class="hour font-size-24 ubuntu-regular">{{ date | dateFormat : 'HH:mm' }}</p>
   <p class="font-size-14 ubuntu-regular">{{ date | dateFormat: 'medium'}}</p>
  </div>

  <div *ngIf="isProd; else elseBlock" class="ubuntu-regular font-size-14">
    v{{infos.version}}
  </div>

  <ng-template #popcontent>
    <div class="ubuntu-regular font-size-14">
      <b>Build number</b> : {{infos.buildNumber}} <br>
      <b>Build time :</b> {{infos.buildTime}} <br>
      <b>Latest commit : </b><a [href]="gitUrl+infos.lastCommit" target="_blank">{{infos.lastCommit}}</a> <br>
      <b>Branch :</b> {{infos.branch}}
    </div>
  </ng-template>

  <ng-template #elseBlock>
    <div class="ubuntu-regular font-size-14 me-5"
      popoverClass="font-size-14"
      [ngbPopover]="popcontent"
      triggers="mouseenter"
      [autoClose]="'outside'">
      v{{infos.version}}
    </div>
  </ng-template>

  <div class="user d-flex">
    <a class="d-flex">
      <span class="font-size-14 ubuntu-regular ms-3">{{ countryName }}</span>
    </a>
    <a class="d-flex" [routerLink]="[ '/profile' ]">
      <div *ngIf="userService.userFirstName" class="user__avatar-letter d-flex justify-content-center align-items-center font-size-18 me-2">
        {{ firstNameFirstLetter }}
      </div>
      <div class="user-info font-size-16 text-nowrap text-lowercase">
        <span class="me-2">{{ userService.userFirstName }}</span>
        <span>{{ userService.userLastName }}</span>
      </div>
    </a>
  </div>

  <div class="logout d-flex justify-content-end align-items-center pointer" (mouseenter)="mouseHovered=true"
    (mouseleave)="mouseHovered=false" (click)="logout()">
    <div class="icon icon-25-16 icon-logout me-1" [ngClass]="{'icon-logout-active':mouseHovered}"></div>
    <div class="font-size-16 d-flex">{{'HEADER.SIGNOUT' | translate}}</div>
  </div>
</div>


