import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'twoDigitsTimeFormat'
})
export class TwoDigitsTimeFormatPipe implements PipeTransform {

  transform(value: number): any {
    if (value) {
      return (value < 10 ? '0' + value : value).toString();
    }
  }
}
