import { Component, OnDestroy } from '@angular/core';
import { VolunteerService } from './volunteer.service';

@Component({
  selector: 'app-volunteer',
  templateUrl: './volunteer.component.html',
  styleUrls: ['./volunteer.component.scss']
})
export class VolunteerComponent implements OnDestroy {

  constructor(public volunteerDataService: VolunteerService) { }


  ngOnDestroy() {
    this.volunteerDataService.step = 1;
    this.volunteerDataService.volunteerData = [];
  }

}
