import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { Document } from '../../../shared/models/document/document';
import { ActivatedRoute, Router } from '@angular/router';
import { IFile } from './../../../shared/interfaces/IFile';
import { DocumentsService } from '../../documents/documents.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
  fileDetails: Document;
  filePath: string;
  file: IFile;
  private destroyRef = inject(DestroyRef);

  constructor(public route: ActivatedRoute, public documentService: DocumentsService, private router: Router) { }

  ngOnInit() {
    this.route.queryParamMap
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(params => {
      this.file = {
        name: params.get('name'),
        lastModified: params.get('lastModified'),
        creationDate: params.get('creationDate'),
        type: params.get('type'),
        size: Number(params.get('size')),
        path: params.get('path'),
        description: params.get('description')
      };
      this.getDownloadLink();
    });
  }

  getDownloadLink() {
    this.documentService.getFilePath(this.file.path)
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(res => {
      this.filePath = res;
    });
  }

  getBack() {
    this.router.navigate(['/trainings']);
  }
}
