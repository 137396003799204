import { DestroyRef, Injectable, inject } from '@angular/core';
import { UnreadMessagesCountResponse } from '../models/message/UnreadMessagesCountResponse';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_URLS } from 'src/environments/api-urls';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()

export class MessagesCountService {

  unreadMessageCount = 0;
  unreadExploitMessageCount = 0;
  unreadOtherMessageCount = 0;
  pendingSwapRequestCount = 0;
  private destroyRef = inject(DestroyRef);

  constructor(private http: HttpClient) {}

  getUnreadMessageCount() {
    return this.http.get<UnreadMessagesCountResponse>(API_URLS.MESSAGE_NOTIFICATION, { headers: new HttpHeaders({ 'Ignore-Loader': 'true' }) })
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(data => {
      this.unreadMessageCount = data.unreadMessageCount;
      this.unreadExploitMessageCount = data.unreadExploitMessageCount;
      this.unreadOtherMessageCount = data.unreadOtherMessageCount;
      this.pendingSwapRequestCount = data.pendingSwapRequestCount;
    });
  }

}
