/**
 * Survey question types
 *
 */
enum QuestionTypeEnum {
  TEXT = 'TEXT',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  SINGLE_CHOICE = 'SINGLE_CHOICE'
}

export default QuestionTypeEnum;
