import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { API_URLS } from 'src/environments/api-urls';
import { Counter } from 'src/app/shared/models/counters/counters';
import { CounterHistory } from 'src/app/shared/models/counters/history';

@Injectable()
export class CountersService {

constructor(private http: HttpClient) { }

  getCounters(): Observable<Counter[]> {
    return this.http.get<Counter[]>(`${API_URLS.COUNTERS}/balance`);
  }

  getCounterDetail(code: string): Observable<CounterHistory> {
    return this.http.get<CounterHistory>(`${API_URLS.COUNTERS}/${code}/balance/detail`);
  }
}
