<div class="history-swap-request tabs-content-container">
  <h3 class="history-swap-request__title font-size-24 ubuntu-bold">
    {{'SWAP_REQUEST.HISTORY.TITLE' | translate}}
  </h3>

  <ul *ngIf="swapRequestHistory?.length" class="history-swap-request__list">

    <li *ngFor="let request of swapRequestHistory" class="history-swap-request__list__item mb-3"
      [attr.data-state]="request.state">
      <a [routerLink]="['/swap/details', request.id]">
        <div class="status d-flex font-size-10 ubuntu-bold text-white text-uppercase align-items-center px-2 py-1 mb-3">
          <ng-container *ngIf="request.state === stateEnum.Accepted">
            <span class="icon icon-8 me-2 icon-checked-white"></span>
            {{ 'SWAP_REQUEST.HISTORY.'+swapStatusEnum.ACCEPTED | translate }}
          </ng-container>

          <ng-container *ngIf="request.state === stateEnum.Refused">
            <span class="icon icon-8 me-2 icon-cross-white"></span>
            {{ 'SWAP_REQUEST.HISTORY.'+swapStatusEnum.DENIED_OPERATIONS | translate }}
          </ng-container>
          
          <ng-container *ngIf="request.state === stateEnum.Expired">
            <span class="icon icon-8 me-2 icon-cross-white"></span>
            {{ 'SWAP_REQUEST.HISTORY.'+swapStatusEnum.EXPIRED | translate }}
          </ng-container>

          <ng-container *ngIf="request.state === stateEnum.Exploit_Pending">
            <span class="icon icon-8 me-2 icon-clock-white"></span>
            {{ 'SWAP_REQUEST.HISTORY.'+swapStatusEnum.PENDING_OPERATIONS | translate }}
          </ng-container>

          <!-- User = Sender -->
          <ng-container *ngIf="request.sender.adminNumber === userService.adminNumber">
            <ng-container *ngIf="request.state === stateEnum.Pending">
              <span class="icon icon-8 me-2 icon-clock-white"></span>
              {{ 'SWAP_REQUEST.HISTORY.'+swapStatusEnum.PENDING_COLLEAGUE | translate }}
            </ng-container>

            <ng-container *ngIf="request.state === stateEnum.Rejected">
              <span class="icon icon-8 me-2 icon-cross-white"></span>
              {{ 'SWAP_REQUEST.HISTORY.'+swapStatusEnum.REFUSED_COLLEAGUE | translate }}
            </ng-container>

            <ng-container *ngIf="request.state === stateEnum.Canceled">
              <span class="icon icon-8 me-2 icon-cross-white"></span>
              {{ 'SWAP_REQUEST.HISTORY.'+swapStatusEnum.CANCELED_COLLEAGUE | translate }}
            </ng-container>
          </ng-container>

          <!-- User = Receiver -->
          <ng-container *ngIf="request.receiver.adminNumber === userService.adminNumber">
            <ng-container *ngIf="request.state === stateEnum.Pending">
              <span class="icon icon-8 me-2 icon-clock-white"></span>
              {{ 'SWAP_REQUEST.HISTORY.'+swapStatusEnum.PENDING | translate }}
            </ng-container>

            <ng-container *ngIf="request.state === stateEnum.Rejected">
              <span class="icon icon-8 me-2 icon-cross-white"></span>
              {{ 'SWAP_REQUEST.HISTORY.'+swapStatusEnum.DENIED | translate }}
            </ng-container>

            <ng-container *ngIf="request.state === stateEnum.Canceled">
              <span class="icon icon-8 me-2 icon-cross-white"></span>
              {{ 'SWAP_REQUEST.HISTORY.'+swapStatusEnum.CANCELED | translate }}
            </ng-container>
          </ng-container>
        </div>

        <div class="history-swap-request__list__item__dates ubuntu-bold font-size-24 mb-3">
          <div *ngIf="request.askedDate">
              {{ request.askedDate | dateFormat : 'medium' }}
          </div>
          <div *ngIf="request.exchangedDate && (request.askedDate | dateFormat : 'DMY') !== (request.exchangedDate | dateFormat : 'DMY')">
              {{ request.exchangedDate | dateFormat : 'medium' }}
          </div>
        </div>

        <div class="text-grey font-size-16">
          {{
            (request.type === swapTypeEnum.SERVICE ? 
            'SWAP_REQUEST.HISTORY.SHIFT_SWAP' : 'SWAP_REQUEST.HISTORY.DAY_OFF_SWAP') | translate
          }}
          <span class="history-swap-request__list__item__colleague-name">
            {{
              request.receiver.adminNumber === userService.adminNumber ?
              request.sender.firstName + ' ' + request.sender.lastName :
              request.receiver.firstName + ' ' + request.receiver.lastName
            }}
          </span>
        </div>
      </a>
    </li>
  </ul>
</div>