import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '../../services/user.service';
import * as moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/fr-ca';
import 'moment/locale/en-gb';
import 'moment/locale/en-au';
import 'moment/locale/en-ie';
import 'moment/locale/en-nz';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  constructor(private userService: UserService) {
    moment().locale(this.userService.site.language.code);
  }

  transform(value: any, args?: any): any {
    if (!value) {
      return '';
    }

    if (!(value instanceof moment)) {
      value = moment(value);
    }
    if (args === 'mini' || args === 'short' || args === 'medium' || args === 'long') {
      return value
        .locale(this.userService.site.language.code)
        .format(this.userService.site.language.dateFormat[args]);
    }

    return value
      .locale(this.userService.site.language.code)
      .format(args);
  }

}
