import {Location} from '@angular/common';

import { ScheduleSettings } from './../../models/settings/settings';

import { UserService } from 'src/app/shared/services/user.service';
import { Component, OnInit, Input } from '@angular/core';
import { PlanningService } from '../../../features/planning/planning.service';
import { ActivatedRoute} from '@angular/router';
import * as moment from 'moment';
import { PeriodType } from 'src/app/shared/enums/home/periodType.enum';
import { ShiftType } from 'src/app/shared/enums/home/shiftType.enum';
import { PlanningDetails } from 'src/app/shared/models/planning/planningDetails';
import { Mission } from '../../models/planning/mission';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: '<app-planning-details>',
  templateUrl: './planning-details.component.html',
  styleUrls: ['./planning-details.component.scss']
})
export class PlanningDetailsComponent implements OnInit {

  @Input() date: moment.Moment;
  @Input() showBanner = false;
  @Input() name: string;
  @Input() adminNumber: string;
  @Input() showBackButton = true;

  viewModel$: Observable<{
    scheduleSettings: ScheduleSettings;
    planningDetails: PlanningDetails;
    queryDate: moment.Moment;
    displayedMissionObservation: boolean;
  }>;
  periodType = PeriodType;
  shiftType = ShiftType;

  constructor(
    public route: ActivatedRoute,
    private planningService: PlanningService,
    public userservice: UserService,
    private location: Location
  ) { }

  ngOnInit() {
    const dateFrom = this.date ? this.date.format('YYYY-MM-DD') : this.route.snapshot.queryParams.from;
    const details$ = this.getDetails(dateFrom);
    this.viewModel$ = combineLatest([details$, this.userservice.typologySetting.asObservable()])
      .pipe(map(([planningDetails, typoSettings]) => {
        return {
          scheduleSettings: typoSettings.scheduleSettings,
          planningDetails,
          queryDate: moment(this.route.snapshot.queryParams.from),
          displayedMissionObservation: typoSettings.scheduleSettings.displayedMissionObservation,
        };
      }));
  }

  getDetails(date: string): Observable<PlanningDetails> {
    return this.planningService.getPlanningDetails(date, this.adminNumber);
  }

  // TODO Avoid call function in the template.
  displayIconInfo(indexMission: number, mission: Mission, previousMission: Mission): boolean {
    if (indexMission === 0) { // is first mission
      return false;
    }
    if (
      mission?.lineNumber !== previousMission?.lineNumber // linNumber not equal
      || mission?.vehicle?.vehicleCode !== previousMission?.vehicle?.vehicleCode
      || mission?.operationCode !== previousMission?.operationCode
      || mission?.missionLabel !== previousMission?.missionLabel
      || mission?.charterNumber !== previousMission?.charterNumber
    ) {
    return true;
    }
    return false;
  }

  getBack(){
    this.location.back();
  }

}
