export enum StateEnum {
    Pending = 'Pending',
    Expired = 'Expired',
    Exploit_Pending = 'Exploit_Pending',
    Accepted = 'Accepted',
    Refused = 'Refused',
    Rejected = 'Rejected',
    Canceled = 'Canceled'
}

export enum SwapStatusEnum {
    PENDING = 'PENDING',
    PENDING_COLLEAGUE = 'PENDING_COLLEAGUE',
    ACCEPTED = 'ACCEPTED',
    REFUSED_COLLEAGUE = 'REFUSED_COLLEAGUE',
    DENIED = 'DENIED',
    DENIED_OPERATIONS = 'DENIED_OPERATIONS',
    CANCELED_COLLEAGUE = 'CANCELED_COLLEAGUE',
    CANCELED = 'CANCELED',
    EXPIRED = 'EXPIRED',
    PENDING_OPERATIONS = 'PENDING_OPERATIONS'
}
