import { Component, Input} from '@angular/core';
import { CalendarDateFormatter, CalendarEvent } from 'angular-calendar';
import { CustomDateFormatter } from '../../../../shared/utils/custom-date-formatter.provider';
import { UserService } from '../../../../shared/services/user.service';
import { PeriodType } from '../../../../shared/enums/home/periodType.enum';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Planning } from '../../../../shared/models/planning/planning';
import { DayType } from 'src/app/shared/enums/home/dayType.enum';

@Component({
  selector: 'app-planning-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }
  ]
})
export class CalendarComponent {

  locale: string;
  @Input() viewDate: Date;
  @Input() events: CalendarEvent[];
  @Input() displayVacationCount: boolean;
  @Input() displayServiceName: boolean;
  periodType = PeriodType;
  dayType = DayType;

  constructor(
    public userService: UserService,
    private router: Router
  ) {
    this.locale = this.userService.site.language.code.slice(0, 2);
  }

  navigateToDetails(day: Planning) {
    const querydate = day.events && day.events[0] ? day.events[0].start : this.viewDate;
    if (day.events && day.events[0] && !day.events[0].meta.estimated) {
      this.router.navigate(['/planning/details'], { queryParams: { from: moment(querydate).format('YYYY-MM-DD') } });
    }
  }
}
