import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CalendarDateFormatter, CalendarEvent } from 'angular-calendar';
import { CustomDateFormatter } from '../../../../shared/utils/custom-date-formatter.provider';
import { UserService } from 'src/app/shared/services/user.service';
import { Planning } from 'src/app/shared/models/planning/planning';
import { AbsenceStatusEnum } from 'src/app/shared/enums/absence/absenceStatus.enum';
import { PeriodType } from 'src/app/shared/enums/home/periodType.enum';
import { DayType } from 'src/app/shared/enums/home/dayType.enum';

@Component({
  selector: 'app-partial-absence-calendar',
  templateUrl: './partial-absence-calendar.component.html',
  styleUrls: ['./partial-absence-calendar.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }
  ]
})
export class PartialAbsenceCalendarComponent {

  eventStatus = AbsenceStatusEnum;
  locale: string;
  @Input() viewDate: Date;
  @Input() events: CalendarEvent[];
  @Input() daySelected: Planning;
  @Output() eSelectDay = new EventEmitter<Planning>();
  @Input() displayVacationCount: boolean;
  @Input() displayServiceName: boolean;
  periodType = PeriodType;
  dayType = DayType;
  constructor(
    public userService: UserService
  ) {
    this.locale = this.userService.site.language.code.slice(0, 2);
  }

  selectDay(day: Planning) {
    if (day.events[0].meta &&
       (day.events[0].meta.absenceStatus === AbsenceStatusEnum.OPEN) ||
       (day.events[0].meta.absenceStatus === AbsenceStatusEnum.UNDESIRABLE)) {
      this.eSelectDay.emit(day);
    }
  }
}
