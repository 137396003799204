<div class="swap-container">
  <div class="ubuntu-regular">
    <div class="header">
      <h1 class="mobime-header ubuntu-bold font-size-32 mb-0">{{'SWAP_REQUEST.TITLE' | translate}}</h1>
    </div>
  </div>
  <app-tabs [tabs]="swapTabs"></app-tabs>

  <router-outlet></router-outlet>
</div>

<div class="banner bg-white">
  <div class="d-flex ubuntu-regular font-size-16 w-100 align-items-center">
    <div class="icon icon-alert icon-20 mx-4"></div>
    <div class="text me-3">{{'SWAP_REQUEST.CREATE.RULES' | translate }}</div>
    <a class="text pointer" (click)="openRules()"><u>{{'SWAP_REQUEST.CREATE.SEE_RULES' | translate}}</u></a>
  </div>
</div>
