import { Component, Input } from '@angular/core';
import { CounterDisplayEnum } from 'src/app/shared/enums/setting/prepay-settings/counter-display.enum';

@Component({
    selector: 'app-prepay-balances',
    templateUrl: './prepay-balances.component.html',
    styleUrls: ['./prepay-balances.component.scss']
})
export class PrepayBalancesComponent {
    @Input() balancesArray = [];
    @Input() counterDisplay: string;
    public counterDisplayEnum = CounterDisplayEnum;
}
