import { DestroyRef, Injectable, inject } from '@angular/core';
import { Document } from '../../shared/models/document/document';
import { DocumentsService } from './documents.service';
import { SnackbarService } from 'src/app/shared/components/snackbar/snackbar.service';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()
export class DocumentListService {
  documents: Document[] = [];
  recentFiles: Document[] = [];
  pathFirstLevel = 'document/';
  documentsPaths: Document[] = [];
  navigate = false;
  currentFolder: Document;
  lastPath = this.pathFirstLevel;
  searchTerm = '';
  isSearchMode = false;
  documents$: Observable<Document>;
  withrecentfiles = true;
  private documentsService = inject(DocumentsService);
  private snackbarService = inject(SnackbarService);
  private destroyRef = inject(DestroyRef);

  get currentPathList(): string[] {
    const paths = this.lastPath.split('/');
    if (!paths[paths.length - 1]) {
      paths.pop();
    }
    paths.shift();
    return paths;
  }

  getDocumentsObservable(path: string): void {
    this.documents$ = this.documentsService.getDocuments(path, this.withrecentfiles, this.isSearchMode ? this.searchTerm : null).pipe(
      shareReplay(1)
    );
  }

  getDocuments(path: string, withrecentfiles: boolean): void {
    if (!this.isSearchMode) {
      this.withrecentfiles = withrecentfiles;
    }
    this.getDocumentsObservable(path);
    this.documents$
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe({
      next: (res) => {
        this.documents = res ? res.children : [];
        if (withrecentfiles) {
          this.recentFiles = res ? res.recentFiles : [];
        }
      },
      error: () => this.snackbarService.show('NOTIFICATIONS_DOCUMENTS.DOCUMENTS.GET_LIST_ERROR', 'error')
    });
  }

  getCurrentFolder(doc: Document) {
    this.currentFolder = doc;
    this.lastPath = doc.path;
  }

  previousOnPath() {
    this.documentsPaths.pop();
  }

  getLastPath(path: string) {
    if (path) {
      return path.split('/').slice(0, -2).join('/') + '/';
    }
    return '';
  }

  getBack(path: string) {
    this.lastPath = this.getLastPath(path);
    this.previousOnPath();
    this.currentFolder = this.documentsPaths[this.documentsPaths.length - 1];
    if (this.lastPath === this.pathFirstLevel) {
      this.navigate = false;
      this.documentsPaths = [];
    }
    this.getDocuments(this.lastPath, true);
  }

  getContentOfFolder(event: Document) {
    this.getDocuments(event.path, false);
    this.getCurrentFolder(event);
    this.navigate = true;
    this.documentsPaths.push(event);
  }

  resetDocuments() {
    this.lastPath = this.pathFirstLevel;
    this.navigate = false;
    this.documentsPaths = [];
    this.searchTerm = '';
    this.isSearchMode = false;
  }

  clearSearch(): void {
    this.searchTerm = '';
    this.isSearchMode = false;
    this.getDocuments(this.lastPath, this.withrecentfiles);
  }

  search(): void {
    this.isSearchMode = true;
    this.getDocuments(this.lastPath, false);
  }
}
