<div class="table-containers">
    <table class="table font-size-12 bg-white" *ngIf="balancesArray">
        <thead class="thead-dark thead-primary-black">
            <tr>
                <th class="border-0 text-center p-0" [ngClass]="{'display-label': counterDisplay !== counterDisplayEnum.CODE}">
                    <div [ngClass]="{'date-label': counterDisplay !== counterDisplayEnum.CODE}"> {{'PREPAY.BALANCE' | translate}}</div>
                </th>
                <th class="border-0" *ngFor="let balance of balancesArray" [ngClass]="{'text-center': counterDisplay === counterDisplayEnum.CODE,'align-vertival': counterDisplay !== counterDisplayEnum.CODE}">
                    <div *ngIf="counterDisplay === counterDisplayEnum.SHORT_LABEL" class="rotated">
                        <div>{{balance.codePresentation}}</div>
                        <div>{{balance.format}}</div>
                      </div>
                      <div *ngIf="counterDisplay === counterDisplayEnum.CODE">
                        <div>{{balance.code}}</div>
                        <div>{{balance.format}}</div>
                      </div>
                      <div *ngIf="counterDisplay === counterDisplayEnum.LABEL" class="rotated">
                        <div> {{balance.label}}</div>
                        <div> {{balance.format}}</div>
                      </div>
                </th>
            </tr>
        </thead>
        <tbody>
            <ng-container class="mb-2">
                <tr>
                    <td>
                        <div>
                            {{'PREPAY.PREVIOUS.BALANCES' | translate}}
                        </div>
                    </td>
                    <td class="  text-center" *ngFor="let balance of balancesArray" >
                        <div *ngIf="balance.format !== 'H'">
                            {{balance.previousBalance}}
                        </div>
                        <div *ngIf="balance.format === 'H'">
                            {{balance.previousBalance| hourHundredthsConverter}}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div>
                            {{'PREPAY.ACQUIRED' | translate}}
                        </div>
                    </td>
                    <td class="text-center " *ngFor="let balance of balancesArray">
                      <ng-container *ngIf="balance.positiveBalance">
                          <div *ngIf="balance.format !== 'H'">
                              {{balance.positiveBalance}}
                          </div>
                          <div *ngIf="balance.format === 'H'">
                              {{balance.positiveBalance| hourHundredthsConverter}}
                          </div>
                      </ng-container>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        {{'PREPAY.TAKEN' | translate}}
                      </div>
                    </td>
                    <td class="text-center" *ngFor="let balance of balancesArray">
                        <ng-container *ngIf="balance.negativeBalance">
                            <div *ngIf="balance.format !== 'H'">
                                {{balance.negativeBalance}}
                            </div>
                            <div *ngIf="balance.format === 'H'">
                                {{balance.negativeBalance| hourHundredthsConverter}}
                            </div>
                        </ng-container>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div>
                            {{'PREPAY.BALANCE_TOTAL' | translate}}
                        </div>
                    </td>
                    <td class=" text-center" *ngFor="let balance of balancesArray">
                        <div *ngIf="balance.format !== 'H'">
                            {{balance.balance}}
                        </div>
                        <div *ngIf="balance.format === 'H'">
                            {{balance.balance| hourHundredthsConverter}}
                        </div>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>
