import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ForcedPasswordChangeComponent } from './forced-password-change.component';
import { TranslateModule } from '@ngx-translate/core';
import { PasswordRestComponent } from './password-rest/password-rest.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
    declarations: [
        ForcedPasswordChangeComponent,
        PasswordRestComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
    ]
})
export class ForcedPasswordChangeModule { }
