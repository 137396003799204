<div class="mobime-header">
  <h1 class="ubuntu-bold font-size-32 mb-0">{{ 'SWAP_REQUEST.SUMMARY.TITLE' | translate }}</h1>
  <div class="ubuntu-bold font-size-16">{{ 'SWAP_REQUEST.SUMMARY.TEXT_TITLE' | translate }}</div>
  <p class="swap__rules font-size-13 text-grey">
    {{ 'SWAP_REQUEST.SUMMARY.TEXT' | translate }}
  </p>
</div>
<div class="swap-summary swap-details-container bg-primary-lightGrey position-relative">
  <div class="container-fluid">

    <!-- Swap Service -->
    <ng-container *ngIf="!swapRest">
      <p class="ubuntu-bold font-size-16 mb-4 ">{{ askedDate | dateFormat : 'long'}}</p>
      <div class="row position-relative mb-5">
          <div class="bubble-swap-request d-flex justify-content-center align-items-center">
              <div class="icon-swap-request-horizontal icon-24"></div>
          </div>

          <div class="col-6">
            <!-- sender shift -->
            <app-swap-details-card
              [firstName]="userShift.firstName"
              [lastName]="userShift.lastName"
              [shift]="userShift.shift"
            >
            </app-swap-details-card>
          </div>
          <div class="col-6">
            <!-- receiver rest -->
            <app-swap-details-card
              [firstName]="colleagueShift.firstName"
              [lastName]="colleagueShift.lastName"
              [shift]="colleagueShift.shift"
              [showName]="true"
            >
            </app-swap-details-card>
          </div>
      </div>
    </ng-container>

    <!-- Swap Rest with askedDate -->
    <ng-container *ngIf="swapRest && askedDate">

        <p class="ubuntu-bold font-size-16 mb-4 ">{{ askedDate | dateFormat : 'long'}}</p>
        <div class="row position-relative mb-5">
            <div class="bubble-swap-request d-flex justify-content-center align-items-center">
                <div class="icon-swap-request-horizontal icon-24"></div>
            </div>

            <div class="col-6">
              <!-- sender shift -->
              <app-swap-details-card
                [firstName]="userShift.firstName"
                [lastName]="userShift.lastName"
                [shift]="userShift.shift"
              >
              </app-swap-details-card>
            </div>
            <div class="col-6">
              <!-- receiver rest -->
              <app-swap-details-card
                [firstName]="colleagueShift.firstName"
                [lastName]="colleagueShift.lastName"
                [showName]="true"
              >
              </app-swap-details-card>
            </div>
        </div>

    </ng-container>

    <!-- Swap Rest with selectedServiceDate -->
    <ng-container *ngIf="swapRest && exchangedDate">

      <p class="ubuntu-bold font-size-16 mb-4 ">{{ exchangedDate | dateFormat : 'long'}}</p>
      <div class="row position-relative mb-5">
          <div class="bubble-swap-request d-flex justify-content-center align-items-center">
              <div class="icon-swap-request-horizontal icon-24"></div>
          </div>

          <div class="col-6">
            <!-- sender shift -->
            <app-swap-details-card
              [firstName]="userRest.firstName"
              [lastName]="userRest.lastName"
            >
            </app-swap-details-card>
          </div>
          <div class="col-6">
            <!-- receiver rest -->
            <app-swap-details-card
              [firstName]="colleagueShift.firstName"
              [lastName]="colleagueShift.lastName"
              [shift]="colleagueShift.shift"
              [showName]="true"
            >
            </app-swap-details-card>
          </div>
      </div>

  </ng-container>

    <div *ngIf="comment">
      <p class="ubuntu-bold font-size-16 mb-4">{{ 'SWAP_REQUEST.DETAILS.COMMENT' | translate }}</p>
      <div class="comment font-size-16 mb-4">
        {{ comment }}
      </div>
    </div>
  </div>
</div>
