<div class="history-absence-request tabs-content-container">
  <h3 class="history-absence-request__title font-size-24 ubuntu-bold">
    {{ absenceRequestsHistory?.absenceRequests?.length > 1 ? ('ABSENCE_REQUEST.HISTORY.LAST_PLURAL' | translate: requestsParam) : ('ABSENCE_REQUEST.HISTORY.LAST' | translate) }}
  </h3>

  <ul *ngIf="!errorGetRequests && absenceRequestsHistory?.absenceRequests?.length > 0" class="history-absence-request__list">
    <li *ngFor="let request of absenceRequestsHistory.absenceRequests"
        class="history-absence-request__list__item mb-3"
        [attr.data-state]="request.state"
    >
      <a [routerLink]="['/absence/details', request.requestId]" class="text-decoration-none text-grey">
        <div class="status d-flex font-size-10 ubuntu-bold text-white text-uppercase align-items-center px-2 py-1 mb-3">
          <ng-container *ngIf="request.state === 'PENDING'">
            <span class="icon icon-8 icon-clock-white me-2"></span>
            {{ 'ABSENCE_REQUEST.HISTORY.PENDING' | translate }}
          </ng-container>
          <ng-container *ngIf="request.state === 'VALIDATED'">
            <span class="icon icon-8 icon-checked-white me-2"></span>
            {{ 'ABSENCE_REQUEST.HISTORY.VALIDATED' | translate }}
          </ng-container>
          <ng-container *ngIf="request.state === 'REFUSED'">
            <span class="icon icon-8 icon-cross-white me-2"></span>
            {{'ABSENCE_REQUEST.HISTORY.REFUSED' | translate}}
          </ng-container>
        </div>

        <div class="ubuntu-bold font-size-24 mb-3 text-black">

          <ng-container *ngIf="(request.start | dateFormat : 'DMY') === (request.end | dateFormat : 'DMY')">
            {{ request.start | dateFormat : 'medium' }}
          </ng-container>
          <ng-container *ngIf="(request.start | dateFormat : 'DMY') !== (request.end | dateFormat : 'DMY')">
            {{ request.start | dateFormat : 'medium' }}
          
            {{'ABSENCE_REQUEST.HISTORY.TO_DATE' | translate}} {{ request.end | dateFormat : 'medium' }}
          </ng-container>
        </div>
        <div class="text-grey font-size-16">
          ( <span *ngFor="let counter of request.counters; let last = last">{{ counter.quantity }} {{ counter.code }}{{ last ? '' : ' + ' }}</span> )
        </div>
      </a>
      
    </li>

  </ul>

  <p *ngIf="!loadingRequests && !errorGetRequests && absenceRequestsHistory?.absenceRequests?.length < 1" class="font-size-16">
    {{ 'ABSENCE_REQUEST.HISTORY.NO_RESULT_FOUND' | translate }}
  </p>
  <p *ngIf="errorGetRequests" class="font-size-16 text-pomegranate">
    {{ 'ABSENCE_REQUEST.HISTORY.ERROR' | translate }}
  </p>

</div>

