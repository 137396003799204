/**
 * State of survey
 *
 */
enum SurveyStatusEnum {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
}

export default SurveyStatusEnum;
