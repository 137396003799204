import { HttpClient } from '@angular/common/http';
import { DestroyRef, Injectable, Injector, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, ReplaySubject, tap } from 'rxjs';
import { API_URLS } from 'src/environments/api-urls';
import { dateFormatslanguages } from '../constants/constants';
import { HeaderInfos } from '../models/header/headerInfos';
import { Settings, Site, UserAttributes } from '../models/settings/settings';
import { TypologyDeviceSettings, TypologySettings } from './../models/settings/settings';
import { AnalyticsService } from './GTM/analytics.service';
import { PartialCustomDimensionsSet } from '../types/customDimensions';
import { DataLayerCustomDimensions } from '../enums/GTM/dataLayer.enum';
import { Title } from '@angular/platform-browser';
import { Md5 } from 'ts-md5';
import { NotificationChoice } from '../models/notification-choice/notification-choice';
import { CustomFunctionSettings } from '../models/settings/customFunctionsSettings/customFunctionsSettings';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserDetail, UserParams } from '@shared/models/user-details.model';
import { PasswordResetResponse, ResetPassword } from '../../features/reset-password/reset-password.model';

const USER_PARAMS_LOCALSTORAGE_KEY = 'userParams';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  public site: Site = {
    firstDayOfWeek: 1,
    language: {
      code: 'fr-FR',
      dateFormat: {
        mini: 'DD-MM', // 20/10
        short: 'DD/MM/YYYY', // 20/10/2019
        medium: 'DD MMMM YYYY', // 20 octobre 2019
        long: 'dddd DD MMMM YYYY', // dimanche 20 octobre 2019
      }
    },
    timeZone: 'Europe/Paris',
    siteTime: null
  };

  driver: boolean;
  userFirstName: string;
  userLastName: string;
  login: string;
  dbName: string;
  userSite: string;
  adminNumber: string;
  hashedLogin: string;
  userAttributes: UserAttributes;
  userAccess: ReplaySubject<{ mobimeAccess: boolean, mobimeDisabled: boolean }> = new ReplaySubject();

  public typologyDeviceSettings: ReplaySubject<TypologyDeviceSettings> = new ReplaySubject();
  public shiftSettings: any = {};
  public typologySetting: ReplaySubject<TypologySettings> = new ReplaySubject();
  public customFunctionSettings: ReplaySubject<CustomFunctionSettings> = new ReplaySubject();
  token: string;
  accessToken: string;
  md5 = new Md5();

  defaultDevice: {
    id: number;
    deviceCode: string;
    type: 'DESKTOP' | 'MOBILE' | 'OTHER';
    readerId: string;
    default: boolean;
    allowToBeDelete: boolean;
  } = {
      id: 0,
      deviceCode: '',
      type: 'DESKTOP',
      readerId: '',
      default: false,
      allowToBeDelete: true
    };

  private analyticsService: AnalyticsService;
  forceSetPassword = false;
  private destroyRef = inject(DestroyRef);

  constructor(
    private injector: Injector,
    private http: HttpClient,
    private translate: TranslateService,
    private titleService: Title,
  ) { }

  get dataLayerSet(): PartialCustomDimensionsSet {
    return {
      [DataLayerCustomDimensions.Language_DB]: this.site.language.code,
      [DataLayerCustomDimensions.Database_FDS]: this.dbName,
      [DataLayerCustomDimensions.Area]: this.userAttributes.area ? this.userAttributes.area.code : null,
      [DataLayerCustomDimensions.Staff_Category]: this.userAttributes.staffCategoryCode,
      [DataLayerCustomDimensions.WorkingGroup]: this.userAttributes.workingGroupCode,
      [DataLayerCustomDimensions.Affectation_Category]: this.userAttributes.affectationCategoryCode,
      [DataLayerCustomDimensions.Site_time]: this.site.siteTime,
      [DataLayerCustomDimensions.Login]: this.hashedLogin,
      [DataLayerCustomDimensions.Contract_type]: this.userAttributes.contractTypeCode,
      [DataLayerCustomDimensions.Roster_grid]: this.userAttributes.rosterGridCode,
      [DataLayerCustomDimensions.Main_habilitation]: this.userAttributes.mainHabilitationCode,
      [DataLayerCustomDimensions.Authentication_kind]: this.getAuthenticationKind(),
      // other data
    };
  }

  getUserSettings(): Observable<Settings> {
    let defaultDevice = null;
    try {
      defaultDevice = JSON.parse(localStorage.getItem('default_device'));
    } catch (error) {
      console.log(error);
    }

    if (defaultDevice) {
      this.defaultDevice = {
        id: defaultDevice.id || 0,
        type: defaultDevice.type || 'DESKTOP',
        readerId: defaultDevice.readerId || '',
        default: !!defaultDevice.default,
        allowToBeDelete: !!defaultDevice.allowToBeDelete,
        deviceCode: defaultDevice.name || '',       // replace name by deviceCode
      };
    }
    const deviceCode = this.defaultDevice.deviceCode;
    const deviceType = this.defaultDevice.type;
    return this.http.get<Settings>
      (`${API_URLS.ENVIRONMENT}?deviceId=${this.defaultDevice.id}&deviceType=${deviceType}&deviceCode=${deviceCode}`);
  }

  getUserDetails(): Observable<UserDetail> {
    return this.http.get<UserDetail>(API_URLS.USER_DETAILS);
  }

  getUserNotificationChoice(): Observable<any> {
    return this.http.get<any>(API_URLS.GET_NOTIFICATIONS);
  }

  saveUserNotificationChoice(notif: NotificationChoice): Observable<any> {
    return this.http.put(API_URLS.SAVE_NOTIFICATION, notif);
  }

  verifyTokenAndAccount(resetPassword: ResetPassword): Observable<PasswordResetResponse> {
    return this.http.post<PasswordResetResponse>(`${API_URLS.RESET_PASSWORD_OR_CREATE_ACCOUNT}`, resetPassword);
  }

  setUserSettings(): void {
    this.getUserSettings()
      .pipe(
        tap(userSetting => {
          if (userSetting) {
            this.setSettings(userSetting);
            this.analyticsService = this.injector.get(AnalyticsService);
            this.analyticsService.updateCustomDimensions(this.dataLayerSet);
            this.analyticsService.dataLayerPush();
          }
        }),
        takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  setSettings(userSetting: Settings) {
    this.typologyDeviceSettings.next(userSetting.typologyDeviceSettings);
    this.typologySetting.next(userSetting.typologySettings);
    this.customFunctionSettings.next(userSetting.typologySettings.customFunctionSettings);
    this.setSiteSetting(userSetting.site);
  }

  setSiteSetting(site: Site) {
    this.mapFirstDayOfWeek(site.firstDayOfWeek);
    this.mapDateFormat(site.language.code);
    localStorage.setItem('language', site.language.code);
    this.site.timeZone = site.timeZone;
    this.site.siteTime = site.siteTime;

    // Do not call translations API again if it's the same as default
    if (this.site.language.code !== site.language.code) {
      this.site.language.code = site.language.code;
      this.translate.setDefaultLang(site.language.code);
      this.setAppTitleByLang();
    }

  }

  initDefaultLang() {
    const navLang = navigator.language;
    const supportedLanguages = ['fr-FR', 'fr-CA', 'en-AU', 'en-IE', 'en-NZ', 'en-US'];
    const defaultLang = supportedLanguages.includes(navLang) ? navLang : 'en-US';

    this.site.language.code = defaultLang;
    this.translate.setDefaultLang(defaultLang);
    this.setAppTitleByLang();
  }

  setUserDetails(loginData: UserDetail) {
    this.shiftSettings = loginData.typology && loginData.typology.settings ? loginData.typology.settings.shiftSettings : null;
    this.driver = loginData.typology ? loginData.typology.driverRequired : false;
    this.userFirstName = loginData.firstName;
    this.userLastName = loginData.lastName;
    this.login = loginData.account.login.value;
    this.dbName = loginData.account.dbName;
    this.userSite = loginData.account.site;
    this.adminNumber = loginData.adminNumber;
    this.userAttributes = loginData.attributes;
    this.hashedLogin = this.md5.appendStr(this.login).end().toString();
    this.userAccess.next({ mobimeAccess: loginData.account.mobimeAccess, mobimeDisabled: loginData.account.mobimeDisabled });
    this.forceSetPassword = loginData.account.forceSetPassword;
    this.setUserParams(loginData);
  }

  setAppTitleByLang() {
    this.translate.get('APP_NAME')
      .pipe(
        tap((appName: string) => {
          this.titleService.setTitle(appName);
        }),
        takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  mapDateFormat(languageCode: string) {
    this.site.language.dateFormat = dateFormatslanguages.find(dateFormatsLang => dateFormatsLang.code === languageCode);
  }

  mapFirstDayOfWeek(firstDayOfWeek: number): number {
    if (firstDayOfWeek === 7) {
      // (dimanche =7) c'est la valeur enregistrée en base de données
      // 0 c'est la valeur mappée pour l'afficher dans le calendrier
      return this.site.firstDayOfWeek = 0;
    }
    return this.site.firstDayOfWeek = firstDayOfWeek;
  }

  getInfos(): Observable<HeaderInfos> {
    return this.http.get<HeaderInfos>(`${API_URLS.INFOS}`);
  }

  getAuthenticationKind(): string {
    return localStorage.getItem('badgeToken') ? 'badge' : 'AD';
  }

  private setUserParams(userData: UserDetail): void {
    const userParams: UserParams = {
      userId: userData?.account.id,
      pilot: userData?.database?.pilot || false,
      isReloaded: false
    };

    const storedUserParamJSON = localStorage.getItem(USER_PARAMS_LOCALSTORAGE_KEY);

    if (!storedUserParamJSON) {
      this.saveUserParams(userParams);
      if (userParams.pilot) {
        this.saveAndReload(userParams);
      }
    }
    else {
      const storedUserParams: UserParams = JSON.parse(storedUserParamJSON);
      const areUserParamsEquals = this.compareUserParams(storedUserParams, userParams);
      if (((userParams.pilot && areUserParamsEquals && !storedUserParams.isReloaded) || !areUserParamsEquals)) {
        this.saveAndReload(userParams);
      }
    }
  }

  private saveAndReload(userParams: UserParams): void {
    userParams.isReloaded = true;
    this.saveUserParams(userParams);
    location.reload();
  }


  private saveUserParams(userParams: UserParams): void {
    localStorage.setItem(USER_PARAMS_LOCALSTORAGE_KEY, JSON.stringify(userParams));
  }
  private compareUserParams(obj1: UserParams, obj2: UserParams): boolean {
    return obj1.userId === obj2.userId &&
      obj1.pilot === obj2.pilot
  }
}
