<div class="mobime-header">
  <h1 class="ubuntu-bold font-size-32 mb-0">{{i18nPrefix + 'SERVICE' | translate}}</h1>
  <div class="description">
    <div class="description__title">
      <div class="ubuntu-bold font-size-16">{{i18nPrefix + 'SELECT_DATE' | translate}}</div>
      <div class="font-size-13 text-grey">
        {{i18nPrefix + 'CONSULT_LEGEND' | translate}}.
      </div>
    </div>
    <div class="description__legend">
      <div class="ubuntu-bold font-size-13">{{i18nPrefix + 'LEGEND' | translate}}</div>
      <div class="row no-gutters mt-3">
        <div class="d-flex col-md-4 col-sm-6 mb-3">
          <div class="square me-2 bg-shadowGreen"></div>
          <div class="font-size-13 text-charcoal-grey">
            {{i18nPrefix + 'WATING_FOR_VALIDATION' | translate}}
          </div>
        </div>
        <div class="d-flex col-md-4 col-sm-6 mb-3">
          <div class="square me-2 bg-gallery "></div>
          <div class="font-size-13 text-charcoal-grey">
            {{i18nPrefix + 'CLOSED_DAY' | translate}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="planning" class="bg-primary-lightGrey row no-gutters planning-container w-100">
  <div class="calendar" *ngFor="let plan of planning">
    <app-swap-calendar class="mb-5" 
      [viewDate]="plan.month" 
      [events]="plan.events" 
      [daySelected]="daySelected"
      (eSelectDay)="selectDay($event)"
      [displayVacationCount]="scheduleSettings.displayVacationCount"
      [displayServiceName]="scheduleSettings.displayServiceName">
    </app-swap-calendar>
  </div>
</div>