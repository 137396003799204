class SwapEmployee {
  adminNumber: string;
  firstName: string;
  lastName: string;
  shift?: any;
}

export class SwapRequestHistory {
  id: number;
  state: string;
  sender: SwapEmployee;
  receiver: SwapEmployee;
  askedDate: string;
  exchangedDate: string;
  type: string;

  constructor(args: {
    id: number,
    state: string,
    sender: SwapEmployee,
    receiver: SwapEmployee,
    askedDate: string,
    exchangedDate: string,
    type: string,
  }) {
    this.id = args.id;
    this.state = args.state;
    this.sender = args.sender;
    this.receiver = args.receiver;
    this.askedDate = args.askedDate;
    // null exchangedDate is saved as '9999-12-31' in BDD
    this.exchangedDate = args.exchangedDate !== '9999-12-31' ? args.exchangedDate : null;
    this.type = args.type;
  }

}

