import { Injectable, Injector } from '@angular/core';
import { WEBSOCKET_HOST } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { Initializable } from './initializable';
import { Router } from '@angular/router';

@Injectable()
export class WebsocketConnectionService implements Initializable {

  webSocket: WebSocket;
  WebSocketReOpened: Subject<void> = new Subject();
  router: Router;

  constructor(private injector: Injector) {
    this.router = this.injector.get(Router);
  }

  init(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (localStorage.getItem('country')) {

        this.webSocket = new WebSocket(WEBSOCKET_HOST);

        this.webSocket.onopen = () => {
          console.log('Websocket opened !');
          resolve();
        };
        this.webSocket.onmessage = (event) => {
          console.log('RECEIVED', event.data);
          this.onReceiveBadgeToken(event.data);
        };

        this.webSocket.onclose = () => {
          console.log('Websocket closed, try to reopen...');
          if (this.router.url === '/badge-authentication') {
            console.log('%cWebsocket closed, reload to connect !', 'color: blue');
            location.reload();
          }
        };

        this.webSocket.onerror = (err) => {
          console.error('Socket encountered error: ', err, 'Closing socket');
          this.webSocket.close();
          reject();
        };
      }
    });
  }

  sendMessage(message: string) {
    const webSocketOpened = 'webSocketOpened';
    if (this.webSocket.readyState === 1) {
      this.webSocket.send(message);
      console.log('Message send', message);
      document.dispatchEvent(new Event('WebsocketOpened'));
      document[webSocketOpened] = true;
      return;
    }
    console.log('Waiting for websocket connected');
    setTimeout(() => {
      this.sendMessage(message);
    }, 1000);
  }

  async onReceiveBadgeToken(badgeToken: string) {
    const localBadgeToken = localStorage.getItem('badgeToken');
    if (badgeToken && !localBadgeToken) {
      localStorage.setItem('badgeToken', badgeToken);
    }

    await this.router.navigate(['home']);
  }

}
