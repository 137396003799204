import { Component, OnInit, Input, inject, DestroyRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Counter } from 'src/app/shared/models/counters/counters';
import { Format } from 'src/app/shared/enums/counters/balanceFormat.enum';
import { AbsenceService } from '../absence.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs';

@Component({
  selector: 'app-counter-balance-modal',
  templateUrl: './counter-balance-modal.component.html',
  styleUrls: ['./counter-balance-modal.component.scss']
})
export class CounterBalanceModalComponent implements OnInit {

  @Input() date: moment.Moment;
  @Input() absenceType: 'PARTIAL' | 'FULL_DAY';
  counters: Counter[] = [];
  format = Format;
  private destroyRef = inject(DestroyRef);

  constructor(
    private absenceService: AbsenceService,
    public activeModal: NgbActiveModal,
  ) {}

  ngOnInit() {
    this.getCountersBalance();
  }

  getCountersBalance() {
    this.absenceService.getCountersBalance(this.date.format('YYYY-MM-DD'), this.absenceType, null)
      .pipe(
        tap((counters: Counter[]) => this.counters = counters.map(counter => ({ ...counter }))),
        takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

}
