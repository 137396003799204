import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SurveyQuestionModel} from '@shared/models/surveys/surveyQuestion.model';
import QuestionTypeEnum from '@shared/enums/surveys/questionType.enum';
import {QuestionOption} from '@shared/interfaces/question';

@Component({
  selector: 'app-survey-question',
  templateUrl: './survey-question.component.html',
  styleUrls: ['./survey-question.component.scss']
})
export class SurveyQuestionComponent {
  /**
   *
   */
  public readonly QUESTION_TYPE = QuestionTypeEnum;
  /**
   *
   */
  @Input()
  question: SurveyQuestionModel;
  /**
   *
   */
  @Output()
  whenChangeValue = new EventEmitter();

  /**
   *
   */
  updateValue() {
    this.whenChangeValue.emit(JSON.parse(JSON.stringify(this.question.toJSON())));
  }

  /**
   *
   */
  updateUniqueValue(selectedId) {
    for (const option of this.question.options) {
      const entry = (option as unknown as QuestionOption);
      if (entry.id === selectedId) {
        entry.answer = true;
      } else {
        entry.answer = null;
      }
    }
    this.updateValue();
  }
}
