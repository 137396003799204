import { CounterBalanceModalComponent } from '../../counter-balance-modal/counter-balance-modal.component';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { Planning } from 'src/app/shared/models/planning/planning';
import { FullDayAbsenceRequestService } from '../fullDay-absence-request.service';
import { AbsenceService } from '../../absence.service';
import * as moment from 'moment';
import { IFullSelectedDatesAbsence } from '../fullDayAbsence.interface';
import { AbsenceStatusEnum } from 'src/app/shared/enums/absence/absenceStatus.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationModalComponent } from 'src/app/shared/components/notification-modal/notification-modal.component';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ScheduleSettings } from 'src/app/shared/models/settings/settings';
import { UserService } from 'src/app/shared/services/user.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-full-day-absence-select',
  templateUrl: './fullDay-absence-select.component.html',
  styleUrls: ['./fullDay-absence-select.component.scss']
})
export class FullDayAbsenceSelectComponent implements OnInit {
  planning: Planning[] = [];
  isScrolledDown = false;
  sub: Subscription;
  scheduleSettings: ScheduleSettings;

  selectedDates: IFullSelectedDatesAbsence = {
    startDate: null,
    endDate: null
  };
  private destroyRef = inject(DestroyRef);

  constructor(
    public fullDayAbsenceRequestService: FullDayAbsenceRequestService,
    public absenceService: AbsenceService,
    public modalService: NgbModal,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit() {
    const startDate = moment().subtract(1, 'months');
    const endDate = moment()
      .add(4, 'months')
      .endOf('month');
    this.getAbsenceCalendar(startDate, endDate, 'DOWN');
    this.getScheduleSettings();
  }

  getAbsenceCalendar(startDate: moment.Moment, endDate: moment.Moment, scroll = 'DOWN') {
    this.absenceService.getPlanning(startDate, endDate, 'FULL_DAY')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: planning => {
          if (scroll === 'DOWN') {
            this.planning = this.planning.concat(planning);
          }
        }
      }
      );
  }

  getScheduleSettings() {
    this.sub = this.userService.typologySetting
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(settings => {
      this.scheduleSettings = settings.scheduleSettings;
    });
  }

  onScrollDown() {
    const startDate = moment().add(5, 'months');
    const endDate = moment()
      .add(10, 'months')
      .endOf('month');
    this.getAbsenceCalendar(startDate, endDate, 'DOWN');
  }

  onScroll() {
    const planningContainerHeight = document.getElementById('fullDayAbsenceCalendar');
    if (
      planningContainerHeight.scrollTop === planningContainerHeight.scrollHeight - planningContainerHeight.offsetHeight &&
      !this.isScrolledDown
    ) {
      this.isScrolledDown = true;
      this.onScrollDown();
    }
  }

  selectDates(dates: IFullSelectedDatesAbsence) {
    this.selectedDates = dates;
    this.fullDayAbsenceRequestService.selectDates(
      moment(dates.startDate.date),
      moment(dates.endDate ? dates.endDate.date : dates.startDate.date)
    );
    this.fullDayAbsenceRequestService.continueEnable(true);
  }

  openCounterBalancePopin() {
    const counterBlanceModalRef = this.modalService.open(CounterBalanceModalComponent, {
      centered: true,
      size: 'lg'
    });
    counterBlanceModalRef.componentInstance.date = moment();
    counterBlanceModalRef.componentInstance.absenceType = 'FULL_DAY';
  }

  goToNextStep() {
    if (this.selectedDates.startDate && this.selectedDates.endDate) {
      if (!this.validateDates()) {
        return this.openInvalidDatesModal();
      }
      this.fullDayAbsenceRequestService.goToNextStep();
    }
  }

  private validateDates(): boolean {
    let result = true;
    this.planning.forEach(planning => {
      for (const event of planning.events) {
        if (
          (event.meta.absenceStatus === AbsenceStatusEnum.WAITING || event.meta.absenceStatus === AbsenceStatusEnum.CLOSE) &&
          new Date(event.meta.date as any) >= this.selectedDates.startDate.date &&
          new Date(event.meta.date as any) <= this.selectedDates.endDate.date
        ) {
          result = false;
          break;
        }
      }
    });
    return result;
  }

  openInvalidDatesModal() {
    const modalRef = this.modalService.open(NotificationModalComponent, {
      centered: true
    });
    modalRef.componentInstance.title = 'ABSENCE_REQUEST.FULL_DAY_ABSENCE';
    modalRef.componentInstance.message = 'ABSENCE_REQUEST.FULL_DAY.INVALID_DATES';
    modalRef.componentInstance.icon = 'icon-alert-red';
  }

  goToPreviousStep() {
    this.router.navigate(['/absence']);
  }
}
