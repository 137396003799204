import { Component, Input } from '@angular/core';
import { SwapShift } from '../../models/swapSearchShift';

@Component({
    selector: 'app-swap-details-card',
    templateUrl: './swap-details-card.component.html',
    styleUrls: ['./swap-details-card.component.scss']
  })
export class SwapDetailsCardComponent {

    @Input() firstName: string;
    @Input() lastName: string;
    @Input() showName: boolean;
    @Input() shift: SwapShift;

}
