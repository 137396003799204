<div id="app-carousel-cards">
  <swiper-container appSwiper #swiperCardsContainer [config]="swiperConfig">
    <ng-container *ngFor="let shift of calendarData[checkedItem].parts; index as indexShift;">
      <swiper-slide>
        <app-calender-card [ngClass]="{'pointer': indexShift !== checkedShift}" [checked]="checkedShift === indexShift"
          [date]="calendarData[checkedItem].date | dateFormat :'YYYY-MM-DD'" [shiftData]="shift"
          [shiftPartIndex]="indexShift" [serviceReference]="calendarData[checkedItem].reference"
          [period]="calendarData[checkedItem].period" (click)="cardClick(indexShift)"
          (actions)="handleActions($event)">
        </app-calender-card>
      </swiper-slide>
    </ng-container>
  </swiper-container>
</div>