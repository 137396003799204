<div id="calender-card" class="card-container position-relative ubuntu-regular border-radius-12"
  (mouseenter)="hovered=true" (mouseleave)="hovered=false"
  [ngClass]="{'card-active': checked, 'bg-gallery': shiftData.passed, 'bg-white': !shiftData.passed, 'w-100 details-mode-card': modeDetails}">
  <div class="hover-layer w-100 h-100 position-absolute"
    *ngIf="period && period === periodType.DETAIL && checked && !modeDetails && hovered && shiftData.type !== shiftType.NO_PLANNING">
    <div class="position-relative w-100 h-100">
      <div (click)="navigateToDetails()"
        class="hover-layer-content position-absolute d-flex justify-content-center align-items-center">
        <div class="text font-size-16 ubuntu-bold text-black pointer">
          {{'PLANNING.CARDS.ACCESS_SERVICE_TEXT' | translate}}</div>
        <div class="icon icon-16 icon-arrow"></div>
      </div>
    </div>
  </div>
  <div *ngIf="showHeader" class="header">
    <div class="position" *ngIf="shiftData && shiftData.type" [ngClass]="{'position-isPassed': shiftData.passed}">
      <!-- position repos -->
      <ng-container [ngSwitch]="shiftData.type">
        <ng-container *ngSwitchCase="shiftType.REST">
          <div [ngClass]="{'border-radius-top-12': !modeDetails}" class="bg-rest position-relative">
            <div class="position-absolute position-title-rest">
              <div *ngIf="locale === 'fr'" class="icon icon-title-rest icon-204-45"></div>
              <div *ngIf="locale !== 'fr'" class="icon icon-title-rest-en"></div>
              <div class="subtitle font-size-16 ubuntu-bold text-valentino"
                *ngIf="period && period !== periodType.POSITION">

              </div>
            </div>
          </div>
        </ng-container>
        <!-- position dispo -->
        <ng-container *ngSwitchCase="shiftType.AVAILABLE">
          <div [ngClass]="{'border-radius-top-12': !modeDetails}" class="bg-available position-relative">
            <div class="position-absolute position-title-available">
              <div *ngIf="locale === 'fr'" class="icon-181-44 icon-title-available"></div>
              <div *ngIf="locale !== 'fr'" class="icon-title-available-en"></div>
              <div class="subtitle font-size-16 ubuntu-bold text-burningOrange"
                *ngIf="period && period !== periodType.POSITION">
                {{shiftData.label}}
              </div>
            </div>
          </div>
        </ng-container>
        <!-- position indispo -->
        <ng-container *ngSwitchCase="shiftType.UNAVAILABLE">
          <div [ngClass]="{'border-radius-top-12': !modeDetails}" class="bg-unavailable position-relative">
            <div class="position-absolute position-title-unavailable">
              <div *ngIf="locale === 'fr'" class="icon-204-37 icon-title-unavailable"></div>
              <div *ngIf="locale !== 'fr'" class="icon-title-unavailable-en"></div>
              <div class="subtitle font-size-16 ubuntu-bold text-valentino"
                *ngIf="period && period !== periodType.POSITION">
                {{shiftData.label}}
              </div>
            </div>
          </div>
        </ng-container>
        <!-- position WORKING_DAY -->
        <ng-container *ngSwitchCase="shiftType.WORKING_DAY">
          <div [ngClass]="{'border-radius-top-12': !modeDetails}" class="bg-primary-lightGrey position-relative">
            <div class="icon icon-vehicle icon-80 position-absolute position-icon-vehicle"></div>
            <div class="position-absolute position-title-working-day">
              <div class="subtitle font-size-16 ubuntu-bold text-grey">
                {{'PLANNING.CARDS.SUBTITLE.DAY_WORKED' | translate}}
              </div>
            </div>
          </div>
        </ng-container>
        <!-- planning-non-dispo -->
        <ng-container *ngSwitchCase="shiftType.NO_PLANNING">
          <div [ngClass]="{'border-radius-top-12': !modeDetails}" class="bg-planning-non-dispo position-relative">
            <div class="position-absolute position-title-non-planning">
              <div class="subtitle font-size-16 ubuntu-bold text-grey">
                {{'PLANNING.CARDS.SUBTITLE.SCHEDULE_NOT_AVAILABLE' | translate}}
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div class="content">
    <ng-container *ngIf="shiftData.details; else withoutDetails">

      <div *ngIf="shiftData.type !== shiftType.WORKING && showHeader" class="alert-message d-flex  bg-primary-lightGrey"
        [ngClass]="{'p-3':modeDetails, 'px-3 py-2': !modeDetails}">
        <div class="icon icon-alert icon-16"></div>
        <span class="message font-size-13 text-grey" [ngClass]="{'w-75':modeDetails}">
          {{ 'PLANNING.CARDS.CONTENT.WITH_DETAILS.ALERT_MESSAGE' | translate }}
        </span>
      </div>

      <div class="details">
        <div *ngIf="shiftData.type === shiftType.WORKING"
          class="service-reference d-flex justify-content-start"
          [ngClass]="{'text-grey': shiftData.passed}"
        >
          <div class="service font-size-16" *ngIf="shiftData.reference !== '-1'"
           [ngClass]="{
            'text-pomegranate': shiftData.isModified ==='O',
            'text-warning': shiftData.isModified ==='N' && shiftData.reference !== serviceReference
          }">
            {{ 'PLANNING.CARDS.CONTENT.SERVICE' | translate }}
            {{shiftData.reference}} <span *ngIf="shiftData.isModified === 'O'">*</span>
          </div>
          <div class="service font-size-16" *ngIf="shiftData.reference === '-1'">
            {{shiftData.partLabel}}
          </div>
          <div *ngIf="displayedTooltipObservations" class="icon icon-chat-message icon-20 ms-3"
            ngbTooltip="{{'PLANNING.CARD.TOOLTIP_MISSION_OBSERVATION' | translate}}" container="body" tooltipClass="tooltip">
          </div>
        </div>

        <div *ngIf="shiftData.type !== shiftType.WORKING" class="service-partlabel px-4 py-2 font-size-12">
          {{ shiftData.partLabel }}
        </div>

        <div class="planning position-relative">
          <div class="icon icon-clock icon-16 position-absolute" [ngClass]="{'mode-details': modeDetails}"></div>
          <ng-container *ngIf="shiftData.details.departure; let departure">
            <div class="begin padding-left-20"
              [ngClass]="{'d-flex align-items-stretch ps-5 mode-details': modeDetails}">
              <div class="time font-size-24 ubuntu-bold align-self-end"
                [ngClass]="{'text-grey': shiftData.passed, 'text-black': !shiftData.passed, 'me-2': modeDetails}">
                {{departure.date| hourFormat:'h':'HH:mm'}}
                <span id="flag-yesterday" class="flag-day ps-2 pe-2 font-size-14 ubuntu-regular"
                  *ngIf="departure.dayBefore">{{ 'PLANNING.CARDS.CONTENT.DAY' | translate }} -
                  1</span>
              </div>
              <div [ngClass]="[
                  shiftData.passed ? 'text-grey' : 'text-charcoal-grey',
                  modeDetails ? 'text-truncate': 'label pb-2 text-truncate-2'
                ]" class="font-size-14 align-self-end">
                {{departure.location}}
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="shiftData.details.arrival; let arrival">
            <div class="end padding-left-20" [ngClass]="{'d-flex align-items-stretch ps-5 mode-details': modeDetails}">
              <div [ngClass]="{'text-grey': shiftData.passed, 'text-black': !shiftData.passed, 'me-2': modeDetails}"
                class="time font-size-24 ubuntu-bold align-self-end">
                {{arrival.date | hourFormat:'h':'HH:mm'}}
                <span id="flag-tomorrow" class="flag-day ps-2 pe-2 font-size-14 ubuntu-regular"
                  *ngIf="arrival.dayAfter">{{ 'PLANNING.CARDS.CONTENT.DAY' | translate }} +
                  1</span>
              </div>
              <div [ngClass]="[
                  shiftData.passed ? 'text-grey' : 'text-charcoal-grey',
                  modeDetails ? 'text-truncate': 'label pb-2 text-truncate-2'
                ]" class="font-size-14 align-self-end">
                {{arrival.location}}
              </div>
            </div>
          </ng-container>
          <div class="vertical-line position-absolute bg-pomegranate" [ngClass]="{'mode-details': modeDetails}"></div>
          <div class="endPoint position-absolute" [ngClass]="{'mode-details': modeDetails}"></div>
        </div>

        <div class="vehicle-reference d-flex align-items-center" *ngIf="shiftData.type === shiftType.WORKING"
          [ngClass]="shiftData.passed ? 'text-grey' : 'text-black'">
          <div class="vehicle font-size-16" *ngIf="shiftData.details.vehicleCodes && shiftData.details.vehicleCodes.length > 0" [ngClass]="{'text-pomegranate': (shiftData.details.vehicleCodes | join :' / ')?.includes('/')}">
            {{ 'PLANNING.CARDS.CONTENT.CAR' | translate }} {{shiftData.details.vehicleCodes | join :' / '}}</div>
          <ng-container *ngIf="shiftData.details.charterManifestCount && shiftData.details.charterManifestCount>0">
            <div class="charter-manifest-count d-flex align-items-center justify-content-center position-relative">
              <div class="font-size-16 me-1">BC</div>
              <div class="icon icon-document icon-16"></div>
              <div class="badge-message count position-absolute position-badge-message">
                {{shiftData.details.charterManifestCount}}</div>
            </div>
          </ng-container>
          <ng-container>
            <div class="icon icon-vehicle icon-100 position-absolute"></div>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <ng-template #withoutDetails>
      <div class="message-no-details" [ngClass]="{'w-100':modeDetails}">
        <span class="font-size-16" [ngClass]="shiftData.passed ? 'text-grey' : 'text-charcoal-grey'">
          {{ messageWithoutDetails | translate }}
        </span>
      </div>
    </ng-template>
  </div>

  <div class="footer position-absolute w-100"
    *ngIf="shiftData.type && shiftData.type === shiftType.WORKING && shiftData.details">
    <div class="info d-flex align-items-center justify-content-center font-size-16 h-100 bg-primary-lightGrey"
      *ngIf="shiftData.details.status && shiftData.details.status === shiftStatus.STARTED">
      <div class="icon icon-alert icon-16 me-2"></div>
      <span class="text-grey">{{ 'PLANNING.CARDS.FOOTER.WORKING.YOU_HAVE_ALREADY_TAKEN_YOUR_SERVICE' | translate
        }}</span>
    </div>

    <div
      *ngIf="checkInEnabled && deviceCheckInEnable && shiftData.details.status && shiftData.details.status === shiftStatus.AVAILABLE"
      class="d-flex justify-content-center">
      <div class="spinner-border" role="status" *ngIf="checkInValidating else checkInButton"></div>
      <ng-template #checkInButton>
        <button type="button" class="btn btn-black d-block m-auto ubuntu-bold font-size-18" [disabled]="!checked"
          (click)="verifyCheckIn()">
          {{ 'PLANNING.CARDS.FOOTER.WORKING.SERVICE_TAKEN' | translate }}
        </button>
      </ng-template>
    </div>

    <div
      *ngIf="checkOutEnabled && deviceCheckOutEnable && shiftData.details.status && shiftData.details.status === shiftStatus.FINISHABLE">
      <button type="button" class="btn btn-black d-block m-auto ubuntu-bold font-size-18" (click)="verifyCheckout()"
        [disabled]="!checked">
        {{ 'PLANNING.CARDS.FOOTER.WORKING.END_OF_SERVICE' | translate }}
      </button>
    </div>
    <div class="info d-flex align-items-center justify-content-center font-size-16 h-100 bg-primary-lightGrey"
      *ngIf="shiftData.details.status && shiftData.details.status === shiftStatus.FINISHED">
      <div class="icon icon-alert icon-16 me-2"></div>
      <span class="text-grey">{{ 'PLANNING.CARDS.FOOTER.WORKING.FINISHED_YOUR_SERVICE' | translate }}</span>
    </div>
    <ng-container *ngIf="displayPunchInOut">
      <div>
        <button type="button" class="btn btn-black d-block m-auto ubuntu-bold font-size-18" (click)="openPunchInOut()">
          {{ 'PLANNING.CARDS.FOOTER.PUNCH_IN_OUT' | translate }}
        </button>
      </div>
    </ng-container>
  </div>

  <!-- footer REST or AVAILABLE -->
  <!-- no footer without details -->
  <div class="footer position-absolute w-100"
    *ngIf="shiftData.type === shiftType.REST || shiftData.type === shiftType.AVAILABLE">
    <div *ngIf="displayPunchInOut">
      <button type="button" class="btn btn-black d-block m-auto ubuntu-bold font-size-18" (click)="openPunchInOut()">
        {{ 'PLANNING.CARDS.FOOTER.PUNCH_IN_OUT' | translate }}
      </button>
    </div>
  </div>

  <div class="passed ubuntu-bold" *ngIf="shiftData.passed">
    <div class="icon icon-16 icon-checked me-2"></div>
    <span class="font-size-16 text-white">{{ 'PLANNING.CARDS.PASSED' | translate }}</span>
  </div>
</div>