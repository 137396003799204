import { NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';

const materialModules = [
  MatTableModule,
  MatExpansionModule,
  MatPaginatorModule,
  MatSortModule
];

@NgModule({
  imports: [
    ...materialModules
  ],
  exports: materialModules,
})

export class AngularMaterialModule { }
