<div>
  <div class="ubuntu-bold font-size-16">{{question.label}}</div>
  <div class="ubuntu-regular font-size-16 text-grey mt-5">{{question.description}}</div>
  <form class="mt-5">
    <ng-container [ngSwitch]="question.type">
      <ng-container *ngSwitchCase="QUESTION_TYPE.SINGLE_CHOICE">
        <ng-container *ngTemplateOutlet="single_choice">
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="QUESTION_TYPE.MULTIPLE_CHOICE">
        <ng-container *ngTemplateOutlet="multiple_choices">
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <ng-container *ngTemplateOutlet="free_service">
        </ng-container>
      </ng-container>
    </ng-container>
  </form>
</div>
<ng-template #single_choice let-options="question">

  <label class="ubuntu-regular font-size-16 me-3">
    {{'SURVEYS.QUESTION.TYPE_UNIQUE_CHOICE' | translate }}
  </label>
  <label *ngIf="!!question.mandatory"
    class="ubuntu-regular text-grey font-size-12 font-italic me-3">
    {{'SURVEY_QUESTION_MANDATORY' | translate }}
  </label>

  <ng-container *ngFor="let option of question.options">
    <div>
      <input class="pointer me-1" 
        [checked]="option.answer" 
        (change)="updateUniqueValue(option.id)" 
        type="radio" 
        [attr.name]="option.id"
        [value]="option.id"
      >
      <label class="me-2">
        {{option.label}}
      </label>
    </div>
  </ng-container>

</ng-template>

<ng-template #multiple_choices let-options="question">
  <label class="ubuntu-regular font-size-16 me-3">
    {{'SURVEYS.QUESTION.TYPE_MULTIPLE' | translate }}
  </label>
  <label *ngIf="!!question.mandatory"
    class="ubuntu-regular text-grey font-size-12 font-italic me-3">
    {{'SURVEY_QUESTION_MANDATORY' | translate }}
  </label>
  <ng-container *ngFor="let option of question.options; let index=index">
    <div>
      <input class="pointer me-1" 
        [(ngModel)]="option.answer" 
        (change)="updateValue()" 
        type="checkbox" 
        [attr.name]="option.id + '_' + index"
        [value]="option.id"
      >
      <label class="me-2">
        {{option.label}}
      </label>
    </div>
  </ng-container>
</ng-template>


<ng-template #free_service let-options="question">
  <label for="free_text" class="ubuntu-regular font-size-16 text-charcoal-grey">
    {{ 'SURVEYS.QUESTION.TYPE_TEXT.LABEL' | translate }}</label>
  <label *ngIf="!!question.mandatory"
    class="ubuntu-regular text-grey font-size-12 font-italic ms-2">
    {{'SURVEY_QUESTION_MANDATORY' | translate }}
  </label>

  <div class="row">
    <div class="col-6">
      <textarea id="free_text" class="form-control font-size-14 ubuntu-regular font-size-16" type="text" maxlength="255"
        (keyup)="updateValue()" [(ngModel)]="question.options[0].answer"></textarea>
    </div>
  </div>

</ng-template>
