import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API_URLS } from 'src/environments/api-urls';
import * as moment from 'moment';
import { Planning } from 'src/app/shared/models/planning/planning';
import { AbsencePlanning } from 'src/app/shared/enums/absence/absence';
import { map } from 'rxjs/operators';
import { UserService } from 'src/app/shared/services/user.service';
import { PlanningDetails } from 'src/app/shared/models/planning/planningDetails';
import { Shift } from 'src/app/shared/models/home/shift';
import { PeriodType } from 'src/app/shared/enums/home/periodType.enum';
import { LocationAndTime } from 'src/app/shared/models/planning/mission';
import { HomeDayCalendar } from 'src/app/shared/models/home/homedayCalendar';
import { Counter } from 'src/app/shared/models/counters/counters';
import { Absence } from 'src/app/shared/models/absence/absence';
import { DayType } from 'src/app/shared/enums/home/dayType.enum';

@Injectable()
export class AbsenceService {

constructor(private http: HttpClient, public userService: UserService) {
  moment.locale('fr');
}

  getAbsenceRequests(): Observable<any> {
    return this.http.get(API_URLS.ABSENCE_HISTORY);
  }

  saveAbsence(data: any, type: 'FULL_DAY' | 'PARTIAL') {
    const absence = this._mapAbsenceData(data, type);
    return this.http.post<any>( `${API_URLS.ABSENCE_CRUD}/?absenceType=${type}`, absence);
  }


  getPlanning(startDate: moment.Moment, endDate: moment.Moment, type: 'FULL_DAY' | 'PARTIAL'): Observable<Planning[]> {
    let params = new HttpParams();
    params = params.append('from', startDate.format('YYYY-MM-01'));
    params = params.append('to', endDate.format('YYYY-MM-DD'));
    params = params.append('absenceType', type);
    params = params.append('withVehicleCode', 'false');
    params = params.append('deviceId', `${this.userService.defaultDevice.id}`);
    params = params.append('deviceCode', `${this.userService.defaultDevice.deviceCode}`);
    params = params.append('deviceType', `${this.userService.defaultDevice.type}`);
    return this.http.get<AbsencePlanning[]>(`${API_URLS.ABSENCE_CALENDAR}`, {params})
      .pipe(
        map(days => {
          const arrayDates = this.getMonthBetweenDates(startDate, endDate);
          const planning: Planning[] = [];
          arrayDates.forEach(monthlyDate => {
            const planningInstance: Planning = {} as Planning;
            const filtredDays = days.filter(day => moment(new Date(day.date as any)).get('M') + 1 === monthlyDate.month);
            if (filtredDays && filtredDays.length) {
              planningInstance.month = new Date(filtredDays[0].date as any);
              planningInstance.events = filtredDays.map(fd => {
                return {
                  title: '',
                  start: new Date(fd.date as any),
                  meta: {
                    shifts: fd.numberOfPoints > 0 ? Array((fd.numberOfPoints)).fill(0) : [],
                    date: fd.date,
                    code: fd.code,
                    type: fd.type,
                    numberOfPoints: fd.numberOfPoints,
                    period: fd.period,
                    valid: fd.valid,
                    recomposedShift: fd.recomposedShift,
                    absenceStatus: fd.absenceStatus,
                    reference: fd.reference
                  }
                };
              });
            } else {
              planningInstance.month = new Date(monthlyDate.year, monthlyDate.month - 1, 1);
              planningInstance.events = [];
            }
            planning.push(planningInstance);
          });
          return planning;
        }
        )
      );
  }

  getMonthBetweenDates(startDate: moment.Moment, endDate: moment.Moment): Array<any> {
    const result = [];
    while (startDate.isBefore(endDate)) {
      result.push({
        month: startDate.get('M') + 1,
        year: startDate.get('year')
      });
      startDate.add(1, 'month');
    }
    return result;
  }

  getPlanningDetails(date: string): Observable<PlanningDetails> {
    return this.http.get<HomeDayCalendar>(`${API_URLS.HOME_PLANNING_DETAILS}/${date}/parts`).pipe(
      map(homeDayCalender => {
        let shifts: Shift[];
        let period: PeriodType;
        let departure: LocationAndTime;
        let arrival: LocationAndTime;
        let numberOfPoints: number;
        let label: string;
        let type: DayType;

        if (homeDayCalender) {
          shifts = homeDayCalender.parts.map(shift => {
            return {
              id: shift.id,
              reference: shift.reference,
              type: shift.type,
              label: homeDayCalender.label,
              partLabel: shift.label,
              passed: shift.passed,
              isModified: shift.isModified,
              details: shift.details,
              missions: shift.missions,
              displayMissions: shift.displayMissions,
              hasComments: shift.hasComments
            };
          });
          period = homeDayCalender.period;
          departure = homeDayCalender.departure;
          arrival = homeDayCalender.arrival;
          numberOfPoints = homeDayCalender.numberOfPoints;
          label = homeDayCalender.label;
          type = homeDayCalender.type;
        } else {
          shifts = [];
          period = null;
          arrival = null;
          departure = null;
          numberOfPoints = null;
          label = null;
          type = null;
        }
        const planningDetails: PlanningDetails = {
          period: period,
          shifts: shifts,
          departure: departure,
          arrival: arrival,
          numberOfPoints: numberOfPoints,
          label: label,
          type: type
        };
        return planningDetails;
      })
    );
  }

  verifyAbsence(data: any, type: 'FULL_DAY' | 'PARTIAL') {
    const absence = this._mapAbsenceData(data, type);
    return this.http.post<any>(`${API_URLS.ABSENCE_CRUD}/verify?absenceType=${type}`, absence);
  }

  getCountersBalance(date: string, absenceType: 'PARTIAL' | 'FULL_DAY', groupingId: number): Observable<Counter[]> {
    let request = `${API_URLS.ABSENCE_COUNTERS}/${date}?absenceType=${absenceType}`;
    request = groupingId ? request += `&groupingId=${groupingId}` : request;
    return this.http.get<Counter[]>(request);
  }


  getFullDayRemainingSlots(startDate: any, endDate: any, groupingId: number) {
    let request = `${API_URLS.ABSENCE_REMAINING_SLOTS}?from=${startDate}&to=${endDate}`;
    request = groupingId ? request += `&groupingId=${groupingId}` : request;
    return this.http.get(request);
  }


  getAbsenceById(absenceId: number): Observable<Absence> {
    return this.http.get<Absence>(`${API_URLS.ABSENCE_CRUD}/${absenceId}`);
  }

  deleteAbsence(absenceId: number) {
    return this.http.delete(`${API_URLS.ABSENCE_CRUD}/${absenceId}`);
  }
  private _convertToMinutes(hour: number, minutes: number) {
    return hour * 60 + minutes;
  }

  private _mapAbsenceData(data: any, type: 'FULL_DAY' | 'PARTIAL') {
    const absence = JSON.parse(JSON.stringify(data));
    absence.startTime = type === 'PARTIAL' ? this._convertToMinutes(data.startTime.hour, data.startTime.minute) : null;
    absence.endTime = type === 'PARTIAL' ? this._convertToMinutes(data.endTime.hour, data.endTime.minute) : null;
    absence.startDate = moment(data.startDate).format('YYYY-MM-DD');
    absence.endDate = data.endDate ? moment(data.endDate).format('YYYY-MM-DD') : moment(data.startDate).format('YYYY-MM-DD');
    return absence;
  }
}

