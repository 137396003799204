import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoaderModalComponent } from '../components/loader-modal/loader-modal.component';

@Injectable({
  providedIn: 'root'
})
export class LoaderModalService {

  loader: NgbModalRef;

  constructor(
    private modalService: NgbModal
  ) { }

  show() {
    this.loader = this.modalService.open(LoaderModalComponent, {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      windowClass: 'loader-modal'
    });
  }

  dismiss() {
    this.loader.dismiss();
  }
}
