<table mat-table [dataSource]="dataSource" matSort class="table table-striped table-hover font-size-12 bg-white mt-5">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef [ngClass]="{'percent-40' : !service.isSearchMode, 'percent-40-with-search' : service.isSearchMode}" mat-sort-header>{{'DOCUMENT.LIST.NAME' | translate}}</th>
    <td mat-cell *matCellDef="let document">
      <div class="document-type position-relative">
        <div class="d-flex  position-relative">
          <div class="type-background bg-verdate position-absolute"
            *ngIf="document.type === DocumentType.FOLDER; else fileTemplate">
            <div class="icon icon-folder"></div>
          </div>
          <ng-template #fileTemplate>
            <div class="type-background bg-greenBlue position-absolute">
              <div class="icon icon-file"></div>
            </div>
          </ng-template>
          <div class="document-text document-name font-size-13">{{document.name}}</div>
        </div>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="percent-10">{{'DOCUMENT.LIST.TYPE' | translate}}</th>
    <td mat-cell *matCellDef="let document">
      {{document.type}}
    </td>
  </ng-container>

  <ng-container *ngIf="service.isSearchMode" matColumnDef="path"  >
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="percent-40-with-search">{{'DOCUMENT.LIST.FILEPATH' | translate}}</th>
    <td mat-cell *matCellDef="let document">
      {{document.path}}
    </td>
  </ng-container>

  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="{'percent-40' : !service.isSearchMode, 'percent-40-with-search' : service.isSearchMode}">{{'DOCUMENT.LIST.DESCRIPTION' | translate}}</th>
    <td mat-cell *matCellDef="let document">
      {{document.description ? document.description : ''}}
    </td>
  </ng-container>

  <ng-container matColumnDef="creationDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="percent-10">{{'DOCUMENT.LIST.DATE.PUBLICATION' | translate}}</th>
    <td mat-cell *matCellDef="let document">
      {{document.creationDate | dateFormat: 'short'}}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns" class="p-3"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="document position-relative pointer"
    (click)="getSelectedDocument(row)"></tr>
</table>
<mat-paginator [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons></mat-paginator>
<div *ngIf="!documents.length" class="font-size-16 ubuntu-regular">
  {{ 'COMMON.EMPTY.LIST' | translate }}
</div>