<div class="new-swap-request tabs-content-container">
  <div class="new-swap-request__icon-container d-flex justify-content-center align-items-center">
    <div class="icon icon-40 icon-swap-request"></div>
  </div>
  <h3 class="new-swap-request__subtitle font-size-24 ubuntu-bold mb-5">{{'SWAP_REQUEST.CREATE.TITLE' | translate}}
  </h3>
  <p class="text-grey font-size-13 mb-5">{{'SWAP_REQUEST.CREATE.TEXT' | translate}}</p>
  <div class="d-flex justify-content-between">
    <button (click)="navigateToService()" class="btn btn-white ubuntu-bold font-size-16 pointer"
      [disabled]="permission.searchQuotaExcedeed" *ngIf="swapServiceEnabled" >
      {{'SWAP_REQUEST.CREATE.SERVICE' | translate}}
    </button>
    <button (click)="navigateToRest()" class="btn btn-white ubuntu-bold font-size-16 pointer"
      [disabled]="permission.searchQuotaExcedeed" *ngIf="swapRestEnabled">
      {{'SWAP_REQUEST.CREATE.DAY_OFF' | translate}}
    </button>
  </div>
  <div *ngIf="permission.searchQuotaExcedeed && (swapRestEnabled || swapServiceEnabled) " class="text-guardsmanRed font-size-13 mt-3 ms-2">
    {{ 'SWAP_REQUEST.CREATE.TOO_MANY_REQUESTS' | translate }}
  </div>
</div>
