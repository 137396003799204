<ng-container *ngIf="surveysService.activeSurveyDetails">
  <div class="survey-view__container d-flex min-vh-100 flex-column">
    <ng-container *ngIf="isSurveyOpened">
      <div class="section-container flex-grow-1">
        <div class="d-flex align-items-center justify-content-between mb-4">

          <div [hidden]=" !(currentQuestionIndex === 0 ||currentQuestionIndex === surveysService.activeSurveyDetails.questions.length)"
            class="icon icon-24 icon-arrow-left-red pointer mb-3" 
            (click)="previousStep()">
          </div>

          <ng-container *ngIf="currentQuestionIndex < surveysService.activeSurveyDetails.questions.length">
            <div class="survey-progress-bar-container w-75 text-right">
              <p><ngb-progressbar  [height]="'.5rem'" [value]="progress"></ngb-progressbar></p>
              <p class="font-size-12">
                {{currentQuestionIndex + 1}}/{{surveysService.activeSurveyDetails.questions.length}}
              </p>
            </div>
          </ng-container>
          
        </div>
        <div class="d-flex flex-column">
          <div class="font-weight-bold text-light-grey font-size-10">
            {{ (surveysService.activeSurveyDetails.anonymous ? 'SURVEYS.COMMON.ANONYMOUS' : 'SURVEYS.COMMON.NOMINATIVE') | translate }}
          </div>
          <h3 class="ubuntu-bold font-size-32 ">
            {{surveysService.activeSurveyDetails.name}}
          </h3>
        </div>
        <ng-container>
          <div class="ubuntu-bold font-size-13 mb-5"
            [hidden]="currentQuestionIndex !== surveysService.activeSurveyDetails.questions.length">
            {{surveysService.activeSurveyDetails.messageBeforeClosing}}</div>
          <div class="d-flex flex-row justify-content-center py-4 buttons-container box-none"
            [hidden]="!(currentQuestionIndex === surveysService.activeSurveyDetails.questions.length)">
            <button class="font-size-16  ubuntu-bold btn-next pointer " [disabled]="surveysService.isSavingData"
              (click)="confirmResponse()">{{"SURVEY_QUESTION_SEND_RESPONSES" |translate }}
            </button>
          </div>
        </ng-container>
        <app-survey-question *ngFor="let entry of surveysService.activeSurveyDetails.questions; let index=index"
          [question]="entry" (whenChangeValue)="updateResponses($event)" [hidden]="currentQuestionIndex !== index">
        </app-survey-question>
      </div>
      <ng-container>
        <div [hidden]="currentQuestionIndex !== surveysService.activeSurveyDetails.questions.length"
          class=" height-last-page flex-grow-1  d-flex flex-column">
        </div>
      </ng-container>
      <div class="d-flex flex-row justify-content-center pt-5 pb-5 buttons-container"
        [hidden]="(currentQuestionIndex === surveysService.activeSurveyDetails.questions.length)">
        <button class="font-size-16 ubuntu-bold pointer" [disabled]="currentQuestionIndex === -1"
          [hidden]="currentQuestionIndex === 0" (click)="previousStep()">{{"COMMON.BUTTON.PREVIOUS" |translate}}
        </button>
        <button class="font-size-16  ubuntu-bold btn-next pointer"
          [hidden]="currentQuestionIndex === surveysService.activeSurveyDetails.questions.length"
          [disabled]="!(currentQuestionIndex === -1 || canContinue )"
          (click)="nextStep()">{{"COMMON.BUTTON.CONTINUE" |translate }}
        </button>
        <button class="font-size-16 ubuntu-bold pointer bg-bostonBlue" [disabled]="surveysService.isSavingData"
          [hidden]="!(currentQuestionIndex === surveysService.activeSurveyDetails.questions.length)"
          (click)="confirmResponse()">{{"COMMON.BUTTON.CONFIRM" |translate }}
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="!isSurveyOpened">
      <div class="flex-grow-1  d-flex flex-column">
        <div class="section-container ">
          <div class="mb-4 icon icon-24 icon-arrow-left-red" (click)="getBack()">
          </div>
          <div class="ubuntu-bold text-light-grey font-size-12">
            {{ (surveysService.activeSurveyDetails.anonymous ? 'SURVEYS.COMMON.ANONYMOUS' : 'SURVEYS.COMMON.NOMINATIVE') | translate }}
          </div>
          <div class="ubuntu-bold font-size-32 mb-3"> {{surveysService.activeSurveyDetails.name}}</div>
          <div class="ubuntu-regular font-size-13 mb-3">{{surveysService.activeSurveyDetails.messageBeforeOpening}}
          </div>
        </div>
        <div class="bg-primary-lightGrey flex-grow-1">
          <div class="col-10 bg-white rounded offset-1 p-5 mt-5 align">
            <div class="mb-2">
              <span
                class="ubuntu-regular font-size-16 text-light-grey pe-5 mb-2">{{'SURVEYS.DETAILS.STATUS' | translate}}</span>
              <!-- badged-->
              <span class="font-size-14">
                <!-- just in case we add new status -->
                <span class="badge-status badge-warning"
                  *ngIf="surveysService.activeSurveyDetails.status === SURVEY_STATUS.OPEN && surveysService.activeSurveyDetails.participation === false ">
                  <div class="icon icon-8 icon-clock-white"></div>

                  {{ 'SURVEYS.LIST.PENDING' | translate }}
                </span>
                <!-- just in case we add new status -->
                <span class="badge-status badge-success"
                  *ngIf="surveysService.activeSurveyDetails.participation === true">
                  <div class="icon icon-8 icon-checked-white"></div>
                  {{ 'SURVEYS.LIST.DONE' | translate }}
                </span>

                <span class="badge-status badge-secondary"
                  *ngIf="surveysService.activeSurveyDetails.status === SURVEY_STATUS.CLOSE && surveysService.activeSurveyDetails.participation === false">
                  <div class="icon icon-8 icon-cross-white"></div>
                  {{ "SURVEYS.LIST.EXPIRED" | translate }}
                </span>

              </span>
            </div>
            <div class="row mb-2">
              <div class="col-3 ubuntu-regular font-size-16 text-light-grey pe-5">
                {{'SURVEYS.DETAILS.PUBLICATION_DATE' | translate}}</div>
              <span class="ubuntu-bold font-size-16">
                {{ surveysService.activeSurveyDetails.startDate | dateFormat: 'short'}}
              </span>
            </div>
            <div class="row mb-2">
              <div class="col-3 ubuntu-regular font-size-16 text-light-grey pe-5">
                {{'SURVEYS.DETAILS.EXPIRATION_DATE' | translate}}</div>
              <div class="ubuntu-bold font-size-16">
                {{ surveysService.activeSurveyDetails.endDate | dateFormat: 'short'}}
              </div>
            </div>
          </div>

        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!isSurveyOpened">
      <div class="d-flex flex-row justify-content-center py-4 buttons-container">
        <button 
          *ngIf="surveysService.activeSurveyDetails.status === SURVEY_STATUS.OPEN && !surveysService.activeSurveyDetails.participation"
          class="font-size-16 ubuntu-bold pointer btn-next"
          (click)="openSurvey()">
          {{"SURVEYS.DETAILS.REPLY_TO_SURVEY" |translate }}
        </button>

        <button 
          *ngIf="surveysService.activeSurveyDetails.status === SURVEY_STATUS.CLOSE"
          disabled
          class="font-size-16 ubuntu-bold pointer btn-next">
          {{"SURVEYS.DETAILS.BUTTON_CLOSE" |translate }}
        </button>
        <button
          *ngIf="surveysService.activeSurveyDetails.status === SURVEY_STATUS.OPEN && surveysService.activeSurveyDetails.participation"
          disabled
          class="font-size-16 ubuntu-bold pointer btn-next">
          {{"SURVEYS.DETAILS.SURVEY_ANSWERED" |translate }}
        </button>

      </div>
    </ng-container>
  </div>
</ng-container>