<div class="prepay-history ubuntu-regular">
  <div class="prepay-header">
    <div class="d-flex align-items-center mt-5 mb-2">
        <div class="icon icon-24 icon-arrow-left-red m-left pointer mb-2 me-3" (click)="getBack()"> </div>
        <h1 class="ubuntu-bold font-size-32 ">{{'PREPAY.PERIOD' | translate}}</h1>
    </div>
      <h1 class="ubuntu-bold font-size-24 ms-5 mb-5">{{ 'PREPAY.FROM' | translate }}
        {{ headerStartDate | dateFormat: 'long' }} {{ 'PREPAY.TO' | translate }}
        {{ headerEndDate | dateFormat: 'long' }}</h1>
  </div>
    <div class="p-5">
        <div class="table-container w-fix-content" *ngIf="prepayCounters.length !== 0"
            [ngClass]="(balances.length === 0)? 'full-page':'half-page'">
            <app-prepay-counters [countersArray]="prepayCounters" [countersArrayHearders] ="movementModel" [counterDisplay]="prepaySettings.counterDisplay"></app-prepay-counters>
        </div>
        <div class="table-container w-fix-content mt-5" *ngIf="balances.length !== 0" >
            <app-prepay-balances [balancesArray]="balances" [counterDisplay]="prepaySettings.counterDisplay"></app-prepay-balances>
        </div>
    </div>
</div>
