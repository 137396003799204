import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { I18nHttpLoaderFactory } from './shared/utils/factories';
import { SharedModule } from './shared/shared.module';
import { registerLocaleData, CommonModule } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { TokenInterceptorService } from './shared/interceptor/token.interceptor.service';
import { WebsocketConnectionService } from 'src/app/shared/services/websocket-connection.service';
import { AuthenticationService } from './auth.service';
import { Initializable } from './shared/services/initializable';
import { HomeModule } from './features/home/home.module';
import { MessagesModule } from './features/messages/messages.module';
import { PlanningModule } from './features/planning/planning.module';
import { AbsenceModule } from './features/absence/absence.module';
import { DocumentsModule } from './features/documents/documents.module';
import { TrainingsModule } from './features/trainings/trainings.module';
import { CountersModule } from './features/counters/counters.module';
import { SwapModule } from './features/swap/swap.module';
import { VolunteerModule } from './features/volunteer/volunteer.module';
import { AccountModule } from './features/account/account.module';
import { NewsModule } from './features/news/news.module';
import { PrepayModule } from './features/prepay/prepay.module';
import { RouterModule } from '@angular/router';
import { ForbiddenAccessModule } from './features/forbidden-access/forbidden-access.module';
import { SurveysModule } from './features/surveys/surveys.module';
import { BadgeConnectionModule } from './features/badge-connection/badge-connection.module';
import { ForcedPasswordChangeModule } from './features/forced-password-change/forced-password-change.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Step 1: Add the following line...
import { register } from 'swiper/element/bundle';
import { LoginModule } from './features/login/login.module';
import { ResetPasswordModule } from './features/reset-password/reset-password.module';

registerLocaleData(localeFr, 'fr');
register();

export function appInit(service: Initializable) {
  return () => {
    return service.init();
  };
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    RouterModule,
    HomeModule,
    CommonModule,
    MessagesModule,
    PlanningModule,
    AbsenceModule,
    DocumentsModule,
    TrainingsModule,
    CountersModule,
    SwapModule,
    VolunteerModule,
    AccountModule,
    ForbiddenAccessModule,
    BadgeConnectionModule,
    NewsModule,
    PrepayModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SurveysModule,
    ForcedPasswordChangeModule,
    LoginModule,
    ResetPasswordModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: I18nHttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SharedModule
  ],
  providers: [
    AuthenticationService,
    WebsocketConnectionService,
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [AuthenticationService]
    }
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
