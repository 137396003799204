<div class="volonteer-counters counters-request-content bg-primary-lightGrey">
  <div class="mobime-header ubuntu-regular bg-white">
    <h1 class="ubuntu-bold font-size-32 mb-0">{{ 'ABSENCE_REQUEST.PARTIAL_ABSENCE' | translate }}</h1>
    <p class="ubuntu-bold font-size-16">{{ 'ABSENCE_REQUEST.AVAILABILITY' | translate }}</p>
    <p class="font-size-13 text-grey">
      {{ 'ABSENCE_REQUEST.AVAILABILITY_DESCRIPTION' | translate }}
    </p>
  </div>
  <div class="py-5 absence-reminder">
    <p class="ubuntu-bold font-size-24 mb-3 text-white">{{ 'ABSENCE_REQUEST.PLANNING_RAPPEL' | translate }}</p>
    <div class="col-12 bg-white d-flex flex-column font-size-16 py-2 br-4">
      <p class="absence-reminder__date mb-3 ubuntu-bold">
        {{ date | dateFormat : 'long' | titlecase}}
      </p>
      <ng-container *ngIf="planningDetails?.period === 'PREVIEW' || planningDetails?.period === 'DETAIL'; else cardTitle">
          <div class="row" *ngFor="let shift of planningDetails.shifts">
            <ng-container *ngIf="shift.details; else displayLabel">
                <div class="col-6 d-flex align-items-center">
                    {{ shift.details?.departure?.date | hourFormat:'h':'HH:mm' }}
                    <div class="icon icon-16 icon-arrow-right mx-2"></div>
                    {{ shift.details?.arrival?.date | hourFormat:'h':'HH:mm' }}</div>
                    <div class="col-6 d-flex flex-s" *ngIf="shift.reference === '-1'">{{shift.partLabel}} </div>
                  <div class="col-6 d-flex align-items-center" *ngIf="shift.reference !== '-1'">Service {{shift.reference}} </div>
            </ng-container>
            <ng-template #displayLabel>
              <span class="ubuntu-bold ms-5">{{shift.label}}</span>
            </ng-template>
           </div>
          </ng-container>
          <ng-template #cardTitle>
              <ng-container [ngSwitch]="planningDetails?.shifts[0].type">
                  <ng-container *ngSwitchCase="shiftTypeEnum.AVAILABLE">
                    <div class="ubuntu-bold">
                     {{'PLANNING.CARDS.SUBTITLE.DAY_AVAILABLE' | translate}}
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="shiftTypeEnum.UNAVAILABLE">
                    <div class="ubuntu-bold">
                     {{'PLANNING.CARDS.SUBTITLE.DAY_UNAVAILABLE' | translate}}
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="shiftTypeEnum.NO_PLANNING">
                      <div class="ubuntu-bold">
                       {{'PLANNING.CARDS.SUBTITLE.SCHEDULE_NOT_AVAILABLE' | translate}}
                      </div>
                    </ng-container>
                  <ng-container *ngSwitchCase="shiftTypeEnum.REST">
                    <div class="ubuntu-bold">
                     {{'PLANNING.CARDS.SUBTITLE.DAY_REST' | translate}}
                    </div>
                  </ng-container>
                  <ng-container  *ngSwitchCase="shiftTypeEnum.WORKING">
                     {{'PLANNING.CARDS.SUBTITLE.DAY_WORKED' | translate}}
                  </ng-container>
                  <ng-container  *ngSwitchCase="shiftTypeEnum.WORKING_DAY">
                       {{'PLANNING.CARDS.SUBTITLE.DAY_WORKED' | translate}}
                    </ng-container>
                </ng-container>
          </ng-template>

    </div>
  </div>

  <form [formGroup]="counterRequestForm" class="counters-request-content__form">
    <div class="p-4 d-flex flex-column m-auto bg-white">
      <div class="px-3">
        <h3 class="ubuntu-bold font-size-16 pt-3">{{ 'ABSENCE_REQUEST.COUNTERS_FORM_TITLE' | translate }}</h3>
        <div class="row">
          <div class="col-md-6 col-lg-3 col-xl-2 d-flex flex-column">
          <p class="ubuntu-normal font-size-13 pb-2">{{ 'ABSENCE_REQUEST.COUNTERS_START_LABEL' | translate}}</p>
            <ngb-timepicker [spinners]="false" id="startTime" formControlName="startTime"></ngb-timepicker>
          </div>
          <div class="col-md-6 col-lg-3 col-xl-2 d-flex flex-column">
            <p class="ubuntu-normal font-size-13 pb-2 mt-3 mt-md-0">{{ 'ABSENCE_REQUEST.COUNTERS_END_LABEL' | translate}}</p>
            <ngb-timepicker [spinners]="false" id="endTime" formControlName="endTime"></ngb-timepicker>
          </div>
        </div>
      </div>

      <div class="mt-5 px-3">
        <h3 class="ubuntu-bold font-size-16 pt-3">{{ 'ABSENCE_REQUEST.TYPE_FORM_TITLE' | translate }}</h3>
        <ng-container *ngFor="let counter of counters">
          <label class="d-flex ps-5 mb-2 pointer my-2" [for]="counter.code">
            <div class="position-relative me-5 mt-2">
              <input class="counter-input-radio" type="radio" name="counter" [value]="counter.code" [id]="counter.code"
                formControlName="counter">
              <div class="radio-check-container d-flex justify-content-center align-items-center">
                <div class="icon icon-16 icon-checked-white"></div>
              </div>
            </div>
            <div class="d-flex flex-column my-2">
              <span *ngIf="counter.label" class="ubuntu-regular font-size-16"> {{counter.code}} ({{counter.label}}) </span>
              <span *ngIf="counter.balance !== null" class="ubuntu-regular font-size-13 text-grey">

                <span *ngIf="counter.unit !== 'H'" >{{counter.balance}}</span>
                <span *ngIf="counter.unit === 'H'">{{ counter.balance | hoursMinutesConverter }}</span>

                <ng-container *ngIf="counter.unit === 'H'">
                  <ng-container *ngIf="counter.balance === 1 else hours">
                    {{ 'ABSENCE_REQUEST.TYPE_FORM_REMAINING_HOUR' | translate}}
                  </ng-container>
                  <ng-template #hours>
                    {{'ABSENCE_REQUEST.TYPE_FORM_REMAINING_HOURS' | translate}}
                  </ng-template>

                </ng-container>

                <ng-container *ngIf="counter.unit === 'J'">
                  <ng-container *ngIf="counter.balance === 1 else days">
                    {{ 'ABSENCE_REQUEST.TYPE_FORM_REMAINING_DAY' | translate}}
                  </ng-container>
                  <ng-template #days>
                    {{'ABSENCE_REQUEST.TYPE_FORM_REMAINING_DAYS' | translate}}
                  </ng-template>

                </ng-container>
              </span>
            </div>
          </label>
        </ng-container>
      </div>

      <div class="mt-5 pb-3 px-3">
        <h3 class="ubuntu-bold font-size-16 pt-3">{{ 'ABSENCE_REQUEST.ADD_COMMENT' | translate }}</h3>
        <textarea id="comment" formControlName="comment" class="w-100 font-size-13 p-3" maxlength="2000" [placeholder]="'ABSENCE_REQUEST.ADD_COMMENT_PLACEHOLDER' | translate">
        </textarea>
      </div>
    </div>
  </form>
</div>


<div class="absence-nav d-flex justify-content-center align-items-center px-5">
  <div class="absence-nav__container d-flex justify-content-between">
    <button (click)="goToPrevStep()" [disabled]="partialAbsenceRequestService.absenceData.groupingId" class="btn button btn-white ubuntu-bold font-size-16">
      {{'COMMON.BUTTON.PREVIOUS' | translate}}
    </button>
    <button (click)="goToNextStep()" [disabled]="counterRequestForm.invalid || !checkEndTimeValidators()"
    class="btn button btn-black ubuntu-bold font-size-16">
      {{'COMMON.BUTTON.CONTINUE' | translate}}
    </button>
  </div>
</div>
