import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_URLS } from 'src/environments/api-urls';
import { MetaData, MetaTableRequest } from '@shared/interfaces/metadata.model';
import { ProviderSettingModel, SmsMessage } from '@shared/models/providers/provider.model';


@Injectable({
  providedIn: 'root'
})
export class MetadataService {

  constructor(
    private http: HttpClient,
  ) { }

  getMetaDataBy(metaTableRequest: MetaTableRequest): Observable<MetaData[]> {
    return this.http.post<MetaData[]>(`${API_URLS.METADATA}`, metaTableRequest);
  }

  getProviderSettings(siteId: string): Observable<ProviderSettingModel[]> {
    return this.http.get<ProviderSettingModel[]>(`${API_URLS.SMS_PROVIDERS}?id=${siteId}`);
  }

  smsPasswordSms(smsMessage: SmsMessage): Observable<string> {
    return this.http.post<string>(`${API_URLS.SEND_SMS_PASSWORD}`, smsMessage);
  }
}
