import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PrepayService } from '../prepay.service';
import { SnackbarService } from 'src/app/shared/components/snackbar/snackbar.service';
import { UserService } from 'src/app/shared/services/user.service';
import { PrepaySettings } from 'src/app/shared/models/settings/prepaySettings/PrepaySettings';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-prepay-details',
    templateUrl: './prepay-details.component.html',
    styleUrls: ['./prepay-details.component.scss']
})
export class PrepayDetailsComponent implements OnInit {

    prepayCounters = [];
    headerStartDate: string;
    headerEndDate: string;
    balances = [];
    movementModel = [];
    prepaySettings: PrepaySettings;
    private route = inject(ActivatedRoute);
    private prepayService = inject(PrepayService);
    private userService = inject(UserService);
    private router = inject(Router);
    private snackBarService = inject(SnackbarService);
    private destroyRef = inject(DestroyRef);

    ngOnInit() {
        this.getPrepaySettings();
        this.headerStartDate = this.route.snapshot.paramMap.get('start');
        this.headerEndDate = this.route.snapshot.paramMap.get('end');
        this.getPrepayBalances(this.headerStartDate, this.headerEndDate);
        this.getPrepayCounters(this.headerStartDate, this.headerEndDate);
        this.getMouvementModel(this.headerStartDate, this.headerEndDate);
    }

    getPrepaySettings(){
        this.userService.typologySetting
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(typoSetting => {
          this.prepaySettings = typoSetting.prepaySettings;
        });
    }

    getMouvementModel(start: string, end: string) {
        this.prepayService.getMouvementModel(start, end)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (res) => {
                    this.movementModel = res.map(movement => movement);
                },
                error: () => {
                    this.snackBarService.show('PREPAY.COUNTERS.ERROR', 'error');
                }
            });
    }

    getPrepayBalances(start: string, end: string) {
        this.prepayService.getPrepayBalances(start, end)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
            next: (res) => {
                this.balances = res;
            },
            error: () => {
                this.snackBarService.show('PREPAY.COUNTERS.ERROR', 'error');
            }
        });
    }

    getPrepayCounters(start: string, end: string) {
        this.prepayService.getPrepayCounters(start, end)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (res) => {
                    this.prepayCounters = res;
                },
                error: () => {
                    this.snackBarService.show('PREPAY.COUNTERS.ERROR', 'error');
                }
            });
    }

    getBack() {
        this.router.navigate(['/prepay']);
    }

}
