import { Component, OnInit, inject, DestroyRef } from '@angular/core';
import { AbsenceService } from '../absence.service';
import { Absence } from 'src/app/shared/models/absence/absence';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SnackbarService } from 'src/app/shared/components/snackbar/snackbar.service';
import { PartialAbsenceRequestService } from '../partial-absence-request/partial-absence-request.service';
import { FullDayAbsenceRequestService } from '../fullDay-absence-request/fullDay-absence-request.service';
import * as moment from 'moment';
import { ConfirmationModalComponent } from 'src/app/shared/components/confirmation-modal/confirmation-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-absence-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class AbsenceDetailsComponent implements OnInit {

  private absence$: Subscription;
  absenceDetails: Absence;
  private destroyRef = inject(DestroyRef);

  constructor(
    public route: ActivatedRoute,
    public absenceService: AbsenceService,
    private router: Router,
    private snackBarService: SnackbarService,
    private partialAbsenceService: PartialAbsenceRequestService,
    private fullDayAbsence: FullDayAbsenceRequestService,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.absence$ = this.route.paramMap
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(params => {
      this.getAbsenceData(Number(params.get('id')));
    });
  }

  getAbsenceData(requestId: number) {
    return this.absenceService.getAbsenceById(requestId)
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(absence => {
      this.absenceDetails = absence;
    });
  }


  cancel() {
    const modalRef =  this.modalService.open(ConfirmationModalComponent, {
      centered: true,
    });
    modalRef.componentInstance.icon = 'icon-alert-red';
    modalRef.componentInstance.message = 'ABSENCE_REQUEST.MESSAGE.CANCEL_DESCRIPTION';
    modalRef.result.then(() => {
      this.absenceService.deleteAbsence(this.absenceDetails.groupingId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.snackBarService.show('ABSENCE_REQUEST.MESSAGE.CANCEL_SUCCESS', 'success');
        this.router.navigate(['/absence/history']);
      });
    });
  }

  modify() {
    if (this.absenceDetails.startTime && this.absenceDetails.endTime) {
      this.partialAbsenceService.absenceData.startDate = moment(this.absenceDetails.startDate);
      this.partialAbsenceService.absenceData.counters = this.absenceDetails.counters;
      this.partialAbsenceService.absenceData.counterLabel = this.absenceDetails.counterLabel;
      this.partialAbsenceService.absenceData.groupingId = this.absenceDetails.groupingId;
      this.partialAbsenceService.absenceData.comment = this.absenceDetails.comment;
      this.partialAbsenceService.absenceData.startTime =
        this.partialAbsenceService.convertMinutesToHourMinutes(this.absenceDetails.startTime);
      this.partialAbsenceService.absenceData.endTime =
        this.partialAbsenceService.convertMinutesToHourMinutes(this.absenceDetails.endTime);
      this.partialAbsenceService.step = 2;
      this.router.navigate(['/absence-request/partial']);
    } else {
      this.fullDayAbsence.step = 2;
      this.fullDayAbsence.absence.startDate = moment(this.absenceDetails.startDate);
      this.fullDayAbsence.absence.endDate = moment(this.absenceDetails.endDate);
      this.fullDayAbsence.absence.counters = this.absenceDetails.counters;
      this.fullDayAbsence.absence.groupingId = this.absenceDetails.groupingId;
      this.fullDayAbsence.absence.comment = this.absenceDetails.comment;
      this.fullDayAbsence.absence.counters = this.absenceDetails.counters;
      this.router.navigate(['/absence-request/full']);
    }
  }

  getBack() {
    this.router.navigate(['/absence/history']);
  }

}
