import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { stringToSimpleHash } from './simple-hash';

/**
 * Factory function to be used by translation service
 * to load json files
 */
export function I18nHttpLoaderFactory(http: HttpClient) {
  const hashAppVersion = stringToSimpleHash(environment.appVersion);
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + hashAppVersion);
}
