import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { PasswordRestComponent } from './password-rest/password-rest.component';

@Component({
  selector: 'app-forced-password-change',
  templateUrl: './forced-password-change.component.html',
  styleUrls: ['./forced-password-change.component.scss']
})
export class ForcedPasswordChangeComponent implements OnInit {

  constructor(
    private modalService: NgbModal,
    modalConfig: NgbModalConfig
  ) {
    modalConfig.backdrop = 'static';
    modalConfig.keyboard = false;
  }

  ngOnInit() {
    this.openPassWordRestModal();
  }

  openPassWordRestModal(): void {
    this.modalService.open(PasswordRestComponent, {
      centered: true,
    });
  }
}
