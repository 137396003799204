const country = (localStorage.getItem('country') ? localStorage.getItem('country') : '').toLowerCase();
export const HOST = `https://api.formation.${country}.mobime.transdev.com`;
export const MOBI_ADMIN_HOST = `https://api.formation.${country}.mobiservices.transdev.net/mobi-admin`;
const AUTH_HOST = getAuthHost();
export const APP_ID = getAppID();
export const WEBSOCKET_HOST = getWebSocket();

const CALLBACK_URL = `https://formation.${country}.mobime.transdev.com`;

export const AUTH_URL = {
  AUTHORIZE: `${AUTH_HOST}/oauth2/authorize?client_id=${APP_ID}&response_type=token` +
             `&scope=aws.cognito.signin.user.admin+openid+profile&redirect_uri=${CALLBACK_URL}`,
  LOGOUT: `${AUTH_HOST}/logout?client_id=${APP_ID}&response_type=token` +
             `&scope=aws.cognito.signin.user.admin+openid+profile&redirect_uri=${CALLBACK_URL}`,
};

export const mobiAdminDomaine = 'https://formation.fr.mobiservices.transdev.net';

export const environment = {
  appVersion: require('../../package.json').version + '-formation',
  recaptcha: {
    siteKey: '6LdG0_ApAAAAAL_pftIS3DWzNc0FCqwvUedZxn8z',
  },
  production: true
};

export const CALENDAR_PROPS = {
  NBR_DAYS: 30,
  PLANNING_DAYS: 365
};

export const GIT_URLS = {
  REPO_MOBINEXT_COMMIT: 'https://github.com/transdev-corp/MobiNext/commit/'
};

export const RECAPTCHA_KEY = '6LeLh-kpAAAAAMxJyIYY9m6QaSl41HHsz8kLzSmi';

function getAppID() {
  switch (country) {
    case 'fr':
      return '3pnq70sj2sja3pqje9huauqk1u';
    default:
      return 'N/A';
  }
}

function getAuthHost() {
  switch (country) {
    case 'fr':
      return 'https://formation-mobime-transdev.auth.eu-west-1.amazoncognito.com';
    default:
      return 'N/A';
  }
}

function getWebSocket() {
  switch (country) {
    case 'fr':
      return 'wss://mipq1wgst9.execute-api.eu-west-1.amazonaws.com/Prod';
    default:
      return 'wss://N/A';
  }
}
