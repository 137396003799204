import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_URLS } from 'src/environments/api-urls';
import { Observable } from 'rxjs';
import { ModifyPasswordResponse} from '../../features/account/models/modifyPassword';

@Injectable()
export class AccountPasswordService {

  constructor(
    private http: HttpClient,
  ) {}

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
          // return if another validator has already found an error on the matchingControl
          return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ mustMatch: true });
      } else {
          matchingControl.setErrors(null);
      }
    };
  }

  modifyPassword(accessToken: string, newPswd: string): Observable<ModifyPasswordResponse> {
    return this.http.post<ModifyPasswordResponse>(API_URLS.MODIFY_PASSWORD, {
        newPassword: newPswd
      },
      {headers: new HttpHeaders().set('accessToken', accessToken)}
    );
  }

  passwordValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const passwordError = {passwordError: true};
      return !this.isPasswordValid(control.value) ? passwordError : null;
    };
  }

  // When comparing strings is compares their character code
  // function translated from logic of backend for respect same rules
  isPasswordValid(password: string): boolean {
    if (!password || password.length < 8) {
      return false;
    }

    let numCount = 0;
    let charCount = 0;
    let upperChaseCharCount = 0;
    let specialCount = 0;

    for (const char of password) {
      if (this._isNumeric(char)) {
        numCount++;
      } else if (this._isLowerCaseLetter(char)) {
        charCount++;
      } else if (this._isUpperCaseLetter(char)) {
        upperChaseCharCount++;
      } else if (this._isSpecialLetter(char)) {
        specialCount++;
      } else {
        return false;
      }
    }
    return !!numCount && !!charCount && !!upperChaseCharCount && !!specialCount;
  }

  private _isNumeric(char: string): boolean {
    return (char >= '0' && char <= '9');
  }

  private _isUpperCaseLetter(char: string): boolean {
    return char >= 'A' && char <= 'Z';
  }

  private _isLowerCaseLetter(char: string): boolean {
    return char >= 'a' && char <= 'z';
  }

  private _isSpecialLetter(char: string): boolean {
    return char >= '!' && char <= '/'
    || char >= '[' && char <= '`'
    || char >= '{' && char <= '~'
    || char >= ':' && char <= '@'
    || char === '€'
    || char === '£';
  }
}
