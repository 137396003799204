<div class="login d-flex">
  <div class="login__text-side col-6 d-flex align-items-center justify-content-center">
    <div class="login__text-side__info">
      <p class="ubuntu-bold font-size-32 mb-4">
        {{ 'FORBIDDEN_ACCESS.TITLE' | translate }}
      </p>
      <p class="text-grey font-size-16">
        {{ 'FORBIDDEN_ACCESS.TEXT_PART_1' | translate }}
      </p>
      <p class="text-grey font-size-16">
        {{ 'FORBIDDEN_ACCESS.TEXT_PART_2' | translate }}
      </p>
    </div>
  </div>
  <div class="login__panel-action">
    <div class="login__panel-action__header">
      <h3 class="ubuntu-bold">{{i18prefix+'NOT_CONNECTED' | translate}}</h3>
    </div>
    <div class="login__panel-action__body font-size-16 h-75">
      <div>
        <a [href]="authUrl" class="btn btn-primary ubuntu-bold pointer font-size-16">{{i18prefix+'CONNECT' | translate}}</a>
      </div>
      <!-- <div>
        <a [routerLink]="forgottenPasswordUrl" class="btn btn-white ubuntu-bold pointer font-size-16">{{i18prefix+'CREATE_ACCOUNT' | translate}}</a>
      </div> -->
      <a [routerLink]="forgottenPasswordUrl">{{i18prefix+'FORGOTTEN_PASSWORD' | translate}}</a>
    </div>
  </div>
  <div class="login__red-side col-6 d-flex align-items-center justify-content-center">
  </div>
</div>