export function stringToSimpleHash(strToHash: string): number {
  let hash = 0;
  for (let i = 0; i < strToHash.length; i++) {
    const char = strToHash.charCodeAt(i);
    /* tslint:disable:no-bitwise */
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash;
    /* tslint:enable:no-bitwise */
  }
  return hash;
}
