<div class="shifts-container d-flex flex-column align-items-center">
    <ng-container *ngIf=" displayServiceName">
        <ng-container *ngIf ="period === periodType.DETAIL || period === periodType.PREVIEW">
            <div *ngIf=" type === dayType.WORKING" class="status font-size-12 ubuntu-bold text-uppercase inactive me-1 pointer">
                {{ (serviceName?.length > 7)? (serviceName | slice:0:6)+'..':(serviceName) }}
            </div>
            <div *ngIf=" type === dayType.REST ||
                         type === dayType.UNAVAILABILITY || 
                         type === dayType.AVAILABILITY "
                  class="status font-size-12 ubuntu-bold text-uppercase inactive me-1 pointer">
                {{ (code?.length > 7)? (code | slice:0:6)+'..':(code) }}
            </div>
        </ng-container>
        <ng-container *ngIf ="period === periodType.POSITION">
            <div  class="status font-size-12 ubuntu-bold text-uppercase inactive me-1 pointer">  
                <span *ngIf=" type === dayType.REST && !workingTime">{{'PLANNING.CODE.REST'  | translate}}</span>
                <span *ngIf=" type === dayType.AVAILABILITY && !workingTime">{{'PLANNING.CODE.AVAILABILITY'  | translate}}</span>
                <span *ngIf=" type === dayType.UNAVAILABILITY && !workingTime">{{'PLANNING.CODE.UNAVAILABILITY'  | translate}}</span>
                <span *ngIf=" type === dayType.WORKING">{{'PLANNING.CODE.WORKING'  | translate}}</span>
            </div>
        </ng-container>
    </ng-container>
    <div *ngIf="displayVacationCount || period === periodType.EMPTY " class="d-flex flex-row">
        <ng-container *ngFor="let shift of shifts; index as indexShift;">
            <ng-container *ngIf="indexShift < 3 && numberOfPoints !== -1">
                <div class="point inactive pointer"
                    [ngClass]="{'active' : indexItem === checkedItem && indexShift === checkedShift}"
                    (click)="handleActions(FrontActions.CHECK_SHIFT,{'indexItem':indexItem,'indexShift':indexShift})">
                </div>
            </ng-container>
            <ng-container *ngIf="numberOfPoints === -1  && !displayServiceName">
                <div class="status font-size-12 ubuntu-bold text-uppercase inactive me-1">
                    {{ (code?.length > 7)? (code | slice:0:6)+'..':(code) }}
                </div>    
            </ng-container>
            <ng-container *ngIf="indexShift === 3">
                <div class="plus inactive pointer" [ngClass]="{'active' : indexItem === checkedItem && checkedShift >= 3}"
                    (click)="handleActions(FrontActions.CHECK_SHIFT,{'indexItem':indexItem,'indexShift':3})">
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>
