import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { AbsenceRequestsHistory } from 'src/app/shared/models/absence/absenceHistory';
import { AbsenceService } from '../absence.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-absence-history',
  templateUrl: './absence-history.component.html',
  styleUrls: ['./absence-history.component.scss']
})
export class AbsenceHistoryComponent implements OnInit {

  absenceRequestsHistory: AbsenceRequestsHistory;

  requestsParam = {count: 0};
  errorGetRequests: boolean;
  loadingRequests = true;
  private absenceService = inject(AbsenceService);
  private destroyRef = inject(DestroyRef);

  ngOnInit() {
    this.getAbsenceHistory();
  }

  getAbsenceHistory() {
    this.absenceService.getAbsenceRequests()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: data => {
          this.absenceRequestsHistory = data;
          this.requestsParam.count = this.absenceRequestsHistory.absenceRequests.length;
          this.loadingRequests = false;
        },
        error: () => {
          this.errorGetRequests = true;
          this.loadingRequests = false;
        }
      }
      );
  }
}
