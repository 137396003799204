import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_URLS } from 'src/environments/api-urls';
import { Document } from '../../shared/models/document/document';


@Injectable()
export class DocumentsService {

  constructor(private http: HttpClient) { }

  getDocuments(path: string, withrecentfiles: boolean, searchTerm?: string | null): Observable<Document> {
    const url = searchTerm ? API_URLS.DOCUMENTS.DOCUMENTS_SEARCH : API_URLS.DOCUMENTS.DOCUMENTS_CONTENT;
    return this.http.get<Document>(`${url}?${this.getRequestParams(path, withrecentfiles, searchTerm)}`);
  }

  getRequestParams(path: string, withrecentfiles: boolean, searchTerm?: string | null): string {
    let params = `path=${encodeURIComponent(path)}&withRecentFiles=${withrecentfiles}`;
    if (searchTerm) {
      params += `&searchTerm=${encodeURIComponent(searchTerm)}`;
    }
    return params;
  }

  getFilePath(path: string): Observable<string> {
    return this.http.get<string>(`${API_URLS.DOCUMENTS.DOWNLOAD}?path=${encodeURIComponent(path)}`);
  }
}
