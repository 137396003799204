import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { IPartialAbsence } from './partialAbsence.interface';

@Injectable()
export class PartialAbsenceRequestService {
  step = 1;

  continueStep = true;

  absenceData: IPartialAbsence = {
    groupingId: null,
    startDate: null,
    counterCode: null,
    counterLabel: null,
    startTime: { hour: null, minute: null },
    endTime: { hour: null, minute: null },
    comment: null
  };

  constructor( private router: Router ) {}

  goToPrevStep() {
    this.step--;
    if (!this.step) {
      this.step = 1;
      this.router.navigate(['absence']);
    }
  }

  goToNextStep() {
    if (this.continueStep === true) {
      this.step++;
    }
  }

  continueEnable(enabled: boolean) {
    this.continueStep = enabled;
  }

  selectDate(date: moment.Moment) {
    this.absenceData.startDate = date;
  }

  convertMinutesToHourMinutes(minutesTotal: number) {
    const hours = Math.floor(minutesTotal / 60);
    const minutes = minutesTotal % 60;
    return {
      hour: hours,
      minute: minutes
     };
  }

}
