
<div class="ubuntu-bold text-black font-size-24 news-list__title" *ngIf="newsService.list.length !== 0">{{ "NEWS.LIST.TITLE" | translate }}</div>
<div class="row">
  <app-news-list-item
    [class.col-4]="!entry.frontPage"
    [class.col-12]="entry.frontPage"
    [item]="entry"
    [index]="index"
    *ngFor="let entry of newsService.list; let index=index">

  </app-news-list-item>

</div>
<div [hidden]="!newsService.isLoadingList"
     class=" news-list__loading text-center ubuntu-bold font-size-16"> {{'NEWS.LIST.LOADING' | translate }}
</div>
