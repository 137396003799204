import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CalendarDateFormatter, CalendarEvent } from 'angular-calendar';
import { CustomDateFormatter } from 'src/app/shared/utils/custom-date-formatter.provider';
import { UserService } from 'src/app/shared/services/user.service';
import { VolunteeringStatusEnum } from 'src/app/shared/enums/volunteer/volunteeringStatus.enum';
import { Planning } from 'src/app/shared/models/planning/planning';

@Component({
  selector: 'app-volunteer-calendar',
  templateUrl: './volunteer-calendar.component.html',
  styleUrls: ['./volunteer-calendar.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }
  ]
})
export class VolunteerCalendarComponent {

  eventStatus = VolunteeringStatusEnum;
  locale: string;
  @Input() viewDate: Date;
  @Input() events: CalendarEvent[];
  @Input() selectedDays: Planning[] = [];
  @Output() eSelectDay = new EventEmitter<Planning[]>();
  @Input() displayVacationCount: boolean;
  @Input() displayServiceName: boolean;
  constructor(
    public userService: UserService
  ) {
    this.locale = this.userService.site.language.code.slice(0, 2);
  }

  selectDay(day: Planning) {
    if (this.isASelectedDay(day)) {
      this.selectedDays = this.selectedDays.filter(selectedDay => selectedDay.date !== day.date);
    } else if (day.events[0].meta &&
      (day.events[0].meta.volunteeringStatus !== VolunteeringStatusEnum.CLOSE)) {
      this.selectedDays.push(day);
    }
    this.eSelectDay.emit(this.selectedDays);
  }

  isASelectedDay(day: Planning): boolean {
    return this.selectedDays.findIndex(selectedDay => selectedDay.date === day.date) > -1;
  }

}
