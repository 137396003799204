import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { PrepayService } from '../prepay.service';
import { SnackbarService } from 'src/app/shared/components/snackbar/snackbar.service';
import { PrepayDescription } from 'src/app/shared/models/prepay/prepay-description-counters';
import { Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/user.service';
import { PrepaySettings } from 'src/app/shared/models/settings/prepaySettings/PrepaySettings';
import { CounterDisplayEnum } from 'src/app/shared/enums/setting/prepay-settings/counter-display.enum';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EMPTY, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-prepay-description',
  templateUrl: './prepay-description.component.html',
  styleUrls: ['./prepay-description.component.scss']
})
export class PrepayDescriptionComponent implements OnInit {
  descriptionCounters: PrepayDescription[];
  prepaySettings: PrepaySettings;
  public counterDisplayEnum = CounterDisplayEnum;
  private prepayService = inject(PrepayService);
  private snackBarService = inject(SnackbarService);
  private userService = inject(UserService);
  private router = inject(Router);
  private destroyRef = inject(DestroyRef);


  ngOnInit() {
    this.getPrepaySettings();
    this.prepayService.getCountersDescription()
    .pipe(
      tap((res) => this.descriptionCounters = res),
      catchError(() => {
        this.snackBarService.show('PREPAY.COUNTERS.ERROR', 'error');
        return EMPTY;
      }),
      takeUntilDestroyed(this.destroyRef))
    .subscribe();
  }

  getPrepaySettings() {
    this.userService.typologySetting
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(typoSetting => {
      this.prepaySettings = typoSetting.prepaySettings;
    });
  }

  getBack() {
    this.router.navigate(['/prepay']);
  }
}
