<div class="absence-recap-content bg-primary-lightGrey" *ngIf="absenceDetails">
  <div class="ubuntu-regular bg-white">
    <div class="mobime-header">
      <div class="icon icon-24 icon-arrow-left-red mb-2 pointer" (click)="getBack()"></div>
      <h1 class="ubuntu-bold font-size-32 mb-0">{{ 'ABSENCE_REQUEST.TITLE' | translate }}</h1>
    </div>
    <p class="ubuntu-bold font-size-16 description-padding">{{ 'ABSENCE_REQUEST.DETAILS.TITLE' | translate }}</p>
    <div class="font-size-13 text-grey description-padding">
      {{ 'ABSENCE_REQUEST.AVAILABILITY_DESCRIPTION' | translate }}
    </div>
    <div class="mt-5 py-5 absence-recap">
      <div class="description-padding">
        <p class="ubuntu-bold font-size-24 mb-3 text-white">
          <ng-container *ngIf="!absenceDetails.startTime && !absenceDetails.endTime">
            {{ 'ABSENCE_REQUEST.DAY_ABSENCE' | translate }}
          </ng-container>
          <ng-container *ngIf="absenceDetails.startTime && absenceDetails.endTime">
            {{ 'ABSENCE_REQUEST.PARTIAL_ABSENCE' | translate }}
          </ng-container>
        </p>
        <div class="col-12 bg-white d-flex flex-column font-size-16 ms-3 py-2">
          <div class="row">
            <div class="col-6 d-flex flex-column">
              <p class="text-grey">{{'ABSENCE_REQUEST.RECAP_WISH' | translate}}</p>
              <p class="ubuntu-bold">
                <ng-container *ngIf="!absenceDetails.startTime && !absenceDetails.endTime">
                  {{ 'ABSENCE_REQUEST.RECAP_FROM' | translate | lowercase}}
                  {{ absenceDetails.startDate | dateFormat: 'LL'}}

                  {{ 'ABSENCE_REQUEST.RECAP_TO' | translate}}
                  {{ absenceDetails.endDate  | dateFormat: 'LL'}}
                </ng-container>
                <ng-container *ngIf="absenceDetails.startTime && absenceDetails.endTime">
                  {{ 'ABSENCE_REQUEST.RECAP_THE' | translate}}
                  {{absenceDetails.startDate | dateFormat: 'medium' }}
                  {{ 'ABSENCE_REQUEST.RECAP_FROM' | translate | lowercase}}
                  {{absenceDetails.startTime | hoursMinutesConverter }}
                  {{ 'ABSENCE_REQUEST.RECAP_TO' | translate }}
                  {{absenceDetails.endTime | hoursMinutesConverter }}
                </ng-container>
              </p>
            </div>
            <div class="col-6 d-flex align-items-center">
              <div class="icon icon-counter icon-40 me-4"></div>
              <div class="d-flex flex-column">
                <p class="text-grey"> {{'ABSENCE_REQUEST.RECAP_TYPE' | translate}} </p>
                <ng-container *ngFor="let counter of absenceDetails.counters">
                  <p id="counter-label" class="ubuntu-bold">{{counter.code}} ({{counter.label}})</p>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row no-gutters justify-content-center h-100 bg-white">
    <div class="col-10 d-flex flex-column m-auto">
      <div class="my-5 px-3" *ngIf="absenceDetails.comment">
        <h3 class="ubuntu-bold font-size-16 pt-3">{{ 'ABSENCE_REQUEST.RECAP_COMMENT' | translate }}</h3>
        <div class="col-12 p-4 m-auto font-size-13 bg-primary-lightGrey text-pre-wrap">{{absenceDetails.comment}}</div>
      </div>
    </div>
  </div>

  <div class="row no-gutters justify-content-center h-100">

    <div class="col-10 d-flex flex-column m-auto mt-5">
      <h2 class="ubuntu-bold font-size-24 py-3">{{ 'ABSENCE_REQUEST.DEMAND_TRACKING' | translate }}</h2>
      <div class="status-tracking w-100 p-4 mb-3 font-size-13 bg-white"
      *ngFor="let tracking of absenceDetails.requestTracking">
        <div class="d-flex justify-content-between">
          <p class="ubuntu-regular text-grey font-size-16">{{tracking.lastStatusUpdated | dateFormat: 'long'}}</p>
          <div [attr.data-state]="tracking.status"
            class="status-tracking-tag d-flex font-size-10 ubuntu-bold text-white text-uppercase align-items-center px-2 py-1 m-3">
            <ng-container *ngIf="tracking.status === 'PENDING'">
              <span class="icon icon-8 icon-clock-white me-2"></span>
              {{ 'ABSENCE_REQUEST.HISTORY.PENDING' | translate }}
            </ng-container>
            <ng-container *ngIf="tracking.status === 'VALIDATED'">
              <span class="icon icon-8 icon-checked-white me-2"></span>
              {{ 'ABSENCE_REQUEST.HISTORY.VALIDATED' | translate }}
            </ng-container>
            <ng-container *ngIf="tracking.status === 'REFUSED'">
              <span class="icon icon-8 icon-cross-white me-2"></span>
              {{'ABSENCE_REQUEST.HISTORY.REFUSED' | translate}}
            </ng-container>
          </div>
        </div>
        <p class="ubuntu-regular text-grey font-size-16">
          <ng-container *ngIf="tracking.status === 'PENDING'">
            {{ 'ABSENCE_REQUEST.DETAILS.PENDING' | translate }}
          </ng-container>
          <ng-container *ngIf="tracking.status === 'VALIDATED'">
            {{ 'ABSENCE_REQUEST.DETAILS.VALIDATED' | translate }}
          </ng-container>
          <ng-container *ngIf="tracking.status === 'REFUSED'">
            {{'ABSENCE_REQUEST.DETAILS.REFUSED' | translate}}
          </ng-container>
        </p>
        <div class="exploit-comment" *ngIf="tracking.exploitComment">
          <h3 class="ubuntu-bold font-size-16 pt-3">{{ 'ABSENCE_REQUEST.RECAP_COMMENT' | translate }}</h3>
          <p class="p-4 bg-primary-lightGrey text-pre-wrap">{{tracking.exploitComment}}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="absence-nav d-flex justify-content-center align-items-center px-5">
    <div class="absence-nav__container d-flex justify-content-between">
      <button id="cancel-button" [disabled]="absenceDetails.requestTracking[0].status !== 'PENDING'" (click)="cancel()"
        class="btn btn-white ubuntu-bold font-size-16">
        {{'ABSENCE_REQUEST.DETAILS.CANCEL_BUTTON' | translate}}
      </button>
      <button id="edit-button" [disabled]="absenceDetails.requestTracking[0].status !== 'PENDING'" (click)="modify()"
        class="btn btn-black ubuntu-bold font-size-16">
        {{'ABSENCE_REQUEST.DETAILS.UPDATE_BUTTON' | translate}}
      </button>
    </div>

  </div>
</div>
