import { Component, OnInit } from '@angular/core';
import { SurveysService } from '../../services/surveys.service';
import { Router } from '@angular/router';
import SurveyStatusEnum from 'src/app/shared/enums/surveys/surveyStatus.enum';

@Component({
  selector: 'app-surveys-list',
  templateUrl: './surveys-list.component.html',
  styleUrls: ['./surveys-list.component.scss']
})
export class SurveysListComponent implements OnInit {

  readonly SURVEY_STATUS = SurveyStatusEnum;

  constructor(
    public surveysService: SurveysService,
    private router: Router,
  ) { }

  get emptyCloseSurvey() {
    return !this.surveysService.list.find(elem => elem.status === this.SURVEY_STATUS.CLOSE);
  }

  get emptyToCompleteSurvey() {
    return !this.surveysService.list.find(elem => elem.status === this.SURVEY_STATUS.OPEN);
  }

  ngOnInit() {
    this.loadMoreItems();
  }


  navigateToDetails(id: number) {
    this.surveysService.resetCurrentActiveSurvey();
    this.router.navigateByUrl(`surveys/${id}`);
  }

  /**
   * Called to load more items
   * Used when we init the component and when we scroll down
   */
  loadMoreItems() {
    this.surveysService.fetchMore();
  }
}
