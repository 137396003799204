<ng-container *ngIf="file.path">
  <div class="container-fluid ">
  <div class="d-flex align-items-center mt-3">
    <div class="mt-5 me-3 icon icon-24 icon-arrow-left-red offset-1 pointer" (click)="getBack()"> </div>
    <h1 class="mt-5 pt-2 ubuntu-bold font-size-32">{{ file.name}}</h1>
  </div>
  <div class="row">
    <h1 class="col-10 offset-1 p-4 ubuntu-bold font-size-16">{{ file.description}}</h1>
  </div>
  <div class="row pb-5">
    <div class="col-10 bg-white rounded offset-1">
      <div class="row d-flex align-items-center">
        <div class="col-4">
          <ng-container *ngIf="filePath">
            <a  href="{{ filePath }}" target="_blank" download>
              <button class="Rectangle btn btn-black ubuntu-bold font-size-18">
                {{'DOCUMENT.FILE.DETAILS.BUTTON.READ' | translate}}
              </button>
            </a>
          </ng-container>
        </div>
        <div class="col-8 text-grey font-size-13">
          {{'DOCUMENT.FILE.DETAILS.BUTTON.DOWNLOAD' | translate}}
        </div>
      </div>
    </div>
  </div>
  <div class="row bg-primary-lightGrey" style="height: 600px;">
    <div class="col-10 bg-white rounded offset-1 p-5 mt-5 align">
      <div class="row font-size-16 mb-2">
        <div class="col-1 ubuntu-regular text-grey">{{'TRAINING.FILE.DETAILS.TYPE' | translate}}</div>
        <div class="col-5 ubuntu-bold">{{ file.type}}</div>
        <div class="col-3 ubuntu-regular text-grey">{{'TRAINING.FILE.DETAILS.DATE.PUBLICATION' | translate}}</div>
        <div class="col-3 ubuntu-bold">{{ file.creationDate |dateFormat : 'short' }}</div>
      </div>
      <div class="row font-size-16">
        <div class="col-1 ubuntu-regular text-grey">{{'TRAINING.FILE.DETAILS.SIZE' | translate}}</div>
        <div class="col-5 ubuntu-bold">{{ file.size | fileSize}}</div>
        <div class="col-3 ubuntu-regular text-grey">{{'DOCUMENT.FILE.DETAILS.DATE.MODIFICATION' | translate}}</div>
        <div class="col-3 ubuntu-bold">{{ file.lastModified |dateFormat : 'short' }}</div>
      </div>
    </div>
  </div>
</div>
</ng-container>