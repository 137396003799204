import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CountersComponent } from './counters.component';
import { TranslateModule } from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { CounterHistoryComponent } from './counter-history/counter-history.component';
import { CountersService } from './counters.service';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forRoot(),
    FormsModule,
    SharedModule,
    RouterModule
  ],
  declarations: [
    CountersComponent,
    CounterHistoryComponent
  ],
  providers: [
    CountersService
  ]
})
export class CountersModule { }
