import {Component, DestroyRef, OnInit, inject} from '@angular/core';
import {SurveysService} from '../../services/surveys.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SnackbarService} from 'src/app/shared/components/snackbar/snackbar.service';
import SurveyStatusEnum from 'src/app/shared/enums/surveys/surveyStatus.enum';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-survey-view',
  templateUrl: './survey-view.component.html',
  styleUrls: ['./survey-view.component.scss']
})
export class SurveyViewComponent implements OnInit {
  readonly LATEST_SURVEY_RESPONSE_LOCAL_STORAGE_KEY = 'LATEST_SURVEY';
  public readonly SURVEY_STATUS = SurveyStatusEnum;
  currentQuestionIndex = 0;
  canContinue = true;
  isSurveyOpened: boolean;
  private destroyRef = inject(DestroyRef);

  constructor(
    public surveysService: SurveysService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private snackbarService: SnackbarService,
  ) { }

  get progress(): number {
    return (this.currentQuestionIndex + 1) / this.surveysService.activeSurveyDetails.questions.length * 100;
  }
  ngOnInit() {
    this.activeRoute.params
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(params => {
      if (params.id) {
        this._loadItemAndResponsesIfExists(params.id);
      }
    });
  }

  private _loadItemAndResponsesIfExists(id = 188) {
    this.surveysService.fetchDetails(id).then(
      () => {
        // get response from localstorage
        this._tryLoadResponse();
      }
    );
  }

  private _tryLoadResponse() {
    // get latest response
    const item: any = JSON.parse(localStorage.getItem(this.LATEST_SURVEY_RESPONSE_LOCAL_STORAGE_KEY));
    if (item) {
      if (item.id === this.surveysService.activeSurveyDetails.id) {
        for (const question of this.surveysService.activeSurveyDetails.questions) {
          const savedResponses = item.questions.find(e => e.id === question.id);
          if (savedResponses) {
            question.options = savedResponses.options;
          }
        }
      } else {
        this._cleanLocalStorage();
      }
    }
  }

  private _cleanLocalStorage() {
    localStorage.removeItem(this.LATEST_SURVEY_RESPONSE_LOCAL_STORAGE_KEY);
  }

  public getBack() {
    this.router.navigate(['/surveys']);
  }

  private _updateCanContinue() {
    const currentIndex = this.currentQuestionIndex;
    const questionList = this.surveysService.activeSurveyDetails.questions;
    // Mandatory ==>
    // if type text, option value == null
    // if type multiplechoices => values null
    // if single choice, all options null
    if (questionList && questionList[currentIndex]) {
      if (questionList[currentIndex].mandatory) {
        const question = questionList[currentIndex];
        // at least one element where answer is not null
        this.canContinue = !!question.options.find(e => e.answer);
      } else {
        this.canContinue = true;
      }
    } else {
      this.canContinue = true;
    }
  }


  /**
   * Navigate to next question
   */
  nextStep() {
    this.currentQuestionIndex++;
    this._updateCanContinue();
  }

  /**
   * Navigate to previous question
   */
  previousStep() {
    let index = this.currentQuestionIndex;
    index--;
    if (index === -1) {
      this.isSurveyOpened = false;
      index = 0;
    }
    this.currentQuestionIndex = index;
    this._updateCanContinue();
  }

  updateResponses(questionNewValue /*future usage */) {
    // store latest response
    const item = this.surveysService.activeSurveyDetails.toJSON();
    const index = item.questions.findIndex(e => e.id === questionNewValue.id);
    // replace by new value
    if(index !== -1){
      item.questions[index] = questionNewValue;
    }
    // replace
    localStorage.setItem(this.LATEST_SURVEY_RESPONSE_LOCAL_STORAGE_KEY, JSON.stringify(item));

    this._updateCanContinue();
  }

  confirmResponse() {
    this.surveysService.sendResponse().then(
      () => {
        // get response from localstorage
        this._cleanLocalStorage();
        this.snackbarService.show('SURVEY_QUESTION_SUCCESS_MESSAGE', 'success');
        this.getBack();
      },
      () => {
        this.snackbarService.show('SURVEY_QUESTION_ERROR_MESSAGE', 'error');
      }
    );
  }

  openSurvey() {
    this.isSurveyOpened = true;
    this._updateCanContinue();
  }
}
