<div class="counters ubuntu-regular">
  <div class="mobime-header">
    <h1 class="ubuntu-bold font-size-32 mb-5">{{'COUNTERS.TITLE' | translate}}</h1>
    <p class="font-size-13 text-grey">{{'COUNTERS.DESCRIPTION' | translate}}</p>
  </div>
  <div class="counters__list-container">

    <ul *ngIf="!errorGetResults && counters?.length > 0" class="counters__list list-unstyled">
      <li *ngFor="let counter of counters" class="counters__list__item mb-3">
          <div class="text-grey font-size-13">
            <span class="text-black ubuntu-bold">{{ counter.code }}</span>
            {{ counter.isForecast ? ('COUNTERS.PROJECTED' | translate) : ('COUNTERS.ON' | translate) }}
            <span>{{(counter.date ? counter.date : moment()) | dateFormat : 'short'}}</span>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <h2 class="ubuntu-bold font-size-24">{{ counter.label }}</h2>
            <div class="ubuntu-bold font-size-32">
              <ng-container *ngIf="counter.format === format.DAY">
                {{ counter.balance }}{{'COUNTERS.DAY' | translate}}
              </ng-container>
              <ng-container *ngIf="counter.format === format.HOUR">
                <span *ngIf="counterSettings?.balanceDisplaying === balanceDisplaying.MINUTE">
                  <span>{{ counter.balance | hoursMinutesConverter }}</span>
                </span>
                <span *ngIf="counterSettings?.balanceDisplaying === balanceDisplaying.HUNDREDTH">
                  {{ counter.decimal }} {{'COUNTERS.HOUR' | translate}}
                </span>
              </ng-container>

              <ng-container *ngIf="counter.format !== format.DAY && counter.format !== format.HOUR ">
                {{ counter.balance }}
              </ng-container>

            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <a [routerLink]="counter.code" routerLinkActive="router-link-active"  class="counters__list__item__more d-flex align-items-center font-size-16 text-grey">
              <span class="icon icon-16 me-2 icon-arrow-right"></span> {{'COUNTERS.DETAILS' | translate}}
            </a>
            <ng-container *ngIf="counter.pending > 0">
              <div class="state-pending d-flex font-size-10 ubuntu-bold text-white text-uppercase align-items-center px-2 py-1">
                <span class="icon icon-8 icon-clock-white me-2"></span>
                {{ counter.pending }} {{ counter.pending > 1 ? ('COUNTERS.PENDING_DAYS' | translate) : ('COUNTERS.PENDING_DAY' | translate)}}
              </div>
            </ng-container>
          </div>
        </li>
    </ul>

    <p *ngIf="!loadingResults && !errorGetResults && !counters?.length" class="font-size-16">
      {{ 'ABSENCE_REQUEST.HISTORY.NO_RESULT_FOUND' | translate }}
    </p>
    <p *ngIf="errorGetResults" class="font-size-16 text-pomegranate">
      {{ 'ABSENCE_REQUEST.HISTORY.ERROR' | translate }}
    </p>

  </div>
</div>
