<div class="ubuntu-regular">
  <div class="header">
      <h1 class="mobime-header ubuntu-bold font-size-32 mb-0">{{ 'VOLUNTEER.SELECT.TITLE' | translate }}</h1>
  </div>
  <div class="description">
      <div class="ubuntu-bold font-size-16">{{ 'VOLUNTEER.SELECT.DESCRIPTION' | translate }}</div>
      <div class="font-size-13 text-grey">
          {{ 'VOLUNTEER.SELECT.CONSULT_LEGEND' | translate }}
      </div>
      <div class="mt-5">
          <div class="ubuntu-bold font-size-13">{{ 'ABSENCE_REQUEST.LEGEND' | translate }}</div>
          <div class="row no-gutters mt-3">
              <div class="d-flex col-md-4 col-sm-6 mb-3">
                  <div class="square me-2 bg-shadowGreen"></div>
                  <div class="font-size-13 text-charcoal-grey">
                      {{ 'VOLUNTEER.SELECT.ALREADY_VOLUNTEER' | translate }}
                  </div>
              </div>
              <div class="d-flex col-md-4 col-sm-6 mb-3">
                  <div class="square me-2 bg-gallery "></div>
                  <div class="font-size-13 text-charcoal-grey">
                      {{ 'VOLUNTEER.SELECT.NOT_AVAILABLE_VOLUNTEER' | translate }}
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
<div id="volunteerCalendar" (scroll)="onScroll()" class="bg-primary-lightGrey row no-gutters planning-container mt-3 w-100">
    <div class="calendar" *ngFor="let plan of planning">
          <app-volunteer-calendar class="mb-5" 
          [viewDate]="plan.month" 
          [events]="plan.events"
          [selectedDays]= "selectedDays"
          (eSelectDay)="selectDay($event)"
          [displayVacationCount]="scheduleSettings.displayVacationCount"
          [displayServiceName]="scheduleSettings.displayServiceName">
          </app-volunteer-calendar>
        </div>
    </div>

<div class="volunteer-nav d-flex justify-content-center align-items-center px-5">
  <div class="volunteer-nav__container d-flex justify-content-between">
      <button
          class="btn btn-black ubuntu-bold font-size-16" [disabled]="!selectedDays.length" (click)="goToSecondStep()">
          {{'COMMON.BUTTON.CONTINUE' | translate}}
      </button>
  </div>
</div>