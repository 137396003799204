import {BaseModel} from '@shared/models/BaseModel';

export class NewsDetailsModel extends BaseModel {
  /**
   * Id
   *
   */
  id: number;
  /**
   * Author
   */
  author: string;

  /**
   * Content
   */
  content: string;
  /**
   * emissionDate
   * ex : 2020-03-20T11:24:03.013Z
   */
  emissionDate: string;
  /**
   * expirationDate
   * ex : 2020-03-20T11:24:03.013Z
   */
  expirationDate: string;
  /**
   * lastModified
   * ex : 2020-03-20T11:24:03.013Z
   */
  lastModified;
  /**
   * Title
   */
  title: string;
  /**
   * subTitle
   */
  subTitle: string;
  /**
   * Image path
   */
  imgUrl: string;
  /**
   * Video url
   */
  videoUrl: string;

  constructor(args: {
    id: number,
    author: string,
    emissionDate: string,
    expirationDate: string,
    lastModified: string,
    title: string,
    subTitle: string,
    imgUrl: string,
    videoUrl: string,
    content: string

  }) {
    super(args);
  }
}
