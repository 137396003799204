import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-mobi-print-errors',
  templateUrl: './mobi-print-errors.component.html',
  styleUrls: ['./mobi-print-errors.component.scss'],
})
export class MobiPrintErrorsComponent {
  @Input() control: FormControl | FormGroup;
 }
