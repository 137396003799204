import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmationModalComponent } from 'src/app/shared/components/confirmation-modal/confirmation-modal.component';
import { AuthenticationService } from 'src/app/auth.service';

@Component({
  selector: 'app-forbidden-access',
  templateUrl: './forbidden-access.component.html',
  styleUrls: ['./forbidden-access.component.scss']
})
export class ForbiddenAccessComponent implements OnInit {

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.accessDenied();
  }

  accessDenied() {
    const modalRef =  this.modalService.open(ConfirmationModalComponent, {
      centered: true,
    });
    modalRef.componentInstance.icon = 'icon-alert-red';
    modalRef.componentInstance.title = 'FORBIDDEN_ACCESS.MODAL.TITLE';
    modalRef.componentInstance.message = 'FORBIDDEN_ACCESS.MODAL.MESSAGE';
    modalRef.componentInstance.btnDeny = null;
    modalRef.result.then(() => {
      this.logout(); // *logout on "confirm"
    }, () => {
      this.logout(); // *logout on "cancel"
    });
    setTimeout(() => {
      this.logout(); // *logout on timer
    }, 10000);
  }

  logout() {
    this.authenticationService.logout();
  }

}
