<div class="counter-history ubuntu-regular">
  <div class="mobime-header">
    <div class="icon icon-24 icon-arrow-left-red pointer" (click)="getBack()"> </div>
    <h1 *ngIf="counterHistory" class="ubuntu-bold font-size-32 mb-5">{{ counterHistory.label }}</h1>
    <p class="font-size-13 text-grey">{{'COUNTERS.HISTORY.INFORMATION' | translate}}</p>
  </div>
  <div *ngIf="counterHistory" class="counter-history__list-container">
    <h2 class="ubuntu-bold font-size-16 mb-3">{{'COUNTERS.HISTORY.PERIOD' | translate}}</h2>
    <ul *ngIf="!errorGetResults && counterHistory.mouvements?.length > 0" class="counter-history__list list-unstyled">
      <li *ngFor="let counter of counterHistory.mouvements" class="counter-history__list__item d-flex justify-content-between align-items-center mb-3">
        <div class="ubuntu-bold font-size-16">
          {{'COUNTERS.HISTORY.ON' | translate}} {{ counter.date | dateFormat : 'long' }}
        </div>
        <div class="ubuntu-bold font-size-32">
          <span *ngIf="counterHistory.format === format.DAY">{{ counter.quantity }}{{'COUNTERS.DAY' | translate}}</span>
          <ng-container *ngIf="counterHistory.format === format.HOUR">
            <span *ngIf="counterSettings?.balanceDisplaying === balanceDisplaying.MINUTE">
              {{ counter.quantity | hoursMinutesConverter }}
            </span>
            <span *ngIf="counterSettings?.balanceDisplaying === balanceDisplaying.HUNDREDTH">
              {{ counter.decimal }} {{'COUNTERS.HOUR' | translate}}
            </span>
          </ng-container>
          <ng-container *ngIf="counterHistory.format !== format.DAY && counterHistory.format !== format.HOUR">
            {{ counter.quantity }}
          </ng-container>
        </div>
      </li>
    </ul>
  </div>
</div>
