import { DestroyRef, Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { API_URLS } from 'src/environments/api-urls';
import { Link } from '../../models/sidebar/link';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()
export class SideBarService {
  public links = new Subject<Link[]>();
  private destroyRef = inject(DestroyRef);

  constructor(private http: HttpClient) {}

  getLinks(): Observable<Link[]> {
    return this.http.get<Link[]>(API_URLS.LINK);
  }

  addlinks() {
    this.getLinks()
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(res => {
      this.links.next(res);
    });
  }
}
