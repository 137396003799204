import { CounterSettings } from './../../../shared/models/settings/counterSettings/counterSettings';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { CountersService } from '../counters.service';
import { CounterHistory } from 'src/app/shared/models/counters/history';
import { UserService } from 'src/app/shared/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BalanceDisplaying } from 'src/app/shared/enums/setting/counterSettings/balanceDisplaying.enum';
import { Format } from 'src/app/shared/enums/counters/balanceFormat.enum';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-counter-history',
  templateUrl: './counter-history.component.html',
  styleUrls: ['./counter-history.component.scss']
})
export class CounterHistoryComponent implements OnInit {

  errorGetResults: boolean;
  loadingResults = true;
  counterHistory: CounterHistory;
  balanceDisplaying = BalanceDisplaying;
  format = Format;
  counterSettings: CounterSettings;
  private destroyRef = inject(DestroyRef);

  constructor(
    private countersService: CountersService,
    private route: ActivatedRoute,
    public userService: UserService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.userService.typologySetting
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(typoSettings => {
      this.counterSettings = typoSettings.counterSettings;
    });
    this.getCounter(
      this.route.snapshot.paramMap.get('code')
    );
  }

  getCounter(code: string) {
    this.countersService.getCounterDetail(code)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (counter: CounterHistory) => {
          this.counterHistory = counter;
          this.counterHistory.mouvements = counter.mouvements.map(mouvement => ({ ...mouvement }));
          this.loadingResults = false;
        },
        error: () => {
          this.errorGetResults = true;
          this.loadingResults = false;
        }
      },
      );
  }
  getBack() {
    this.router.navigate(['/counters']);
  }

}
