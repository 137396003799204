<div class="p-5">
    <div class="form-group header d-flex justify-content-between">
        <span class="ubuntu-bold font-size-24">{{'PLANNING.CHECKOUT_MODAL.CONDITIONS' | translate}}</span>
        <div class="icon-close icon-16 pointer" (click)="activeModal.dismiss('Cross click')"></div>
    </div>
    <div class="form-group">
        <div class="header d-flex justify-content-between">
            <label class="font-size-16 ubuntu-bold">
                {{'PLANNING.CHECKOUT_MODAL.CHECKOUT_TIME' | translate}}
            </label>
        </div>
        <div class="no-gutters font-size-24 text-center p-3">
            <input class="text-center hour-minute" type="number" min="0" max="35" [value]="broadcastTime.hour"
                (change)="validateTime(hour.value, minute.value)" #hour>
            <span class="text-center ps-3 pe-3">:</span>
            <input class="text-center hour-minute p-3" type="number" min="0" max="59" [value]="broadcastTime.minute"
                (change)="validateTime(hour.value, minute.value)" #minute>
        </div>
        <p *ngIf="!isTimeValid" class="text-pomegranate font-size-16">{{'PLANNING.CHECKOUT_MODAL.CHECKOUT_MESSAGE_INVALID_SELECT' | translate}}</p>
    </div>
    <div class="form-group">
        <label class="font-size-16 ubuntu-bold">
            {{'PLANNING.CHECKOUT_MODAL.CHECKOUT_REASON' | translate}}
        </label>
        <ng-select [(ngModel)]="selectedOption" [items]="delayTypes" bindValue="code" bindLabel="label" [disabled]="!checkSelectedType()" allowClear="true"
            placeholder="{{'PLANNING.CHECKOUT_MODAL.CHECKOUT_REASON_SELECTION' | translate}}"
            class="font-size-16 custom">
        </ng-select>
    </div>

<div *ngIf="showConditions">
    <div class="form-group">
        <p class="ubuntu-regular font-size-16 text-grey" [innerHTML]="conditions | nl2br">
            {{ conditions }}
        </p>
        <br>
        <div class="d-flex flew-row">
            <input type="checkbox" [(ngModel)]="conditionValidated">
            <p class="font-size-16">{{'PLANNING.CHECKOUT_MODAL.ACCEPT_CONDITIONS' | translate}}</p>
        </div>
    </div>
</div>

<div class="footer my-2 d-flex flex-column justify-content-center">
    <button type="button" class="btn btn-black d-block m-auto ubuntu-bold font-size-18" [disabled]="!getConditionValidated()" (click)="validate()">
        {{'PLANNING.CHECKOUT_MODAL.CHECKOUT_CONFIRM' | translate}}
    </button>
</div>

</div>