<div class="container ubuntu-regular m-0 p-0 pointer font-size-16">
  <div class="mobime-header container__header">
    <h1 class="container__header-title ubuntu-bold font-size-32 mb-0"> {{'SURVEYS.LIST.TITLE' | translate}}</h1>
  </div>
  <div class="container__content p-5 h-auto">
    <div class="container__content-group-list ms-5 mb-5">
      <div class="period ubuntu-bold font-size-24 mb-3">
        {{'SURVEYS.LIST.TO_DO' | translate}}
      </div>
      <div class="documents-container me-5" *ngIf="!emptyToCompleteSurvey ; else notExistToCompleteSurvey">
        <ng-container *ngFor="let item of surveysService.list">
          <div class="list-item pointer font-size-14" (click)="navigateToDetails(item.id)"
            *ngIf="item.status === SURVEY_STATUS.OPEN || item.status === SURVEY_STATUS.PENDING">
            <ng-container *ngTemplateOutlet="survey_list_item; context: {item : item}">
            </ng-container>
          </div>
        </ng-container>
      </div>
      <ng-template #notExistToCompleteSurvey>
        <h3>{{'SURVEYS.TO.COMPLETE.EMPTY' | translate}}</h3>
      </ng-template>
    </div>
    <div class="container__content-group-list ms-5">
      <div class="period ubuntu-bold font-size-24 mb-3">
        {{'SURVEYS.LIST.FINISHED' | translate}}
      </div>
      <div class="documents-container me-5" *ngIf="!emptyCloseSurvey ; else notExistCloseSurvey">
        <ng-container *ngFor="let item of surveysService.list">
          <div class="list-item font-size-14" (click)="navigateToDetails(item.id)"
            *ngIf="item.status ===  SURVEY_STATUS.CLOSE">
            <ng-container *ngTemplateOutlet="survey_list_item; context: {item : item }">
            </ng-container>
          </div>
        </ng-container>
      </div>
      <ng-template #notExistCloseSurvey>
        <h3>{{'SURVEYS.CLOSE.EMPTY' | translate}}</h3>
      </ng-template>
    </div>
  </div>
</div>
<div [hidden]="!surveysService.isLoadingList" class=" news-list__loading text-center ubuntu-bold font-size-16">
  {{'NEWS.LIST.LOADING' | translate }}
</div>

<ng-template #survey_list_item let-item="item">
  <div class="ubuntu-bold text-light-grey">
    {{item.anonymous ? ('SURVEYS.COMMON.ANONYMOUS'| translate) : ('SURVEYS.COMMON.NOMINATIVE' | translate) }}
  </div>
  <div>
    <div class="float-right font-size-14 text-light-grey">{{ item.endDate | dateFormat: 'short'}}</div>
    <div class="ubuntu-bold">{{ item.name }}</div>
  </div>
  <span class="badge-status badge-warning" *ngIf="item.status === SURVEY_STATUS.OPEN && !item.participation">
    <i class="icon icon-8 icon-clock-white"></i>
    {{ 'SURVEYS.LIST.PENDING' | translate }}
  </span>
  <span class="badge-status badge-success" *ngIf="(item.status === SURVEY_STATUS.OPEN || item.status === SURVEY_STATUS.CLOSE ) && item.participation">
    <i class="icon icon-10 icon-checked-white"></i>
    {{ 'SURVEYS.LIST.DONE' | translate }}
  </span>
  <span class="badge-status badge-secondary" *ngIf="item.status === SURVEY_STATUS.CLOSE && !item.participation">
    <i class="icon icon-8 icon-cross-white"></i>
    {{ "SURVEYS.LIST.EXPIRED" | translate }}
  </span>
</ng-template>
