<div class="header"
    [ngClass]="{'rest' : day?.type === dayType.REST && day?.period !== periodType.EMPTY,'availability': day?.type === dayType.AVAILABILITY && day?.period !== periodType.EMPTY,'unavailability' : day?.type === dayType.UNAVAILABILITY && day?.period !== periodType.EMPTY}">
    <div class=" d-flex flex-row">
        <div>
            <div class="ubuntu-bold font-size-12">{{day?.date | date : 'd'}}</div>
            <div class="ubuntu-bold font-size-12">{{day?.date | dateFormat : 'MMM' | titlecase}}</div>
        </div>
        <div>
            <div class="icon icon-calendar icon-12 mt-1 ms-2 me-2"></div>
            <div *ngIf="day?.departure?.date && day?.period !== periodType.POSITION" class="icon icon-clock-red icon-12 mt-2 ms-2 me-2"></div>
        </div>
        <div>
            <div *ngIf="day?.date" class="ubuntu-bold font-size-12">{{day?.date | dateFormat : 'dddd' |
                titlecase}}</div>
            <div *ngIf="day?.departure?.date && day?.period !== periodType.EMPTY && day?.period !== periodType.POSITION" class="ubuntu-bold font-size-12">{{day?.departure?.date |
                hourFormat:':':'HH:mm' }} - {{day?.arrival?.date | hourFormat:':':'HH:mm' }}</div>
        </div>
    </div>
    <div class="d-flex flex-row justify-content-between">
        <ng-container *ngIf="day?.period === periodType.DETAIL">
            <div *ngIf="day?.type === dayType.WORKING" class="ubuntu-bold font-size-12">
                {{'PLANNING.PLANNING_WEEKS.TILE.HEADER.SERVICE' | translate}} {{day?.reference}}</div>
            <div *ngIf="day?.type === dayType.REST || day?.type === dayType.AVAILABILITY || day?.type === dayType.UNAVAILABILITY"
                class="ubuntu-bold font-size-12">{{day?.label | titlecase}}</div>
            <div *ngIf="day?.type === dayType.NO_PLANNING" class="ubuntu-bold font-size-12 text-pomegranate">
                {{'PLANNING.PLANNING_WEEKS.TILE.HEADER.NO_PLANNING' | translate}}
            </div>
            <div *ngIf="day?.recomposedShift" class="ubuntu-bold font-size-12 recomposed-flag">
                *{{'PLANNING.PLANNING_WEEKS.TILE.HEADER.MODIFIED' | translate}}</div>
        </ng-container>

        <ng-container *ngIf="day?.period === periodType.PREVIEW">
            <div *ngIf="day?.type === dayType.WORKING" class="ubuntu-bold font-size-12">
                {{'PLANNING.PLANNING_WEEKS.TILE.HEADER.SERVICE' | translate}} {{day?.reference}}TT</div>
            <div *ngIf="day?.type === dayType.REST || day?.type === dayType.AVAILABILITY || day?.type === dayType.UNAVAILABILITY"
                class="ubuntu-bold font-size-12">{{day?.label | titlecase}}</div>
            <div *ngIf="day?.type ===   'NO_PLANNING'" class="ubuntu-bold font-size-12 text-pomegranate">
                {{'PLANNING.PLANNING_WEEKS.TILE.HEADER.NO_PLANNING' | translate}}
            </div>
            <div *ngIf="day?.recomposedShift" class="recomposed-flag ubuntu-bold font-size-12">
                *{{'PLANNING.PLANNING_WEEKS.TILE.HEADER.MODIFIED' | translate}}</div>
        </ng-container>

        <ng-container *ngIf="day?.period === periodType.POSITION">
            <div *ngIf="day?.type === dayType.WORKING" class="ubuntu-bold font-size-12">
                {{'PLANNING.PLANNING_WEEKS.TILE.HEADER.WORKING_DAY' | translate}}</div>
            <div *ngIf="day?.type === dayType.REST" class="ubuntu-bold font-size-12">
                {{'PLANNING.PLANNING_WEEKS.TILE.HEADER.REST' | translate}}</div>
            <div *ngIf="day?.type === dayType.AVAILABILITY" class="ubuntu-bold font-size-12">
                {{'PLANNING.PLANNING_WEEKS.TILE.HEADER.AVAILABILITY' | translate}}</div>
            <div *ngIf="day?.type === dayType.UNAVAILABILITY" class="ubuntu-bold font-size-12">
                {{'PLANNING.PLANNING_WEEKS.TILE.HEADER.UNAVAILABILITY' | translate}}</div>
        </ng-container>

        <ng-container *ngIf="day?.period === periodType.EMPTY">
            <div class="ubuntu-bold font-size-12 text-pomegranate">
                {{'PLANNING.PLANNING_WEEKS.TILE.HEADER.NO_PLANNING' | translate}}
            </div>
        </ng-container>
    </div>
</div>
<div>
    <div *ngIf="day?.duration > 0" class="ubuntu-bold font-size-12 duration">
        {{'PLANNING.PLANNING_WEEKS.TILE.INFOS.DURATION' | translate}} {{day?.duration | hoursMinutesConverter}}</div>
</div>
<ng-container *ngIf="day?.period === periodType.DETAIL || day?.period === periodType.PREVIEW">
    <div class="container part">
        <div class="timeline">
            <ng-container *ngFor="let part of day?.parts">
                <div *ngIf="part?.details"
                    class="d-flex flex-column justify-content-between timeline-item bg-white rounded ms-3 p-4 shadow">
                    <div class="d-flex flex-row justify-content-between pb-2">
                        <span *ngIf="part?.reference !== '-1'"
                            class="ubuntu-bold font-size-12" 
                            [ngClass]="{
                                'text-pomegranate': part?.isModified ==='O',
                                'text-warning': part?.isModified ==='N' && part?.reference !== day?.reference
                              }">{{part?.reference}}</span> <span class="text-pomegranate ubuntu-bold font-size-12" *ngIf="part.isModified ==='O'">*</span>
                        <div class="d-flex flex-row ms-auto vehicle">
                            <div *ngIf="part?.details?.vehicleCodes && part?.details?.vehicleCodes?.length > 0 " [ngClass]="{'vehicles': part?.details?.vehicleCodes && part?.details?.vehicleCodes?.length > 1}" class="icon icon-12 icon-vehicle-grey ms-3 mt-1">
                            </div>
                            <span [ngClass]="{'text-pomegranate': (part?.details?.vehicleCodes | join :' / ')?.includes('/')}" class="ubuntu-bold font-size-12">{{part?.details?.vehicleCodes | join :' / '}}</span>
                        </div>
                    </div>
                    <div>
                        <span class="ubuntu-bold font-size-12">{{part?.details?.departure?.date |
                            hourFormat:':':'HH:mm'}}
                            {{part?.details?.departure?.locationCode }}</span><br>
                        <span class="ubuntu-bold font-size-12">{{part?.details?.arrival?.date |
                            hourFormat:':':'HH:mm'}}
                            {{part?.details?.arrival?.locationCode}}</span>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="d-flex flex-row justify-content-between" *ngIf="day?.type !== 'WORKING' && day?.parts[0]?.details">
        <div class="icon icon-alert icon-12 ms-4 mt-2"></div>
        <div class="ubuntu-bold font-size-12 unavailability-message">
            {{'PLANNING.PLANNING_WEEKS.TILE.INFOS.PARTIAL_UNAVAILABILITY_MESSAGE' | translate}}
        </div>
    </div>
</ng-container>
<div *ngIf="day?.type === dayType.REST && day?.period !== periodType.POSITION && day?.parts[0]?.details === null && day?.period !== periodType.EMPTY" class="ubuntu-bold font-size-12 rest-message ">
    <div class="m-auto text-center">
        {{'PLANNING.PLANNING_WEEKS.TILE.INFOS.REST_MESSAGE' | translate}}
    </div>
</div>
<div *ngIf="day?.type === dayType.NO_PLANNING || day?.period === periodType.EMPTY" class="ubuntu-bold font-size-12 noplanning-message text-pomegranate ">
    <div class="m-auto text-center">
        {{'PLANNING.PLANNING_WEEKS.TILE.INFOS.NO_PLANNING_MESSAGE' | translate}}
    </div>
</div>
<div *ngIf="day?.period === periodType.POSITION" class="ubuntu-bold font-size-12 position-message">
    <div class="m-auto text-center">
        {{'PLANNING.PLANNING_WEEKS.TILE.INFOS.POSITION_MESSAGE' | translate}}
    </div>
    <div *ngIf="day?.type === dayType.WORKING" class="icon icon-vehicle-grey icon-80 m-auto mt-2"></div>
</div>