export interface ProviderSettingModel {
  code: string;
  key: string;
  value: string;
}

export enum SmsType {
  WHISPIR = 'WHISPIR',
  TWILIO = 'TWILIO',
  SMSBOX = 'SMSBOX'
}

export enum ProviderParameters {
  API_KEY = 'API_KEY',
  FROM_PHONE_NUMBER = 'FROM_PHONE_NUMBER',
  TO_PHONE_NUMBER = 'TO_PHONE_NUMBER',
  ACCOUNT_SID = 'ACCOUNT_SID',
  AUTH_TOKEN = 'AUTH_TOKEN',
  API_URL = 'API_URL',
  WORKSPACE_ID = 'WORKSPACE_ID',
  USER_NAME = 'USER_NAME',
  USER_PASSWORD = 'USER_PASSWORD'
}

export interface SmsMessage {
  type: SmsType;
  message: string;
  toPhoneNumber: string;
  siteName: string;
  siteKey: string;
  adminNumber: string;
  providerSettings: ProviderSettings;
}


export interface SmsBoxProviderSettings {
  API_URL: string;
  API_KEY: string;
}

export interface TwilioProviderSettings {
  FROM_PHONE_NUMBER: string;
  ACCOUNT_SID: string;
  AUTH_TOKEN: string;
}

export interface WhispirProviderSettings {
  API_URL: string;
  API_KEY: string;
  WORKSPACE_ID: string;
  USER_NAME: string;
  USER_PASSWORD: string;
}

export type ProviderSettings = SmsBoxProviderSettings | TwilioProviderSettings | WhispirProviderSettings;
