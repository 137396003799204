import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URLS } from 'src/environments/api-urls';
import { Observable } from 'rxjs';
import { Prepay } from '../../shared/interfaces/prepay';
import { PrepayDescription } from 'src/app/shared/models/prepay/prepay-description-counters';

@Injectable({
  providedIn: 'root'
})
export class PrepayService {
  constructor(private http: HttpClient) { }

  getPrepays(): Observable<Prepay[]> {
    return this.http.get<Prepay[]>(API_URLS.PREPAY);
  }

  getPrepayCounters(start: string, end: string): Observable<any> {
    return this.http.get<any>(`${API_URLS.PREPAY_COUNTERS}?from=${start}&to=${end}`);
  }

  getPrepayBalances(start: string, end: string): Observable<any> {
    return this.http.get<any>(`${API_URLS.PREPAY_BALANCES}?from=${start}&to=${end}`);
  }

  getCountersDescription(): Observable<PrepayDescription[]> {
    return this.http.get<PrepayDescription[]>(API_URLS.PREPAY_DESCRIPTION_COUNTERS);
  }

  getMouvementModel(start: string, end: string): Observable<any> {
    return this.http.get<any>(`${API_URLS.PREPAY_MOVEMENT_MODEL}?from=${start}&to=${end}`);
  }

}

