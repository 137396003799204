import { CustomDateFormatter } from '../../../shared/utils/custom-date-formatter.provider';
import { ServiceSwapStatusEnum } from '../enums/serviceSwapStatus.enum';
import { Planning } from '../../../shared/models/planning/planning';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CalendarDateFormatter, CalendarEvent } from 'angular-calendar';
import { UserService } from 'src/app/shared/services/user.service';
import { PeriodType } from 'src/app/shared/enums/home/periodType.enum';
import { DayType } from 'src/app/shared/enums/home/dayType.enum';

@Component({
  selector: 'app-swap-calendar',
  templateUrl: './swap-calendar.component.html',
  styleUrls: ['./swap-calendar.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }
  ]
})
export class SwapCalendarComponent {

  eventStatus = ServiceSwapStatusEnum;
  locale: string;
  @Input() viewDate: Date;
  @Input() events: CalendarEvent[];
  @Input() daySelected: Planning;
  @Output() eSelectDay = new EventEmitter<Planning>();
  @Input() displayVacationCount: boolean;
  @Input() displayServiceName: boolean;
  periodType = PeriodType;
  dayType = DayType;
  constructor(
    public userService: UserService
  ) {
    this.locale = this.userService.site.language.code.slice(0, 2);
  }

  selectDay(day: Planning) {
    if (day.events[0].meta && day.events[0].meta.serviceSwapStatus === ServiceSwapStatusEnum.OPEN) {
      this.eSelectDay.emit(day);
    }
  }
}
