import { ValidatorFn, AbstractControl } from '@angular/forms';
import { CountryCode, isValidNumber, NationalNumber } from 'libphonenumber-js';

export function phoneNumberValidator(country: CountryCode): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const isValid = isValidNumber(`${control.value}` as NationalNumber, country) ?
                    isValidNumber(`${control.value}` as NationalNumber, country) : null;
    return !isValid && control.value ? { invalidPhoneNumber: {value: control.value} } : null;
  };
}

