import { NotificationModalComponent } from './../../../../shared/components/notification-modal/notification-modal.component';
import { PartialAbsenceRequestService } from '../partial-absence-request.service';
import { Component, OnInit, Input, DestroyRef, inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { AbsenceService } from '../../absence.service';
import { PlanningDetails } from 'src/app/shared/models/planning/planningDetails';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PeriodType } from 'src/app/shared/enums/home/periodType.enum';
import { ShiftType } from 'src/app/shared/enums/home/shiftType.enum';
import { DayType } from 'src/app/shared/enums/home/dayType.enum';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs';

@Component({
  selector: 'app-partial-absence-request-counters',
  templateUrl: './partial-absence-request-counters.component.html',
  styleUrls: ['./partial-absence-request-counters.component.scss']
})
export class PartialAbsenceRequestCountersComponent implements OnInit {
  @Input() date: moment.Moment;
  planningDetails: PlanningDetails;
  counterRequestForm: UntypedFormGroup;
  counters: any;
  periodType = PeriodType;
  shiftTypeEnum = ShiftType;
  dayTypeEnum = DayType;
  private destroyRef = inject(DestroyRef);

  constructor(
    public absenceService: AbsenceService,
    public partialAbsenceRequestService: PartialAbsenceRequestService,
    private formBuilder: UntypedFormBuilder,
    public modalService: NgbModal
  ) { }

  ngOnInit() {
    if (this.date) {
      this.getDateDetails(this.date.format('YYYY-MM-DD'));
    }
    this.createCountersRequestForm();
    this.initFormWithData();
  }

  getDateDetails(date: string) {
    this.absenceService.getPlanningDetails(date)
    .pipe(
      tap((planningDetails) => this.planningDetails = planningDetails),
      takeUntilDestroyed(this.destroyRef))
    .subscribe();
  }

  getCounterBalance() {
    this.absenceService.getCountersBalance(
      this.partialAbsenceRequestService.absenceData.startDate.format('YYYY-MM-DD'), 'PARTIAL',
      this.partialAbsenceRequestService.absenceData.groupingId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(counters => {
        this.counters = counters;
      });
  }

  createCountersRequestForm() {
    this.getCounterBalance();
    this.counterRequestForm = this.formBuilder.group({
      startTime: ['', [Validators.required, Validators.required]],
      endTime: ['', [Validators.required, Validators.required]],
      counter: [null, [Validators.required]],
      comment: [null]
    });
  }

  checkEndTimeValidators(): boolean {
    if (this.counterRequestForm.controls.startTime.value.hour > this.counterRequestForm.controls.endTime.value.hour ||
      (this.counterRequestForm.controls.startTime.value.hour === this.counterRequestForm.controls.endTime.value.hour &&
        this.counterRequestForm.controls.startTime.value.minute >= this.counterRequestForm.controls.endTime.value.minute)
    ) {
      return false;
    }
    return true;
  }

  setAbsenceData() {
    this.partialAbsenceRequestService.absenceData.counterCode = this.counterRequestForm.controls.counter.value;
    this.partialAbsenceRequestService.absenceData.counterLabel =
      this.counters.find((counter: any) => counter.code === this.partialAbsenceRequestService.absenceData.counterCode).label;
    this.partialAbsenceRequestService.absenceData.startTime = this.counterRequestForm.controls.startTime.value;
    this.partialAbsenceRequestService.absenceData.endTime = this.counterRequestForm.controls.endTime.value;
    this.partialAbsenceRequestService.absenceData.comment = this.counterRequestForm.controls.comment.value;
  }

  initFormWithData() {
    this.counterRequestForm.controls.startTime.setValue(this.partialAbsenceRequestService.absenceData.startTime);
    this.counterRequestForm.controls.endTime.setValue(this.partialAbsenceRequestService.absenceData.endTime);
    this.counterRequestForm.controls.counter.setValue(this.partialAbsenceRequestService.absenceData.counterCode);
    this.counterRequestForm.controls.comment.setValue(this.partialAbsenceRequestService.absenceData.comment);
  }

  goToPrevStep() {
    this.partialAbsenceRequestService.goToPrevStep();
  }

  goToNextStep() {
    if (this.counterRequestForm.valid) {
      if (this.checkEndTimeValidators()) {
        this.setAbsenceData();
        this.absenceService.verifyAbsence(this.partialAbsenceRequestService.absenceData, 'PARTIAL')
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(verifyData => {
          if (verifyData.hasAccess) {
            this.partialAbsenceRequestService.goToNextStep();
          } else {
            this.openErrorModal();
          }
        });
      }
    }
  }

  openErrorModal() {
    const modalRef = this.modalService.open(NotificationModalComponent, {
      centered: true
    });
    modalRef.componentInstance.title = 'ABSENCE_REQUEST.MODAL.TITLE';
    modalRef.componentInstance.message = 'ABSENCE_REQUEST.MODAL.MESSAGE';
    modalRef.componentInstance.icon = 'icon-alert-red';
  }
}
