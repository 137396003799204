import { Component, Input } from '@angular/core';
import { SwapDetailsEmployee } from '../models/swap-details';

@Component({
  selector: 'app-swap-summary',
  templateUrl: './swap-summary.component.html',
  styleUrls: ['./swap-summary.component.scss']
})
export class SwapSummaryComponent {

  @Input() swapRest: boolean;
  @Input() userShift: SwapDetailsEmployee;
  @Input() userRest: any;
  @Input() colleagueShift: SwapDetailsEmployee;
  @Input() comment: string;
  @Input() askedDate: string;
  @Input() exchangedDate: string;

}
