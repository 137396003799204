import { SnackbarService } from './../../../shared/components/snackbar/snackbar.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwapService } from './../swap.service';
import { SwapRequestHistory } from '../models/swapHistory';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { SwapStatusEnum, StateEnum } from '../enums/swapHistoryState.enum';
import { SwapTypeEnum } from '../enums/requestType.enum';
import { NotificationModalComponent } from 'src/app/shared/components/notification-modal/notification-modal.component';
import { UserService } from 'src/app/shared/services/user.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-history',
  templateUrl: './swap-history.component.html',
  styleUrls: ['./swap-history.component.scss']
})
export class SwapHistoryComponent implements OnInit {

  swapRequestHistory: SwapRequestHistory[];
  stateEnum = StateEnum;
  swapStatusEnum = SwapStatusEnum;
  swapTypeEnum = SwapTypeEnum;
  public userService = inject(UserService);
  private swapService = inject(SwapService);
  private snackbarService = inject(SnackbarService);
  private modalService = inject(NgbModal);
  private destroyRef = inject(DestroyRef);


  ngOnInit() {
    this.getSwapHistory();
  }

  getSwapHistory() {
    this.swapService.getSwapRequests()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: histories => {
          this.swapRequestHistory = histories.map(history => new SwapRequestHistory({ ...history }));
          if (!histories || !histories.length) {
            const modalRef = this.modalService.open(NotificationModalComponent, {
              centered: true,
            });
            modalRef.componentInstance.icon = 'icon-alert-red';
            modalRef.componentInstance.title = 'SWAP_REQUEST.HISTORY.NO_RESULT_FOUND';
            modalRef.componentInstance.message = 'SWAP_REQUEST.HISTORY.NO_RESULT_FOUND';
          }
        },
        error: () => {
          this.snackbarService.show('ABSENCE_REQUEST.HISTORY.ERROR', 'error');
        }
      }
      );
  }
}
