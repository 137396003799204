<table class="table font-size-12 background">
    <thead class="thead-dark thead-primary-black">
        <tr>
            <th class="sticky-top text-center border-0 p-0" [ngClass]="{'display-label': counterDisplay !== counterDisplayEnum.CODE}">
              <div [ngClass]="{'date-label': counterDisplay !== counterDisplayEnum.CODE}"> {{ int18Prefix + 'DATE' | translate}}</div>
            </th>
            <th class="sticky-top text-center border-0 w-max-10" *ngIf="prepaySettings.hoursOfVacations">
              {{ int18Prefix + 'VACATION' | translate}}
            </th>
            <th class="sticky-top text-center border-0 w-max-10" *ngIf="prepaySettings.lunchBreak">
                {{ int18Prefix + 'LUNCH.BREAK' | translate}}
            </th>
            <th class="sticky-top border-0" *ngFor="let header of headers" [ngClass]="{'text-center': counterDisplay === counterDisplayEnum.CODE}">
              <div *ngIf="counterDisplay === counterDisplayEnum.SHORT_LABEL" class="rotated">
                <div>{{header.codePresentation}}</div>
                <div> {{header.unit}}</div>
              </div>
              <div *ngIf="counterDisplay === counterDisplayEnum.CODE">
                <div>{{header.code}}</div>
                <div> {{header.unit}}</div>
              </div>
              <div *ngIf="counterDisplay === counterDisplayEnum.LABEL" class="rotated">
                <div>{{header.label}}</div>
                <div> {{header.unit}}</div>
              </div>
            </th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngFor="let row of tableRows ; let indexTableRow = index" class="mb-2">
            <tr>
                 <td class="sticky">
                  <div class="font-size-16 ubuntu-bold mb-2 mr-left">
                    {{ row.date | dateFormat: 'long' }}
                  </div>
                  <div class="font-size-13 text-grey mb-3" *ngIf="row.shiftDto.start && row.shiftDto.end" >
                    {{ row.shiftDto.start | dateFormat : 'HH:mm' }} >
                    {{ row.shiftDto.end | dateFormat : 'HH:mm' }}
                  </div>
                  <div id="displayTheoOrPlanned" *ngIf="prepaySettings.positionStage !== positionStage.NONE" class="d-flex align-items-center">
                    <div class="font-size-13 text-grey mb-3 mr-2 code-position">
                        {{row.shiftDto.positionType}} [{{row.shiftDto.codePositionTheoOrPlanned}}]
                      </div>
                      <div class="en-attente text-white ubuntu-bold d-flex align-items-center px-2 ">
                        <div class="icon icon-8 icon-clock-white me-2"></div>
                        <span>{{ int18Prefix + row.shiftDto.positionStage | translate}}</span>
                      </div>
                  </div>
                  <div class="d-flex align-items-center rectangle">
                      <div class="font-size-13 text-grey mb-3 me-2 code-position">
                        {{row.shiftDto.positionTheoreticalOrPlannedType}} [{{row.shiftDto.codePosition}}]
                      </div>
                      <div class="realized text-white ubuntu-bold d-flex align-items-center px-2 ">
                        <div class="icon icon-8 icon-checked-white me-2"></div>
                        <span class="ubuntu-bold font-size-12">{{ int18Prefix +'REALIZED' | translate}}</span>
                      </div>
                  </div>
                </td>
                <td class="text-center" *ngIf="prepaySettings.hoursOfVacations">
                  <ng-container *ngFor="let vacation of row.shiftDto.parts">
                    <p *ngIf="vacation.start && vacation.end" class="font-size-13 text-grey">
                      {{ vacation.start | dateFormat : 'HH:mm' }}&nbsp;&gt;&nbsp;{{ vacation.end | dateFormat : 'HH:mm' }}
                    </p>
                  </ng-container>
                </td>
                <td class="text-center" *ngIf="prepaySettings.lunchBreak">
                  <ng-container *ngFor="let lunchBreak of row.lunchBreaks">
                    <p *ngIf="lunchBreak.departure.date && lunchBreak.arrival.date" class="font-size-13 text-grey">
                        {{ lunchBreak.departure.date | dateFormat : 'HH:mm' }}&nbsp;&gt;&nbsp;{{ lunchBreak.arrival.date | dateFormat : 'HH:mm' }}
                    </p>
                  </ng-container>
                </td>
                
                <td class="text-center" *ngFor="let header of headers ; let indexHeader = index">
                    <span *ngIf="header.unit === 'H'">{{ lines[indexTableRow] [indexHeader ] | hourHundredthsConverter }}</span>
                    <span *ngIf="header.unit !== 'H'">{{ lines[indexTableRow] [indexHeader ] }} </span>
                </td>
            </tr>
            <tr class="thead-dark thead-primary-black"
              *ngIf="row.counterMovmentDtos.length !== 0 &&  row.counterMovmentDtos[0].subTotal  && row.counterMovmentDtos[0].subTotal.quantity !== null">
                <th class="sticky">
                    <div class="font-size-16 ubuntu-bold"> {{int18Prefix +'SUB.TOTAL' | translate}}
                        <span *ngIf="row.prepaySubtotalPeriodRefLabel === subtotalTypeEnum.WEEK" class="text-lowercase">({{int18Prefix +'SUB.WEEK' | translate}})</span>
                        <span *ngIf="row.prepaySubtotalPeriodRefLabel === subtotalTypeEnum.FORTNIGHT" class="text-lowercase">({{ int18Prefix +'SUB.FORTNIGHT' | translate}})</span>
                    </div>
                </th>
                <th class="text-center" *ngIf="prepaySettings.hoursOfVacations">

                </th>
                <th class="text-center" *ngIf="prepaySettings.lunchBreak">

                </th>
                <th class="text-center" *ngFor="let header of headers ; let indexHeader = index">
                    <ng-container *ngIf="lineSubTotal[indexTableRow] [indexHeader ]">
                        <span *ngIf="header.unit === 'H'">
                            {{ lineSubTotal[indexTableRow] [indexHeader ] | hourHundredthsConverter }}</span>
                        <span *ngIf="header.unit !== 'H'">
                            {{ lineSubTotal[indexTableRow] [indexHeader ] }}</span>
                    </ng-container>
                </th>
            </tr>
        </ng-container>
        <tr class="thead-dark thead-primary-black" *ngIf="lineTotals.length !== 0 && now > periodEnd">
            <th class="sticky">
                <div class="  font-size-16 ubuntu-bold"> {{ int18Prefix +'TOTAL' | translate}}</div>
            </th>
            <th class="text-center" *ngIf="prepaySettings.hoursOfVacations">
            </th>
            <th class=" text-center" *ngIf="prepaySettings.lunchBreak">
            </th>
            <th class=" text-center" *ngFor="let total of lineTotals ">
                <span *ngIf="total.unit === 'H'">
                    {{ total.balance | hourHundredthsConverter }}</span>
                <span *ngIf="total.unit !== 'H'">
                    {{ total.balance }}</span>
            </th>
        </tr>
    </tbody>
</table>
