import { Injectable } from '@angular/core';
import { UserService } from '../user.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TypologyDeviceSettings } from '../../models/settings/settings';
import { Router } from '@angular/router';

@Injectable()
export class TrainingsAccessService {

    constructor(
        private userService: UserService,
        private router: Router,
    ) { }

    canActivate(): Observable<boolean> {
        return this.userService.typologyDeviceSettings.pipe(
            map(
                (typologyDeviceSettings: TypologyDeviceSettings) => {
                    if (!typologyDeviceSettings.typologyDeviceSettingsData.training) {
                        this.router.navigate(['/home']);
                        return false;
                    }
                    return true;
                }
            )
        );
    }

}
