import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ForbiddenAccessComponent } from './forbidden-access.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forRoot(),
    SharedModule,
    RouterModule
  ],
  declarations: [ForbiddenAccessComponent]
})
export class ForbiddenAccessModule { }
