import { CalendarComponent } from './plannig-year/calendar/calendar.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlanningComponent } from './planning.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { PlanningService } from './planning.service';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarModule } from 'angular-calendar';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule} from '@angular/forms';
import { PlanningWeeksComponent } from './planning-weeks/planning-weeks.component';
import { DayWeekCardComponent } from './planning-weeks/day-week-card/day-week-card.component';
import { PlannigYearComponent } from './plannig-year/plannig-year.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    CalendarModule,
    NgSelectModule,
    FormsModule
  ],
  declarations: [
    PlanningComponent,
    CalendarComponent,
    PlanningWeeksComponent,
    DayWeekCardComponent,
    PlannigYearComponent,
  ],
  providers: [PlanningService]
})
export class PlanningModule { }
