<div class="p-5">
    <div class="header d-flex justify-content-between">
      <span class="ubuntu-bold font-size-24">{{'PLANNING.CHECKIN_MODAL.CONDITIONS' | translate}}</span>
      <div class="icon-close icon-16 pointer" (click)="activeModal.dismiss('Cross click')"></div>
    </div>
    <div class="body my-5">
      <p class="ubuntu-regular font-size-16 text-grey mt-3" [innerHTML]="conditions | nl2br">
        {{ conditions }}
      </p>
      <br>
      <div class="d-flex flew-row">
      <input type="checkbox" [(ngModel)]="conditionValidated">
      <p class="font-size-16">{{'PLANNING.CHECKIN_MODAL.ACCEPT_CONDITIONS' | translate}}</p>
    </div>
    </div>
    <div class="footer my-2 d-flex flex-column justify-content-center">
      <button type="button" class="btn btn-black d-block m-auto ubuntu-bold font-size-18" [disabled]="!conditionValidated" (click)="validate()">
          {{'PLANNING.CHECKIN_MODAL.ACCEPT' | translate}}
      </button>
    </div>
  </div>