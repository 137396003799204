import {Injectable} from '@angular/core';
import {API_URLS} from '../../../environments/api-urls';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SurveysWsService {
  constructor(private httpClient: HttpClient) {
  }

  /**
   *
   */
  list() {
    return this.get('employees/surveys');
  }

  /**
   *  Get number surveys to answer
   */
  waiting() {
    return this.get(`employees/surveys/waiting/count`);
  }

  /**
   * Details service
   */
  read(id) {
    return this.get(`employees/surveys/${id}`);
  }

  /**
   *  Post reply
   */
  answer(data: any) {
    return this.put(`employees/surveys/answers`, data);
  }

  /**
   * Invoke BE WS.
   * This function will be invoked internally.
   */
  private _sendRequest(method, path, options): Observable<any> {
    path = API_URLS.BASE_API + '/' + path;
    return this.httpClient.request(method, path, options);
  }

  /**
   */
  get(path: string, options: any = {}): Observable<any> {
    return this._sendRequest('GET', path, options);
  }

  /**
   * Put request
   */
  put(path, body: any = {}, options = {}): Observable<any> {
    const httpHeadersObject = Object.assign({}, {body}, options);
    return this._sendRequest('PUT', path, httpHeadersObject);
  }
}
