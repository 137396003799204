import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrainingsComponent } from './trainings.component';
import { TrainingsFoldersListComponent } from './trainingsList/list.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { LinkyModule } from 'ngx-linky';
import { RecentTrainingsListComponent } from './recentList/list.component';
import { DetailsComponent } from './details/details.component';
import { TrainingListService } from './training-list.service';
import { DocumentsService } from '../documents/documents.service';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forRoot(),
    FormsModule,
    LinkyModule,
    SharedModule
  ],
  declarations: [
    DetailsComponent,
    TrainingsComponent,
    TrainingsFoldersListComponent,
    RecentTrainingsListComponent
  ],
  providers: [
    DocumentsService,
    TrainingListService
  ],
})
export class TrainingsModule { }
