<div class="m-4 p-4">
    <div class="header d-flex justify-content-between">
      <div class="icon-alert-red icon-56"></div>
      <div class="icon-close icon-16 pointer" (click)="activeModal.dismiss('Cross click')"></div>
    </div>
    <div class="body my-5">
      <span class="ubuntu-bold font-size-24">{{ task.title }}</span>
      <p class="ubuntu-regular font-size-16 text-grey mt-3">
        {{ task.title }} : {{ 'POPIN.EXPIRE' | translate }} {{task.date | dateFormat : 'short' }}, {{ 'POPIN.RENEW' | translate }}
      </p>
    </div>
    <div class="footer my-2 d-flex flex-column justify-content-center">
      <button type="button" class="btn btn-light p-3" (click)="remindMeLater()">
        <span class="ubuntu-regular font-size-16 text-grey">{{'POPIN.REMIND_LATER' | translate }}</span>
      </button>
    </div>
  </div>
