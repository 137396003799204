import {Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-arrow',
  templateUrl: './icon-arrow.component.html',
  styleUrls: ['./icon-arrow.component.scss']
})
export class IconArrowComponent {
  /**
   * Fill color
   * A fill svg color format
   * Use this to change the arrow color
   */
  @Input()
  fillColor = '#fff';
  /**
   * Rotation in degree as string used to display the arrow in the following positions
   * left/right/top/down
   */
  @Input()
  rotationDegree = '0';
  /**
   * Rotation in degree as string used to display the arrow in the following positions
   * left/right/top/down
   */
  @Input()
  size = 16;
}
