<div class="swap-details-card h-100">
  <label class="w-100 h-100 mb-0 bg-white br-4">
      <div class="swap-details-card__title text-white d-flex justify-content-between align-items-center"
          [ngClass]="[showName ? 'bg-bostonBlue': 'bg-shadowGreen']">
          <div class="d-flex align-items-center ubuntu-bold font-size-16">

              <!-- Show name -->
              <ng-container *ngIf="showName else noName">
                  {{ firstName }}
                  {{ lastName }}
              </ng-container>

              <!-- Not name -->
              <ng-template #noName>
                  {{ 'SWAP_REQUEST.DETAILS.MON_PLANNING' | translate }}
              </ng-template>

          </div>
      </div>

      <div class="swap-details-card__content p-4">

          <!-- workingShift -->
          <ng-container *ngIf="shift else rest">
              <div class="font-size-16 mb-1">
                  {{ 'SWAP_REQUEST.DETAILS.SERVICE' | translate }} {{ shift.label }}
              </div>
              <div class="d-flex justify-content-between text-grey font-size-16">
                  <div class="d-flex align-items-center">
                      <div *ngIf="shift.departure?.date">
                          {{ shift.departure.date | dateFormat:"H"}}h{{ shift.departure.date | dateFormat:"mm"}}
                      </div>
                      <div class="icon icon-16 me-2 icon-arrow-right mx-1" *ngIf="shift.arrival?.date"></div>
                      <div *ngIf="shift.arrival?.date">
                          {{ shift.arrival.date | dateFormat:"H"}}h{{ shift.arrival.date | dateFormat:"mm"}}
                      </div>
                  </div>
                  <div class="font-size-13">
                      {{ 'SWAP_REQUEST.DETAILS.TTE' | translate }} : {{shift.workingTime | hoursMinutesConverter}}
                  </div>
              </div>
          </ng-container>

          <!-- restShift -->
          <ng-template #rest>
              {{ 'SWAP_REQUEST.DETAILS.REPOS' | translate }}
          </ng-template>

      </div>
  </label>
</div>
