export enum MessageType {
    ACCEPTANCE_REFUSAL_VACATION = 'ACCEPTANCE_REFUSAL_VACATION',
    ACCEPTANCE_REFUSAL_REST = 'ACCEPTANCE_REFUSAL_REST',
    ACCEPTANCE_EXCHANGE_SHIFT = 'ACCEPTANCE_EXCHANGE_SHIFT',
    CUSTOM_MESSAGE = 'CUSTOM_MESSAGE',
    UPDATED_SHIFT = 'UPDATED_SHIFT',
    PROPOSAL_EXCHANGE_VACATION = 'PROPOSAL_EXCHANGE_VACATION',
    PROPOSAL_EXCHANGE_REST = 'PROPOSAL_EXCHANGE_REST',
    PROPOSAL_EXCHANGE_SHIFT = 'PROPOSAL_EXCHANGE_SHIFT',
    VALIDATION_REFUSAL_ABSENCE = 'VALIDATION_REFUSAL_ABSENCE',
    VALIDATION_REFUSAL_EXCHANGE_VACATION = 'VALIDATION_REFUSAL_EXCHANGE_VACATION',
    VALIDATION_REFUSAL_REST = 'VALIDATION_REFUSAL_REST',
    VALIDATION_REFUSAL_SHIFT = 'VALIDATION_REFUSAL_SHIFT',
    RELAUNCH_SURVEY = 'RELAUNCH_SURVEY',
}
