import {TranslateModule} from '@ngx-translate/core';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {NewsService} from './services/news.service';
import {NewsListComponent} from './components/news-list/news-list.component';
import {NewsListItemComponent} from './components/news-list-item/news-list-item.component';
import { NewsDetailsComponent } from './components/news-details/news-details.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forRoot(),
    RouterModule
  ],
  declarations: [
    NewsListComponent,
    NewsListItemComponent,
    NewsDetailsComponent
  ],
  exports: [
    NewsListComponent
  ],
  providers: [NewsService]
})
export class NewsModule {
}
