import { Component, HostListener } from '@angular/core';
import { mobiAdminDomaine } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  /** When MobiMe called in an Iframe in MobiNext  */
  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent) {
    if (event.origin !== mobiAdminDomaine || event.data == null || event.data.key == null) {
      return;
    }
    window.localStorage.setItem(event.data.key, JSON.stringify(event.data.value));
  }
}
