<div *ngFor="let week of plannigsWeeks" class="mb-3">
    <div class="bg-red">
        <span class="ubuntu-bold font-size-16 ps-3 text-white">{{'PLANNING.PLANNING_WEEKS.HEADER.FROM' | translate}}
            {{week[0]?.date | dateFormat :'short' }} {{'PLANNING.PLANNING_WEEKS.HEADER.TO' | translate}} {{week[6]?.date
                | dateFormat :'short'}}</span>

        <a *ngIf= "week?.link && (week[6]?.period === periodType.DETAIL || week[6]?.period === periodType.PREVIEW)" class="ubuntu-bold font-size-16 ps-3 text-white ms-3 pointer"  href="{{ week?.link }}" target="_blank" download >
            {{'PLANNING.PLANNING_WEEKS.VIEW_PDF'| translate}}
        </a>
    </div>
    <div class="d-flex">
        <div class="d-flex flex-column calendar-week" *ngFor="let day of week">
            <div class="content"
                [ngClass]="{'passed' : day.passed || day.period === periodType.POSITION || day.period === periodType.PREVIEW || day?.period === periodType.EMPTY || day?.type === 'NO_PLANNING'}">
                <app-day-week-card [day]="day"></app-day-week-card>
            </div>
            <div *ngIf="day.type !== 'NO_PLANNING'" class=" middle m-auto">
                <div class="d-flex flex-row ps-3 mt-4 mb-2">
                    <div *ngIf="day?.period === periodType.DETAIL" class="text font-size-10 ubuntu-bold text-black pointer" (click)="navigateToDetails(day)">
                        {{'PLANNING.PLANNING_WEEKS.ACCESS_SERVICE_DETAIL' | translate}}
                    </div>
                    <div *ngIf="day?.period === periodType.PREVIEW"
                        class="text font-size-10 ubuntu-bold text-black pointer" (click)="navigateToDetails(day)">
                        {{'PLANNING.PLANNING_WEEKS.ACCESS_SERVICE_PREVIEW' | translate}}
                    </div>
                    <div *ngIf="day?.period=== periodType.DETAIL || day?.period === periodType.PREVIEW"
                        class="icon icon-12 icon-arrow mt-2"></div>
                </div>
                <ng-container *ngIf="day?.link">
                    <div class="d-flex flex-row flex-wrap" *ngIf="day?.period=== periodType.DETAIL || day?.period === periodType.PREVIEW" class=" font-size-10 ubuntu-bold text-black">
                        <span>{{'PLANNING.PLANNING_WEEKS.VIEW_YOUR'| translate}}</span>
                        <button type="button" class="btn btn-link btn-charter-instructions"
                          (click)="downloadCharterInstructions(day)">
                          {{'PLANNING.PLANNING_WEEKS.CHARTER_INSTRECTIONS' | translate}}
                        </button>
                    </div>
                </ng-container>
            </div>
            <div class="passed-content" *ngIf="day?.passed">
                <div class="d-flex flex-row passed-flag ms-3">
                    <div class="icon icon-10 icon-checked me-1 mt-1"></div>
                    <span class="ubuntu-bold font-size-11 text-white">{{'PLANNING.PLANNING_WEEKS.PASSED' | translate}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
