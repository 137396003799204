<form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()" class="profile__change-password p-5">
  <h3 class="ubuntu-bold font-size-24 mb-2">
    {{'ACCOUNT.CHANGE_PASSWORD.TITLE' | translate}}
  </h3>
  <p class="font-size-13 text-grey mb-5">
    {{'ACCOUNT.CHANGE_PASSWORD.TEXT' | translate}}
  </p>

  <div class="form-group mb-3">
    <label for="new-password" class="font-size-16 text-charcoal-grey">{{ 'ACCOUNT.CHANGE_PASSWORD.NEW_PASSWORD' |
      translate }}</label>
    <input class="font-size-16 w-100 bg-gallery br-4 px-3" type="password" id="new-password" name="new-password"
      formControlName="newPswd" [ngClass]="{'error': (newPswd.errors || errorNewPswd) && formSubmitted}" />
    <p *ngIf="newPswd.errors && formSubmitted" class="font-size-13 mt-2 text-pomegranate">{{
      'ACCOUNT.CHANGE_PASSWORD.ERROR_PASSWORD_RULES' | translate }}</p>
    <p class="font-size-13" [ngClass]="newPswd.errors && formSubmitted ? 'text-pomegranate': 'text-grey mt-2'">
      {{ 'ACCOUNT.CHANGE_PASSWORD.PASSWORD_RULES' | translate }}
    </p>
  </div>

  <div class="form-group mb-5">
    <label for="new-password-confirm" class="font-size-16 text-charcoal-grey">{{
      'ACCOUNT.CHANGE_PASSWORD.CONFIRM_PASSWORD' | translate }}</label>
    <input class="font-size-16 w-100 bg-gallery br-4 px-3" type="password" id="new-password-confirm"
      name="new-password-confirm" formControlName="newPswdConfirm"
      [ngClass]="{'error': newPswdConfirm.errors && formSubmitted}" />
    <p *ngIf="newPswdConfirm.errors?.mustMatch && formSubmitted" class="font-size-13 mt-2 text-pomegranate">{{
      'ACCOUNT.CHANGE_PASSWORD.ERROR_PASSWORD_CONFIRM' | translate }}</p>
  </div>

  <div class="d-flex justify-content-start">
    <button id="forced-reset-password-form-submit-btn"
      class="btn btn-black ubuntu-bold font-size-16 pointer d-flex align-items-center justify-content-center br-4"
      >
      {{'ACCOUNT.CHANGE_PASSWORD.BUTTON' | translate}}
    </button>
  </div>

</form>