<div class="ubuntu-regular">
    <div class="mobime-header header">
        <h1 class="title ubuntu-bold font-size-32 mb-0">{{ 'PREPAY.TITLE' | translate }}</h1>
        <div class="mt-4 font-size-13 text-grey">{{ 'PREPAY.SUBTITLE.CALCULUS' | translate }}</div>
        <div class="mt-2 font-size-13 text-grey" *ngIf="prepaySettings.counterDisplay !== counterDisplayEnum.LABEL">
          {{ 'PREPAY.SUBTITLE.KNOW_MORE' | translate }}
          <a [routerLink]="['/prepay/description']"
            class="text-grey decoration-underline">
            {{ 'PREPAY.SUBTITLE.COUNTER_DESC' | translate }}
          </a>
        </div>
    </div>
    <div class="prepay-container ubuntu-bold">
        <div class="row mb-5">
            <div class="col font-size-24">{{ 'PREPAY.LAST_PERIOD' | translate }}</div>
        </div>
        <ng-container *ngIf="mostRecent; else notExist">
          <ng-container *ngTemplateOutlet="prepayBloc; context:{prepay: mostRecent} "></ng-container>
        </ng-container>

        <div class="row mb-5 mt-70">
            <div class="col font-size-24">{{ 'PREPAY.HISTORY' | translate }}</div>
        </div>

        <ng-container *ngIf="prepays.length; else notExist">
          <ng-container *ngFor="let prepay of prepays;">
            <ng-container *ngTemplateOutlet="prepayBloc; context:{prepay: prepay} "></ng-container>
          </ng-container>
        </ng-container>
    </div>
</div>


<ng-template #prepayBloc let-prepay="prepay" >
  <div class="mb-3 bg-white rounded font-size-16 bloc pointer"
    (click)="navigateToPrepayDetail(prepay)"
  >
    {{ 'PREPAY.FROM' | translate }} {{ prepay.start | dateFormat: 'long' }}
    {{ 'PREPAY.TO' | translate }} {{ prepay.end | dateFormat: 'long' }}
  </div>
</ng-template>

<ng-template #notExist>
  <div class="font-size-16 ubuntu-regular">
      {{ 'COMMON.EMPTY.LIST' | translate }}
  </div>
</ng-template>
