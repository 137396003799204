import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CalendarDateFormatter, CalendarEvent } from 'angular-calendar';
import { CustomDateFormatter } from 'src/app/shared/utils/custom-date-formatter.provider';
import { AbsenceStatusEnum } from 'src/app/shared/enums/absence/absenceStatus.enum';
import { Planning } from 'src/app/shared/models/planning/planning';
import { UserService } from 'src/app/shared/services/user.service';
import { IFullSelectedDatesAbsence } from '../fullDayAbsence.interface';
import { PeriodType } from 'src/app/shared/enums/home/periodType.enum';
import { DayType } from 'src/app/shared/enums/home/dayType.enum';

@Component({
  selector: 'app-full-day-absence-calendar',
  templateUrl: './fullDay-absence-calendar.component.html',
  styleUrls: ['./fullDay-absence-calendar.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }
  ]
})
export class FullDayAbsenceCalendarComponent {

  eventStatus = AbsenceStatusEnum;
  locale: string;
  @Input() viewDate: Date;
  @Input() events: CalendarEvent[];
  @Input() selectedDays: IFullSelectedDatesAbsence;
  @Output() eSelectDay = new EventEmitter<IFullSelectedDatesAbsence>();
  @Input() displayVacationCount: boolean;
  @Input() displayServiceName: boolean;
  periodType = PeriodType;
  dayType = DayType;

  constructor(
    public userService: UserService
  ) {
    this.locale = this.userService.site.language.code.slice(0, 2);
  }

  selectDate(day: Planning) {
    if (
      !day.events[0].meta
      || ![AbsenceStatusEnum.OPEN, AbsenceStatusEnum.UNDESIRABLE].includes(day.events[0].meta.absenceStatus)) {
      return;
    }
    if (
      !this.selectedDays.startDate
      || this.selectedDays.startDate.date !== this.selectedDays.endDate.date
      || day.date < this.selectedDays.startDate.date
    ) {
      this.selectedDays.startDate = day;
      this.selectedDays.endDate = day;
    } else if (day.date >= this.selectedDays.startDate.date) {
      this.selectedDays.endDate = day;
    }
    this.eSelectDay.emit(this.selectedDays);
  }

}
