import { DestroyRef, Injectable, inject } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

const ITEMS_PER_PAGE = 'PAGINATOR.ITEMS.PER.PAGE';
const NEXT_PAGE = 'PAGINATOR.NEXT.PAGE';
const PREV_PAGE = 'PAGINATOR.PREVIOUS.PAGE';
const FIRST_PAGE = 'PAGINATOR.FIRST.PAGE';
const LAST_PAGE = 'PAGINATOR.LAST.PAGE';
const OF = 'PAGINATOR.OF';

@Injectable()
export class MatPaginatorI18nService extends MatPaginatorIntl {
  changes: Subject<void>;
  itemsPerPageLabel = 'Items per page';
  nextPageLabel = 'Next page';
  previousPageLabel = 'Previous page';
  firstPageLabel = 'First page';
  lastPageLabel = 'Last page';
  of = 'of';
  private destroyRef = inject(DestroyRef);

  constructor(private translateService: TranslateService) {
    super();

    this.translateService.onLangChange
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(() => {
      this.translate();
    });

    this.translate();
  }

  public getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.of} ${length}`;
    }

    length = Math.max(length, 0);

    const startIndex: number = page * pageSize;
    const endIndex: number = startIndex < length
      ? Math.min(startIndex + pageSize, length)
      : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${this.of} ${length}`;
  }

  public translate(): void {
    this.translateService.get([
      ITEMS_PER_PAGE,
      NEXT_PAGE,
      PREV_PAGE,
      FIRST_PAGE,
      LAST_PAGE,
      OF,
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((translation: any) => {
        this.itemsPerPageLabel = translation[ITEMS_PER_PAGE];
        this.nextPageLabel = translation[NEXT_PAGE];
        this.previousPageLabel = translation[PREV_PAGE];
        this.firstPageLabel = translation[FIRST_PAGE];
        this.lastPageLabel = translation[LAST_PAGE];
        this.of = translation[OF];

        this.changes.next();
      });
  }
}
