import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { SwapStepsService } from '../../swap-steps.service';
import { SwapService } from '../../swap.service';
import { SwapSearchShift } from 'src/app/features/swap/models/swapSearchShift';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationModalComponent } from 'src/app/shared/components/notification-modal/notification-modal.component';
import { SnackbarService } from 'src/app/shared/components/snackbar/snackbar.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-swap-shift-search',
  templateUrl: './swap-search.component.html',
  styleUrls: ['./swap-search.component.scss']
})
export class SwapShiftSearchComponent implements OnInit {

  errorTime: boolean;
  searchForm: UntypedFormGroup;
  private destroyRef = inject(DestroyRef);

  constructor(
    public swapStepService: SwapStepsService,
    public swapService: SwapService,
    private formBuilder: UntypedFormBuilder,
    private snackbarService: SnackbarService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.createSearchForm();
    this.setContinueStateStep3();
  }

  onSubmit() {
    this.resetSwapSearch();
    this.saveSwapSearchForm();
    this.getSearchShiftResults();
  }

  getSearchShiftResults() {
    let startTime = null;
    let endTime = null;
    if (this.searchForm.value.startTimeHour != null || this.searchForm.value.startTimeMinute != null) {
      startTime = Number(this.searchForm.value.startTimeHour) * 60 + Number(this.searchForm.value.startTimeMinute);
    }
    if (this.searchForm.value.endTimeHour != null || this.searchForm.value.endTimeMinute != null) {
      endTime = Number(this.searchForm.value.endTimeHour * 60) + Number(this.searchForm.value.endTimeMinute);
    }

    // control startHours < endHours
    if (startTime && endTime && startTime >= endTime) {
      this.errorTime = true;
      return;
    } else {
      this.errorTime = false;
    }
    this.swapStepService.getSearchShift(
      this.swapStepService.selectedDate,
      startTime,
      endTime,
      this.searchForm.value.searchTerm
    )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (shifts: SwapSearchShift[]) => {
          this.swapStepService.swapSearch.swapSearchShift = shifts.map((shift, index) => ({ id: index, ...shift }));
          // display notification while get empty result
          if (!shifts || !shifts.length) {
            const modalRef = this.modalService.open(NotificationModalComponent, {
              centered: true,
            });
            modalRef.componentInstance.icon = 'icon-alert-red';
            modalRef.componentInstance.title = 'SWAP_REQUEST.SEARCH.NO_RESULT_TITLE';
            modalRef.componentInstance.message = 'SWAP_REQUEST.SEARCH.TRY_AGAIN';
          }
        },
        error: () => {
          // diplay error message while get error from back
          this.snackbarService.show('COMMON.NOTIFICATION.GENERAL_ERROR', 'error');
        }
      },
      );
  }

  createSearchForm() {
    this.searchForm = this.formBuilder.group({
      startTimeHour: [0],
      startTimeMinute: [0],
      endTimeHour: [35],
      endTimeMinute: [59],
      searchTerm: [null]
    });
    this.setSearchFormValue();
  }
  setSearchFormValue() {
    const form = this.swapStepService.swapSearch;
    if (this.swapStepService.swapSearch) {
      this.searchForm.patchValue({
        startTimeHour: form.startTimeHour ? form.startTimeHour : 0,
        startTimeMinute: form.startTimeMinute ? form.startTimeMinute : '00',
        endTimeHour: form.endTimeHour ? form.endTimeHour : 35,
        endTimeMinute: form.endTimeMinute ? form.endTimeMinute : 59,
        searchTerm: form.searchTerm ? form.searchTerm : null
      });
    }
  }

  saveSwapSearchForm() {
    this.swapStepService.swapSearch = this.searchForm.value;
  }
  selectShift(index: number) {
    this.swapStepService.swapSearch.colleagueShift = this.swapStepService.swapSearch.swapSearchShift[index];
    this.swapStepService.continueEnable(true);
  }

  setContinueStateStep3() {
    if (this.swapStepService.swapSearch.colleagueShift) {
      this.swapStepService.continueEnable(true);
    } else {
      this.swapStepService.continueEnable(false);
    }
  }

  resetSwapSearch() {
    // Delete previously saved search results and selection
    this.swapStepService.swapSearch.swapSearchShift = null;
    this.swapStepService.swapSearch.colleagueShift = null;
    this.setContinueStateStep3();
  }

  formatTime(input: HTMLInputElement, max: number) {
    let value = Number(input.value);
    if (value > max) {
      value = max;
    }
    if (value < 0) {
      value = 0;
    }
    return value;
  }
  formatStartHour(input: HTMLInputElement) {
    this.searchForm.controls.startTimeHour.setValue(this.formatTime(input, 35));
  }
  formatStartMinute(input: HTMLInputElement) {
    this.searchForm.controls.startTimeMinute.setValue((0 + this.formatTime(input, 59).toString()).slice(-2));
  }
  formatEndHour(input: HTMLInputElement) {
    this.searchForm.controls.endTimeHour.setValue(this.formatTime(input, 35));
  }
  formatEndMinute(input: HTMLInputElement) {
    this.searchForm.controls.endTimeMinute.setValue((0 + this.formatTime(input, 59).toString()).slice(-2));
  }
}
