import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'hourFormat'
})
export class HourFormatPipe extends DatePipe implements PipeTransform {

  transform(value: any, timeSeparator?: string, ...args: any[]): any {
    const transformedDate = super.transform(value, ...args);

    if (transformedDate) {
      if (timeSeparator && transformedDate.includes(':')) {
        return transformedDate.replace(':', timeSeparator);
      }
    }

    return super.transform(value, ...args);
  }

}
