import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API_URLS } from 'src/environments/api-urls';
import * as moment from 'moment';
import { HomeDayCalendar } from 'src/app/shared/models/home/homedayCalendar';
import { Planning } from 'src/app/shared/models/planning/planning';
import { Shift } from 'src/app/shared/models/home/shift';
import { PlanningDetails } from 'src/app/shared/models/planning/planningDetails';
import { UserService } from 'src/app/shared/services/user.service';
import { CalendarType } from 'src/app/shared/enums/planning/calendar-type.enum';
import { InstructionLink } from 'src/app/shared/models/planning/link';

@Injectable()
export class PlanningService {
  private calendarType = new BehaviorSubject(null);
  private weekDates = new BehaviorSubject(null);
  currentCalendarType = this.calendarType.asObservable();
  currentWeekDates = this.weekDates.asObservable();
  constructor(private http: HttpClient, private userService: UserService) {}

  updateCalendarType(type: CalendarType) {
    this.calendarType.next(type);
  }
  updateAWeekDates(date: any) {
    this.weekDates.next(date);
  }

  getPlanning(startDate: moment.Moment, endDate: moment.Moment): Observable<Planning[]> {
    let params = new HttpParams();
    params = params.append('from', startDate.format('YYYY-MM-01'));
    params = params.append('to', endDate.format('YYYY-MM-DD'));
    params = params.append('withVehicleCode', 'false');
    params = params.append('deviceId', `${this.userService.defaultDevice.id}`);
    params = params.append('deviceCode', `${this.userService.defaultDevice.deviceCode}`);
    params = params.append('deviceType', `${this.userService.defaultDevice.type}`);

    return this.http
      .get<HomeDayCalendar[]>(`${API_URLS.HOME_PLANNING}`, { params })
      .pipe(
        map(days => {
          const arrayDates = this.getMonthBetweenDates(startDate, endDate);
          const planning: Planning[] = [];
          arrayDates.forEach(monthlyDate => {
            const planningInstance: Planning = {} as Planning;
            const filtredDays = days.filter(day => {
              const currentMonth = moment(new Date(day.date as any));
              return currentMonth.get('M') + 1 === monthlyDate.month && currentMonth.get('year') === monthlyDate.year;
            });
            if (filtredDays && filtredDays.length) {
              planningInstance.month = new Date(filtredDays[0].date as any);
              planningInstance.events = filtredDays.map(fd => {
                return {
                  title: '',
                  start: new Date(fd.date as any),
                  meta: {
                    shifts: Array(fd.parts.length)
                      .fill(0)
                      .map(i => i),
                    numberOfPoints: fd.numberOfPoints,
                    code: fd.code,
                    period: fd.period,
                    valid: fd.valid,
                    estimated: fd.estimated,
                    recomposedShift: fd.recomposedShift,
                    reference: fd.reference,
                    type: fd.type
                  }
                };
              });
            } else {
              planningInstance.month = new Date(monthlyDate.year, monthlyDate.month - 1, 1);
              planningInstance.events = [];
            }
            planning.push(planningInstance);
          });
          return planning;
        })
      );
  }

  getPlannigWeeks(firstDate: string, lastDate: string) {
    let params = new HttpParams();
    params = params.append('from', firstDate);
    params = params.append('to', lastDate);
    params = params.append('withVehicleCode', 'true');
    params = params.append('deviceId', `${this.userService.defaultDevice.id}`);
    params = params.append('deviceCode', `${this.userService.defaultDevice.deviceCode}`);
    params = params.append('deviceType', `${this.userService.defaultDevice.type}`);
    return this.http.get<HomeDayCalendar[]>(`${API_URLS.HOME_PLANNING}`, { params });
  }

  get firstDayOfWeek() {
    return this.userService.site.firstDayOfWeek;
  }

  getMonthBetweenDates(startDate: moment.Moment, endDate: moment.Moment): Array<any> {
    const result = [];
    while (startDate.isBefore(endDate)) {
      result.push({
        month: startDate.get('M') + 1,
        year: startDate.get('year')
      });
      startDate.add(1, 'month');
    }
    return result;
  }

  getPlanningDetails(date: string, adminNumber?: string): Observable<PlanningDetails> {
    const endPoint = adminNumber
      ? `${API_URLS.HOME_PLANNING_DETAILS}/${date}/parts/${adminNumber}`
      : `${API_URLS.HOME_PLANNING_DETAILS}/${date}/parts`;

    return this.http.get<HomeDayCalendar>(endPoint).pipe(
      map(homeDayCalendar => {
        if (!homeDayCalendar) {
          return {
            period: null,
            shifts: [],
            departure: null,
            arrival: null,
            numberOfPoints: null,
            label: null,
            date: null,
            type: null,
            reference: null,
          };
        }

        const shifts: Shift[] = homeDayCalendar.parts.map(shift => ({
          ...shift,
          label: homeDayCalendar.label ? homeDayCalendar.label.trim() : shift.label,
        }));

        const planningDetails: PlanningDetails = {
          period: homeDayCalendar.period,
          departure: homeDayCalendar.departure,
          arrival: homeDayCalendar.arrival,
          shifts: shifts,
          numberOfPoints: homeDayCalendar.numberOfPoints,
          label: homeDayCalendar.label,
          type: homeDayCalendar.type,
          reference: homeDayCalendar.reference,
        };

        return planningDetails;
      })
    );
  }

  getCharterInstructions(firstDate: string, lastDate: string) {
    let params = new HttpParams();
    params = params.append('from', firstDate);
    params = params.append('to', lastDate);
    return this.http.get<InstructionLink[]>(`${API_URLS.GET_CHARTER_INSTRUCTIONS}`, { params });
  }

  getRosterInstructions(firstDate: string, lastDate: string) {
    let params = new HttpParams();
    params = params.append('from', firstDate);
    params = params.append('to', lastDate);
    return this.http.get<InstructionLink[]>(`${API_URLS.GET_ROSTER_INSTRUCTIONS}`, { params });
  }

  getPlanningData(firstDate, lastDate) {
    return  zip(
                this.getPlannigWeeks(firstDate, lastDate),
                this.getCharterInstructions(firstDate, lastDate),
                this.getRosterInstructions(firstDate, lastDate)).
              pipe(
              map(([planning, charterInstructions, rosterInstructions]) => ({ planning, charterInstructions, rosterInstructions }))
            );
  }
}
