import { SnackbarService } from './../../../shared/components/snackbar/snackbar.service';
import { NotificationModalComponent } from './../../../shared/components/notification-modal/notification-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, OnDestroy, inject, DestroyRef } from '@angular/core';
import { SwapStepsService } from '../swap-steps.service';
import { forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/shared/services/user.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-swap-request-service',
  templateUrl: './swap-request-service.component.html',
  styleUrls: ['./swap-request-service.component.scss']
})
export class SwapRequestServiceComponent implements OnInit, OnDestroy {

  i18nPrefixCOMMON = 'COMMON.BUTTON.';
  private destroyRef = inject(DestroyRef);

  constructor(
    public swapStepService: SwapStepsService,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private snackbarService: SnackbarService,
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.swapStepService.reset();
  }

  updateComment(comment: string) {
    this.swapStepService.comment = comment;
  }

  controlThirdStep() {
    forkJoin([
      this.swapStepService.searchControl(),
      this.swapStepService.searchSimulation()
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: validations => {
          if (validations[0].counterBalanceExceeded || !validations[1]) {
            const modalRef = this.modalService.open(NotificationModalComponent, {
              centered: true,
            });
            modalRef.componentInstance.icon = 'icon-alert-red';
            if (validations[0].counterBalanceExceeded) {
              // if control or both control and simulation failed, show modal about control
              modalRef.componentInstance.title = 'SWAP_REQUEST.SERVICE.CONTROL.TITLE';
              modalRef.componentInstance.message = 'SWAP_REQUEST.SERVICE.CONTROL.MESSAGE';
            } else if (!validations[1]) {
              // if only simulation failed, show modal about simulation
              modalRef.componentInstance.title = 'SWAP_REQUEST.SERVICE.SIMULATION.TITLE';
              modalRef.componentInstance.message = 'SWAP_REQUEST.SERVICE.SIMULATION.MESSAGE';
            }
          }
          if (!validations[0].counterBalanceExceeded && validations[1]) {
            this.swapStepService.goToNextStep();
          }
        },
        error: () => {
          this.snackbarService.show('COMMON.NOTIFICATION.GENERAL_ERROR', 'error');
        }
      });
  }

  goToNextStep() {
    if (this.swapStepService.step === 2) {
      this.controlThirdStep();
    } else if ( this.swapStepService.step === 5) {
      this.swapStepService.title = this.translateService.instant('SWAP_REQUEST.SUMMARY.NEW_SERVICE_SWAP_REQUEST');
      this.swapStepService.message = this.translateService.instant(
        'SWAP_REQUEST.DETAILS.DESCRIPTION.PENDING',
        {
          firstName: this.userService.userFirstName,
          lastName: this.userService.userLastName
        }
      );
      this.swapStepService.sendSwapRequest()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: () => {
            const modalRef = this.modalService.open(NotificationModalComponent, {
              centered: true,
            });
            modalRef.componentInstance.icon = 'icon-checked-green';
            modalRef.componentInstance.title = 'SWAP_REQUEST.SUMMARY.MODAL.TITLE';
            modalRef.componentInstance.message = 'SWAP_REQUEST.SUMMARY.MODAL.TEXT';
            setTimeout(() => {
              modalRef.close();
              this.router.navigate(['/swap']);
            }, 2000);
          },
          error: () => {
            this.snackbarService.show('COMMON.NOTIFICATION.GENERAL_ERROR', 'error');
          }
        }
        );
    } else {
      this.swapStepService.goToNextStep();
    }

  }
}
