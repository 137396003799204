import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { API_URLS } from 'src/environments/api-urls';
import {map} from 'rxjs/operators';
import {MessageGroup, MessageGroupResponse} from '@shared/models/message/messageGroup';
import {UserService} from '@shared/services/user.service';
import {Message, MessageResponse} from '@shared/models/message/message';
import {MessageDateGroup} from '@shared/enums/message/messageDateGroup.enum';
import { MessageActionType } from 'src/app/shared/enums/message/messageAction.enum';


@Injectable()
export class MessagesService {

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {
    moment.locale('fr');
  }

  search(keywords: string) {
    return this.http.get<MessageResponse>(`${API_URLS.MESSAGE_SEARCH}?keywords=${keywords}`).pipe(
      map(messageResponse => {
        return this.getMessages(messageResponse);
      })
    );
  }

  private getMessages(messageResponse: MessageResponse) {
    const messageGroups: MessageGroup[] = [];
    const msgs = messageResponse.messages.map(message => {
      return {
        id: message.id,
        archived: message.archived,
        title: message.title,
        content: message.content,
        creationDate: moment(message.creationDate),
        favorite: message.favorite,
        markedRead: message.markedRead,
        sender: message.sender,
        type: message.type,
        status: message.status,
        showActions: false, // Set to false or any default value as required
        concernDate: moment(message.concernDate),
        url: message.url
      };
    });
    const today = moment().startOf('day');
    const yesterday = moment(today).subtract(1, 'day').startOf('day');
    const firstDayOfWeek = moment(today).isoWeekday(this.userService.site.firstDayOfWeek).startOf('day');
    const todayMessages = msgs.filter(message => {
      return message.creationDate.format('YYYY-MM-DD') === today.format('YYYY-MM-DD');
    });
    const yesterdayMessages = msgs.filter(message => {
      return message.creationDate.format('YYYY-MM-DD') === yesterday.format('YYYY-MM-DD');
    });
    const weekMessages = msgs.filter(message => {
      return message.creationDate < yesterday && message.creationDate >= firstDayOfWeek;
    });
    const otherMessages = msgs.filter(message => {
      return message.creationDate < yesterday && message.creationDate < firstDayOfWeek;
    });
    messageGroups.push({ group: MessageDateGroup.TODAY, label: 'MESSAGE.GROUPMESSAGE.TODAY', messages: todayMessages });
    messageGroups.push({ group: MessageDateGroup.YESTERDAY, label: 'MESSAGE.GROUPMESSAGE.YESTERDAY', messages: yesterdayMessages });
    messageGroups.push({ group: MessageDateGroup.THIS_WEEK, label: 'MESSAGE.GROUPMESSAGE.THIS_WEEK', messages: weekMessages });
    messageGroups.push({ group: MessageDateGroup.BEFORE, label: 'MESSAGE.GROUPMESSAGE.BEFORE', messages: otherMessages });

    return {
      messageGroups: messageGroups,
      hasNext: messageResponse.hasNext
    };
  }


  getMessagesGroup(page = 0): Observable<MessageGroupResponse> {
    return this.http.get<MessageResponse>(`${API_URLS.MESSAGE}?page=${page}`).pipe(
      map(messageResponse => {
        return this.getMessages(messageResponse);
      })
    );
  }

  addMessageToFavorites(message: Message): Observable<any> {
    return this.http.put(`${API_URLS.MESSAGE}/${message.id}/action`, { action: MessageActionType.FAVORITE});
  }

  markMessageAsRead(message: Message) {
    return this.http.put(`${API_URLS.MESSAGE}/${message.id}/action`, { action: MessageActionType.READ});
  }

  archiveMessage(message: Message) {
    return this.http.put(`${API_URLS.MESSAGE}/${message.id}/action`, { action: MessageActionType.ARCHIVE});
  }


}
