<div class="password-reset d-flex">
  <div class="password-reset__text-side col-6 d-flex align-items-center justify-content-center">
    <div>
      <p class="ubuntu-bold font-size-32 mb-4">
        {{ 'FORBIDDEN_ACCESS.TITLE' | translate }}
      </p>
      <p class="text-grey font-size-16">
        {{ 'FORBIDDEN_ACCESS.TEXT_PART_1' | translate }}
      </p>
      <p class="text-grey font-size-16">
        {{ 'FORBIDDEN_ACCESS.TEXT_PART_2' | translate }}
      </p>
    </div>
  </div>
  <div class="password-reset__red-side col-6 d-flex align-items-center justify-content-center">
  </div>
</div>