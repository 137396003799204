import { Injectable } from '@angular/core';
import { DataLayerCustomDimensionsService } from './dataLayerCustomDimensions.service';
import { PartialCustomDimensionsSet } from '../../types/customDimensions';
import { CustomEventsTypeEnum } from '../../enums/GTM/tagEvent.enum';

@Injectable()
export class AnalyticsService {

  constructor(
    private dataLayerCustomDimensions: DataLayerCustomDimensionsService,
  ) { }

  updateCustomDimensions(someDimensions: PartialCustomDimensionsSet) {
    this.dataLayerCustomDimensions.setDimensions(someDimensions);
  }

  dataLayerPush() {
    this.dataLayerCustomDimensions.dataLayerPush();
  }

  trigger(event: CustomEventsTypeEnum, data?: object) {
    (window as any).dataLayer.push({
      event,
      data
    });
  }

  // * exemple of trigger custom event and push data to dataLayer
  //   private router: Router
  //
  // pageView(someDimensions: PartialCustomDimensionsSet, overridePath?: string) {
  //   this.updateCustomDimensions(someDimensions);
  //   this.dataLayerPush();
  //   this.trigger(CustomEventsTypeEnum.PAGE_NAVIGTION_TRIGGER, {
  //     virtualPageURL: overridePath || this.router.url,
  //     virtualPageTitle: overridePath || this.router.url,
  //   });
  // }

}
