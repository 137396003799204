<div class="absence-recap-content bg-primary-lightGrey">

  <div class="mobime-header bg-white">
    <h1 class="ubuntu-bold font-size-32 mb-0">{{ 'ABSENCE_REQUEST.RECAP_TITLE' | translate }}</h1>
    <p class="ubuntu-bold font-size-16">{{ 'ABSENCE_REQUEST.RECAP_DESC' | translate }}</p>
    <p class="font-size-13 text-grey">{{ 'ABSENCE_REQUEST.AVAILABILITY_DESCRIPTION' | translate }}</p>
  </div>

  <div class="absence-recap">
    <p class="ubuntu-bold font-size-24 mb-3 text-white">
      {{ 'ABSENCE_REQUEST.DAY_ABSENCE' | translate }}
      <span class="font-size-16 ubuntu-regular text-black ms-2">
        {{numberOfDays}}
        <ng-container *ngIf="numberOfDays > 1"> {{ 'ABSENCE_SELECTED_DAYS' | translate}} </ng-container>
        <ng-container *ngIf="numberOfDays === 1"> {{ 'ABSENCE_SELECTED_DAY' | translate}} </ng-container>
      </span>
    </p>
    <div class="container-fluid bg-white d-flex flex-column font-size-16 py-2">
      <div class="row">

        <div class="col-6 d-flex flex-column">
          <p class="ubuntu-regular text-grey">{{'ABSENCE_REQUEST.RECAP_WISH' | translate}}</p>
          <p>
            {{ 'ABSENCE_REQUEST.RECAP_FROM' | translate | lowercase}}
            {{ absenceData.startDate | dateFormat: 'LL'}}

            {{ 'ABSENCE_REQUEST.RECAP_TO' | translate}}
            {{ absenceData.endDate | dateFormat: 'LL'}}
          </p>
        </div>

        <div class="col-6 d-flex align-items-center">
          <div class="icon icon-counter icon-40 me-4"></div>
          <div class="d-flex flex-column">
            <p class="text-grey"> {{'ABSENCE_REQUEST.RECAP_TYPE' | translate}} </p>
            <p *ngIf="absenceData.counters && absenceData.counters.length; else manualCounter">
              <ng-container *ngFor="let counter of absenceData.counters">
                {{counter.code}} ({{counter.label}})
              </ng-container>
            </p>
            <ng-template #manualCounter>
              <p>
                {{absenceData.counterCode}} ({{absenceData.counterLabel}})
              </p>
            </ng-template>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="description-padding">
    <div class="d-flex flex-column bg-white">
      <div class="my-5 px-3" *ngIf="absenceData.comment">
        <h3 class="ubuntu-bold font-size-16 pt-3">{{ 'ABSENCE_REQUEST.RECAP_COMMENT' | translate }}</h3>
          <div class="col-12 p-4 m-auto font-size-13 bg-primary-lightGrey text-pre-wrap">{{absenceData.comment}}</div>
      </div>
    </div>
  </div>

</div>

<div class="absence-nav d-flex justify-content-center align-items-center px-5">
  <div class="absence-nav__container d-flex justify-content-between">
    <button (click)="goToPreviousStep()" class="btn btn-white ubuntu-bold font-size-16">
      {{'COMMON.BUTTON.MODIFY' | translate}}
    </button>
    <button (click)="sendDataToHistory()" class="btn btn-black ubuntu-bold font-size-16">
      {{'COMMON.BUTTON.CONFIRM' | translate}}
    </button>
  </div>
</div>
