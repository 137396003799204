import { Pipe, PipeTransform } from '@angular/core';
import { units, size } from 'src/app/shared/constants/constants';

/*
 * Convert bytes into largest possible unit.
 * Takes an precision argument that defaults to 2.
 * Usage:
 *   bytes | fileSize:precision
 * Example:
 *   {{ 1024 |  fileSize}}
 *   formats to: 1 KB
*/
@Pipe({ name: 'fileSize' })
export class FileSizePipe implements PipeTransform {

    transform(bytes = 0, precision = 2): string {
        if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) { return '?'; }
        let unit = 0;
        while (bytes >= size) {
            bytes /= size;
            unit++;
        }
        return bytes.toFixed(+ precision) + ' ' + units[unit];
    }
}

