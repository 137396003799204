<div class="mobime-header">
    <div class="icon icon-24 icon-arrow-left-red m-left pointer mb-5" (click)="getBack()"> </div>
    <h1 class="title ubuntu-bold font-size-32 mb-0">{{ 'PREPAY.DESCRIPTION.TITLE' | translate }}</h1>
    <div class="mt-4 font-size-13 text-grey">{{ 'PREPAY.DESCRIPTION.TEXT' | translate }}</div>
</div>
<div class="prepay-container">
    <ng-container *ngFor="let description of descriptionCounters">
        <div class="row no-gutters bg-white rounded mb-3 bloc ">
            <div class="col-12 font-size-16 ubuntu-bold mb-3" *ngIf="prepaySettings.counterDisplay === counterDisplayEnum.CODE; else codePresentation">{{description.code}}</div>
            <ng-template #codePresentation>
                <div class="col-12 font-size-16 ubuntu-bold mb-3">{{description.codePresentation}}</div>
            </ng-template>
            <div class="col-12 font-size-13 ubuntu-regular">{{description.label}}</div>
        </div>
    </ng-container>
</div>