<div class="mobime-header">
    <h1 class="ubuntu-bold font-size-32 mb-0">{{ 'SWAP_REQUEST.TITLE' | translate }}</h1>
    <div class="ubuntu-bold font-size-24 my-2" *ngIf="stepService.selectedServiceDate">
        {{ i18nPrefix + 'DESIRED_REST_DAY' | translate }} : {{ stepService.selectedServiceDate | dateFormat: 'long' }}
    </div>
    <div class="ubuntu-bold font-size-24 my-2" *ngIf="stepService.selectedRestDate">
        {{ i18nPrefix + 'DESIRED_WORK_DAY' | translate }} : {{ stepService.selectedRestDate | dateFormat: 'long' }}
    </div>
</div>
<div class="swap">
    <div class="ubuntu-bold font-size-16">{{ i18nPrefix + 'SEARCH_CRITERIA' | translate }}</div>
    <p class="swap__rules font-size-13 text-grey">
        {{ i18nPrefix + 'RULES_TEXT' | translate }}
    </p>

    <form [formGroup]="searchForm" (ngSubmit)="onSubmit()" class="swap__search-form">
        <div class="form-group mb-5">
            <label for="searchTerm" class="font-size-16 text-charcoal-grey">
                {{ i18nPrefix + 'SEARCH_FOR_COWORKER' | translate }}
            </label>
            <input type="text" id="searchTerm" name="searchTerm" formControlName="searchTerm"
                class="font-size-16 w-100 bg-gallery br-4 px-3" />
        </div>
        <div>
            <button type="submit" class="btn btn-black ubuntu-bold font-size-16 br-4">
                {{ i18nPrefix + 'SEARCH' | translate }}
            </button>
        </div>
    </form>

    <div class="swap__search-results pb-5" *ngIf="stepService.swapRestSearch.swapSearchShift?.length > 0">
        <div class="font-size-13 mb-2">
            <span class="ubuntu-bold">{{ i18nPrefix + 'SEARCH_RESULTS' | translate }}</span>
            ({{ stepService.swapRestSearch.swapSearchShift.length }})
        </div>
        <div class="row">
            <div *ngFor="let result of stepService.swapRestSearch.swapSearchShift; let index = index"
                class="col-md-6 col-lg-4 col-xl-3">
                <div class="swap-search-card mb-4">
                    <label class="w-100 mb-0 pointer">
                        <input type="radio" name="search-results-group" [value]="index"
                            [id]="'service-' + result.shift.id" (click)="selectShift(index)" />
                        <div class="swap-search-card__title text-white d-flex justify-content-between align-items-center">
                            <div class="d-flex align-items-center ubuntu-bold font-size-16">{{ result.fullName }}</div>
                            <div class="icon icon-24"
                                [ngClass]="stepService.swapRestSearch?.colleagueShift?.id === index ? 'icon-radio-active': 'icon-radio '">
                            </div>
                        </div>
                        <div class="swap-search-card__content p-4">
                            <ng-container *ngIf="result.shift.type !== dayType.REST; else rest">
                                <div class="font-size-16 mb-1">
                                    {{ i18nPrefix + 'SERVICE' | translate }} {{ result.shift.label ? result.shift.label : result.shift.id }}
                                </div>
                                <div class="d-flex justify-content-between text-grey font-size-16">
                                    <div class="d-flex align-items-center">
                                        <div>
                                            {{ result.shift.departure.date | dateFormat:"H"}}h{{ result.shift.departure.date | dateFormat:"mm"}}
                                        </div>
                                        <div class="icon icon-16 me-2 icon-arrow-right mx-1"></div>
                                        <div>
                                            {{ result.shift.arrival.date | dateFormat:"H"}}h{{ result.shift.arrival.date | dateFormat:"mm"}}
                                        </div>
                                    </div>
                                    <div class="font-size-13" title="{{ i18nPrefix + 'WORKING_TIME' | translate }}">
                                        {{ i18nPrefix + 'WT' | translate }} : {{ result.formatedWorkingTime }}
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #rest>
                                {{'SWAP_REQUEST.SEARCH.REST' | translate}}
                            </ng-template>
                        </div>
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>