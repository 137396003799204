<div class="row d-flex justify-content-between recent-documents">
  <div *ngFor="let document of recentFiles" class="col-3 mb-4">
    <div class="document d-flex position-relative pointer" (click)="getSelectedFile(document)">
      <div class="favorite h-100 position-relative">
        <div class="icon icon-16 position-absolute pointer"></div>
      </div>
      <div class="document-type h-100 position-relative">
        <ng-container>
          <div class="position-absolute type-background bg-greenBlue">
            <div class="icon icon-file"></div>
          </div>
        </ng-container>
      </div>
      <div class="document-body overflow-hidden ms-4 mt-4">
        <div class="document-subjet align-items-center">
          <div class="document-title font-size-16 text-truncate me-2 font-weight-bold">
            {{ (document.name ? document.name : 'DOCUMENT.TYPE.'+document.type) | translate}}
          </div>
          <div class="icon icon-8 icon-clock-white me-2"></div>
          <div class="status font-size-12 text-truncate text-white">{{document.name}}</div>
        </div>
        <div class="document-text w-100 font-size-13 overflow-hidden">
          {{document.description}}
        </div>
      </div>
      <div class="setting position-absolute d-flex flex-column align-items-end">
        <div class="time font-size-10 text-grey mt-5">
          {{document.creationDate | dateFormat: 'short'}}
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #elseBlock>
  <div class="font-size-16 ubuntu-regular">
    {{ 'COMMON.EMPTY.LIST' | translate }}
  </div>
</ng-template>