<nav id="sidebar-container">
    <div class="sidebar-header d-flex" [ngClass]="{'ps-5':!isCollapsed}">
        <div class="branding me-2" [ngClass]="{'branding-center':isCollapsed}">
            <div class="icon icon-36 icon-symbole-transdev"></div>
        </div>
        <div class="icon icon-80-48 icon-logo-mobime" *ngIf="!isCollapsed"></div>
    </div>
    <div class="menu">
        <div class="sub-menu" *ngFor="let menuItem of menuSideBare; index as i" [ngClass]="{'menuItem-notCollapsed':!isCollapsed}"> <!--ng for menuItems-->
            <ng-container>
                <p class="menuGroupe font-size-13 ubuntu-bold" [ngClass]="{'ps-5':!isCollapsed}"
                    *ngIf="!isCollapsed && menuItem?.menuGroupeToShow">
                    {{menuItem.menuGroupe | translate}}
                </p>
                <ul class="ubuntu-regular" [ngClass]="{'p-0':isCollapsed}"> 
                    <ng-container *ngFor="let subMenu of menuItem.subMenu">
                      <div *ngIf="subMenu.show">
                        <li class="list-unstyled pointer"  *ngIf="!isCollapsed || (isCollapsed && subMenu.showWhenCollapsed)"
                            [ngClass]="{'subMenu-notCollapsed':!isCollapsed,'position-relative':isCollapsed}"
                            (mouseenter)="currentLink = subMenu.link"
                            (mouseleave)="currentLink = ''"
                        >  <!--ng for subMenu-->
                            <a (click)="navigate(subMenu)" [id]="subMenu.label"
                                class="d-flex  w-100 h-100"
                                [ngClass]="{
                                    'align-items-center ms-3':!isCollapsed,
                                    'link-collapsed flex-column justify-content-center':isCollapsed,
                                    'linkActive': router.url.includes(subMenu.link)
                                }"
                            >
                                <ng-container *ngIf="!isCollapsed">
                                    <div class="d-flex align-items-baseline font-size-16 text-nowrap" *ngIf="subMenu.external; else defaultLabel">                 
                                        <div class="icon icon-24 {{subMenu.icon}} me-2"
                                          [ngClass]="router.url.includes(subMenu.link) || currentLink.includes(subMenu.link) ?subMenu.icon+'-active':''">
                                        </div>
                                        <span>{{subMenu.label | translate | ellipsis: 18}}</span>
                                        <div class="icon icon-6 icon-external-link ms-3"></div>
                                    </div>
                                    <ng-template #defaultLabel>
                                      <div class="icon icon-24 {{subMenu.icon}} me-2"
                                        [ngClass]="router.url.includes(subMenu.link) ||currentLink.includes(subMenu.link) ?subMenu.icon+'-active':''">
                                      </div>
                                      <span class="font-size-16">
                                        {{subMenu.label | translate}}
                                      </span>
                                    </ng-template>
                                </ng-container>
                                <ng-container *ngIf="isCollapsed">
                                    <div *ngIf="subMenu.external; else defaultLabel">
                                        <div class="icon icon-28 {{subMenu?.icon}} me-2 icon-center"
                                          [ngClass]="router.url.includes(subMenu.link) ||currentLink.includes(subMenu.link) ? subMenu.icon+'-active':''">
                                        </div>
                                        <div class="ubuntu-bold font-size-10 text-center" *ngIf="isCollapsed">
                                            {{subMenu.labelCollapsed || subMenu.label | translate}}
                                        </div>
                                    </div>
                                    <ng-template #defaultLabel>
                                        <div class="icon icon-28 {{subMenu?.icon}} me-2 icon-center"
                                            [ngClass]="router.url.includes(subMenu.link) ||currentLink.includes(subMenu.link) ? subMenu.icon+'-active':''">
                                        </div>
                                        <div class="ubuntu-bold font-size-10 text-center" *ngIf="isCollapsed">
                                            {{subMenu.labelCollapsed || subMenu.label | translate}}
                                        </div>
                                    </ng-template>
                                </ng-container>
                                <div [ngSwitch]="subMenu.label" *ngIf="!isCollapsed || (isCollapsed && subMenu.showWhenCollapsed)">
                                    <ng-container >
                                        <ng-container *ngSwitchCase="'SIDEBAR.MY_SPACE.MY_MESSAGES'">
                                            <span *ngIf="messagesCountService.unreadMessageCount > 0" class="badge-message ubuntu-bold font-size-12" [ngClass]="{'badge-collapsed':isCollapsed}" >{{messagesCountService.unreadMessageCount}}</span>
                                        </ng-container>
                                        <!--* NO MVP -->
                                        <!--<span class="badge-message font-size-8" [ngClass]="{'badge-collapsed':isCollapsed}" *ngSwitchCase="'SIDEBAR.THE_COMMUNITY.EXCHANGE_REQUEST'">{{numberOfAbsenceRequests}}</span>
                                        <span class="badge-message font-size-8" [ngClass]="{'badge-collapsed':isCollapsed}" *ngSwitchCase="'SIDEBAR.THE_COMMUNITY.STOCK_EXCHANGE'">{{numberOfExchangeRequests}}</span>
                                        <span class="badge-message font-size-8" [ngClass]="{'badge-collapsed':isCollapsed}" *ngSwitchCase="'SIDEBAR.THE_COMMUNITY.SURVEYS'">{{numberOfSurveys}}</span> -->
                                        <!--* NO MVP -->
                                    </ng-container>
                                </div>
                            </a>
                        </li>
                      </div>
                    </ng-container>
                </ul>
            </ng-container>
        </div>
        <div  *ngIf="isCollapsed" class="plus pointer position-relative" (click)="unCollapseMenu()" (mouseenter)="hoverPlus = true" (mouseleave)="hoverPlus = false">
            <div class="plus-collapsed position-absolute">
                <div class="icon icon-28 icon-ellipsis icon-center"
                    [ngClass]="{'icon-ellipsis-active':hoverPlus}"
                    (click)="hideMenu = !hideMenu">
                </div>
                <div class="ubuntu-bold font-size-10 text-center mt-1">
                    {{'SIDEBAR.PLUS' | translate}}
                </div>
            </div>
        </div>

    </div>
</nav>
