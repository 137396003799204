import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hoursMinutesConverter'
})
export class HoursMinutesConverterPipe implements PipeTransform {

  transform(value: number): any {
    if (value) {
      const valueConverted = this.getHours(value) + 'h' + this.getMinutes(value);
      return value >= 0 ? valueConverted : '-' + valueConverted;
    }
    if (value === 0) {
      return '0h' + this.getMinutes(value);
    }
  }

  getHours(value: number) {
    const hour = Math.abs(Math.trunc(value / 60));
    return hour < 10 ? '0' + hour.toString() : hour.toString();
  }

  getMinutes(value: number) {
    const absValue = Math.abs(value);
    const hour = Math.trunc(absValue / 60);
    const minute = Math.round(absValue - hour * 60);
    return ('0' + minute).slice(-2);
  }
}
