import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API_URLS } from 'src/environments/api-urls';
import { CALENDAR_PROPS } from 'src/environments/environment';
import { HomeDayCalendar } from 'src/app/shared/models/home/homedayCalendar';
import { Shift } from 'src/app/shared/models/home/shift';
import * as moment from 'moment';
import { Task } from 'src/app/shared/models/home/task';
import { LocationAndTime } from '../../shared/models/planning/mission';
import { UserService } from 'src/app/shared/services/user.service';
import { SelectableItem } from 'src/app/shared/interfaces/selectableItem';
import { PunchInOutConditions, WorkOutsideDepartments } from 'src/app/shared/models/home/punchInOutConditions';
import { ISavedPunchInOuts } from './punchInOutConditions/punchInOut.interface';
import { CiCoVerifyResponse } from './models/cico-reponse';
import { CiCoStatusEnum } from 'src/app/shared/enums/home/check-in-out-status.enum';

@Injectable()
export class HomeService {
  constructor(private http: HttpClient, private userService: UserService) {
    moment.locale('fr');
  }

  getCalenderData(): Observable<HomeDayCalendar[]> {
    const d = new Date();
    const startDate = moment(d.setDate(d.getDate() - 1)).format('YYYY-MM-DD');
    const endDate = moment(d.setDate(d.getDate() + CALENDAR_PROPS.NBR_DAYS)).format('YYYY-MM-DD');
    let params = new HttpParams();
    params = params.append('from', startDate).append('to', endDate).append('withVehicleCode', 'true')
      .append('deviceId', `${this.userService.defaultDevice.id}`)
      .append('deviceCode', `${this.userService.defaultDevice.deviceCode}`)
      .append('deviceType', `${this.userService.defaultDevice.type}`);

    return this.http.get<Array<any>>(`${API_URLS.HOME_PLANNING}`, { params }).pipe(
      map(days => days.map(day => ({
        ...day,
        date: moment(new Date(day.date)),
        parts: day.parts?.map(shift => ({
          ...shift,
          details: shift.details ? {
            ...shift.details,
            departure: this.getLocationAndTime(shift.details.departure),
            arrival: this.getLocationAndTime(shift.details.arrival),
          } : undefined
        })) as Shift[]
      })) as HomeDayCalendar[])
    );
  }

  private getLocationAndTime(locationAndTime: LocationAndTime): LocationAndTime {
    return {
      date: locationAndTime.date,
      location: locationAndTime.location ? locationAndTime.location.trim() : '',
      dayBefore: locationAndTime.dayBefore,
      dayAfter: locationAndTime.dayAfter
    };
  }

  verifyCheckIn(shiftDate: string, partIndex: number): Observable<CiCoStatusEnum> {
    let params = new HttpParams();
    params = params.append('deviceId', `${this.userService.defaultDevice.id}`);
    params = params.append('deviceCode', `${this.userService.defaultDevice.deviceCode}`);
    params = params.append('deviceType', `${this.userService.defaultDevice.type}`);
    partIndex++;
    return this.http
      .get<CiCoVerifyResponse>(`${API_URLS.HOME_PLANNING_DETAILS}/${shiftDate}/parts/${partIndex}/check-in/verify`, { params })
      .pipe(
        map(cicoStatusType => {
          return cicoStatusType.checkInOutStatusType;
        })
      );
  }

  verifyCheckOut(shiftDate: string, partIndex: number): Observable<CiCoStatusEnum> {
    let params = new HttpParams();
    params = params.append('deviceId', `${this.userService.defaultDevice.id}`);
    params = params.append('deviceCode', `${this.userService.defaultDevice.deviceCode}`);
    params = params.append('deviceType', `${this.userService.defaultDevice.type}`);
    partIndex++;
    return this.http
      .get<CiCoVerifyResponse>(`${API_URLS.HOME_PLANNING_DETAILS}/${shiftDate}/parts/${partIndex}/check-out/verify`, { params })
      .pipe(
        map(cicoStatusType => {
          return cicoStatusType.checkInOutStatusType;
        })
      );
  }

  validateCheckIn(shiftDate: string, partIndex: number): Observable<CiCoVerifyResponse> {
    partIndex++;
    return this.http.post<CiCoVerifyResponse>(`${API_URLS.HOME_PLANNING_DETAILS}/${shiftDate}/parts/${partIndex}/check-in`, {
      deviceId: this.userService.defaultDevice.id,
      deviceType: this.userService.defaultDevice.type,
      deviceCode: this.userService.defaultDevice.deviceCode
    });
  }

  validateCheckOut(shiftDate: string, partIndex: number, declarationTime: string, declarationReason: string) {
    partIndex++;
    return this.http.post<CiCoVerifyResponse>(`${API_URLS.HOME_PLANNING_DETAILS}/${shiftDate}/parts/${partIndex}/check-out`, {
      deviceId: this.userService.defaultDevice.id,
      deviceType: this.userService.defaultDevice.type,
      deviceCode: this.userService.defaultDevice.deviceCode,
      declarationTime,
      declarationReason
    });
  }

  getTasks(): Observable<Array<Task>> {
    return this.http.get<Task[]>(`${API_URLS.TASKS}`).pipe(
      map(tasks => tasks.map(task => ({
        title: task.title,
        remainingDays: task.remainingDays,
        remainingDaysToDisplay: task.remainingDays < 0 ? `+${Math.abs(task.remainingDays)}` : task.remainingDays === 0 ? '' : `-${task.remainingDays}`,
        limitDays: task.limitDays,
        date: moment(new Date(task.date as any))
      })))
    );
  }

  getDepartmentsData(): Observable<SelectableItem[]> {
    return this.http.get<SelectableItem[]>(`${API_URLS.DEPARTMENTS}`);
  }

  getCanPunch(shiftDate: string): Observable<{ canPunch: boolean }> {
    return this.http.get<{ canPunch: boolean }>(`${API_URLS.PUNCH_IN_OUT}/${shiftDate}/punches/can-punch`);
  }

  getSavedPunchInOuts(shiftDate: string): Observable<ISavedPunchInOuts[]> {
    return this.http.get<ISavedPunchInOuts[]>(`${API_URLS.PUNCH_IN_OUT}/${shiftDate}/punches`);
  }

  validatePunchInConditions(shiftDate: string): Observable<PunchInOutConditions> {
    return this.http.post<PunchInOutConditions>(`${API_URLS.PUNCH_IN_OUT}/${shiftDate}/punches/punch-in`, {});
  }

  validatePunchOutConditions(shiftDate: string, data: WorkOutsideDepartments[]): Observable<PunchInOutConditions[]> {
    const punchOutData = this._mapPunchOutData(data);
    return this.http.post<PunchInOutConditions[]>(`${API_URLS.PUNCH_IN_OUT}/${shiftDate}/punches/punch-out`, {
      workOutsideDepartments: punchOutData
    });
  }

  _mapPunchOutData(data: WorkOutsideDepartments[]) {
    const result = [];
    data.forEach(item => {
      if (item.departmentCode) {
        result.push(item);
      }
    });
    result.map((item: any) => {
      item.startHour = item.startHour ? item.startHour.hour * 60 + item.startHour.minute : null;
      item.endHour = item.endHour ? item.endHour.hour * 60 + item.endHour.minute : null;
      return item;
    });
    return result;
  }
}
