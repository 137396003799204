<div class="new-absence-request tabs-content-container">
  <div class="new-absence-request__icon-container d-flex justify-content-center align-items-center">
    <img src="/assets/images/icons/absence/door.svg" alt="Absence icon" />
  </div>
  <h3 class="new-absence-request__subtitle font-size-24 ubuntu-bold mb-5">{{'ABSENCE_REQUEST.NEW_REQUEST.SUBTITLE' | translate}}</h3>
  <p class="text-grey font-size-13 mb-5">{{'ABSENCE_REQUEST.NEW_REQUEST.TEXT' | translate}}</p>
  <div class="d-flex justify-content-between">
    <button (click)="navigateToPartial()" *ngIf="partialAbsenceEnabled" class="btn btn-white ubuntu-bold font-size-16">{{'ABSENCE_REQUEST.PARTIAL_ABSENCE' | translate}}</button>
    <button (click)="navigateToFull()" *ngIf="fullDayAbsenceEnabled" class="btn btn-white ubuntu-bold font-size-16">{{'ABSENCE_REQUEST.DAY_ABSENCE' | translate}}</button>
  </div>
</div>