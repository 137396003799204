import {Route} from '@angular/router';
import {SurveysListComponent} from './components/surveys-list/surveys-list.component';
import {SurveyViewComponent} from './components/survey-view/survey-view.component';
import { SurveysAccessService } from 'src/app/shared/services/routes-activate/surveys-access.service';

const SurveysRoutes: Route = {
  path: 'surveys',
  canActivate: [SurveysAccessService],
  children: [
    {
      path: '',
      component: SurveysListComponent
    }, {
      path: ':id',
      component: SurveyViewComponent
    }
  ]

};
export {SurveysRoutes};
