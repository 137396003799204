import { BaseModel } from '../BaseModel';
export class NewsListItemModel extends BaseModel {
  id: number;
  imgUrl: string;
  publicationDate: string; // lastDateUpdate  ex : 2020-03-20T11:24:03.013Z
  title: string;
  url: string;
  rss: boolean; // Indicates whether it is an external link or internal article
  frontPage: boolean;

  constructor(args: {
    id: number,
    rss: boolean,
    title: string,
    publicationDate: string,
    imgUrl: string,
    frontPage: boolean
  }) {
    super(args);
  }
}
