import { UserService } from 'src/app/shared/services/user.service';
import { TypologyDeviceSettings, TypologySettings } from './../../models/settings/settings';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, inject, DestroyRef } from '@angular/core';
import { menuItems } from '../../models/menuItems';
import { Router } from '@angular/router';
import { SideBarService } from './sidebar.service';
import { MessagesCountService } from '../../services/messages-count.service';
import { combineLatest, interval, Subscription } from 'rxjs';
import { EllipsisPipe } from '../../pipes/ellipsis/ellipsis.pipe';
import { takeUntil } from 'rxjs/operators';
import { UserGuardService } from '../../services/user-guard.service';
import { CustomFunctionSettings } from '../../models/settings/customFunctionsSettings/customFunctionsSettings';
import { Link } from '../../models/sidebar/link';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input() isCollapsed: boolean;
  hideMenu = true;
  currentLink = '';
  hoverPlus = false;
  numberOfAbsenceRequests = 1;
  numberOfExchangeRequests = 10;
  numberOfSurveys = 1;
  typologyDeviceSettings: TypologyDeviceSettings;
  typologySettings: TypologySettings;
  externalLinksGroup = menuItems.find(item => item.menuGroupe === 'SIDEBAR.MY_EXPLOITATION.MY_EXPLOITATION');
  @Output() collapsedMenu = new EventEmitter();
  ellipsis = new EllipsisPipe();
  menuSideBare = [];
  customFunctionSettings: CustomFunctionSettings;
  links: Link[];
  private menuItems$: Subscription;
  private destroyRef = inject(DestroyRef);

  constructor(
    public router: Router,
    public sideBarService: SideBarService,
    public messagesCountService: MessagesCountService,
    public userService: UserService,
    private userGuard: UserGuardService
  ) {}

  ngOnInit() {
    this.getDeviceSettings();
    this.updateUnreadMessageCount();
    this.userService.typologySetting
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(typoSetting => {
      this.typologySettings = typoSetting;
    });
  }

  updateUnreadMessageCount() {
    this.messagesCountService.getUnreadMessageCount();
    // update unread message count every 30 second
    interval(30 * 1000)
      .pipe(takeUntil(this.userGuard.cancelPendingRequests$))
      .subscribe(() => {
        this.messagesCountService.getUnreadMessageCount();
    });
  }

  getDeviceSettings(): void {
    this.menuSideBare = JSON.parse(JSON.stringify(menuItems));
    this.menuItems$ = combineLatest([
      this.sideBarService.getLinks(),
      this.userService.customFunctionSettings,
      this.userService.typologyDeviceSettings
    ])
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(([links, customFunctionSettings, typologyDeviceSettings]) => {
      this.links = links;
      this.customFunctionSettings = customFunctionSettings;
      this.typologyDeviceSettings = typologyDeviceSettings;
      if (this.typologyDeviceSettings.typologyDeviceSettingsData.customFunctions) {
        this.addCustomFunctionsToMenuItems(this.customFunctionSettings);
      }
      if (!this.typologyDeviceSettings.typologyDeviceSettingsData.links || !this.links.length) {
        this.menuSideBare.find(item => item.menuGroupe === 'SIDEBAR.MY_EXPLOITATION.MY_EXPLOITATION').menuGroupeToShow = false;
      } else {
        this.addExternalLinksToMenuItems(this.links);
      }
      this.checkMenusToHide();
    });
  }

  unCollapseMenu() {
    this.collapsedMenu.emit();
    this.hoverPlus = false;
  }

  private addExternalLinksToMenuItems(links: Link[]): void {
    links.forEach(link =>
      this.menuSideBare.find(item => item.menuGroupe === 'SIDEBAR.MY_EXPLOITATION.MY_EXPLOITATION').subMenu.push({
        external: true,
        showWhenCollapsed: false,
        icon: '',
        label: link.name,
        link: link.url,
        show: true,
        settingLabel: 'links'
      })
    );
  }

  private addCustomFunctionsToMenuItems(settings: CustomFunctionSettings): void {
    settings?.customFunctions.forEach(customFunction =>
      this.menuSideBare.find(item => item.menuGroupe === 'SIDEBAR.MY_SPACE.MY_SPACE').subMenu.push({
        external: true,
        showWhenCollapsed: true,
        icon: 'icon-function',
        label: customFunction.name,
        link: customFunction.url,
        show: customFunction.enabled && settings.status === 'ACTIVE',
        settingLabel: 'customFunction'
      })
    );
  }

  checkMenusToHide() {
    this.menuSideBare.forEach(menuItem => {
      menuItem.subMenu.forEach(submenu => {
        if (submenu.settingLabel) {
          if (Object.prototype.hasOwnProperty.call(this.typologyDeviceSettings.typologyDeviceSettingsData, submenu.settingLabel)) {
            submenu.show = this.typologyDeviceSettings.typologyDeviceSettingsData[submenu.settingLabel];
          }
        }
      });
    });
  }

  navigate(subMenu: any) {
    if (subMenu.external) {
      let externalLink = null;
      switch (subMenu.settingLabel) {
        case 'eventLog':
          externalLink = this.typologySettings.eventLogSettings.log;
          break;
        default:
          externalLink = subMenu.link;
          break;
      }
      window.open(externalLink, '_blank');
    } else {
      this.router.navigate([subMenu.link]);
    }
  }

  ngOnDestroy(): void {
    this.menuItems$.unsubscribe();
  }
}
