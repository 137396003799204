<div class="tabs-container">
  <ul class="tabs d-flex font-size-16 ubuntu-bold">
    <li *ngFor="let tab of tabs"
      class="tab" 
      [attr.data-state]="router.url === tab.link ? 'active' : ''"
    >
      <a class="d-flex align-items-center justify-content-center p-4"
        [routerLink]="tab.link">{{(tab.title) | translate}}</a>
    </li>
  </ul>
</div>