<div class="p-5 d-flex flex-column">
  <div class="modal-header d-flex">
    <div class="d-flex align-items-center">
      <div class="position-relative type-background bg-pomegranate me-3">
        <div class="icon icon-44 icon-symbole-transdev"></div>
      </div>
      <h1 class="modal-title ubuntu-bold font-size-32 pe-3" *ngIf="message.title; else elseBlock">{{message.title}}</h1>
      <ng-template #elseBlock>
        <h1 class="modal-title ubuntu-bold font-size-32 pe-3">{{'MESSAGE.TYPE.CUSTOM_MESSAGE' | translate}}</h1>
      </ng-template>
      <div *ngIf="message.status" class="message-status d-inline-flex align-items-center p-1">
        <div class="icon icon-8 icon-clock-white me-2"></div>
        <div class="status font-size-12 text-truncate text-white">{{message.status}}</div>
      </div>
    </div>
    <button type="button" id="close-modal-message" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <div class="icon icon-16 icon-close"></div>
    </button>
  </div>
  <div class="modal-body ubuntu-regular font-size-20 d-flex align-items-center">
    <div [innerHTML]="message.content | linky | nl2br"></div>
  </div>
  <div *ngIf="message.url" class="align-self-center my-4">
    <ng-container class="align-self-center my-4">
      <a *ngIf="urlDownload$ | async as urlDownload; else loadingFileUrl" href="{{ urlDownload }}" target="_blank" download>
        <button class="Rectangle btn btn-black ubuntu-bold font-size-18">
          {{'DOCUMENT.FILE.DETAILS.BUTTON.READ' | translate}}
        </button>
      </a>
    </ng-container>
    <ng-template #loadingFileUrl>
      <button class="Rectangle btn btn-black ubuntu-bold font-size-18"
        disabled="true">
        {{'DOCUMENT.FILE.DETAILS.BUTTON.READ' | translate}}
      </button>
    </ng-template>
  </div>
  <div class="modal-footer ubuntu-regular font-size-16">
    {{message.creationDate | dateFormat: 'short'}} {{message.creationDate | dateFormat: 'HH:mm'}}
  </div>
</div>
