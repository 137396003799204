<div id="home" class="ubuntu-regular">
  <app-planning-day></app-planning-day>
</div>
<div id="tasks" class="tasks-container">
  <label class="ubuntu-bold text-black font-size-24">{{ 'TASKS.TITLE' | translate }}</label>
  <div class="d-flex flex-wrap mt-4">

    <!-- Deadlines -->
    <ng-container *ngIf="tasks && tasks.length">
      <app-task *ngFor="let task of tasks" [task]="task" (click)="openDueDateTask(task)"></app-task>
    </ng-container>

    <!-- Tiles -->

    <div *ngIf="messagesCountService.unreadExploitMessageCount"
         class="tile pointer d-flex flex-column br-4 tile__exploit-messages"
         (click)="navigateToMessages()"
    >
      <span class="font-size-48 text-white ubuntu-bold">
        {{ messagesCountService.unreadExploitMessageCount }}
      </span>
      <span class="font-size-16 text-white ubuntu-bold" title="{{ 'TILES.UNREAD_EXPLOIT_MULTIPLE' | translate }}">
        {{ (messagesCountService.unreadExploitMessageCount > 1 ? 'TILES.UNREAD_EXPLOIT_MULTIPLE' : 'TILES.UNREAD_EXPLOIT') | translate }}
      </span>
    </div>

    <div *ngIf="messagesCountService.unreadOtherMessageCount"
         class="tile pointer d-flex flex-column br-4 tile__other-messages"
         (click)="navigateToMessages()"
    >
      <span class="font-size-48 text-white ubuntu-bold">
        {{ messagesCountService.unreadOtherMessageCount }}
      </span>
      <span class="font-size-16 text-white ubuntu-bold" title="{{ 'TILES.UNREAD_OTHER_MULTIPLE' | translate }}">
        {{ (messagesCountService.unreadOtherMessageCount > 1 ? 'TILES.UNREAD_OTHER_MULTIPLE' : 'TILES.UNREAD_OTHER') | translate }}
      </span>
    </div>

    <div *ngIf="messagesCountService.pendingSwapRequestCount"
         class="tile pointer d-flex flex-column br-4 tile__swap-request"
         (click)="navigateToSwaps()"
    >
      <span class="font-size-48 text-white ubuntu-bold">
        {{ messagesCountService.pendingSwapRequestCount }}
      </span>
      <span class="font-size-16 text-white ubuntu-bold" title="{{ 'TILES.UNREAD_SWAP_REQUEST_MULTIPLE' | translate }}">
        {{ (messagesCountService.pendingSwapRequestCount > 1 ? 'TILES.UNREAD_SWAP_REQUEST_MULTIPLE' : 'TILES.UNREAD_SWAP_REQUEST') | translate }}
      </span>
    </div>

    <div *ngIf="waitingSurveysCount"
         class="tile pointer d-flex flex-column br-4 tile__exploit-messages"
         (click)="navigateToSurveys()"
    >
      <span class="font-size-48 text-white ubuntu-bold">
        {{ waitingSurveysCount }}
      </span>
      <span class="font-size-16 text-white ubuntu-bold" title="{{ 'SURVEYS.COMMON.WAITING_TO_REPLY' | translate }}">
        {{ 'SURVEYS.COMMON.WAITING_TO_REPLY' | translate }}
      </span>
    </div>

  </div>
</div>
<div class="section-container news-container" *ngIf="newsActiveSetting">
  <app-news-list></app-news-list>
</div>
