import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PeriodType } from 'src/app/shared/enums/home/periodType.enum';
import * as moment from 'moment';
import { DayType } from 'src/app/shared/enums/home/dayType.enum';

@Component({
  selector: 'app-day-week-card',
  templateUrl: './day-week-card.component.html',
  styleUrls: ['./day-week-card.component.scss']
})
export class DayWeekCardComponent implements OnInit {
  @Input() day;
  periodType = PeriodType;
  dayType = DayType;
  constructor(private router: Router) {}

  ngOnInit(): void {}

  navigateToDetails(day) {
    if (day.date) {
      this.router.navigate(['/planning/details'], { queryParams: { from: moment(day.date).format('YYYY-MM-DD') } });
    }
  }
}
