import { AfterViewInit, Component, DestroyRef, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { EMPTY, catchError, finalize, tap } from 'rxjs';
import * as moment from 'moment';
import { SwiperOptions } from 'swiper/types';
import { SwiperContainer } from 'swiper/element';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FullDayAbsenceRequestService } from '../fullDay-absence-request.service';
import { AbsenceService } from '../../absence.service';
import { NotificationModalComponent } from 'src/app/shared/components/notification-modal/notification-modal.component';
import { UserService } from 'src/app/shared/services/user.service';
import { absenceCountersCodes } from 'src/app/shared/constants/constants';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-full-day-absence-counter-request',
  templateUrl: './fullDay-absence-counter-request.component.html',
  styleUrls: ['./fullDay-absence-counter-request.component.scss']
})
export class FullDayAbsenceCounterRequestComponent implements OnInit, AfterViewInit {
  @ViewChild('swiper') swiper!: ElementRef<SwiperContainer>;
  private userService: UserService = inject(UserService);
  private modalService: NgbModal = inject(NgbModal);
  private formBuilder: FormBuilder = inject(FormBuilder);
  fullDayAbsenceRequestService: FullDayAbsenceRequestService = inject(FullDayAbsenceRequestService);
  absenceService: AbsenceService = inject(AbsenceService);
  private destroyRef = inject(DestroyRef);

  remainingSlots: any = [];
  counters: any;
  counterRequestForm: FormGroup;
  isAutomaticLeave = false;
  isAutomaticAbsence = false;
  index = 0;
  swiperConfig: SwiperOptions = {
    slidesPerView: 'auto',
    navigation: true,
    width: 210,
    spaceBetween: 12,
    centeredSlides: true,
  };

  ngOnInit() {
    this.createCountersRequestForm();
    this.userService.typologySetting
      .pipe(
        tap(typoSetting => {
          this.isAutomaticLeave = typoSetting.absenceRequestSettings.fullDayAbsence.absencePeriodCounterEnabled;
          this.isAutomaticAbsence = typoSetting.absenceRequestSettings.fullDayAbsence.leavePeriodCounterEnabled;
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();

    this.getRemainingSlots(this.fullDayAbsenceRequestService.absence.startDate, this.fullDayAbsenceRequestService.absence.endDate);
    this.initFormWithData();
  }

  ngAfterViewInit() {
    this.swiper.nativeElement.swiper.activeIndex = this.index;
  }

  private getRemainingSlots(startDate: any, endDate: any) {
    this.absenceService
      .getFullDayRemainingSlots(
        moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), this.fullDayAbsenceRequestService.absence.groupingId)
      .pipe(
        tap(slots => {
          this.remainingSlots = slots;
        }),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe();
  }

  getCounterBalance() {
    this.absenceService.getCountersBalance(
      this.fullDayAbsenceRequestService.absence.startDate.format('YYYY-MM-DD'), 'FULL_DAY',
      this.fullDayAbsenceRequestService.absence.groupingId)
      .pipe(
        catchError(() => EMPTY),
        tap(counters => this.counters = counters),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe();
  }

  createCountersRequestForm() {
    this.getCounterBalance();
    this.counterRequestForm = this.formBuilder.group({
      counter: [null, [Validators.required]],
      comment: [null]
    });
  }

  setAbsenceData() {
    this.fullDayAbsenceRequestService.absence.counterCode = this.counterRequestForm.controls.counter.value;
    if (this.counterRequestForm.controls.counter.value !== absenceCountersCodes.AUTOMATIC_ABSENCE &&
      this.counterRequestForm.controls.counter.value !== absenceCountersCodes.AUTOMATIC_LEAVE) {
      this.fullDayAbsenceRequestService.absence.counterLabel =
        this.counters.find((counter: any) => counter.code === this.fullDayAbsenceRequestService.absence.counterCode).label;
    }
    this.fullDayAbsenceRequestService.absence.comment = this.counterRequestForm.controls.comment.value;
  }

  initFormWithData() {
    this.counterRequestForm.controls.counter.setValue(this.fullDayAbsenceRequestService.absence.counterCode);
    this.counterRequestForm.controls.comment.setValue(this.fullDayAbsenceRequestService.absence.comment);
    this.fullDayAbsenceRequestService.absence.counters = null;
  }

  goToPrevStep() {
    this.fullDayAbsenceRequestService.goToPrevStep();
  }

  goToNextStep() {
    if (this.counterRequestForm.valid) {
      this.setAbsenceData();
      this.absenceService.verifyAbsence(this.fullDayAbsenceRequestService.absence, 'FULL_DAY')
        .pipe(
          tap(dataVerification => {
            if (dataVerification.deadlineOverlap) {
              this.openErrorModal('ABSENCE_REQUEST.MODAL.DEADLINE_OVERLAP_MESSAGE');
            } else if (!dataVerification.hasAccess) {
              this.openErrorModal();
            } else {
              this.fullDayAbsenceRequestService.absence.counters = dataVerification.counters;
              this.fullDayAbsenceRequestService.goToNextStep();
            }
          }),
          takeUntilDestroyed(this.destroyRef)
        ).subscribe();
    }
  }

  openErrorModal(message = 'ABSENCE_REQUEST.MODAL.MESSAGE') {
    const modalRef = this.modalService.open(NotificationModalComponent, {
      centered: true
    });
    modalRef.componentInstance.title = 'ABSENCE_REQUEST.MODAL.TITLE';
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.icon = 'icon-alert-red';
  }

  slideChange(swiper: any) {
    this.index = swiper.detail[0].activeIndex;
  }

}
