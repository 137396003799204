<div class="d-flex my-4 align-items-center">
  <span class="d-flex font-size-24 ubuntu-bold text-black me-2">{{ viewDate | dateFormat:'MMMM' }}</span>
  <span class="d-flex font-size-16 ubuntu-bold text-black">{{ viewDate | dateFormat:'YYYY' }}</span>
</div>

<mwl-calendar-month-view [viewDate]="viewDate" [events]="events" [locale]="locale"
  [weekStartsOn]="userService.site.firstDayOfWeek"
  [headerTemplate]="headerTemplate"
  [cellTemplate]="customCellTemplate" >
</mwl-calendar-month-view>

<ng-template #headerTemplate let-days="days" let-locale="locale">
    <div class="cal-cell-row cal-header text-white">
        <div class="cal-cell cal-header" *ngFor="let day of days">
          <div class="day-label text-uppercase">
              {{ day.date.toLocaleString(userService.site.language.code, {weekday: 'short'})  }}
          </div>
        </div>
    </div>
</ng-template>

<ng-template #customCellTemplate let-day="day" let-locale="locale">
  <div class="d-flex flex-column justify-content-center cal-cell-top show-today" (click)="navigateToDetails(day)"
    [ngClass]="{'background-grey': !day?.events[0]?.meta || day.events[0].meta.estimated }">
    <span class="d-flex justify-content-center font-size-20 text-black ubuntu-bold day-number"
      [ngClass]="{'not-valid' : !day.events || !day.events.length || !day.events[0].meta ||  !day.events[0].meta.valid,
                  'recomposed-shift' : day?.events[0]?.meta?.recomposedShift,
                  'text-black' : !day?.isToday && day.events[0]?.meta?.valid}">
      {{ day.date | calendarDate:'monthViewDayNumber':locale }}
    </span>
    <div class="shifts-container d-flex flex-column justify-content-center align-items-center"
      *ngIf="day.events && day.events.length && day.events[0].meta">

      <ng-container *ngIf="displayServiceName">
        <ng-container *ngIf="day?.events[0]?.meta?.period === periodType.DETAIL || day?.events[0]?.meta?.period === periodType.PREVIEW">
          <div *ngIf="day?.events[0]?.meta?.type === dayType.WORKING">
            <span class="service-name font-size-10 ubuntu-bold text-uppercase mr-1"
              [ngClass]="{'not-valid' : !day.events[0].meta.valid, 'text-black' : !day?.isToday && day.events[0].meta.valid}">
              {{ (day?.events[0]?.meta?.reference?.length > 7)? (day?.events[0]?.meta?.reference |
              slice:0:6)+'..':(day?.events[0]?.meta?.reference) }}
            </span>
          </div>
          <div *ngIf="day?.events[0]?.meta?.type === dayType.UNAVAILABILITY ||
                      day?.events[0]?.meta?.type === dayType.AVAILABILITY ||
                      day?.events[0]?.meta?.type === dayType.REST"
                class="service-name font-size-10 ubuntu-bold text-uppercase me-1"
                [ngClass]="{'not-valid' : !day.events[0].meta.valid, 'text-black' : !day?.isToday && day.events[0].meta.valid}">
                {{ (day?.events[0]?.meta?.code?.length > 7)? (day?.events[0]?.meta?.code | slice:0:6)+'..':(day?.events[0]?.meta?.code) }}
          </div>
        </ng-container>
        <ng-container *ngIf="day?.events[0]?.meta?.period === periodType.POSITION">
          <div  class="service-name font-size-10 ubuntu-bold text-uppercase me-1"
            [ngClass]="{'not-valid' : !day.events[0].meta.valid}">
            <span *ngIf="day?.events[0]?.meta?.type === 'REST' && !day?.events[0]?.meta?.workingTime" >{{'PLANNING.CODE.REST'  | translate}}</span>
            <span *ngIf="day?.events[0]?.meta?.type === 'AVAILABILITY' && !day?.events[0]?.meta?.workingTime" >{{'PLANNING.CODE.AVAILABILITY'  | translate}}</span>
            <span *ngIf="day?.events[0]?.meta?.type === 'UNAVAILABILITY' && !day?.events[0]?.meta?.workingTime" >{{'PLANNING.CODE.UNAVAILABILITY' | translate}}</span>
            <span *ngIf="day?.events[0]?.meta?.type === 'WORKING'" >{{'PLANNING.CODE.WORKING'  | translate}}</span>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="displayVacationCount || day?.events[0]?.meta?.period === periodType.EMPTY">
        <div class="d-flex flex-row" *ngIf="day.events[0].meta.numberOfPoints > 0">
          <ng-container *ngFor="let shift of day.events[0].meta.shifts; index as indexShift">
            <div *ngIf="indexShift < 3" class="point"></div>
            <div *ngIf="indexShift === 3" class="plus"></div>
          </ng-container>
        </div>
        <ng-container *ngIf="day.events[0].meta.numberOfPoints === -1 && !displayServiceName">
          <div class="status font-size-10 ubuntu-bold text-uppercase me-1"
               [ngClass]="{'not-valid' : !day.events[0].meta.valid, 'text-black' : !day?.isToday && day.events[0].meta.valid}">
            {{ (day.events[0].meta?.code?.length > 7)? (day.events[0].meta?.code | slice:0:6)+'..':(day.events[0].meta?.code) }}

          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>
