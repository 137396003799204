import {HttpClient} from '@angular/common/http';
import {API_URLS} from 'src/environments/api-urls';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable()
export class BaseApiService {

  constructor(private http: HttpClient) {
  }

  /**
   *
   */
  private static _getFullPath(path: string) {
    return API_URLS.BASE_API + '/' + path;
  }

  /**
   * Invoke BE WS.
   * This function will be invoked internally.
   */
  private _sendRequest(method, path, options): Observable<any> {
    path = BaseApiService._getFullPath(path);
    return this.http.request(method, path, options);
  }

  /**
   */
  get(path: string, options: any = {}): Observable<any> {
    return this._sendRequest('GET', path, options);
  }

  /**
   *
   *
   */
  post(path: string, body: any = {}, options = {}): Observable<any> {
    const httpHeadersObject = Object.assign({}, {body}, options);
    return this._sendRequest('POST', path, httpHeadersObject);
  }

  /**
   * Put request
   */
  put(path, body: any = {}, options = {}): Observable<any> {
    const httpHeadersObject = Object.assign({}, {body}, options);
    return this._sendRequest('PUT', path, httpHeadersObject);
  }

  /**
   * Delete request
   */
  delete(path, body: any = {}, options = {}): Observable<any> {
    const httpHeadersObject = Object.assign({}, {body}, options);
    return this._sendRequest('DELETE', path, httpHeadersObject);
  }
}
