<form *ngIf="resetFormGroup" [formGroup]="resetFormGroup" (ngSubmit)="onSendPassword()">
  <div class="form-access d-flex">
    <div class="form-access__red-page col-12 d-flex align-items-center justify-content-center">
      <section class="form-access__red-page__panel-action col-xs-12 col-lg-6">
        <div class="form-access__red-page__panel-action__header d-flex align-items-center justify-content-center">
          <div class="branding ms-2 me-2">
            <div class="form-access__red-page__panel-action__header__icon icon icon-36 icon-symbole-transdev"></div>
          </div>
          <div class="form-access__red-page__panel-action__header__title m-2">
            <h3 *ngIf="!sent(); else smsSent" class="mx-3 font-size-18 ubuntu-bold">
              {{'RESET_PASSWORD.EMPLOYEE_INSTUCTIONS' | translate}}
            </h3>
          </div>
        </div>

        <ng-container *ngIf="!sent()">

          <div class="form-access__red-page__panel-action__body">
            <div class="form-access__red-page__panel-action__body__content">
              <div class="row mb-3 mt-4">
                <div class="col-lg-6 col-xs-12">
                  <label for="siteId" class="font-size-16 text-charcoal-grey pt-3">
                    {{ "RESET_PASSWORD.LOCATION" | translate }}
                  </label>
                </div>
                <div class="col-lg-6 col-xs-12">
                  <ng-select class="font-size-16 " id="siteId" bindLabel="label" bindValue="code"
                    formControlName="siteId" [multiple]="false" [clearable]="true" [items]="databases$ | async">
                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                      <span>{{item.label}}</span>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index"
                      let-search="searchTerm">
                      <span>{{item.label}}</span>
                    </ng-template>
                  </ng-select>
                  <p>
                    <app-mobi-print-errors [control]="resetFormGroup.controls.siteId"></app-mobi-print-errors>
                  </p>
                </div>
              </div>

              <div class="row mb-3 mt-4">
                <div class="col-lg-6 col-xs-12">
                  <label for="adminNumber" class="font-size-16 text-charcoal-grey">
                    {{ "RESET_PASSWORD.ADMIN_NUMBER" | translate }}
                  </label>
                </div>
                <div class="col-lg-6 col-xs-12">
                  <input class="font-size-16 bg-gallery" id="adminNumber" name="adminNumber" required
                    formControlName="adminNumber" />
                  <p>
                    <app-mobi-print-errors [control]="resetFormGroup.controls.adminNumber"></app-mobi-print-errors>
                  </p>
                </div>
              </div>

              <div class="row mb-3 mt-4">
                <div class="col-lg-6 col-xs-12">
                  <label for="phoneNumber" class="font-size-16 text-charcoal-grey">
                    {{ "RESET_PASSWORD.LAST_FOUR_DIGITS" | translate }}
                  </label>
                </div>
                <div class="col-lg-6 col-xs-12">
                  <input class="font-size-16 bg-gallery" maxlength="4" minlength="4" pattern="[0-9]{1,4}" required
                    id="phoneNumber" name="phoneNumber" formControlName="phoneNumber" />
                  <p>
                    <app-mobi-print-errors [control]="resetFormGroup.controls.phoneNumber"></app-mobi-print-errors>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="form-access__red-page__panel-action__footer align-items-center justify-content-center d-flex">
            <input id="send" type="submit" class="font-size-16 btn btn-primary"
              [disabled]="resetFormGroup.invalid || isProcessing()"
              [textContent]="'RESET_PASSWORD.SEND' | translate " />

          </div>
        </ng-container>
      </section>
    </div>
  </div>
</form>

<ng-template #smsSent>
  <h3 class="sms-success-message mx-3 ubuntu-bold font-size-18">{{'RESET_PASSWORD.SUCCESS_SENDED' | translate}}</h3>
</ng-template>
