<div class="news-card-item font-size-16 d-flex news-card-item--full-width pointer" *ngIf="item.frontPage; else notLead">
  <a (click)="navigate()"
    class="row m-0 w-100"
  >
    <div class="news-card-item-cover col-8 m-0 position-relative" [style.backgroundImage]="'url(' + item.imgUrl + ')'">
      <img class="news-card-item__headline-image position-absolute" alt="Headline image"
        [src]="'/assets/images/news/headline.'+ lang + '.png' ">
    </div>
    <div class="col-md-4 col-lg-3 d-flex flex-column">
      <div>
        <div class="news-card-item-date">
          {{ item.publicationDate | dateFormat: 'mini'}}
        </div>
      </div>
      <div class="news-card-item__heading-title font-size-16 flex-grow-1">
        {{item.title}}
      </div>
      <div class="news-card-item__read-more-link font-weight-bold">
        <span>{{'NEWS.ITEM.READ_MORE_LINK' | translate}}</span>
        <app-icon-arrow [rotationDegree]="90" fillColor="red"></app-icon-arrow>
      </div>
    </div>
  </a>
</div>

<!-- not lead article -->
<ng-template #notLead>
  <a (click)="navigate()"
    class="news-card-item font-size-16 d-flex flex-column pointer"
  >
    <div class="news-card-item__heading">
      <div class="news-card-item__heading-cover" [style.backgroundImage]="'url(' + item.imgUrl + ')'">
        <span class="news-card-item__heading-cover-time">
          {{ item.publicationDate | dateFormat: 'mini'}}
        </span>
      </div>

      <div class="news-card-item__heading-title font-size-16">
        {{item.title}}
      </div>
    </div>

    <div class="news-card-item__read-more-link font-weight-bold">
      <span>{{'NEWS.ITEM.READ_MORE_LINK' | translate}}</span>
      <app-icon-arrow [rotationDegree]="90" fillColor="red"></app-icon-arrow>
    </div>
  </a>

</ng-template>
