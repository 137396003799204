import { Injectable, Injector } from '@angular/core';
import { UserService } from './shared/services/user.service';
import { AUTH_URL } from 'src/environments/environment';
import { Initializable } from './shared/services/initializable';
import { Router } from '@angular/router';
import { countries } from './shared/constants/constants';
import { UserGuardService } from './shared/services/user-guard.service';
@Injectable()
export class AuthenticationService implements Initializable {
  private userService: UserService;
  private router: Router;
  private userGuard: UserGuardService;

  constructor(
    private injector: Injector,
  ) { }

  init() {
    this.userService = this.injector.get(UserService);

    const fragment = window.location.hash.substr(1);
    if (fragment) {
      const params = fragment.split('&').map(pair => pair.split('='));
      const result = {};
      params.forEach(([key, value]) => result[key] = value);
      if (result[`id_token`]) {
        this.userService.token = result[`id_token`];
      }
      if (result[`access_token`]) {
        this.userService.accessToken = result[`access_token`];
      }
    }
    if (!localStorage.getItem('country')) {
      this.setCountry();
      // * reload to re generate HOST of environment: https://api.dev.${localStorage.getItem('country')}.mobime.transdev.com`
      // if not the host will be api.dev.null.mobime so that the app stop (white page)
      window.location.reload();
    }
    this.userService.initDefaultLang();
  }

  signIn(): void {
    this.router = this.injector.get(Router);
    this.router.navigate(['login']);
  }

  logout() {
    this.router = this.injector.get(Router);
    this.userGuard = this.injector.get(UserGuardService);
    const modeBadge = !!localStorage.getItem('badgeToken');
    this.userGuard.isAuthenticated = false;
    this.userGuard.cancelPendingRequests();

    if (modeBadge) {
      localStorage.removeItem('badgeToken');
      this.router.navigate(['badge-authentication']);
    }
    else {
      sessionStorage.removeItem('lastRoute');
      window.location.href = AUTH_URL.LOGOUT;
    }
  }

  forbidden() {
    this.router = this.injector.get(Router);
    this.router.navigate(['forbidden-access']);
  }

  setCountry() {
    const country = window.location.hostname.split('.').find(
      element => {
        return countries.find(c => c.code === element);
      }
    );
    localStorage.setItem('country', country || 'fr');
  }
}
