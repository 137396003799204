<div class="mobime-header">
  <h1 class="ubuntu-bold font-size-32 mb-0">{{ 'SWAP_REQUEST.TITLE' | translate }}</h1>
  <div class="ubuntu-bold font-size-24">{{ swapStepService.selectedDate | dateFormat: 'long' }}</div>
</div>


<div class="row">
  <div class="col-md-12 col-xl-6 br-grey">
    <div class="swap">
      <div class="ubuntu-bold font-size-16">{{ 'SWAP_REQUEST.SEARCH.SEARCH_CRITERIA' | translate }}</div>
      <p class="swap__rules font-size-13 text-grey">
        {{ 'SWAP_REQUEST.SEARCH.RULES_TEXT' | translate }}
      </p>
      <form [formGroup]="searchForm" (ngSubmit)="onSubmit()" class="swap__search-form">
        <div class="row mb-5">
          <div class="col-sm-6">
            <div class="form-group">
              <label for="startTimeHour"
                class="font-size-16 text-charcoal-grey">{{ 'SWAP_REQUEST.SEARCH.START_AFTER' | translate }}</label>
              <div class="d-flex">
                <div>
                  <input type="number" min="0" max="35" placeholder="0" id="startTimeHour" name="startTimeHour"
                    formControlName="startTimeHour" class="font-size-16 text-center w-100 bg-gallery br-4 px-3"
                    [ngClass]="{'error': errorTime}" (change)="formatStartHour($event.target)" />
                </div>
                <div class="d-flex px-3 font-size-16 align-items-center">:</div>
                <div>
                  <input type="number" min="0" max="59" placeholder="00" id="startTimeMinute" name="startTimeMinute"
                    formControlName="startTimeMinute" class="font-size-16 text-center w-100 bg-gallery br-4 px-3"
                    [ngClass]="{'error': errorTime}" (change)="formatStartMinute($event.target)" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="endTimeHour"
                class="font-size-16 text-charcoal-grey">{{ 'SWAP_REQUEST.SEARCH.FINISH_BEFORE' | translate }}</label>
              <div class="d-flex">
                <div>
                  <input type="number" min="0" max="35" placeholder="0" id="endTimeHour" name="endTimeHour"
                    formControlName="endTimeHour" class="font-size-16 text-center w-100 bg-gallery br-4 px-3"
                    [ngClass]="{'error': errorTime}" (change)="formatEndHour($event.target)" />
                </div>
                <div class="d-flex px-3 font-size-16 align-items-center">:</div>
                <div>
                  <input type="number" min="0" max="59" placeholder="00" id="endTimeMinute" name="endTimeMinute"
                    formControlName="endTimeMinute" class="font-size-16 text-center w-100 bg-gallery br-4 px-3"
                    [ngClass]="{'error': errorTime}" (change)="formatEndMinute($event.target)" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group mb-5">
          <label for="searchTerm"
            class="font-size-16 text-charcoal-grey">{{ 'SWAP_REQUEST.SEARCH.COWORKER_OR_SERVICE' | translate }}</label>
          <input type="text" id="searchTerm" name="searchTerm" formControlName="searchTerm"
            class="font-size-16 w-100 bg-gallery br-4 px-3" />
        </div>
        <div>
          <button type="submit"
            class="btn btn-black ubuntu-bold font-size-16 br-4">{{ 'SWAP_REQUEST.SEARCH.SEARCH' | translate }}</button>
        </div>
      </form>
    </div>
  </div> 
  <app-swap-service-detail class="ms-5 col-xl-5 col-md-12" [date]="swapStepService.selectedDate"></app-swap-service-detail>
 
</div>
<div class="row ps-5">
  <div class="col-12">
    <div class="swap__search-results pb-5"
    *ngIf="swapStepService.swapSearch.swapSearchShift?.length > 0">
      <div class="font-size-13 mb-2">
        <span class="ubuntu-bold">{{ 'SWAP_REQUEST.SEARCH.SEARCH_RESULTS' | translate }}</span>
        ({{ swapStepService.swapSearch.swapSearchShift.length }})
      </div>
    </div>
  </div>
</div>
<div class="row ps-5 pe-5"> 
  <div *ngFor="let result of swapStepService.swapSearch.swapSearchShift; let index = index"
    class="col-md-6 col-lg-4 col-xl-3">
    <div class="swap-search-card mb-4">
      <label class="w-100 mb-0 pointer">
        <input type="radio" name="search-results-group" [value]="index" [id]="'service-' + result.id"
          (click)="selectShift(index)" />
        <div class="swap-search-card__title text-white d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center ubuntu-bold font-size-16">{{ result.fullName }}</div>
          <div class="icon icon-24"
            [ngClass]="swapStepService.swapSearch?.colleagueShift?.id === index ? 'icon-radio-active': 'icon-radio '">
          </div>
        </div>
        <div class="swap-search-card__content p-4">
          <div class="font-size-16 mb-1">
            {{ 'SWAP_REQUEST.SEARCH.SERVICE' | translate }} {{ result.shift.label }}
          </div>
          <div class="d-flex justify-content-between text-grey font-size-16">
            <div class="d-flex align-items-center">
              <div>
                {{ result.shift.departure.date | dateFormat:"H"}}h{{ result.shift.departure.date | dateFormat:"mm"}}
              </div>
              <div class="icon icon-16 me-2 icon-arrow-right mx-1"></div>
              <div>{{ result.shift.arrival.date | dateFormat:"H"}}h{{ result.shift.arrival.date | dateFormat:"mm"}}
              </div>
            </div>
            <div class="font-size-13" title="{{ 'SWAP_REQUEST.SEARCH.WORKING_TIME' | translate }}">
              {{ 'SWAP_REQUEST.SEARCH.WT' | translate }} : {{ result.formatedWorkingTime }}</div>
          </div>
        </div>
      </label>
    </div>
  </div>
</div>