import { Component, OnInit } from '@angular/core';
import { NewsService } from '../../services/news.service';

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent implements OnInit {

  constructor(
    public newsService: NewsService,
  ) { }
  /**
   * Fired when the component is just loaded
   * Angular init hooks
   */
  ngOnInit() {
    this.loadMoreItems();
  }

  /**
   * Called to load more items
   * Used when we init the component and when we scroll down
   */
  private loadMoreItems() {
    this.newsService.fetchMore();
  }
}
