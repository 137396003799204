import { SwapRequestSettings } from '@shared/models/settings/swapRequestSettings/swapRequestSettings';
import { UserService } from 'src/app/shared/services/user.service';
import { RestStepsService } from './../rest-steps.service';
import { Component, DestroyRef, OnDestroy, OnInit, inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SnackbarService } from 'src/app/shared/components/snackbar/snackbar.service';
import { forkJoin } from 'rxjs';
import { NotificationModalComponent } from 'src/app/shared/components/notification-modal/notification-modal.component';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-swap-request-rest',
  templateUrl: './swap-request-rest.component.html',
  styleUrls: ['./swap-request-rest.component.scss']
})
export class SwapRequestRestComponent implements OnDestroy, OnInit {

  i18nPrefixCOMMON = 'COMMON.BUTTON.';
  swapRequestSettings: SwapRequestSettings;
  private destroyRef = inject(DestroyRef);
  constructor(
    public restStepsService: RestStepsService,
    private userService: UserService,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private snackbarService: SnackbarService,
    private router: Router
  ) { }

  ngOnInit() {
    this.userService.typologySetting
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(typologySettings => {
      this.swapRequestSettings = typologySettings.swapRequestSettings;
    });
  }

  updateComment(comment: string) {
    this.restStepsService.comment = comment;
  }

  ngOnDestroy(): void {
    this.restStepsService.reset();
  }

  searchValidation() {
    forkJoin([
      this.restStepsService.searchControl(),
      this.restStepsService.searchSimulation()
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: validations => {
          if (validations[0].counterBalanceExceeded || !validations[1]) {
            const modalRef = this.modalService.open(NotificationModalComponent, {
              centered: true,
            });
            modalRef.componentInstance.icon = 'icon-alert-red';
            if (validations[0].counterBalanceExceeded) {
              // if control or both control and simulation failed, show modal about control
              modalRef.componentInstance.title = 'SWAP_REQUEST.SERVICE.CONTROL.TITLE';
              modalRef.componentInstance.message = 'SWAP_REQUEST.SERVICE.CONTROL.MESSAGE';
            } else if (!validations[1]) {
              // if only simulation failed, show modal about simulation
              modalRef.componentInstance.title = 'SWAP_REQUEST.SERVICE.SIMULATION.TITLE';
              modalRef.componentInstance.message = 'SWAP_REQUEST.SERVICE.SIMULATION.MESSAGE';
            }
          }
          if (!validations[0].counterBalanceExceeded && validations[1]) {
            this.restStepsService.goToNextStep();
          }
        },
        error: () => {
          this.snackbarService.show('COMMON.NOTIFICATION.GENERAL_ERROR', 'error');
        }
      });
  }

  goToNextStep() {
    if (this.restStepsService.step === 3) {
      this.searchValidation();
    } else if (this.restStepsService.step === 5) {
      this.restStepsService.title = this.translateService.instant('SWAP_REQUEST.SUMMARY.NEW_REST_SWAP_REQUEST');
      this.restStepsService.message = this.translateService.instant(
        'SWAP_REQUEST.DETAILS.DESCRIPTION.PENDING_REST',
        {
          firstName: this.userService.userFirstName,
          lastName: this.userService.userLastName
        }
      );
      this.restStepsService.sendSwapRequest()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: () => {
            const modalRef = this.modalService.open(NotificationModalComponent, {
              centered: true,
            });
            modalRef.componentInstance.icon = 'icon-checked-green';
            modalRef.componentInstance.title = 'SWAP_REQUEST.SUMMARY.MODAL.TITLE';
            modalRef.componentInstance.message = 'SWAP_REQUEST.SUMMARY.MODAL.TEXT';
            setTimeout(() => {
              modalRef.close();
              this.router.navigate(['/swap']);
            }, 2000);
          },
          error: () => {
            this.snackbarService.show('COMMON.NOTIFICATION.GENERAL_ERROR', 'error');
          }
        }
        );
    } else {
      this.restStepsService.goToNextStep();
    }
  }
}
