<ng-container *ngIf="swapRequestSettings">
  <div class="swap-rest font-size-16">
    <!-- les compnents selon rest steps -->
    <app-rest-first-calendar *ngIf="restStepsService.step === 1"></app-rest-first-calendar>
    <app-rest-second-calendar *ngIf="restStepsService.step === 2"></app-rest-second-calendar>
    <app-swap-rest-search *ngIf="restStepsService.step === 3"></app-swap-rest-search>
    <app-swap-comment *ngIf="restStepsService.step === 4" (inputComment)="updateComment($event)"
      [title]="'SWAP_REQUEST.CREATE.DAY_OFF'" [comment]="restStepsService.comment">
    </app-swap-comment>

    <app-swap-summary *ngIf="restStepsService.step === 5" 
      [swapRest]="true"
      [userShift]="restStepsService.userShift"
      [userRest]="restStepsService.userRest"
      [colleagueShift]="restStepsService.swapRestSearch.colleagueShift" 
      [comment]="restStepsService.comment"
      [askedDate]="restStepsService.selectedServiceDate" 
      [exchangedDate]="restStepsService.selectedRestDate"
    ></app-swap-summary>
  </div>

  <div class="swap-nav d-flex justify-content-center align-items-center px-5">
    <div class="swap-nav__container d-flex justify-content-between">
      <button (click)="restStepsService.goToPreviousStep()"
        class="btn btn-white ubuntu-bold font-size-16">{{i18nPrefixCOMMON + 'PREVIOUS' | translate}}</button>
      <button (click)="goToNextStep()" class="btn btn-black ubuntu-bold font-size-16"
        [disabled]="!restStepsService.continueStep">
        {{ (restStepsService.step < 5 ? i18nPrefixCOMMON + 'CONTINUE' : 'SWAP_REQUEST.SUMMARY.CONFIRM') | translate}}
      </button>
    </div>
  </div>
</ng-container>