import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { IFullDayAbsence } from './fullDayAbsence.interface';

@Injectable()
export class FullDayAbsenceRequestService {

  step = 1;

  continueStep = true;
  absence: IFullDayAbsence = {
    groupingId: null,
    startDate: null,
    endDate: null,
    counterCode: null,
    counterLabel: null,
    comment: ''
  };

  constructor() {}

  goToPrevStep() {
    this.step--;
  }

  goToNextStep() {
    if (this.continueStep === true) {
      this.step++;
    }
  }

  continueEnable(enabled: boolean) {
    this.continueStep = enabled;
  }

  selectDates(startDate: moment.Moment, endDate: moment.Moment) {
    this.absence.startDate = startDate;
    this.absence.endDate = endDate;
  }

}
