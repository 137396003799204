import { VolunteerRequestComponent } from './volunteer-request/volunteer-request.component';
import { VolunteerSelectComponent } from './volunteer-select/volunteer-select.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from './../../shared/shared.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VolunteerComponent } from './volunteer.component';
import { VolunteerCalendarComponent } from './volunteer-calendar/volunteer-calendar.component';
import { RouterModule } from '@angular/router';
import { CalendarModule } from 'angular-calendar';
import { VolunteerService } from './volunteer.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule, 
    TranslateModule.forRoot(),
    RouterModule,
    CalendarModule,
  ],
  declarations: [
    VolunteerComponent,
    VolunteerCalendarComponent,
    VolunteerSelectComponent,
    VolunteerRequestComponent,
  ],
  providers: [VolunteerService],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class VolunteerModule {}
