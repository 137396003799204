export const menuItems = [
  {
    menuGroupe: 'SIDEBAR.ACCUEIL',
    menuGroupeToShow: false,
    subMenu: [
      {
        external: false,
        showWhenCollapsed: true,
        icon: 'icon-accueil',
        label: 'SIDEBAR.ACCUEIL',
        link: '/home',
        show: true,
        settingLabel: ''
      }
    ]
  },
  {
    menuGroupe: 'SIDEBAR.MY_SPACE.MY_SPACE',
    menuGroupeToShow: true,
    subMenu: [
      {
        external: false,
        showWhenCollapsed: true,
        icon: 'icon-letter',
        label: 'SIDEBAR.MY_SPACE.MY_MESSAGES',
        labelCollapsed: 'SIDEBAR.MY_SPACE.MESSAGES',
        link: '/messages',
        show: false,
        settingLabel: 'messages'
      },
      {
        external: false,
        showWhenCollapsed: true,
        icon: 'icon-planning',
        label: 'SIDEBAR.MY_SPACE.MY_PLANNING',
        labelCollapsed: 'SIDEBAR.MY_SPACE.PLANNING',
        link: '/planning',
        show: false,
        settingLabel: 'myAssignments'
      },
      {
        external: false,
        showWhenCollapsed: true,
        icon: 'icon-hand',
        label: 'SIDEBAR.MY_SPACE.VOLUNTEER',
        link: '/volunteer',
        show: false,
        settingLabel: 'oTVolunteer'
      },
      {
        external: false,
        showWhenCollapsed: true,
        icon: 'icon-change',
        label: 'SIDEBAR.THE_COMMUNITY.SWAP_REQUEST',
        labelCollapsed: 'SIDEBAR.THE_COMMUNITY.SWAP',
        link: '/swap',
        show: false,
        settingLabel: 'swapRequest'
      },
      {
        external: false,
        showWhenCollapsed: true,
        icon: 'icon-door',
        label: 'SIDEBAR.MY_SPACE.ABSENCE_REQUEST',
        labelCollapsed: 'SIDEBAR.MY_SPACE.ABSENCE',
        link: '/absence',
        show: false,
        settingLabel: 'absenceRequest'
      },
      {
        external: false,
        showWhenCollapsed: true,
        icon: 'icon-counter',
        label: 'SIDEBAR.MY_SPACE.MY_COUNTER',
        labelCollapsed: 'SIDEBAR.MY_SPACE.COUNTER',
        link: '/counters',
        show: false,
        settingLabel: 'myCounters'
      },
      {
        external: false,
        showWhenCollapsed: true,
        icon: 'icon-coin',
        label: 'SIDEBAR.MY_SPACE.PREPAYMENT',
        labelCollapsed: 'SIDEBAR.MY_SPACE.PREPAYMENT',
        link: '/prepay',
        show: true,
        settingLabel: 'prepay'
      },
      {
        external: false,
        showWhenCollapsed: true,
        icon: 'icon-documents',
        label: 'SIDEBAR.MY_SPACE.MY_DOCUMENTS',
        labelCollapsed: 'SIDEBAR.MY_SPACE.DOCUMENTS',
        link: '/documents',
        show: false,
        settingLabel: 'myDocuments'
      },
      {
        external: false,
        showWhenCollapsed: true,
        icon: 'icon-documents',
        label: 'SIDEBAR.MY_SPACE.MY_TRAININGS',
        labelCollapsed: 'SIDEBAR.MY_SPACE.MY_TRAININGS',
        link: '/trainings',
        show: true,
        settingLabel: 'training'
      },



      // *NO MVP
      // {
      //   external: false,
      //   showWhenCollapsed: false,
      //   icon: 'icon-disperse',
      //   label: 'SIDEBAR.THE_COMMUNITY.STOCK_EXCHANGE',
      //   link: '/stock-exchange',
      //   show: false,
      //   settingLabel: 'exchangeForum'
      // },
      // {
      //   external: false,
      //   showWhenCollapsed: false,
      //   icon: 'icon-annuaire',
      //   label: 'SIDEBAR.THE_COMMUNITY.DIRECTORY',
      //   link: '/annuaire',
      //   show: false,
      //   settingLabel: 'directory'
      // },
      // {
      //   external: false,
      //   showWhenCollapsed: false,
      //   icon: 'icon-team',
      //   label: 'SIDEBAR.THE_COMMUNITY.TEAM_PLANNING',
      //   link: '/team-planning',
      //   show: false,
      //   settingLabel: 'teamSchedule'
      // },
      // *END NO MVP
      {
        external: false,
        showWhenCollapsed: true,
        icon: 'icon-sondage',
        label: 'SIDEBAR.THE_COMMUNITY.SURVEYS',
        link: '/surveys',
        show: true,
        settingLabel: 'survey'
      },
      {
        external: true,
        showWhenCollapsed: true,
        icon: 'icon-main-courant',
        label: 'SIDEBAR.THE_COMMUNITY.HANDRAIL',
        link: '/handrail',
        show: true,
        settingLabel: 'eventLog'
      }
    ]
  },
  {
    menuGroupe: 'SIDEBAR.MY_EXPLOITATION.MY_EXPLOITATION',
    menuGroupeToShow: true,
    subMenu: []
  }
];
