<ng-container *ngIf="control && control.errors && control.enabled">
  <div class="text-state-error font-size-11 mt-1 mb-0" *ngIf="control.errors.required">{{'INPUT_ERROR.COMMON.REQUIRED' | translate}}</div>
  <div class="text-state-error font-size-11 mt-1 mb-0"
    *ngIf="control.errors.maxlength
        || control.errors.minlength
        || control.errors.pattern
        || control.errors.numericPattern
        || control.errors.max
        || control.errors.min
        || control.errors.invalidPhoneNumber
        || control.errors.rangeError
        || control.errors.unicity
        || control.errors.invalid"
  >
      {{'INPUT_ERROR.COMMON.INVALID' | translate}}
  </div>
  <div class="text-state-error font-size-11 mt-1 mb-0"
    *ngIf="control.errors.codeExist"
  >
    {{'INPUT_ERROR.COMMON.CODE_EXIST' | translate}}
  </div>
  <div class="text-state-error fit-width font-size-11 mt-1 mb-0" *ngIf="control.errors.dateStartEnd">
    {{'NOTIFICATIONS_MESSAGE.ERROR_RANGE_DATE' | translate}}
  </div>
  <div class="text-state-error fit-width font-size-11 mt-1 mb-0" *ngIf="control.errors.timeStartEnd">
    {{'NOTIFICATIONS_MESSAGE.TIME_FRAME_ERROR' | translate}}
  </div>
</ng-container>
