import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { TranslateModule } from '@ngx-translate/core';
import { PlanningDayComponent } from './planning-day/planning-day.component';
import { HomeService } from './home.service';
import { PlanningStatusComponent } from './planning-status/planning-status.component';
import { TaskComponent } from './task/task.component';
import { DueDateModalComponent } from './dueDateModal/dueDateModal.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CheckInConditionsComponent } from './checkInConditions/checkInConditions.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CheckOutConditionsComponent } from './checkOutConditions/checkOutConditions.component';
import { PunchInOutConditionsComponent } from './punchInOutConditions/punchInOutConditions.component';
import {NewsModule} from '../news/news.module';
import {SurveysModule} from '../surveys/surveys.module';
import { PlanningDaysCarouselComponent } from './planning-days-carousel/planning-days-carousel.component';
 
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule.forRoot(),
        NgSelectModule,
        SharedModule,
        NewsModule,
        SurveysModule,
    ],
    declarations: [
        HomeComponent,
        PlanningDayComponent,
        PlanningStatusComponent,
        TaskComponent,
        DueDateModalComponent,
        CheckInConditionsComponent,
        CheckOutConditionsComponent,
        PunchInOutConditionsComponent,
        PlanningDaysCarouselComponent
    ],
    exports: [
        HomeComponent,
    ],
    providers: [
        HomeService,
        NgbActiveModal
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HomeModule { }
