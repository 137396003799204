<div class="mobime-header">
    <h1 class="ubuntu-bold font-size-32 mb-0">{{ 'ABSENCE_REQUEST.FULL_DAY_ABSENCE' | translate }}</h1>
    <div class="description">
        <div class="description__title">
            <div class="ubuntu-bold font-size-16">{{ 'ABSENCE_REQUEST.SELECT_DATE' | translate }}</div>
            <div class="font-size-13 text-grey">
                {{ 'ABSENCE_REQUEST.FULLDAY_CONSULT_LEGEND' | translate }}
            </div>
        </div>
    </div>
    <div class="description__legend mt-5">
        <div class="ubuntu-bold font-size-13">{{ 'ABSENCE_REQUEST.LEGEND' | translate }}</div>
        <div class="row no-gutters mt-3">
            <div class="d-flex col-md-4 col-sm-6 mb-3">
                <div class="square me-2 bg-shadowGreen"></div>
                <div class="font-size-13 text-charcoal-grey">
                    {{ 'ABSENCE_REQUEST.AWAITING_VALIDATION' | translate }}
                </div>
            </div>
            <div class="d-flex col-md-4 col-sm-6 mb-3">
                <div class="square me-2 bg-orange"></div>
                <div class="font-size-13 text-charcoal-grey">
                    {{ 'SWAP_REQUEST.CALENDAR.UNDESIRABLE' | translate }}
                </div>
            </div>
            <div class="d-flex col-md-4 col-sm-6 mb-3">
                <div class="square me-2 bg-gallery"></div>
                <div class="font-size-13 text-charcoal-grey">
                    {{ 'ABSENCE_REQUEST.CLOSED_PERIOD' | translate }}
                </div>
            </div>
        </div>
    </div>
</div>
<div id="fullDayAbsenceCalendar" (scroll)="onScroll()" class="bg-primary-lightGrey row no-gutters planning-container mt-3 w-100">
  <div class="calendar" *ngFor="let plan of planning">
        <app-full-day-absence-calendar class="mb-5"
        [viewDate]="plan.month"
        [events]="plan.events"
        [selectedDays]="selectedDates"
        (eSelectDay)="selectDates($event)"
        [displayVacationCount]="scheduleSettings.displayVacationCount"
        [displayServiceName]="scheduleSettings.displayServiceName">
        </app-full-day-absence-calendar>
      </div>
  </div>

<div class="absence-nav d-flex justify-content-center align-items-center px-5">
  <div class="d-flex justify-content-between">
      

    <div class="d-flex me-4">
        <button (click)="openCounterBalancePopin()"
            class="btn btn-white ubuntu-bold font-size-16 d-flex justify-content-center align-items-center">
            <div class="icon icon-counter icon-20 me-3"></div>
            {{'ABSENCE_REQUEST.DAILY_BALANCE' | translate}}
        </button>
    </div>

    <div class="d-flex" style = "margin-left: 100px">
        <button (click)="goToPreviousStep()"
        class="btn btn-white ubuntu-bold font-size-16 me-4">
      
        {{'COMMON.BUTTON.PREVIOUS' | translate}}
     </button>
     <button [disabled]="!selectedDates.startDate || !selectedDates.endDate" (click)="goToNextStep()"
          class="btn btn-black ubuntu-bold font-size-16">
          {{'COMMON.BUTTON.CONTINUE' | translate}}
      </button>
    </div>
  </div>
</div>
