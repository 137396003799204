<div class="container ubuntu-regular font-size-16 width-position">
  <div class="back-link news-details-element-block">
    <app-icon-arrow [rotationDegree]="270" fillColor="#8c9aad" [size]="18"></app-icon-arrow>
    <div class="mt-1 d-inline-block">
      <a [routerLink]="'/home'">
        {{ "COMMON.BACK" | translate}}
      </a>
    </div>
  </div>
  <div *ngIf="newsService.currentDetailsItem && !newsService.isLoadingDetails; else loading">
    <h2 class="ubuntu-bold font-size-32 news-details-element-block">{{newsService.currentDetailsItem.title}}</h2>
    <div class="news-details-date-and-author">
      {{ newsService.currentDetailsItem.emissionDate | dateFormat: 'medium'}}
      <span class="font-size-16 ubuntu-bold text-grey">{{newsService.currentDetailsItem.author}}</span>
    </div>
    <!-- video or image -->
    <div class="news-details-element-block">
      <div *ngIf="trustedVideoUrl && newsService.currentDetailsItem.videoUrl; else noVideo">
        <iframe class="position-video" id="player" type="text/html" [attr.width]="videoPlaceHolderWidth" height="360"
          [attr.src]="trustedVideoUrl" frameborder="0"></iframe>
      </div>
    </div>
    <div class="ubuntu-bold font-size-16">{{newsService.currentDetailsItem.subTitle}}</div>
    <div class="news-details-element-block article-content" [innerHTML]="newsService.currentDetailsItem.content">
    </div>
  </div>
</div>
<ng-template #noVideo>
  <img class="position-img" [attr.src]="newsService.currentDetailsItem.imgUrl" />
</ng-template>
<ng-template #loading>
  <div class="text-center">{{ "COMMON.LOADING" | translate}}</div>
</ng-template>