<div class="p-5">
  <div class="modal-header">
    <div class="d-flex align-items-center">
      <h1 class="modal-title ubuntu-bold font-size-32 pe-3">{{'EXCHANGE_RULES.TITLE' | translate}}</h1>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <div class="icon icon-16 icon-close"></div>
    </button>
  </div>
  <div class="modal-body ubuntu-regular font-size-16 d-flex align-items-center">
    <ul class="ms-5">
      <li *ngIf="swapRequestSettings.service.status === settingsActivation.ACTIVE">{{'EXCHANGE_RULES.SERVICE_SWAP_ALLOWED' | translate }}</li>
      <li *ngIf="swapRequestSettings.rest.status === settingsActivation.ACTIVE">{{'EXCHANGE_RULES.DAY_OFF_SWAP_ALLOWED' | translate}}</li>
      <li *ngIf="swapRequestSettings.rest.status === settingsActivation.ACTIVE && swapRequestSettings.rest.dateRequired">
        {{'EXCHANGE_RULES.DAY_OFF_SWAP_WITHOUT_RESTITUTION_ALLOWED' | translate}}</li>
      <li *ngIf="swapRequestSettings.counterBalanceConfigEnabled && swapRequestSettings.counter !== null"
        [innerHTML]="'EXCHANGE_RULES.CODE_IN_RANGE' | translate:{CODE_COMPTEUR: swapRequestSettings.counter.code, MIN: counterLowerBound, MAX: counterUpperBound}">
      </li>
      <li *ngIf="swapRequestSettings.tteDeviationConfigEnabled"
        [innerHTML]="'EXCHANGE_RULES.GAP_IN_RANGE' | translate:{MIN: tteLowerBound, MAX: tteUpperBound}">
      </li>

      <li *ngIf="swapRequestSettings.requestNumberConfigEnabled"
        [innerHTML]="'EXCHANGE_RULES.NUMBER_CANT_EXCEED' | translate:{PERIODE_CONTRACTUELLE: contractualPeriod.label, NOMBRE_MAX: swapRequestSettings.maxRequestNumber}">
      </li>
      <li *ngIf="swapRequestSettings.noticePeriodConfigEnabled"
        [innerHTML]="'EXCHANGE_RULES.AT_LEAST_NUMBER_OF_DAYS' | translate:{NB_JOUR: swapRequestSettings.noticePeriod}">
      </li>
      <li *ngIf="swapRequestSettings.unSwappableWorksConfigEnabled">{{'EXCHANGE_RULES.TYPES_FORBIDDEN' | translate}}
      </li>
      <li>{{'EXCHANGE_RULES.MORE_QUESTIONS' | translate}}</li>
    </ul>
  </div>
</div>
