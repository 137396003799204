import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Document } from '../../../shared/models/document/document';
import { Router } from '@angular/router';


@Component({
  selector: 'app-recent-files-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class RecentFilesListComponent {

  @Input() recentFiles: Document[] = [];
  @Output() selectedFolder = new EventEmitter();
  @Output() selectedFile = new EventEmitter();
  documentType = DocumentType;

  constructor(
    private router: Router
  ) { }

  getSelectedFile(document: Document) {
    this.router.navigate(['/documents/details'], {
      queryParams: {
        path: document.path,
        size: document.size,
        name: document.name,
        creationDate: document.creationDate,
        lastModified: document.lastModified,
        type: document.type,
        description : document.description
      }
    });
  }

}
