<ng-container *ngIf="viewModel$ | async as viewModel">
  <div *ngIf="viewModel.queryDate" class="planning-details-container ubuntu-regular">
    <div class="mobime-header">
      <div *ngIf = "showBackButton" class="icon icon-24 icon-arrow-left-red mb-2 pointer" (click)="getBack()"> </div>
      <h1 class="ubuntu-bold font-size-32">{{viewModel.queryDate | dateFormat: 'long'}}</h1>
      <div class="details position-relative"
        *ngIf="viewModel.planningDetails?.departure && viewModel.planningDetails?.arrival && (viewModel.planningDetails?.period === periodType.DETAIL || viewModel.planningDetails?.period === periodType.PREVIEW)">
        <div class="icon icon-clock icon-16 position-absolute"></div>
        <div class="begin">
          <div class="d-flex align-items-stretch h-100">
            <div class="time font-size-24 ubuntu-bold ps-5 me-4 align-self-end">
              {{viewModel.planningDetails.departure.date | hourFormat:'h':'HH:mm'}}
            </div>
            <div *ngIf="viewModel.planningDetails.departure.dayBefore"
              class="flag-yesterday flag-day px-2 mb-3 me-3 font-size-12 ubuntu-regular text-nowrap align-self-end">
              {{ 'PLANNING.CARDS.CONTENT.DAY'  | translate }} - 1
            </div>
            <div class="label font-size-14 pb-2 align-self-end">
              {{viewModel.planningDetails.departure.location}}
            </div>
          </div>
        </div>
        <div class="end">
          <div class="d-flex align-items-stretch h-100">
            <div class="time font-size-24 ubuntu-bold  ps-5 me-4 align-self-end">
              {{viewModel.planningDetails.arrival.date | hourFormat:'h':'HH:mm'}}
            </div>
            <div *ngIf="viewModel.planningDetails.arrival.dayAfter"
              class="flag-tomorrow flag-day px-2 mb-3 me-3 font-size-12 ubuntu-regular text-nowrap align-self-end">
              {{ 'PLANNING.CARDS.CONTENT.DAY'  | translate }} + 1
            </div>
            <div class="label font-size-14 pb-2 align-self-end">
              {{viewModel.planningDetails.arrival.location }}
            </div>
          </div>
        </div>
        <div class="vertical-line position-absolute bg-pomegranate"></div>
        <div class="endPoint position-absolute bg-pomegranate"></div>
      </div>
    </div>

    <div *ngIf="viewModel.planningDetails?.shifts?.length > 0" class="content bg-primary-lightGrey overflow-auto" [ngClass]="{'with-bandeau': showBanner || name}">
      <div class="services mt-4 m-auto">
        <div class="font-size-16 ubuntu-bold mb-4">{{'PLANNING.DETAILS.DETAIL_OF_THE_DAY' | translate}}</div>
        <div class="service bg-white" *ngFor="let service of viewModel.planningDetails.shifts; index as indexService">

          <div class="separator d-flex align-items-center" *ngIf="indexService > 0">
            <div class="font-size-14 ubuntu-bold text-grey">
              {{viewModel.planningDetails.shifts[indexService -1].details.arrival.date| hourFormat:'h':'HH:mm'}}
              &gt;
              {{service.details.departure.date| hourFormat:'h':'HH:mm'}}
            </div>
          </div>

          <div
            [ngClass]="{'px-5 pt-5 pb-4':(viewModel.planningDetails.period === periodType.DETAIL || viewModel.planningDetails.period === periodType.PREVIEW) && service.type === shiftType.WORKING}">
            <ng-container
              *ngIf="(viewModel.planningDetails.period === periodType.DETAIL || viewModel.planningDetails.period === periodType.PREVIEW) && service.type === shiftType.WORKING; else card">

              <div class="sequence d-flex align-items-center justify-content-start">
                <div class="sequence-number font-size-11 text-center me-3">
                  {{(indexService + 1) + '/' + viewModel.planningDetails.shifts?.length}}
                </div>
                <div class="service-reference font-size-16" *ngIf="service.reference !== '-1'"
                [ngClass]="{
                  'text-pomegranate': service.isModified ==='O',
                  'text-warning': service.isModified ==='N' && service.reference !== viewModel.planningDetails.reference
                }">{{'PLANNING.DETAILS.SERVICE' | translate}}
                  {{service.reference}} <span *ngIf="service.isModified === 'O'">*</span>
                </div>
                <div class="service-reference font-size-16" *ngIf="service.reference === '-1'">
                  {{service.partLabel}}
                </div>
                <div *ngIf="viewModel.displayedMissionObservation && service.hasComments"
                  class="icon icon-chat-message icon-20 ms-3" ngbTooltip="{{'PLANNING.CARD.TOOLTIP_MISSION_OBSERVATION' | translate}}"
                  container="body" tooltipClass="tooltip">
                </div>
              </div>
              <div class="details position-relative"
                [ngClass]="{'pb-3': service.reference === '-1'}"
              >
                <div class="icon icon-clock icon-16 position-absolute"></div>
                <ng-container *ngIf="service.details.departure; let departure">
                  <div class="begin d-flex align-items-stretch">
                    <div class="time font-size-24 ubuntu-bold ps-5 me-4 align-self-end"
                      [ngClass]="service.passed ? 'text-grey' : 'text-black'">
                      {{departure.date| hourFormat:'h':'HH:mm'}}
                    </div>
                    <div
                      class="flag-yesterday flag-day px-2 mb-3 me-2 font-size-12 ubuntu-regular text-nowrap me-2 align-self-end"
                      *ngIf="departure.dayBefore">
                      {{ 'PLANNING.CARDS.CONTENT.DAY'  | translate }} - 1
                    </div>
                    <div [ngClass]="service.passed ? 'text-grey' : 'text-charcoal-grey'"
                      class="label font-size-14 pb-2 align-self-end">
                      {{departure.location}}
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="service.details.arrival; let arrival">
                  <div class="end d-flex align-items-stretch">
                    <div [ngClass]="service.passed ? 'text-grey' : 'text-black'"
                      class="time font-size-24 ubuntu-bold  ps-5 me-4 align-self-end">
                      {{arrival.date | hourFormat:'h':'HH:mm'}}
                    </div>
                    <div class="flag-tomorrow flag-day px-2 mb-3 me-2 font-size-12 ubuntu-regular align-self-end"
                      *ngIf="arrival.dayAfter">
                      {{ 'PLANNING.CARDS.CONTENT.DAY'  | translate }} + 1
                    </div>
                    <div class="label font-size-14 pb-2 align-self-end"
                      [ngClass]="service.passed ? 'text-grey' : 'text-charcoal-grey'">
                      {{arrival.location}}
                    </div>
                  </div>
                </ng-container>
                <div class="vertical-line position-absolute bg-pomegranate"></div>
                <div class="endPoint position-absolute bg-pomegranate"></div>
              </div>
              
              <div [ngClass]="{'text-pomegranate': (service.details.vehicleCodes | join :' / ')?.includes('/')}"  class="font-size-16 mt-4">

              {{ 'PLANNING.CARDS.CONTENT.CAR' | translate }} {{service.details.vehicleCodes | join :' / '}}
              </div>

              <div *ngIf="service.reference !== '-1'"
                class="displayDetails d-flex align-items-center justify-content-between position-relative"
                [ngClass]="service.passed ? 'text-grey' : 'text-black'"
              >
                <div class="display" (click)="service.displayMissions=!service.displayMissions"
                  *ngIf="viewModel.planningDetails.period === periodType.DETAIL">
                  <div class="d-flex align-items-center pointer" *ngIf="service.missions">
                    <div class="icon icon-16 me-2"
                      [ngClass]="service.displayMissions? 'icon-arrow-up' : 'icon-arrow-down'">
                    </div>
                    <div class="font-size-16 text-grey">
                      {{(service.displayMissions ? 'PLANNING.DETAILS.HIDE_THE_DETAIL' : 'PLANNING.DETAILS.DISPLAY_THE_DETAIL') | translate}}
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="service.details.charterManifestCount && service.details.charterManifestCount>0">
                  <div class="charter-manifest-count d-flex align-items-center justify-content-center position-relative">
                    <div class="font-size-16 me-1">BC</div>
                    <div class="icon icon-document icon-16"></div>
                    <div class="badge-message count position-absolute position-badge-message">
                      {{service.details.charterManifestCount}}
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="service.details.charterManifestCount === 0">
                  <div class="icon icon-vehicle icon-100 position-absolute"></div>
                </ng-container>
              </div>
              <div class="missions position-relative" *ngIf="service.missions && service.displayMissions">

                <!-- start mission block -->
                <div class="mission" *ngFor="let mission of service.missions; index as indexMission; last as last">
                  <div class="d-flex align-items-stretch">

                    <div class="left align-self-center ubuntu-bold font-size-16 text-right me-4"
                      [ngClass]="{'text-grey': indexMission > 0}">
                      {{mission.departure.date | hourFormat:'h':'HH:mm'}}
                    </div>

                    <div class="center d-flex flex-column align-items-center justify-content-center">
                      <!-- If First Mission -->
                      <ng-container *ngIf="indexMission === 0">
                        <div class="m-auto mb-0 icon icon-clock icon-16"></div>
                      </ng-container>

                      <!-- If NOT First Mission -->
                      <ng-container *ngIf="indexMission > 0">
                        <div class="bar" [ngStyle]="{'background-color': service.missions[indexMission - 1].color}"></div>
                        <div class="m-auto square" [ngStyle]="{'border-color': mission.color}"></div>
                        <div class="bar" [ngStyle]="{'background-color': mission.color}"></div>
                      </ng-container>

                    </div>
                    <div class="right align-self-center ubuntu-bold font-size-24 ms-4 d-flex justify-content-between flex-no-wrap">
                      <div>
                        {{mission.label}}
                      </div>
                      <div *ngIf="viewModel.displayedMissionObservation && mission.observation" class="icon icon-chat-message icon-20 px-3"
                        container="body" tooltipClass="tooltip" [ngbTooltip]="mission.observation">
                      </div>
                    </div>
                  </div>

                  <div class="depart d-flex align-items-stretch" *ngIf="mission.departure">
                    <div class="left align-self-center font-size-10 text-grey text-right me-4">
                      {{'PLANNING.DETAILS.DEPARTURE' | translate}}
                    </div>

                    <div class="center">
                      <div class="bar h-100 m-auto"
                        [ngClass]="{'border-radius-top-5': indexMission === 0 }"
                        [ngStyle]="{'background-color': mission.color}"
                      ></div>
                    </div>


                    <div class="right align-self-center font-size-14 ms-4">
                      {{mission.departure.location}}
                    </div>
                  </div>
                  <div class="d-flex align-items-stretch">
                    <div class="left align-self-center font-size-10 text-grey text-nowrap text-right me-4" *ngIf="service.reference !== '-1'"
                    [ngClass]="{
                      'text-pomegranate': mission.isMissionTheo ==='N' || mission.missionReference !== service.reference}">
                      {{'PLANNING.DETAILS.SERVICE' | translate}}
                      {{mission.missionReference}} <span *ngIf ="mission.isMissionTheo ==='N' || mission.missionReference !== service.reference">*</span>
                    </div>
                    <div class="left align-self-center font-size-10 text-grey text-nowrap text-right me-4" *ngIf="service.reference === '-1'">
                    </div>

                    <div class="center smooth-bar-icon">
                      <div class="bar h-100 m-auto" [ngStyle]="{'background-color': mission.color}"></div>
                      <div *ngIf="displayIconInfo(indexMission, mission, service.missions[indexMission - 1])"
                        class="icon icon-24 icon-info-yellow m-auto"
                      ></div>
                    </div>

                    <div class="right align-self-center font-size-14 ms-4 d-flex align-items-center">
                      <div class="line font-size-16 text-white text-center w-auto px-2 me-4" *ngIf="mission.lineNumber">
                        {{('PLANNING.DETAILS.LINE' | translate)}} {{mission.lineNumber}}
                      </div>
                      <ng-container *ngIf="mission.drivingMission">
                        <div class="icon icon-16 icon-vehicle-grey me-2"></div>
                        <div class="vehicule" *ngIf="viewModel.scheduleSettings.assignedVehicle" [ngClass]="service.missions[indexMission - 1]?.vehicle?.vehicleCode && service.missions[indexMission - 1]?.vehicle?.vehicleCode !== mission?.vehicle?.vehicleCode  ? 'text-pomegranate' : 'text-black'">
                          {{mission.vehicle?.vehicleCode}}
                          <span *ngIf="service.missions[indexMission - 1]?.vehicle?.vehicleCode && service.missions[indexMission - 1]?.vehicle?.vehicleCode !== mission?.vehicle?.vehicleCode">*</span>
                        </div>
                      </ng-container>
                    </div>

                  </div>

                  <div class="d-flex align-items-stretch">

                    <div class="left align-self-center font-size-10 text-grey text-right me-4 mb-4">
                      &nbsp;
                    </div>

                    <div class="center">
                      <div class="bar h-100 m-auto" [ngStyle]="{'background-color': mission.color}">
                      </div>
                    </div>

                    <div class="right align-self-center font-size-12 ms-4  mt-4">
                      <div class="pb-1" *ngIf="viewModel.scheduleSettings.operationCode && mission.operationCode"> {{'PLANNING.DETAILS.OPERATION_CODE' | translate}} {{mission.operationCode}}</div>
                      <div class="pb-1" *ngIf="viewModel.scheduleSettings.charterNumber && mission.charterNumber"> {{'PLANNING.DETAILS.CHARTER_NUMBER' | translate}} {{mission.charterNumber}}</div>
                      <div *ngIf="viewModel.scheduleSettings.missionLabel && mission.missionLabel"> {{'PLANNING.DETAILS.MISSION_LABEL' | translate}} {{mission.missionLabel}}</div>
                    </div>
                  </div>

                  <div *ngIf="mission?.stops?.length && viewModel.scheduleSettings.passingTimes" class="stops d-flex align-items-stretch">
                    <div class="left align-self-center font-size-10 text-grey text-right me-4">
                      &nbsp;
                    </div>

                    <div class="center smooth-bar-icon">
                      <div class="bar h-100 m-auto" [ngStyle]="{'background-color': mission.color}"></div>
                    </div>

                    <div class="right align-self-center font-size-14 ms-4 pt-4">
                      <div class="d-flex align-items-center pointer"
                        (click)="mission.displayStops=!mission.displayStops">
                        <div class="icon icon-16 me-2"
                          [ngClass]="mission.displayStops ? 'icon-arrow-up' : 'icon-arrow-down'">
                        </div>
                        <div class="font-size-16 text-grey">
                          {{ mission.stops.length }}
                          {{(mission.stops.length > 1 ? 'PLANNING.DETAILS.STOPS' : 'PLANNING.DETAILS.STOP') | translate}}
                        </div>
                      </div>
                      <ul *ngIf="mission.displayStops" class="mt-2">
                        <li *ngFor="let stop of mission.stops" class="d-flex ps-3">
                          <div class="text-grey font-size-14 me-4">
                            {{stop.arrival.date | hourFormat:'h':'HH:mm'}}
                          </div>
                          <div class="text-grey font-size-14 ubuntu-bold">
                            {{stop.arrival.location}}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="arrival d-flex align-items-stretch" *ngIf="mission.arrival">
                    <div class="left align-self-center font-size-10 text-grey text-right me-4">
                      {{'PLANNING.DETAILS.ARRIVAL' | translate}}</div>
                    <div class="center">
                      <div class="bar h-100 m-auto" [ngStyle]="{'background-color': mission.color}"></div>
                    </div>
                    <div class="right align-self-center font-size-14 ms-4">{{mission.arrival.location}}
                    </div>
                  </div>

                  <div class="d-flex align-items-stretch pb-3" *ngIf="indexMission === service.missions.length - 1">
                    <div class="left align-self-center ubuntu-bold font-size-16 text-right me-4">
                      {{mission.arrival.date | hourFormat:'h':'HH:mm'}}
                    </div>
                    <div class="center d-flex flex-column align-items-center justify-content-center">
                      <div class="endPoint bg-casper m-auto" [ngStyle]="{'background-color': service.missions[service.missions.length - 1].color}">

                        <div class="bg-casper" [ngStyle]="{'background-color': service.missions[service.missions.length - 1].color}"></div>
                      </div>
                    </div>
                    <div class="right align-self-center ubuntu-bold font-size-24 ms-4">
                    </div>
                  </div>

                  <!-- If indexMission < service.missions.length - 1 -->
                  <div class="mission-separator d-flex align-items-stretch"
                    *ngIf="indexMission < service.missions.length - 1">
                    <div class="left align-self-center ubuntu-bold font-size-16 text-right me-4">
                    </div>
                    <div class="center">
                      <div class="bar h-100 m-auto" [ngStyle]="{'background-color': mission.color}"></div>
                    </div>
                    <div class="right align-self-center ubuntu-bold font-size-24 ms-4">
                    </div>
                  </div>

                </div>
                <!-- End Mission block -->

              </div>
            </ng-container>
            <ng-template #card>
              <app-calender-card
                [shiftData]="service"
                [period]="viewModel.planningDetails.period"
                [modeDetails]="true"
                [showHeader]="indexService > 0 ? viewModel.planningDetails.shifts[indexService - 1].type !== service.type : true"
              >
              </app-calender-card>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showBanner" class="bandeau text-white font-size-16">
      {{'PLANNING.MY_SERVICE' | translate}}
    </div>
    <div *ngIf="name" class="bandeau text-white font-size-16">
        {{'PLANNING.SERVICE_OF' | translate}} <b>{{name}}</b>
    </div>
  </div>
</ng-container>