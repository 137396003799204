import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss']
})
export class NotificationModalComponent {

  @Input() title: string;
  @Input() message: string;
  @Input() icon: string;

  constructor(public activeModal: NgbActiveModal) { }

}
