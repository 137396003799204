import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join'
})
export class JoinWordsPipe implements PipeTransform {
  transform(words: Array<string>, sep: string): string {
    if (words) {
      for (let index = words.length - 1; index > 0; index--) {
        if (index <= words.length && words[index] === words[index - 1]) {
          words.splice(index, 1);
        }
      }
      return words.length <= 3 ? words.join(sep) : words.slice(0, 3).join(sep) + `${sep}...`;
    }
  }
}
