import { Injectable } from '@angular/core';
import { FullCustomDimensionsSet, PartialCustomDimensionsSet } from '../../types/customDimensions';
import { DataLayerCustomDimensions } from '../../enums/GTM/dataLayer.enum';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class DataLayerCustomDimensionsService {

  currentSet: FullCustomDimensionsSet;
  constantDimensions: PartialCustomDimensionsSet;

  constructor(
    private deviceService: DeviceDetectorService
  ) {
    this.generateEmptyDimensionsSet();
    this.constantDimensions = {
      [DataLayerCustomDimensions.Device_Type] : this.detectDeviceType,
      [DataLayerCustomDimensions.App] : 'Web',
      [DataLayerCustomDimensions.Browser] : this.deviceService.browser,
      [DataLayerCustomDimensions.Browser_version] : this.deviceService.browser_version,
      [DataLayerCustomDimensions.OS] : this.deviceService.os,
      [DataLayerCustomDimensions.OS_version] : this.deviceService.os_version,
    };
  }

  setDimensions(someDimensions: PartialCustomDimensionsSet) {
      Object.keys(DataLayerCustomDimensions).map(key => DataLayerCustomDimensions[key])
      .forEach(key => {
        this.currentSet[key] = someDimensions[key] || this.constantDimensions[key];
      });
  }

  dataLayerPush() {
    (window as any).dataLayer.push(this.currentSet);
  }

  generateEmptyDimensionsSet() {
    this.currentSet = {
      [DataLayerCustomDimensions.Language_DB] : undefined,
      [DataLayerCustomDimensions.Database_FDS] : undefined,
      [DataLayerCustomDimensions.WorkingGroup] : undefined,
      [DataLayerCustomDimensions.Area] : undefined,
      [DataLayerCustomDimensions.Staff_Category] : undefined,
      [DataLayerCustomDimensions.Affectation_Category] : undefined,
      [DataLayerCustomDimensions.App] : undefined,
      [DataLayerCustomDimensions.Device_Type] : undefined,
      [DataLayerCustomDimensions.Browser] : undefined,
      [DataLayerCustomDimensions.Browser_version] : undefined,
      [DataLayerCustomDimensions.OS] : undefined,
      [DataLayerCustomDimensions.Authentication_kind] : undefined,
      [DataLayerCustomDimensions.Login] : undefined,
      [DataLayerCustomDimensions.Screen_resolution] : undefined,
      [DataLayerCustomDimensions.OS_version] : undefined,
      [DataLayerCustomDimensions.Site_time] : undefined,
      [DataLayerCustomDimensions.Contract_type] : undefined,
      [DataLayerCustomDimensions.Roster_grid] : undefined,
      [DataLayerCustomDimensions.Main_habilitation] : undefined,
    };
  }

  get detectDeviceType() {
    let deviceType: 'Mobile' | 'Tablet' | 'Desktop';

    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktop = this.deviceService.isDesktop();

    if (isMobile) {
       deviceType = 'Mobile';
    } else if (isTablet) {
       deviceType = 'Tablet';
    } else if (isDesktop) {
       deviceType = 'Desktop';
    } else {
       deviceType = undefined;
    }
    return deviceType;
  }

}
