<div class="d-flex my-4 align-items-center">
  <span class="d-flex font-size-24 ubuntu-bold text-black me-2">{{ viewDate | dateFormat:'MMMM' }}</span>
  <span class="d-flex font-size-16 ubuntu-bold text-black">{{ viewDate | dateFormat:'YYYY' }}</span>
</div>

<mwl-calendar-month-view [viewDate]="viewDate" [events]="events" [locale]="locale"
  [weekStartsOn]="userService.site.firstDayOfWeek"
  [headerTemplate]="headerTemplate"
  [cellTemplate]="partialAbsenceCellTemplate">
</mwl-calendar-month-view>

<ng-template #headerTemplate let-days="days" let-locale="locale">
  <div class="cal-cell-row cal-header text-white">
      <div class="cal-cell cal-header" *ngFor="let day of days">
        <div class="day-label text-uppercase">
            {{ day.date.toLocaleString(userService.site.language.code, {weekday: 'short'})  }}
        </div>
      </div>
  </div>
</ng-template>

<ng-template #partialAbsenceCellTemplate let-day="day" let-locale="locale">
  <!-- change !day.events[0].meta.valid -->
  <div class="d-flex flex-column justify-content-center align-items-center cal-cell-top" (click)="selectDay(day)"
    [ngClass]="{
              'bg-gallery': !day.events || !day.events.length || !day.events[0]?.meta || day?.events[0]?.meta?.absenceStatus === eventStatus.CLOSE,
              'bg-white': day?.events[0]?.meta?.absenceStatus === eventStatus.OPEN,
              'bg-green': day?.events[0]?.meta?.absenceStatus === eventStatus.WAITING,
              'selected': day === daySelected
            }">
    <!-- show day number -->
    <span class="font-size-20 text-black ubuntu-bold" [ngClass]="{
              'not-valid' : !day.events[0]?.meta.valid,
              'text-black': day?.events[0]?.meta?.absenceStatus === eventStatus.OPEN || day?.events[0]?.meta?.absenceStatus === eventStatus.CLOSE,
              'text-white': day?.events[0]?.meta?.absenceStatus === eventStatus.WAITING || day === daySelected,
              'recomposed-shift' : day?.events[0]?.meta?.recomposedShift
            }">
      {{ day.date | calendarDate:'monthViewDayNumber':locale }}
    </span>
    <!-- show day number -->
    <div class="shifts-container d-flex flex-column justify-content-center align-items-center"
      *ngIf="day.events && day.events.length && day.events[0].meta">
      <ng-container *ngIf="displayServiceName">
        <ng-container *ngIf="day?.events[0]?.meta?.period === periodType.DETAIL || day?.events[0]?.meta?.period === periodType.PREVIEW">
          <div *ngIf="day?.events[0]?.meta?.type === dayType.WORKING"
            class="absenceStatus font-size-10 ubuntu-bold text-uppercase me-1 text-black">
            {{ (day.events[0].meta.reference?.length>7)? (day.events[0].meta.reference |
            slice:0:6)+'..':(day.events[0].meta.reference) }}
          </div>
          <div *ngIf="day?.events[0]?.meta?.type === dayType.UNAVAILABILITY ||
                      day?.events[0]?.meta?.type === dayType.AVAILABILITY ||
                      day?.events[0]?.meta?.type === dayType.REST" 
            class="absenceStatus font-size-10 ubuntu-bold text-uppercase me-1 text-black">
            {{ (day.events[0].meta.code?.length>7)? (day.events[0].meta.code | slice:0:6)+'..':(day.events[0].meta.code) }}
          </div>
        </ng-container>
        <ng-container *ngIf="day?.events[0]?.meta?.period === periodType.POSITION">
          <div class="absenceStatus font-size-10 ubuntu-bold text-uppercase me-1 text-black">
            <span *ngIf="day?.events[0]?.meta?.type === dayType.REST &&  !day?.events[0]?.meta?.workingTime" >{{'PLANNING.CODE.REST' | translate}}</span>
            <span *ngIf="day?.events[0]?.meta?.type === dayType.AVAILABILITY && !day?.events[0]?.meta?.workingTime" >{{'PLANNING.CODE.AVAILABILITY'  | translate}}</span>
            <span *ngIf="day?.events[0]?.meta?.type === dayType.UNAVAILABILITY && !day?.events[0]?.meta?.workingTime" >{{'PLANNING.CODE.UNAVAILABILITY'  | translate}}</span>
            <span *ngIf="day?.events[0]?.meta?.type === dayType.WORKING" >{{'PLANNING.CODE.WORKING'  | translate}}</span> 
          </div>
        </ng-container>
      </ng-container>
      <!-- display code or point -->
      <ng-container *ngIf="displayVacationCount || day?.events[0]?.meta?.period === 'EMPTY'">
        <div class="d-flex flex-row">
          <ng-container *ngFor="let shift of day.events[0].meta.shifts; index as indexShift">
            <ng-container *ngIf="indexShift < 3 && day.events[0].meta.numberOfPoints > 0">
              <div class="point" [ngClass]="{
                      'not-valid' : !day.events[0]?.meta.valid,
                      'bg-white': day?.events[0]?.meta?.absenceStatus === eventStatus.WAITING || day === daySelected
                    }"></div>
            </ng-container>
            <ng-container *ngIf="indexShift === 3 && displayVacationCount">
              <div class="plus" [ngClass]="{
                      'not-valid' : !day.events[0]?.meta.valid,
                      'bg-white': day?.events[0]?.meta?.absenceStatus === eventStatus.WAITING || day === daySelected
                    }"></div>
            </ng-container>
          </ng-container>
        </div>
        <ng-container *ngIf="day.events[0].meta.numberOfPoints === -1 && !displayServiceName">
          <div class="absenceStatus font-size-10 ubuntu-bold text-uppercase me-1 text-black" [ngClass]="{
                      'not-valid' : !day.events[0].meta.valid,
                      'text-white': day?.events[0]?.meta?.absenceStatus === eventStatus.WAITING || day === daySelected
                    }">
            {{day.events[0].meta.code}}
          </div>
        </ng-container>
      </ng-container>

     
      <!-- display code or point  -->
    </div>
  </div>
</ng-template>
