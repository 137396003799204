import {Component, DestroyRef, OnInit, ViewEncapsulation, inject} from '@angular/core';
import {NewsService} from '../../services/news.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-news-details',
  templateUrl: './news-details.component.html',
  styleUrls: ['./news-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewsDetailsComponent implements OnInit {
  /**
   *
   */
  videoPlaceHolderWidth;
  /**
   *
   */
  trustedVideoUrl;
  private destroyRef = inject(DestroyRef);

  constructor(public newsService: NewsService,
              private router: Router,
              private domSanitizer: DomSanitizer,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this._getIdAndFetchDetails();
  }

  /**
   * This function will subscribe to activate route
   * params and then fetch item details
   */
  private _getIdAndFetchDetails() {
    this.activatedRoute.params
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(params => {
      const id = params.id;
      if (!id) {
        return this.router.navigate([404]);
      }
      this.newsService.fetchItem(id)
        .then(
          () => {
            // ensure not null
            this._handleVideoOrImage();
          },
          () => {
          }
        );
    });
  }

  /**
   * This function will set a placeholder image
   * if the article item does'nt have a main image
   * @see SurveyQuestionModel.imgUrl
   */
  private _handleVideoOrImage() {
    this._setVideoPlaceHolderWidth();
    const details = this.newsService.currentDetailsItem;
    let videoUrl = details && details.videoUrl;
    if (videoUrl) {
      // https://stackoverflow.com/questions/25661182/embed-youtube-video-refused-to-display-in-a-frame-because-it-set-x-frame-opti

      videoUrl = this._getYoutubeVideoUrl(videoUrl);
      this.trustedVideoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${videoUrl}`);
    } else {
      if (!details.imgUrl) {
        details.imgUrl = '/assets/images/news/no-image-article.png';
      }
    }
  }

  /**
   * https://stackoverflow.com/questions/21607808/convert-a-youtube-video-url-to-embed-code/21607897
   */
  private _getYoutubeVideoUrl(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
  }

  /**
   * Set the width of the video regarding available width
   */
  private _setVideoPlaceHolderWidth() {
    this.videoPlaceHolderWidth = '100%';
  }
}
