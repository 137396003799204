import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SurveysService } from './services/surveys.service';
import { SurveysListComponent } from './components/surveys-list/surveys-list.component';
import { SurveyViewComponent } from './components/survey-view/survey-view.component';
import { SurveyQuestionComponent } from './components/survey-question/survey-question.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forRoot(),
    RouterModule,
    FormsModule
  ],
  declarations: [
    SurveysListComponent,
    SurveyViewComponent,
    SurveyQuestionComponent
  ],
  exports: [
    SurveysListComponent,
    SurveyViewComponent
  ],
  providers: [SurveysService]
})
export class SurveysModule {
}
