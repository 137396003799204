import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ellipsis'
})
export class EllipsisPipe implements PipeTransform {

  transform(value: string, args?: number): any {
    if (value.length > 15) {
        if (args) {
            return value.slice(0, args) + '...';
        }
        return value.slice(0, 12) + '...';
      } else {
          return value;
      }
    }
}
