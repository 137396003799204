import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResetPasswordComponent } from './reset-password.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MobiPrintErrorsComponent } from '@shared/components/mobi-print-errors/mobi-print-errors.component';
import { SharedModule } from '@shared/shared.module';
@NgModule({
  declarations: [
    ResetPasswordComponent,
    MobiPrintErrorsComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    SharedModule
  ],
  providers: [],
})
export class ResetPasswordModule {
}
