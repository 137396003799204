import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../user.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SettingsActivation } from '../../enums/setting/typology-settings/settingsActivation.enum';

@Injectable()
export class SwapServiceAccess  {

  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  canActivate(): Observable<boolean> {
    return this.userService.typologySetting.pipe(
      map(typoSetting => {
        const swapServiceEnabled = typoSetting.swapRequestSettings.service.status === SettingsActivation.ACTIVE;
        if (!swapServiceEnabled) {
          this.router.navigate(['/swap/new']);
          return false;
        }
        return true;
      })
    );
  }
}
