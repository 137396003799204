export enum DataLayerCustomDimensions {
    Language_DB = 'database_language',
    Database_FDS= 'database_fds',
    WorkingGroup = 'working_group',
    Area = 'area',
    Staff_Category = 'staff_category',
    Affectation_Category = 'affectation_category',
    Device_Type = 'deviceType',
    App = 'App',
    Browser = 'Browser',
    Browser_version = 'Browser_version',
    OS = 'OS',
    OS_version = 'OS_version',
    Login = 'login',
    Site_time = 'site_time',
    Screen_resolution = 'screen_resolution',
    Authentication_kind = 'authentication_kind',
    Contract_type = 'contract_type',
    Roster_grid = 'roster_grid',
    Main_habilitation = 'main_habilitation'
}


