<div class="mobime-header">
    <h1 class="ubuntu-bold font-size-32">{{title | translate}}</h1>
</div>
<div class="swap-comment">
    <h2 class="ubuntu-bold font-size-20 my-4"> {{i18nPrefix + 'ADD_COMMENT' | translate}}</h2>
    <textarea class="bg-gallery br-4 border-0 font-size-16 p-4" 
        (ngModelChange)="control($event)"
        [(ngModel)]="comment"
        maxlength="2000" 
        [placeholder]="i18nPrefix + 'WRITE_COMMENT' | translate">{{comment}}</textarea>
</div>