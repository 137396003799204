<div class="m-4 p-4">
  <div class="modal-header d-flex justify-content-between">
    <h2 class="ubuntu-bold">{{'COUNTERS.TITLE' | translate}}</h2>
    <div class="icon-close icon-16 pointer" (click)="activeModal.dismiss('Cross click')"></div>
  </div>
  <div class="body bg-gallery p-4 my-5">
    <div class="counters__list-container">
      <ul class="counters__list">
        <li *ngFor="let counter of counters" class="counters__list-item mb-3 d-flex justify-content-between align-items-center">
        <h2 class="ubuntu-bold font-size-16">{{counter.code}} - ({{ counter.label | titlecase }})</h2>
          <div class="ubuntu-bold font-size-24 text-grey">
            <ng-container *ngIf="counter.unit === format.DAY">
              {{ counter.balance }}{{'COUNTERS.DAY' | translate}}
            </ng-container>
            <ng-container *ngIf="counter.unit === format.HOUR">
              <span>{{ counter.balance | hoursMinutesConverter }}</span>
            </ng-container>

            <ng-container *ngIf="counter.unit !== format.DAY && counter.unit !== format.HOUR">
              {{ counter.balance }}
            </ng-container>
          </div>
        </li>
      </ul>

      <p *ngIf="!counters" class="font-size-16">
        {{ 'ABSENCE_REQUEST.HISTORY.NO_RESULT_FOUND' | translate }}
      </p>
    </div>
  </div>
</div>
