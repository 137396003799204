import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '../../services/user.service';

@Pipe({
  name: 'hourHundredthsConverter'
})
export class HourHundredthsConverterPipe implements PipeTransform {
  constructor(private userService: UserService) {
  }

  transform(value: number): any {
    return value === null ? null :
    (value / 60).toLocaleString(this.userService.site.language.code, {minimumFractionDigits: 0, maximumFractionDigits: 2});
  }
}
