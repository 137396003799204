<div class="app-carousel ps-3 pe-3" *ngIf="calenderData?.length">
  <div class="app-carousel-calender">
    <app-schedule-swiper>
      <ng-container *ngFor="let item of calenderData; index as itemIndex;">
        <div class="calender-container d-flex flex-column align-items-center text-center text-white me-4">
          <div class="weekday ubuntu-bold text-uppercase" [ngClass]="{'disabled': !item.valid && !item.workingTime}">
            <span>{{ item.date | dateFormat : 'ddd'}}</span>
          </div>
          <div class="day ubuntu-bold pointer" [ngClass]="{'activeDay' : itemIndex === selectedDayIndex }"
            (click)="selectDay(itemIndex)">
            <span
              [ngClass]="{'disabled': !item.valid && !item.workingTime , 'recomposed-shift': item.recomposedShift}">{{
              item.date | dateFormat : 'D' }}</span>
          </div>

          <div class="shifts">
            <div class="d-flex justify-content-center">
              <app-planning-status [numberOfPoints]="item.numberOfPoints" [code]="item.code"
                [displayVacationCount]="scheduleSettings?.displayVacationCount"
                [displayServiceName]="scheduleSettings?.displayServiceName" [serviceName]="item?.reference"
                [period]="item?.period" [type]="item?.type" [indexItem]="itemIndex" [checkedItem]="selectedDayIndex"
                [checkedShift]="selectedShift" [workingTime]="item.workingTime" (actions)="handleActions($event)">
              </app-planning-status>
            </div>
          </div>
        </div>
      </ng-container>
    </app-schedule-swiper>
  </div>
  <ng-container *ngIf="calenderData && refreshSwiper">
    <app-planning-days-carousel #cardCarousel [calendarData]="calenderData" [checkedItem]="selectedDayIndex" [checkedShift]="selectedShift"
      (actions)="handleActions($event)" (selectItem)="selectShift($event)">
    </app-planning-days-carousel>
  </ng-container>

</div>