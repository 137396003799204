<div class="m-4 p-4">
  <div class="header d-flex justify-content-between">
    <div [class]="'icon icon-56 ' + icon"></div>
    <div class="icon-close icon-16 pointer" (click)="activeModal.dismiss('Cross click')"></div>
  </div>
  <div class="body my-5">
    <span class="ubuntu-bold font-size-24">{{ title | translate }}</span>
    <p class="ubuntu-regular font-size-16 text-grey mt-3">
      {{ message | translate }}
    </p>
  </div>

  <div class=" d-flex justify-content-between">
      <button *ngIf="btnDeny"
        type="button" 
        class="btn btn-white ubuntu-bold font-size-16" 
        (click)="activeModal.dismiss()">
        {{(btnDeny | translate)}}
      </button>
      <button type="button" 
        class="btn btn-black ubuntu-bold font-size-16"
        [ngClass]="{'w-100': !btnDeny}"
        (click)="activeModal.close()">
        {{(btnAccept | translate)}}
      </button>
  </div>

</div>
