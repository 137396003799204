

import { parsePhoneNumberFromString, CountryCode } from 'libphonenumber-js';

export const phoneNumberInputAllowedCharacters = ['-', '.', ' ', '+'];

export function validateInput(event: KeyboardEvent): boolean {
  return phoneNumberInputAllowedCharacters.includes(event.key) || !isNaN(Number(event.key));
}

export function validatePhoneNumber(phone: string, countryCode: CountryCode): any {
  const phoneNumber  = parsePhoneNumberFromString(`${phone}`, countryCode);
  return {
    URI: phoneNumber ? phoneNumber.getURI() : null,
    phoneNumber: phoneNumber ? phoneNumber.formatInternational() : null
  };
}
