<div class="px-3 d-flex align-items-center justify-content-center" *ngIf="show" [ngClass]="['snackbar-wrap', type]">
  <div class="h-100 w-100 d-flex justify-content-between pb-2">
    <div class="d-flex align-items-center ps-4">
      <div class="h-100" class="icon icon-24" [ngClass]="{
          'icon-checked': type === 'success',
          'icon-cross': type === 'error',
          'icon-info': type === 'info'
        }"></div>
      <p class="snackbar-text ubuntu-regular font-size-14 ps-3">
        {{ message | translate }}
      </p>
    </div>
    <div class="icon icon-12 icon-leave pointer" (click)="hideSnackBar()"></div>
  </div>
</div>