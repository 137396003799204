import {Injectable} from '@angular/core';
import {BaseApiService} from './base.api.service';

@Injectable({
  providedIn: 'root'
})
export class NewsApiService extends BaseApiService {
  /**
   *
   */
  list() {
    return this.get('employees/news');
  }

  /**
   *
   */
  read(id: number) {
    return this.get(`employees/news/${id}`);
  }
}
