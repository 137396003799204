import { Component, OnInit, inject } from '@angular/core';
import { UserService } from '@shared/services/user.service';
import { AUTH_URL } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  private readonly userService = inject(UserService);
  protected readonly i18prefix = 'LOGIN.';
  protected readonly authUrl = AUTH_URL.AUTHORIZE;
  protected readonly forgottenPasswordUrl = '/account';

  ngOnInit(): void {
    this.userService.initDefaultLang();
  }
}
