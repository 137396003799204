import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PrepayComponent } from './prepay.component';
import { PrepayService } from './prepay.service';
import { PrepayDetailsComponent } from './prepay-details/prepay-details.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PrepayBalancesComponent } from './prepay-details/prepay-balances/prepay-balances.component';
import { PrepayCountersComponent } from './prepay-details/prepay-counters/prepay-counters.component';
import { PrepayDescriptionComponent } from './prepay-description/prepay-description.component';

@NgModule({
  imports: [CommonModule, TranslateModule.forRoot(), SharedModule, FormsModule, RouterModule],
  declarations: [PrepayComponent, PrepayDetailsComponent, PrepayBalancesComponent, PrepayCountersComponent, PrepayDescriptionComponent],
  providers: [PrepayService]
})
export class PrepayModule { }


