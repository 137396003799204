import { Component, DestroyRef, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { TrainingListService } from './training-list.service';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TrainingsFoldersListComponent } from './trainingsList/list.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-tranings-list',
  templateUrl: './trainings.component.html',
  styleUrls: ['./trainings.component.scss']
})
export class TrainingsComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  isDisplay = true;
  private destroyRef = inject(DestroyRef);

  @ViewChild(TrainingsFoldersListComponent, { static: true }) private listDocument: TrainingsFoldersListComponent;

  constructor(
    public service: TrainingListService,
    private router: Router
  ) { }

  ngOnInit() {
    if (this.service.lastPath === this.service.pathFirstLevel) {
      this.service.getDocuments(this.service.pathFirstLevel, true);
    }
    this.beforeNavigate();
  }

  beforeNavigate() {
    this.subscription = this.router.events
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe((navigationStart) => {
      if (navigationStart instanceof NavigationStart) {
        if (!navigationStart.url.startsWith('/trainings')) {
          this.service.resetDocuments();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  changeDisplay() {
    this.isDisplay = !this.isDisplay;
  }

  onBackClick(): void {
    this.service.getBack(this.service.lastPath);
    this.listDocument.loadDocuments();
  }

  onClearSearch(): void {
    this.service.clearSearch();
    this.listDocument.loadDocuments();
  }

  onSelectFolder(event): void {
    this.service.getContentOfFolder(event);
    this.listDocument.loadDocuments();
  }

  onSearch() {
    this.service.search();
    this.listDocument.loadDocuments();
  }
}
