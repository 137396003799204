import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { SnackbarService } from './snackbar.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit {
  show = false;
  message: string;
  type: string;
  private destroyRef = inject(DestroyRef);

  constructor(private snackbarService: SnackbarService) { }

  ngOnInit() {
    this.snackbarService.snackbarState
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(
      state => {
        if (state.type) {
          this.type = state.type;
        } else {
          this.type = 'success';
        }
        this.message = state.message;
        this.show = state.show;
        setTimeout(() => {
          this.show = false;
        }, 3000);
      }
    );
  }

  hideSnackBar() {
    this.show = false;
  }
}
