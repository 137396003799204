import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_URLS } from 'src/environments/api-urls';
import { MetaData, MetaTableRequest } from '@shared/interfaces/metadata.model';
import { ProviderSettingModel, SmsMessage } from '@shared/models/providers/provider.model';
import { map } from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  constructor(
    private http: HttpClient,
  ) { }

  getTranslationInLanguage(key: string, language: string): Observable<any> {
    const translationFilePath = `assets/i18n/${language}.json`;

    return this.http.get<{ [key: string]: any }>(translationFilePath).pipe(
      map(translations => {
        const keys = key.split('.');
        let value = translations;
        for (const k of keys) {
          value = value[k];
        }
        return value;
      })
    );
  }
}
