import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import * as moment from 'moment';
import { Planning } from 'src/app/shared/models/planning/planning';
import { ScheduleSettings } from 'src/app/shared/models/settings/settings';
import { PlanningService } from '../planning.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs';

@Component({
  selector: 'app-plannig-year',
  templateUrl: './plannig-year.component.html',
  styleUrls: ['./plannig-year.component.scss']
})
export class PlannigYearComponent implements OnInit {
  planning: Planning[] = [];
  previousMonths = 3;
  @Input() scheduleSettings: ScheduleSettings;
  private planningService = inject(PlanningService);
  private destroyRef = inject(DestroyRef);


  ngOnInit(): void {
      this.getPlanning();
  }

  getPlanning() {
    const startDate = moment()
      .subtract(this.previousMonths, 'months')
      .startOf('month');
    const endDate = moment()
      .add(11, 'months')
      .endOf('month');
    this.planningService.getPlanning(startDate, endDate)
      .pipe(
        tap((planning) => this.planning = planning),
        takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }
}
