<div class="swap-service">
    <app-swap-request-calendar *ngIf="swapStepService.step === 1"></app-swap-request-calendar>
    <app-swap-shift-search *ngIf="swapStepService.step === 2" ></app-swap-shift-search>
    <app-planning-details *ngIf="swapStepService.step === 3"
      [date]="swapStepService.selectedDate"
      [name]="swapStepService.swapSearch.colleagueShift.firstName + ' ' + swapStepService.swapSearch.colleagueShift.lastName"
      [adminNumber]="swapStepService.swapSearch.colleagueShift.adminNumber">
    </app-planning-details>
    <app-swap-comment *ngIf="swapStepService.step === 4"
      (inputComment)="updateComment($event)"
      [title] = "'SWAP_REQUEST.CREATE.SERVICE'"
      [comment]="swapStepService.comment">
    </app-swap-comment>

    <app-swap-summary *ngIf="swapStepService.step === 5"
      [userShift]="swapStepService.userShift"
      [colleagueShift]="swapStepService.swapSearch.colleagueShift"
      [comment]="swapStepService.comment"
      [askedDate]="swapStepService.selectedDate"
      [exchangedDate]="swapStepService.selectedDate"
    ></app-swap-summary>
</div>

<div class="swap-nav d-flex justify-content-center align-items-center px-5">
  <div class="swap-nav__container d-flex justify-content-between">
    <button class="btn btn-white ubuntu-bold font-size-16"
      (click)="swapStepService.goToPreviousStep()">
      {{i18nPrefixCOMMON + 'PREVIOUS' | translate}}
    </button>
    <button class="btn btn-black ubuntu-bold font-size-16"
      (click)="goToNextStep()"
      [disabled]="!swapStepService.continueStep">
      {{ (swapStepService.step < 6 ? i18nPrefixCOMMON + 'CONTINUE' : 'SWAP_REQUEST.SUMMARY.CONFIRM') | translate}}
    </button>
  </div>
</div>
