import { swapFilterType } from 'src/app/shared/enums/setting/swap-request-settings/swapFilter.enum';
import { SettingsActivation } from 'src/app/shared/enums/setting/typology-settings/settingsActivation.enum';

export interface CreditDebitCounter {
    code: string;
    lowerBound: number;
    upperBound: number;
}

export interface SwapRequestSettings {
    service: {
      status: SettingsActivation;
    };
    rest: {
      swappableRestType: string[];
      dateRequired: boolean;
      status: SettingsActivation;
    };
    swapFilter: Array<swapFilterType>;
    contractualPeriod: string;
    counterBalanceConfigEnabled: boolean;
    counter: CreditDebitCounter;
    tteDeviationConfigEnabled: boolean;
    tteLowerBound: number;
    tteUpperBound: number;
    requestNumberConfigEnabled: boolean;
    maxRequestNumber: number;
    noticePeriodConfigEnabled: boolean;
    noticePeriod: number;
    unSwappableWorksConfigEnabled: boolean;
    unSwappableWorks: string[];
    status: SettingsActivation;
}

export function createDefaultSwapRequestSettings(): SwapRequestSettings {
  return {
    service: { status: SettingsActivation.INACTIVE },
    rest: {
      swappableRestType: [],
      dateRequired: false,
      status: SettingsActivation.INACTIVE
    },
    swapFilter: [],
    contractualPeriod: '',
    counterBalanceConfigEnabled: false,
    counter: {} as CreditDebitCounter,
    tteDeviationConfigEnabled: false,
    tteLowerBound: 0,
    tteUpperBound: 0,
    requestNumberConfigEnabled: false,
    maxRequestNumber: 0,
    noticePeriodConfigEnabled: false,
    noticePeriod: 0,
    unSwappableWorksConfigEnabled: false,
    unSwappableWorks: [],
    status: SettingsActivation.INACTIVE,
  };
}
