<div class="document-container ubuntu-regular">
  <div class="mobime-header header">
    <h1 class="title ubuntu-bold font-size-32 mb-0"> {{'TRAINING.TITLE' | translate}}</h1>
    <div *ngIf="!service.isSearchMode" class="search d-flex">
      <div class="input-group d-flex">
        <div class="search-bar position-relative">
          <input class="font-size-16 w-100 h-100" type="text" #messageSearchInput
            [placeholder]="'COMMON.SEARCH' | translate" id="searchMessageInput" [(ngModel)]="service.searchTerm"
            (keyup.enter)="onSearch()">
          <div class="icon icon-16 icon-search position-absolute"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="content p-5 h-auto">
    <ng-container *ngIf="!service.navigate && !service.isSearchMode">
      <div class="documentGroup ms-5">
        <div class="d-flex align-items-center pointer" (click)="changeDisplay()">
          <div id="titleRecents" class="period ubuntu-bold font-size-24 mb-3">
            {{'DOCUMENT.RECENT' | translate}}
          </div>
          <div class="icon icon-16 ms-3 mb-2" [ngClass]="isDisplay ? 'icon-arrow-up-black' : 'icon-arrow-down-black'">
          </div>
        </div>

        <div class="documents-container me-5 mt-4" *ngIf="isDisplay">
          <app-recent-files-list [recentFiles]="service.recentFiles">
          </app-recent-files-list>
        </div>
      </div>
    </ng-container>
    <div class="documentGroup m-5">
      <ng-container *ngIf="!service.navigate && !service.isSearchMode">
        <div id="titleDocuments" class="period ubuntu-bold font-size-24 mb-3">
          {{'DOCUMENT.DOCUMENTS' | translate}}
        </div>
      </ng-container>
      <ng-container *ngIf="service.navigate && !service.isSearchMode">
        <div class=" d-flex align-items-center mt-3">
          <div id="btnPrevious" type="button" class="icon icon-24 icon-arrow-left-red m-left" (click)="onBackClick()"></div>
          <div class="period ubuntu-bold font-size-24 flex-wrap align-items-center">
            <ng-container *ngFor="let path of service.currentPathList; last as isLast;">
              <span [ngClass]="{'breadCrumb-item-last' : isLast, 'breadCrumb-item' : !isLast}">{{path}}</span>
            </ng-container>
          </div>
        </div>
        <div *ngIf="service.currentFolder.description" class="period ubuntu-regular font-size-16 mb-3">
          {{service.currentFolder.description}}
        </div>
      </ng-container>
      <ng-container *ngIf="service.isSearchMode">
        <div class=" d-flex align-items-center mt-3">
          <div id="btnClearSearch" type="button" class="icon icon-24 icon-arrow-left-red m-left" (click)="onClearSearch()"> </div>
          <div class="period ubuntu-bold font-size-24 flex-wrap align-items-center">
            <span id="titleSearchResults" class=" breadCrumb-item-last">{{'DOCUMENT.LIST.SEARCH.RESULTS' | translate}}: "{{service.searchTerm}}"</span>
          </div>
        </div>
      </ng-container>
      <div class="documents-container">
        <app-training-list (selectedFolder)="onSelectFolder($event)" [navigate]="service.navigate">
        </app-training-list>
      </div>
    </div>
  </div>
</div>