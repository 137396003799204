import { Message } from 'src/app/shared/models/message/message';
import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageType } from 'src/app/shared/enums/message/messageType.enum';
import { DocumentsService } from '../../documents/documents.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {

  @Input()
  set message(message: Message) {
    this.privateMessage = message;
    if (message?.url) {
      this.urlDownload$ = this.documentService.getFilePath(message.url);
    }
  }
  get message(): Message {
    return this.privateMessage;
  }

  private privateMessage: Message;
  messageType = MessageType;
  urlDownload$: Observable<string>;

  constructor(
    private documentService: DocumentsService,
    public activeModal: NgbActiveModal
  ) {
   }
}
