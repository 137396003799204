import { NotificationModalComponent } from 'src/app/shared/components/notification-modal/notification-modal.component';
import { PunchInOutSettings } from './../../models/settings/punchInOutSettings/punchInOutSettings';
import { ScheduleSettings } from './../../models/settings/settings';
import * as moment from 'moment';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, DestroyRef, inject } from '@angular/core';
import { Shift } from 'src/app/shared/models/home/shift';
import { ShiftType } from 'src/app/shared/enums/home/shiftType.enum';
import { ShiftStatus } from 'src/app/shared/enums/home/shiftStatus.enum';
import { FrontActions } from 'src/app/shared/enums/actions.enum';
import { IPayloadShift } from 'src/app/shared/interfaces/payloadShift.interface';
import { PeriodType } from 'src/app/shared/enums/home/periodType.enum';
import { Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/user.service';
import { SnackbarService } from 'src/app/shared/components/snackbar/snackbar.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HomeService } from 'src/app/features/home/home.service';
import { CheckInConditionsComponent } from 'src/app/features/home/checkInConditions/checkInConditions.component';
import { CheckOutConditionsComponent } from 'src/app/features/home/checkOutConditions/checkOutConditions.component';
import { PunchInOutConditionsComponent } from 'src/app/features/home/punchInOutConditions/punchInOutConditions.component';
import { SettingsActivation } from '../../enums/setting/typology-settings/settingsActivation.enum';
import { CiCoStatusEnum } from '../../enums/home/check-in-out-status.enum';
import { Subscription } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
@Component({
  selector: 'app-calender-card',
  templateUrl: './calender-card.component.html',
  styleUrls: ['./calender-card.component.scss']
})
export class CalenderCardComponent implements OnInit, OnDestroy {

  @Input() checked = true;
  @Input() shiftData: Shift;
  @Input() period: PeriodType;
  @Input() date: string;
  @Input() modeDetails = false;
  @Input() shiftPartIndex: number;
  @Input() showHeader = true;
  @Input() serviceReference: string;
  @Output() actions: EventEmitter<{ actions: FrontActions, payload: IPayloadShift }> = new EventEmitter();
  typoSub: Subscription;
  deviceSub: Subscription;

  hovered = false;
  shiftType = ShiftType;
  shiftStatus = ShiftStatus;
  periodType = PeriodType;
  punchInOutStatus = SettingsActivation;
  messageWithoutDetails: string;
  scheduleSettings: ScheduleSettings;
  punchInOutSettings: PunchInOutSettings;

  checkInEnabled = false;
  checkOutEnabled = false;
  checkInDisabled = false;
  checkOutDisabled = false;

  // punchInOut in relation to the date
  punchInOutAvailable = false;
  punchInOutEnabled = false;

  deviceCheckInEnable = false;
  deviceCheckOutEnable = false;

  checkInValidating = false;
  canPunchChecking = false;

  displayedTooltipObservations = false;
  private destroyRef = inject(DestroyRef);

  get locale(): string {
    return this.userService.site.language.code.slice(0, 2);
  }

  get displayPunchInOut(): boolean {
    return !this.userService.driver
    && this.punchInOutAvailable
    && this.punchInOutEnabled
    && !!this.punchInOutSettings
    && this.punchInOutSettings.status === this.punchInOutStatus.ACTIVE;
  }

  constructor(
    private router: Router,
    private homeService: HomeService,
    public userService: UserService,
    private snackbarService: SnackbarService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.setAvailabilitiesFromTypoSettings();
    this.setAvailabilitiesFromDeviceSettings();
    this.messageWithoutDetails = this.getMessageWithoutDetails(this.shiftData.type);
  }
  // set availability of check in check out, punch in punch out
  setAvailabilitiesFromTypoSettings() {
    this.typoSub = this.userService.typologySetting
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(typoSettings => {
      this.scheduleSettings = typoSettings.scheduleSettings;
      this.punchInOutSettings = typoSettings.punchInOutSettings;
      if (this.punchInOutSettings.status === SettingsActivation.ACTIVE) {
        this.togglePunchInOut();
      }
      this.checkInEnabled = typoSettings.shiftSettings.checkIn.status === SettingsActivation.ACTIVE;
      this.checkOutEnabled = typoSettings.shiftSettings.checkOut.status === SettingsActivation.ACTIVE;
      this.checkInDisabled = typoSettings.shiftSettings.checkIn.status === SettingsActivation.INACTIVE;
      this.checkOutDisabled = typoSettings.shiftSettings.checkOut.status === SettingsActivation.INACTIVE;
      this.displayedTooltipObservations =
        typoSettings.scheduleSettings.displayedMissionObservation &&
        this.shiftData.hasComments &&
        this.period === PeriodType.DETAIL;
    });
  }

  // set availability of check in check out
  setAvailabilitiesFromDeviceSettings() {
    this.deviceSub = this.userService.typologyDeviceSettings
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(deviceSetting => {
        this.deviceCheckInEnable = deviceSetting.typologyDeviceSettingsData.checkIn;
        this.deviceCheckOutEnable = deviceSetting.typologyDeviceSettingsData.checkOut;
        this.punchInOutEnabled = deviceSetting.typologyDeviceSettingsData.punchInOut;
      });
  }

  getMessageWithoutDetails(shiftType: ShiftType): string {
    let message = '';

    switch (shiftType) {
      case ShiftType.REST:
        message = this.period === PeriodType.POSITION ?
        'PLANNING.CARDS.CONTENT.WITHOUT_DETAILS.WORKING_DAY_MESSAGE' :
        'PLANNING.CARDS.CONTENT.WITHOUT_DETAILS.REST_MESSAGE';
        break;
      case ShiftType.WORKING_DAY:
        message = 'PLANNING.CARDS.CONTENT.WITHOUT_DETAILS.WORKING_DAY_MESSAGE';
        break;
      case ShiftType.UNAVAILABLE:
        message = 'PLANNING.CARDS.CONTENT.WITHOUT_DETAILS.UNAVAILABLE_MESSAGE';
        break;
      case ShiftType.AVAILABLE:
        message = 'PLANNING.CARDS.CONTENT.WITHOUT_DETAILS.AVAILABLE_MESSAGE';
        break;
      case ShiftType.NO_PLANNING:
        message = 'PLANNING.CARDS.CONTENT.WITHOUT_DETAILS.NO_PLANNING_MESSAGE';
        break;
    }

    return message;
  }


  handleActions(action: FrontActions, payload: any) {
    this.actions.emit({ actions: action, payload });
  }

  navigateToDetails() {
    if (this.period === PeriodType.DETAIL) {
      this.router.navigate(['/planning/details'], { queryParams: { from: this.date } });
    }
  }

  verifyCheckIn() {
    this.homeService.verifyCheckIn(this.date, this.shiftPartIndex)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (ciCoStatus) => {
          if (ciCoStatus !== CiCoStatusEnum.SUCCESS) {
            this.snackbarService.show(`PLANNING.NOTIFICATIONS.CHECKIN.${ciCoStatus}`, 'error');
            return;
          }
          this.checkIn();
        },
        error: () => {
          this.snackbarService.show('PLANNING.NOTIFICATIONS.CHECKIN.GENERAL_ERROR', 'error');
        }
      }
      );
  }

  checkIn() {
    if (this.userService.shiftSettings.checkIn.conditionsEnabled) {
      const modalRef = this.modalService.open(CheckInConditionsComponent, {
        centered: true
      });
      modalRef.componentInstance.conditions = this.userService.shiftSettings.checkIn.conditionsText;

      modalRef.result.then(() => {
        this.validateCheckIn(this.date, this.shiftPartIndex);
      }).catch(() => {});
    } else {
      this.validateCheckIn(this.date, this.shiftPartIndex);
    }
  }

  validateCheckIn(shiftDate: string, shiftPartIndex: number) {
    this.checkInValidating = true;
    this.homeService.validateCheckIn(shiftDate, shiftPartIndex)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: data => {
          this.checkInValidating = false;
          if (data.checkInOutStatusType !== CiCoStatusEnum.SUCCESS) {
            this.snackbarService.show(`PLANNING.NOTIFICATIONS.CHECKIN.${data.checkInOutStatusType}`, 'error');
            return;
          }
          this.snackbarService.show('PLANNING.NOTIFICATIONS.CHECKIN.SUCCESS', 'success');
          this.shiftData.details.status = data.showCheckInOutType;
        },
        error: error => {
          this.checkInValidating = false;
          this.snackbarService.show(
            error.error
              ? `PLANNING.NOTIFICATIONS.CHECKIN.${error.error.checkInOutStatusType}`
              : 'PLANNING.NOTIFICATIONS.CHECKIN.GENERAL_ERROR', 'error');
        }
      }
      );
  }

  verifyCheckout() {
    this.homeService.verifyCheckOut(this.date, this.shiftPartIndex)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (ciCoStatus) => {
          if (ciCoStatus !== CiCoStatusEnum.SUCCESS) {
            this.snackbarService.show(`PLANNING.NOTIFICATIONS.CHECKOUT.${ciCoStatus}`, 'error');
            return;
          }

          const modalRef = this.modalService.open(CheckOutConditionsComponent, {
            centered: true
          });
          modalRef.componentInstance.shiftData = this.shiftData;
          (modalRef.componentInstance as CheckOutConditionsComponent).date = this.date;
          (modalRef.componentInstance as CheckOutConditionsComponent).shiftPartIndex = this.shiftPartIndex;
          (modalRef.componentInstance as CheckOutConditionsComponent).conditions = this.userService.shiftSettings.checkOut.conditionsText;
          (modalRef.componentInstance as CheckOutConditionsComponent).showConditions =
            this.userService.shiftSettings.checkOut.conditionsEnabled;
          (modalRef.componentInstance as CheckOutConditionsComponent).openedModalTime = {
            hour: new Date().getHours(),
            minute: new Date().getMinutes(),
            date: (new Date()).toString()
          };
          (modalRef.componentInstance as CheckOutConditionsComponent).cicoSuccess
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((shiftStatus: ShiftStatus) => {
            if (shiftStatus) {
              this.shiftData.details.status = shiftStatus;
            }
          });
        },
        error: () => {
          this.snackbarService.show('PLANNING.NOTIFICATIONS.CHECKOUT.GENERAL_ERROR', 'error');
        }
      }
      );
  }

  togglePunchInOut() {
    const currentDate = moment().format().toString().substring(0, 10);
    const previousDate = moment().subtract(1, 'days').format().toString().substring(0, 10);
    const nextDate = moment().subtract(-1, 'days').format().toString().substring(0, 10);
    if (this.date === currentDate  || this.date === previousDate || this.date === nextDate) {
      this.punchInOutAvailable = true;
    } else {
      this.punchInOutAvailable = false;
    }
  }

  openPunchInOut() {
    if (this.canPunchChecking) {
      return;
    }
    this.canPunchChecking = true;
    this.homeService.getCanPunch(this.date)
    .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: canPunch => {
          if (canPunch.canPunch) {
            const modalRef = this.modalService.open(PunchInOutConditionsComponent, {
              centered: true,
              size: 'xl'
            });
            modalRef.componentInstance.shiftData = this.shiftData;
            (modalRef.componentInstance as PunchInOutConditionsComponent).date = this.date;
          } else {
            const modalRef = this.modalService.open(NotificationModalComponent, {
              centered: true
            });
            modalRef.componentInstance.icon = 'icon-alert-red';
            modalRef.componentInstance.title = 'PLANNING.CARDS.FOOTER.PUNCH_IN_OUT';
            modalRef.componentInstance.message = 'PUNCH_IN_OUT.CONDITIONS.PUNCH_IN_OUT_ERROR_CAN_PUNCH';
          }
          this.canPunchChecking = false;
        },
        error: () => {
          this.canPunchChecking = false;
        }
      }
      );
  }

  ngOnDestroy(): void {
    this.typoSub.unsubscribe();
    this.deviceSub.unsubscribe();
  }
}
