import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { PlanningService } from 'src/app/features/planning/planning.service';
import { PlanningDetails } from 'src/app/shared/models/planning/planningDetails';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs';

@Component({
  selector: 'app-swap-service-detail',
  templateUrl: './swap-service-detail.component.html',
  styleUrls: ['./swap-service-detail.component.scss']
})
export class SwapServiceDetailComponent implements OnInit {
  @Input() date: moment.Moment;
  planningDetails: PlanningDetails;
  duration = 0;
  isloader = true;
  private route = inject(ActivatedRoute);
  private planningService = inject(PlanningService);
  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.getdetails(this.date.format('YYYY-MM-DD'));
  }

  getdetails(date: string) {
    this.planningService.getPlanningDetails(date)
    .pipe(
      tap( planningDetails => {
        this.planningDetails = planningDetails;
        this.getDuration(this.planningDetails);
        this.isloader = false;
    }),
      takeUntilDestroyed(this.destroyRef))
    .subscribe();
  }

  getDuration(planningDetails: PlanningDetails): number {
    planningDetails.shifts.forEach(shift => {
      if (shift.details !== null) {
        const departure = moment(shift?.details.departure?.date);
        const arrival = moment(shift?.details.arrival?.date);
        this.duration = this.duration + moment.duration(arrival.diff(departure)).as('minutes');
      }
    });
    return this.duration;
  }
}
