<div class="bg-primary-lightGrey swap-details" *ngIf="swapDetails">

    <div class="mobime-header bg-white">
        <div class="icon icon-24 icon-arrow-left-red pointer" (click)="getBack()"></div>
        <h1 class=" ubuntu-bold font-size-32 mb-0">{{ 'SWAP_REQUEST.TITLE' | translate }}</h1>
        <p class="ubuntu-bold font-size-16">
            {{ (swapDetails.type === swapTypeEnum.REST ? 'SWAP_REQUEST.DETAILS.TITLE_REST' : 'SWAP_REQUEST.DETAILS.TITLE_SERVICE') | translate }}
        </p>
        <div class="font-size-13 text-grey">
            {{ 'SWAP_REQUEST.DETAILS.TEXT' | translate }}
        </div>
    </div>

    <div class="swap-details-container position-relative">
        <div class="container-fluid">
            
            <ng-container *ngIf="swapDetails.askedDate">
                <p class="ubuntu-bold font-size-16 mb-4 ">{{ swapDetails.askedDate | dateFormat : 'long'}}</p>
                <div class="row position-relative mb-5">
                    <div class="bubble-swap-request d-flex justify-content-center align-items-center">
                        <div class="icon-swap-request-horizontal icon-24"></div>
                    </div>

                    <!-- sender shift -->
                    <div class="col-6">
                        <!-- sender view -->
                        <app-swap-details-card *ngIf="userService.adminNumber !== swapDetails.receiver.adminNumber"
                            [firstName]="swapDetails.sender.firstName"
                            [lastName]="swapDetails.sender.lastName"
                            [shift]="swapDetails.sender.workingShift"
                        ></app-swap-details-card>
                        <!-- receiver view -->
                        <app-swap-details-card *ngIf="userService.adminNumber === swapDetails.receiver.adminNumber"
                            [firstName]="swapDetails.receiver.firstName"
                            [lastName]="swapDetails.receiver.lastName"
                            [shift]="swapDetails.type === swapTypeEnum.SERVICE ? swapDetails.receiver.workingShift : null"
                        ></app-swap-details-card>
                    </div>
                    
                    <!-- receiver rest -->
                    <div class="col-6">
                        <!-- sender view -->
                        <app-swap-details-card *ngIf="userService.adminNumber !== swapDetails.receiver.adminNumber"
                            [firstName]="swapDetails.receiver.firstName"
                            [lastName]="swapDetails.receiver.lastName"
                            [shift]="swapDetails.type === swapTypeEnum.SERVICE ? swapDetails.receiver.workingShift : null"
                            [showName]="true"
                        ></app-swap-details-card>
                        <!-- receiver view -->
                        <app-swap-details-card *ngIf="userService.adminNumber === swapDetails.receiver.adminNumber"
                            [firstName]="swapDetails.sender.firstName"
                            [lastName]="swapDetails.sender.lastName"
                            [shift]="swapDetails.sender.workingShift"
                            [showName]="true"
                        ></app-swap-details-card>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="swapDetails.type === swapTypeEnum.REST && swapDetails.exchangedDate">
                <p class="ubuntu-bold font-size-16 mb-4 ">{{ swapDetails.exchangedDate | dateFormat : 'long'}}</p>
                <div class="row position-relative mb-5">
                    <div class="bubble-swap-request d-flex justify-content-center align-items-center">
                        <div class="icon-swap-request-horizontal icon-24"></div>
                    </div>

                    <!-- sender rest -->
                    <div class="col-6">
                        <!-- sender view -->
                        <app-swap-details-card *ngIf="userService.adminNumber !== swapDetails.receiver.adminNumber"
                            [firstName]="swapDetails.sender.firstName"
                            [lastName]="swapDetails.sender.lastName"
                        ></app-swap-details-card>
                        <!-- receiver view -->
                        <app-swap-details-card *ngIf="userService.adminNumber === swapDetails.receiver.adminNumber"
                            [firstName]="swapDetails.receiver.firstName"
                            [lastName]="swapDetails.receiver.lastName"
                            [shift]="swapDetails.receiver.workingShift"
                        ></app-swap-details-card>
                    </div>

                    <!-- receiver shift -->
                    <div class="col-6">
                        <!-- sender view -->
                        <app-swap-details-card *ngIf="userService.adminNumber !== swapDetails.receiver.adminNumber"
                            [firstName]="swapDetails.receiver.firstName"
                            [lastName]="swapDetails.receiver.lastName"
                            [shift]="swapDetails.receiver.workingShift"
                            [showName]="true"
                        ></app-swap-details-card>
                        <!-- receiver view -->
                        <app-swap-details-card *ngIf="userService.adminNumber === swapDetails.receiver.adminNumber"
                            [firstName]="swapDetails.sender.firstName"
                            [lastName]="swapDetails.sender.lastName"
                            [showName]="true"
                        ></app-swap-details-card>
                    </div>
                </div>
            </ng-container>

            <div *ngIf="swapDetails?.agentComment">
                <p class="ubuntu-bold font-size-16 mb-4">{{ 'SWAP_REQUEST.DETAILS.COMMENT' | translate }}</p>
                <div class="comment font-size-16 mb-4">
                    {{swapDetails.agentComment}}
                </div>
            </div>
            <div *ngIf="swapDetails.state === 'Pending' && swapDetails.receiver.adminNumber === userService.adminNumber"
                class="notification d-flex align-items-center position-absolute">
                <div class="icon icon-alert icon-24 me-2">
                </div>
                <div class="font-size-16 text-grey">
                    {{ 'SWAP_REQUEST.DETAILS.NOTIFICATION' | translate : {remainingDate:swapDetails.remainingDate | dateFormat : 'medium'} }}
                </div>

            </div>

            <h2 class="ubuntu-bold font-size-16 py-3">{{ 'SWAP_REQUEST.DETAILS.TRACKING_REQUEST' | translate }}</h2>
            <div class="state-tracking p-4 mb-4 font-size-13 bg-white">
                <div class="d-flex justify-content-end">
                    <div [attr.data-state]="swapDetails.state"
                        class="state-tracking-tag d-flex font-size-10 ubuntu-bold text-white text-uppercase align-items-center px-2 py-1 mb-3">
                        <ng-container
                            *ngIf="swapDetails.state === swapStateEnum.Pending && swapDetails.sender.adminNumber === userService.adminNumber">
                            <span class="icon icon-8 icon-clock-white me-2"></span>
                            {{ 'SWAP_REQUEST.HISTORY.PENDING_COLLEAGUE' | translate }}
                        </ng-container>
                        <ng-container
                            *ngIf="swapDetails.state === swapStateEnum.Pending && swapDetails.receiver.adminNumber === userService.adminNumber">
                            <span class="icon icon-8 icon-clock-white me-2"></span>
                            {{ 'SWAP_REQUEST.HISTORY.PENDING' | translate }}
                        </ng-container>
                        <ng-container *ngIf="swapDetails.state === swapStateEnum.Accepted">
                            <span class="icon icon-8 icon-checked-white me-2"></span>
                            {{ 'SWAP_REQUEST.HISTORY.ACCEPTED' | translate }}
                        </ng-container>
                        <ng-container
                            *ngIf="swapDetails.state === swapStateEnum.Rejected && swapDetails.sender.adminNumber === userService.adminNumber">
                            <span class="icon icon-8 icon-cross-white me-2"></span>
                            {{'SWAP_REQUEST.HISTORY.REFUSED_COLLEAGUE' | translate}}
                        </ng-container>
                        <ng-container
                            *ngIf="swapDetails.state === swapStateEnum.Rejected && swapDetails.receiver.adminNumber === userService.adminNumber">
                            <span class="icon icon-8 icon-cross-white me-2"></span>
                            {{'SWAP_REQUEST.HISTORY.DENIED' | translate}}
                        </ng-container>
                        <ng-container *ngIf="swapDetails.state === swapStateEnum.Refused">
                            <span class="icon icon-8 icon-cross-white me-2"></span>
                            {{'SWAP_REQUEST.HISTORY.DENIED_OPERATIONS' | translate}}
                        </ng-container>
                        <ng-container *ngIf="swapDetails.state === swapStateEnum.Expired">
                            <span class="icon icon-8 icon-cross-white me-2"></span>
                            {{'SWAP_REQUEST.HISTORY.EXPIRED' | translate}}
                        </ng-container>
                        <ng-container *ngIf="swapDetails.state === swapStateEnum.Exploit_Pending">
                            <span class="icon icon-8 icon-cross-white me-2"></span>
                            {{'SWAP_REQUEST.HISTORY.PENDING_OPERATIONS' | translate}}
                        </ng-container>
                    </div>
                </div>
                <p class="ubuntu-regular text-grey font-size-16">
                    <ng-container
                        *ngIf="swapDetails.state === swapStateEnum.Pending && swapDetails.sender.adminNumber === userService.adminNumber">
                        {{ 'SWAP_REQUEST.DETAILS.DESCRIPTION.PENDING_COLLEAGUE' | translate : {firstName:swapDetails.receiver.firstName, lastName:swapDetails.receiver.lastName} }}
                    </ng-container>
                    <ng-container
                        *ngIf="swapDetails.state === swapStateEnum.Pending && swapDetails.receiver.adminNumber === userService.adminNumber">
                        {{ 'SWAP_REQUEST.DETAILS.DESCRIPTION.PENDING' | translate : {firstName:swapDetails.sender.firstName, lastName:swapDetails.sender.lastName} }}
                    </ng-container>
                    <ng-container *ngIf="swapDetails.state === swapStateEnum.Accepted">
                        {{ 'SWAP_REQUEST.DETAILS.DESCRIPTION.ACCEPTED' | translate }}
                        <div *ngIf="swapDetails.exploitComment !== null && swapDetails.exploitComment.length !== 0">
                            <p class="ubuntu-bold text-black font-size-16 mt-3 mb-3">
                                {{ 'SWAP_REQUEST.DETAILS.COMMENT' | translate }}
                            </p>
                            <div class="state-tracking-comment text-black font-size-16 p-4">
                                {{swapDetails.exploitComment}}
                            </div>
                        </div>
                    </ng-container>
                    <ng-container
                        *ngIf="swapDetails.state === swapStateEnum.Rejected && swapDetails.sender.adminNumber === userService.adminNumber">
                        {{'SWAP_REQUEST.DETAILS.DESCRIPTION.REFUSED_COLLEAGUE' | translate : {firstName:swapDetails.receiver.firstName, lastName:swapDetails.receiver.lastName} }}
                    </ng-container>
                    <ng-container
                        *ngIf="swapDetails.state === swapStateEnum.Rejected && swapDetails.receiver.adminNumber === userService.adminNumber">
                        {{'SWAP_REQUEST.DETAILS.DESCRIPTION.DENIED' | translate}}
                    </ng-container>
                    <ng-container *ngIf="swapDetails.state === swapStateEnum.Refused">
                        {{'SWAP_REQUEST.DETAILS.DESCRIPTION.DENIED_OPERATIONS' | translate}}
                        <div *ngIf="swapDetails.exploitComment !== null && swapDetails.exploitComment.length !== 0">
                            <p class="ubuntu-bold text-black font-size-16 mt-3 mb-3">
                                {{ 'SWAP_REQUEST.DETAILS.COMMENT' | translate }}
                            </p>
                            <div class="state-tracking-comment text-black font-size-16 p-4">
                                {{swapDetails.exploitComment}}
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="swapDetails.state === swapStateEnum.Expired">
                        {{'SWAP_REQUEST.DETAILS.DESCRIPTION.EXPIRED' | translate}}
                    </ng-container>
                    <ng-container *ngIf="swapDetails.state === swapStateEnum.Exploit_Pending && swapDetails.sender.adminNumber === userService.adminNumber">
                        {{'SWAP_REQUEST.DETAILS.DESCRIPTION.PENDING_OPERATIONS' | translate : {firstName:swapDetails.receiver.firstName, lastName:swapDetails.receiver.lastName} }}
                    </ng-container>
                    <ng-container *ngIf="swapDetails.state === swapStateEnum.Exploit_Pending && swapDetails.receiver.adminNumber === userService.adminNumber">
                        {{'SWAP_REQUEST.DETAILS.DESCRIPTION.PENDING_OPERATIONS_ME' | translate : {firstName:swapDetails.sender.firstName, lastName:swapDetails.sender.lastName} }}
                    </ng-container>
                </p>
            </div>
        </div>
    </div>

    <div *ngIf="swapDetails.state === swapStateEnum.Pending" class="swap-nav d-flex justify-content-center align-items-center px-5">
        <div class="swap-nav__container d-flex"
            [ngClass]="swapDetails.state === swapStateEnum.Pending && swapDetails.sender.adminNumber === userService.adminNumber ? 'justify-content-center' : 'justify-content-between'"
        >
            <ng-container *ngIf="swapDetails.state === swapStateEnum.Pending && swapDetails.receiver.adminNumber === userService.adminNumber">
                <button id="refuse-button" class="btn btn-white ubuntu-bold font-size-16"
                    (click)="refuse()"
                >
                    {{'SWAP_REQUEST.DETAILS.REFUSE_BUTTON' | translate}}
                </button>
                <button id="accept-button" class="btn btn-black ubuntu-bold font-size-16"
                    (click)="accept()"
                >
                    {{'SWAP_REQUEST.DETAILS.ACCEPT_BUTTON' | translate}}
                </button>
            </ng-container>

            <button *ngIf="swapDetails.state === swapStateEnum.Pending && swapDetails.sender.adminNumber === userService.adminNumber"
                id="cancel-button"
                class="btn btn-black ubuntu-bold font-size-16"
                (click)="cancel()"
            >
                {{'SWAP_REQUEST.DETAILS.CANCEL_BUTTON' | translate}}
            </button>
        </div>
    </div>

</div>
