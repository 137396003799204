export enum CiCoStatusEnum {
    SUCCESS = 'OK',
    TOO_EARLY = 'TOO_EARLY',
    TOO_LATE = 'TOO_LATE',
    WRONG_DAY = 'WRONG_DAY',
    ALREADY_DONE = 'ALREADY_DONE',
    NOT_A_DRIVER = 'NOT_A_DRIVER',
    CHECK_IN_DISABLE = 'CHECK_IN_DISABLE'
}

