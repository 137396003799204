<div class="counters-request-content bg-primary-lightGrey">
  <div class="mobime-header ubuntu-regular bg-white">
    <h1 class="ubuntu-bold font-size-32 mb-3">{{ 'ABSENCE_REQUEST.DAY_ABSENCE' | translate }}</h1>
    <p class="ubuntu-bold font-size-16 description-padding">{{ 'ABSENCE_REQUEST.REMAINING_SLOTS' | translate }}</p>
    <p class="ubuntu-regular font-size-16 text-grey description-padding">{{ 'ABSENCE_REQUEST.AVAILABILITY_FULLDAY' | translate }}</p>
  </div>
  <div class="absence-reminder">
    <div class="app-carousel-absence-counters py-2">
      <swiper-container appSwiper 
      #swiper 
      [config]="swiperConfig" 
      (slidechange)="slideChange($event)">
      <swiper-slide *ngFor="let slot of remainingSlots;">
        <div class="bg-white br-4 p-3 card-slot">
          <p class="ubuntu-bold font-size-16"> {{slot.date | dateFormat : 'medium' }} </p>
          <p class="ubuntu-regular font-size-13 text-grey">
              <ng-container *ngIf="slot.value">
              {{slot.value}} {{ 'ABSENCE_REQUEST.REMAINING_SLOTS' | translate | lowercase }}
              </ng-container>
            </p>
          </div>
      </swiper-slide>
      </swiper-container>
    </div>
  </div>

  <form [formGroup]="counterRequestForm" class="counters-request-content__form">
    <div class="p-4 d-flex flex-column bg-white">
      <div class="px-3">
        <h3 class="ubuntu-bold font-size-16 pt-3">{{ 'ABSENCE_REQUEST.WISHED_ABSENCE_TYPE' | translate }}</h3>
        <p class="ubuntu-regular font-size-13 text-grey"> {{ 'ABSENCE_REQUEST.SELECT_COUNTER' | translate }} </p>
        <ng-container *ngFor="let counter of counters">
          <label class="d-flex ps-5 mb-2 pointer my-2" [for]="counter.code">
            <div class="position-relative me-5 mt-2">
              <input class="counter-input-radio" type="radio" formControlName="counter" name="counter" [value]="counter.code" [id]="counter.code">
              <div class="radio-check-container d-flex justify-content-center align-items-center">
                <div class="icon icon-16 icon-checked-white"></div>
              </div>
            </div>
            <div class="d-flex flex-column my-2">
              <span *ngIf="counter.label" class="ubuntu-regular font-size-16"> {{counter.code}} ({{counter.label}})</span>
              <span  class="ubuntu-regular font-size-13 text-grey"> 
                <span *ngIf="counter.unit !== 'H'" >{{counter.balance}}</span>
                <span *ngIf="counter.unit === 'H'">{{ counter.balance | hoursMinutesConverter }}</span>
                <ng-container *ngIf="counter.unit === 'H'">
                    {{ 'ABSENCE_REQUEST.TYPE_FORM_REMAINING_HOUR' | translate}}
                </ng-container>
                <ng-container *ngIf="counter.unit === 'J'">
                    {{'ABSENCE_REQUEST.TYPE_FORM_REMAINING_DAYS' | translate}}
                </ng-container>
              </span>
            </div>
          </label>
        </ng-container>
        <!-- AUTOMATIC_LEAVE -->
        <label class="d-flex ps-5 mb-2 pointer my-2" [for]="'AUTOMATIC_LEAVE'" *ngIf="isAutomaticAbsence">
          <div class="position-relative me-5 mt-2">
            <input class="counter-input-radio" type="radio" formControlName="counter" name="counter" [value]="'AUTOMATIC_LEAVE'" [id]="'AUTOMATIC_LEAVE'">
            <div class="radio-check-container d-flex justify-content-center align-items-center">
              <div class="icon icon-16 icon-checked-white"></div>
            </div>
          </div>
          <div class="d-flex flex-column my-2">
            <span class="ubuntu-regular font-size-16"> {{'ABSENCE.COUNTERS.AUTOMATIC_LEAVE' | translate}} </span>
          </div>
        </label>
        <!-- AUTOMATIC_ABSENCE -->
        <label class="d-flex ps-5 mb-2 pointer my-2" [for]="'AUTOMATIC_ABSENCE'" *ngIf="isAutomaticLeave">
          <div class="position-relative me-5 mt-2">
            <input class="counter-input-radio" type="radio" formControlName="counter" name="counter" [value]="'AUTOMATIC_ABSENCE'" [id]="'AUTOMATIC_ABSENCE'">
            <div class="radio-check-container d-flex justify-content-center align-items-center">
              <div class="icon icon-16 icon-checked-white"></div>
            </div>
          </div>
          <div class="d-flex flex-column my-2">
            <span class="ubuntu-regular font-size-16"> {{'ABSENCE.COUNTERS.AUTOMATIC_ABSENCE' | translate}} </span>
          </div>
        </label>
      </div>

      <div class="mt-5 pb-3 px-3">
        <h3 class="ubuntu-bold font-size-16 pt-3">{{ 'ABSENCE_REQUEST.ADD_COMMENT' | translate }}</h3>
        <textarea id="comment" class="w-100 font-size-13 p-3" formControlName="comment" maxlength="2000" [placeholder]="'ABSENCE_REQUEST.ADD_COMMENT_PLACEHOLDER' | translate">
        </textarea>
      </div>
    </div>
  </form>
</div>


<div class="absence-nav d-flex justify-content-center align-items-center px-5">
  <div class="absence-nav__container d-flex justify-content-between">
    <button (click)="goToPrevStep()" [disabled]="fullDayAbsenceRequestService.absence.groupingId" class="btn button btn-white ubuntu-bold font-size-16">
      {{'COMMON.BUTTON.PREVIOUS' | translate}}
    </button>
    <button (click)="goToNextStep()" [disabled]="counterRequestForm.invalid"
    class="btn button btn-black ubuntu-bold font-size-16">
      {{'COMMON.BUTTON.CONTINUE' | translate}}
    </button>!
  </div>
</div>

