<div class="planning">
  <div class="mobime-header">
    <div class="d-flex flex-row mt-5">
      <h1 class="ubuntu-bold font-size-32 mb-5">{{'PLANNING.MY_SERVICE' | translate}}</h1>
      <h1 class="ubuntu-bold font-size-16 mb-5 ms-5 mt-4">{{'PLANNING.LABEL' | translate}}</h1>
      <div class="planning-mode">
        <a class="planning-mode-link ubuntu-bold font-size-16 mb-5 me-5" [ngClass]="{'planning-mode-link-inactive':calendarType !== 'CALENDAR'}" (click)="setCalendarType('CALENDAR')">{{'PLANNING.CALENDAR' | translate}}</a>
        <a class="planning-mode-link ubuntu-bold font-size-16 mb-5" [ngClass]="{'planning-mode-link-inactive':calendarType !== 'WEEK'}" (click)="setCalendarType('WEEK')">{{'PLANNING.RESUME' | translate}}</a>
      </div>
      <div *ngIf="calendarType === 'WEEK'" class="d-flex flex-row ms-auto me-4 mt-2">
        <label class="ubuntu-bold font-size-16 me-4 mt-3">{{'PLANNING.WEEKS' | translate}} : </label>
        <ng-select [items]="weeks" [closeOnSelect]="false" class="font-size-16 mt-2" [hideSelected]="true" [clearable]="false"
          bindLabel="name" [(ngModel)]="selectedWeek" (change)="getSelectedOption($event)">
          <ng-template ng-option-tmp let-item="item" ng-label-tmp let-index="index">
            <div title="item">
              {{'PLANNING.FROM' | translate}} {{item.first | dateFormat :'short'}} {{'PLANNING.TO' | translate}} {{item.last | dateFormat :'short'}}
            </div>
          </ng-template>
        </ng-select>
        <div class="d-flex flex-row ms-4 mt-4">
          <div class="arrow arrow-left me-4" [ngClass]="{'disabled': selectedWeek?.id === 0 }" (click) ="decreaseDate()"></div>
          <div class="arrow arrow-right ml-4" [ngClass]="{'disabled': selectedWeek?.id === weeks?.length - 1 }" (click) ="increaseDate()"></div>
        </div>
      </div>
    </div>
    <p class="mb-4 ubuntu-bold font-size-13 text-black">{{'PLANNING.LEGEND' | translate}}</p>
    <div class="d-flex legend">
      <div class="square me-2"></div>
      <span class="ubuntu-regular font-size-13 text-charcoal-grey">
        {{'PLANNING.DATE_NOT_FINAL' | translate}}
      </span>
    </div>
  </div>

  <div *ngIf="calendarType === 'CALENDAR'" id="planning" class="bg-primary-lightGrey padding-40 planning-container" #planningContainer>
     <app-plannig-year [scheduleSettings] = "scheduleSettings"></app-plannig-year>
  </div>

  <div *ngIf="calendarType === 'WEEK'" id="planning-mode" class="bg-primary-lightGrey planning-container">
    <app-planning-weeks [datesOfWeek]="selectedWeek"></app-planning-weeks>
  </div>
</div>
