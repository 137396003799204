<div class="forbidden-access d-flex">
  <div class="forbidden-access__text-side col-6 d-flex align-items-center justify-content-center">
    <div>
      <p class="ubuntu-bold font-size-32 mb-4">
        {{ 'FORBIDDEN_ACCESS.TITLE' | translate }}
      </p>
      <p class="text-grey font-size-16">
        {{ 'FORBIDDEN_ACCESS.TEXT_PART_1' | translate }}
      </p>
      <p class="text-grey font-size-16">
        {{ 'FORBIDDEN_ACCESS.TEXT_PART_2' | translate }}
      </p>
    </div>


  </div>
  <div class="forbidden-access__red-side col-6 d-flex align-items-center justify-content-center">
    <!-- Old -->
    <!--
    <div class="arrows"></div>

    <div>
      <div class="bus mx-auto"></div>
      <div class="mobime mx-auto"></div>
      <div class="d-flex justify-content-start align-items-center">
        <div class="by text-white font-size-10 ml-5 me-2">{{ 'FORBIDDEN_ACCESS.BY' | translate }}</div>
        <div class="logo"></div>
      </div>
    </div>
    -->

  </div>
</div>