import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-swap-comment',
  templateUrl: './swap-comment.component.html',
  styleUrls: ['./swap-comment.component.scss']
})
export class SwapCommentComponent {
  @Input() title: string;
  @Input() comment: string;
  @Output() inputComment = new EventEmitter();

  i18nPrefix = 'SWAP_REQUEST.COMMENT.';

  control(event: string) {
    if (event.length > 2000) {
      event.substr(0, 2000);
    }
    this.inputComment.emit(event);
  }
}
