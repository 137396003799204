import { Route } from '@angular/router';
import { NewsDetailsComponent } from './components/news-details/news-details.component';
import { NewsAccessService } from 'src/app/shared/services/routes-activate/news-access.service';

const NewsRoutes: Route = {
  path: 'news/:id',
  component: NewsDetailsComponent,
  canActivate: [NewsAccessService],
};

export {
  NewsRoutes
};
