import {Component, Input, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { NewsListItemModel } from 'src/app/shared/models/news/newsListItem.model';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-news-list-item',
  templateUrl: './news-list-item.component.html',
  styleUrls: ['./news-list-item.component.scss']
})
export class NewsListItemComponent implements OnInit {
  /**
   * Model instance of newsItemModel
   * @see NewsListItemModel
   * @see NewsService.lists
   */
  @Input() item: NewsListItemModel;
  // Index of the item in the news list, Used to style to first item
  @Input() index: number;
  // Used to display the heading image
  get lang() {
    return this.userService.site.language.code.substring(0, 2);
  }

  constructor(
    private userService: UserService,
    private router: Router,
  ) {}

  ngOnInit() {
    // set the alternative src image if the article does not have an url
    this.verifyImageUrl();
  }

  /**
   * This function will set a placeholder image
   * if the article item doesn't have a main image
   * @see NewsListItemModel.imgUrl
   */
  private verifyImageUrl() {
    if (!this.item.imgUrl) {
      this.item.imgUrl = '/assets/images/news/no-image-article.png';
    }
  }

  navigate() {
    if (this.item.rss) {
      window.open(this.item.url, '_blank');
      return;
    }
    this.router.navigate(['news', this.item.id]);
  }
}
