import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent {

  @Input() title: string;
  @Input() message: string;
  @Input() icon: string;
  @Input() btnAccept = 'COMMON.CONFIRM_MODAL.ACCEPT';
  @Input() btnDeny = 'COMMON.CONFIRM_MODAL.DENY';

  constructor(public activeModal: NgbActiveModal) {}
}
