
<div class="body position-relative ubuntu-regular">
  <div class="sidebar position-fixed" [ngClass]="{'collapsed':isCollapsed}">
      <div class="oval position-absolute pointer" (click)="isCollapsed = !isCollapsed">
          <div class="icon icon-12" [ngClass]="(isCollapsed)?'icon-menu-right':'icon-menu-left'"></div>
      </div>
      <app-sidebar [isCollapsed]="isCollapsed" (collapsedMenu)="uncollapseMenu()"></app-sidebar>
  </div>

  <div class="main-container" [ngClass]="{'collapsed':isCollapsed}">
      <div class="header fixed-top" [ngStyle]="{'left': isCollapsed ? '8rem' : '24rem'}">
          <app-header></app-header>
      </div>
      <div class="content">
          <router-outlet></router-outlet>
      </div>
  </div>
</div>


<app-snackbar></app-snackbar>
