export const storageKeys = {
  DUE_DATE_MODAL_EXPIRY: 'dueDateModalExpiryDate'
};

export const dateFormatslanguages = [
  {
    label: 'Français (France)',
    code: 'fr-FR',
    mini: 'DD-MM',
    short: 'DD/MM/YYYY',
    medium: 'DD MMMM YYYY',
    long: 'dddd DD MMMM YYYY',
  },
  {
    label: 'Français (Canada)',
    code: 'fr-CA',
    mini: 'DD-MM',
    short: 'DD/MM/YYYY',
    medium: 'DD MMMM YYYY',
    long: 'dddd DD MMMM YYYY',
  },
  {
    label: 'English (USA)',
    code: 'en-US',
    mini: 'MM-DD',
    short: 'MM/DD/YYYY',
    medium: 'MMMM DD YYYY',
    long: 'dddd MMMM DD YYYY',
  },
  {
    label: 'English (Australia)',
    code: 'en-AU',
    mini: 'DD-MM',
    short: 'DD/MM/YYYY',
    medium: 'DD MMMM YYYY',
    long: 'dddd DD MMMM YYYY',
  },
  {
    label: 'English (New Zealand)',
    code: 'en-NZ',
    mini: 'DD-MM',
    short: 'DD/MM/YYYY',
    medium: 'DD MMMM YYYY',
    long: 'dddd DD MMMM YYYY',
  },
  {
    label: 'English (Ireland)',
    code: 'en-IE',
    mini: 'DD-MM',
    short: 'DD/MM/YYYY',
    medium: 'DD MMMM YYYY',
    long: 'dddd DD MMMM YYYY',
  }
];

export const absenceCountersCodes = {
  AUTOMATIC_ABSENCE: 'AUTOMATIC_ABSENCE',
  AUTOMATIC_LEAVE: 'AUTOMATIC_LEAVE'
};

export const units = [
  'bytes',
  'KB',
  'MB',
  'GB',
  'TB',
  'PB'
];

export const size = 1024;

export const countries = [
  {
    code: 'au',
    label: 'AUS/NZ'
  },
  {
    code: 'ca',
    label: 'Canada'
  },
  {
    code: 'fr',
    label: 'France'
  },
  {
    code: 'ie',
    label: 'Ireland'
  },
  {
    code: 'nz',
    label: 'New Zealand'
  },
  {
    code: 'us',
    label: 'USA'
  }
];

export const PROVIDER_LOGIC_NAME = '40105';
