export enum State {
    PENDING = 'PENDING',
    VALIDATED = 'VALIDATED',
    REFUSED = 'REFUSED'
}

export enum AbsenceStatusEnum {
    OPEN = 'OPEN',
    CLOSE = 'CLOSED',
    WAITING = 'PENDING',
    UNDESIRABLE = 'UNDESIRABLE'
}

