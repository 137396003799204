<div class="volonteer-counters counters-request-content bg-primary-lightGrey">
  <div class="ubuntu-regular bg-white">
    <div class="header">
      <h1 class="mobime-header ubuntu-bold font-size-32 mb-3">{{ 'VOLUNTEER.SELECT.TITLE' | translate }}</h1>
    </div>
    <div class="mt-5 py-5 absence-reminder">
      <div class="description-padding">
        <p class="ubuntu-bold font-size-24 mb-3 text-white">{{ 'ABSENCE_REQUEST.PLANNING_RAPPEL' | translate }}
        </p>
        <div class="app-carousel-volunteer-counters col-12 ms-3 py-2">
          <swiper-container appSwiper 
          #swiper 
          [config]="swiperConfig" 
          (slidechange)="slideChange($event)">
            <swiper-slide *ngFor="let planning of plannings; index as indexPlanning">
              <div class="bg-white d-flex flex-column font-size-16 py-3 px-4 card-planning"  id="card-{{indexPlanning}}">
                <span class="ubuntu-bold my-3">
                  {{ planning.date | dateFormat : 'medium' | titlecase}}
                </span>
                <ng-container *ngIf="planning?.period === 'PREVIEW' || planning?.period === 'DETAIL'; else cardTitle">
                <div class="row" *ngFor="let shift of planning.shifts">
                  <ng-container *ngIf="shift.details; else displayLabel">
                      <div class="col-6 d-flex">
                          {{ shift.details?.departure?.date | hourFormat:'h':'HH:mm' }}
                          <div class="icon icon-16 icon-arrow-right m-2"></div>
                          {{ shift.details?.arrival?.date | hourFormat:'h':'HH:mm' }}</div>
                          <div class="col-6" *ngIf="shift.reference === '-1'">{{shift.partLabel}} </div>
                        <div class="col-6 d-flex" *ngIf="shift.reference !== '-1'">Service {{shift.reference}} </div>
                  </ng-container>
                  <ng-template #displayLabel>
                    <span class="ms-5">{{shift.label}}</span>
                  </ng-template>
                </div>
                </ng-container>
                <ng-template #cardTitle>
                    <ng-container [ngSwitch]="planning.shifts[0].type">
                        <ng-container *ngSwitchCase="volunteerWorkTypeEnum.AVAILABLE">
                          {{'PLANNING.CARDS.SUBTITLE.DAY_AVAILABLE' | translate}}
                        </ng-container>
                        <ng-container *ngSwitchCase="volunteerWorkTypeEnum.UNAVAILABLE">
                          {{'PLANNING.CARDS.SUBTITLE.DAY_UNAVAILABLE' | translate}}
                        </ng-container>
                        <ng-container *ngSwitchCase="volunteerWorkTypeEnum.NO_PLANNING">
                            {{'PLANNING.CARDS.SUBTITLE.SCHEDULE_NOT_AVAILABLE' | translate}}
                          </ng-container>
                        <ng-container *ngSwitchCase="volunteerWorkTypeEnum.REST">
                          {{'PLANNING.CARDS.SUBTITLE.DAY_REST' | translate}}
                        </ng-container>
                        <ng-container  *ngSwitchCase="volunteerWorkTypeEnum.WORKING">
                          {{'PLANNING.CARDS.SUBTITLE.DAY_WORKED' | translate}}
                        </ng-container>
                        <ng-container  *ngSwitchCase="volunteerWorkTypeEnum.WORKING_DAY">
                            {{'PLANNING.CARDS.SUBTITLE.DAY_WORKED' | translate}}
                        </ng-container>
                      </ng-container>
                </ng-template>
              </div>
            </swiper-slide>
        </swiper-container>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="absence-nav d-flex justify-content-center align-items-center px-5">
  <div class="absence-nav__container d-flex justify-content-between">
    <button (click)="goToPrevStep()" class="btn button btn-white ubuntu-bold font-size-16">
      {{'COMMON.BUTTON.PREVIOUS' | translate}}
    </button>
    <button id="cancel-button" *ngIf="!hasOnlyPendingDay" (click)="cancel()"
      class="btn button btn-black ubuntu-bold font-size-16">
      {{'VOLUNTEER.REQUEST.DELETE' | translate}}
    </button>
    <button (click)="validate()" *ngIf="hasOnlyPendingDay" class="btn button btn-black ubuntu-bold font-size-16">
      {{'COMMON.BUTTON.CONFIRM' | translate}}
    </button>
  </div>
</div>
